import { ADD_ADMIN, ADD_ADMIN_SUCCESS, ADD_ADMIN_FAIL, ADMIN_LOADING } from "./actionType"

const initialState = {
  error: "",
  success: "",
  loading: false
}

const AddAdmin = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADMIN:
      state = {
        ...state,
      }
      break
    case ADD_ADMIN_SUCCESS:
      state = { ...state, success: action.payload,loading: false }
      break
    case ADD_ADMIN_FAIL:
      state = { ...state, error: action.payload,loading: false }
      break
    case ADMIN_LOADING:
      state ={
        loading: action.payload
      }
  }
  return state
}

export default AddAdmin
