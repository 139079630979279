import React from 'react';
import { useForm } from 'react-hook-form';
import Select from "react-select";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from "yup";
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';

const TrainingEdit = ({ setTrainConfig, setShowDetails }) => {
    const basicInputValues = {
        sampleDataset: '',
        documentApproved: '',
        percentage:'',
        epochs:'',
        training:''


    };
    const basicSchema = Yup.object({
        modelName: Yup.string().required("Enter a model name").max(40, "Should not exceed 40 characters"),
        documentType: Yup.string().required("Enter a last name").max(40, "Should not exceed 40 characters"),
    })

    const { register, handleSubmit, formState: { errors, }, } = useForm({
        resolver: yupResolver(basicSchema),
        defaultValues: basicInputValues
    });

    const onSubmit = (values) => {
        console.log(values);

    }
    const options = [
        { value: 'Image', label: 'Image' },
        { value: 'PDF', label: 'PDF' },
        { value: 'DOC', label: 'DOC' }
    ]
    return (
        <>
            <div className='models-layout'>
                <form
                    className="form-horizontal custom-input"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Card>
                        <CardBody className='b-radius-x-0'>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">Sample Dataset</Label><br />
                                        <span>Select the date from where you want to select sample files</span>
                                        <div className='drop-down'>
                                <Select
                                    options={options}
                                    className={`form-control p-0`}
                                    classNamePrefix="custom_select"
                                    placeholder={'All Approved Files'}
                                    // menuIsOpen={true}
                                    search={true}
                                />
                            </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">Document Approved After</Label><br />
                                        <span>Select the date from where you want to select sample files</span>
                                        <input type="text" {...register("documentApproved")} className={errors.documentApproved?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.documentApproved?.message ? <p className={`invalid-feedback`} >{errors.documentApproved?.message}</p> : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">Sampling Percentage</Label><br />
                                        <span>Set percentage to define the sample size</span>
                                        <input type="text" {...register("percentage")} className={errors.percentage?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.percentage?.message ? <p className={`invalid-feedback`} >{errors.percentage?.message}</p> : null}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">No. of Epochs</Label><br />
                                        <span>No. of epoch for training. Please enter value between 5-50</span>
                                        <input type="text" {...register("epochs")} className={errors.epochs?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.epochs?.message ? <p className={`invalid-feedback`} >{errors.epochs?.message}</p> : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">No. of Documents in Training</Label><br />
                                        <span>No. of approved documents after sampling must be atleast 20</span>
                                        <input type="text" {...register("training")} className={errors.training?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.training?.message ? <p className={`invalid-feedback`} >{errors.training?.message}</p> : null}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
            <div className='filter-btn download-btn d-flex'>
                <SecountryBtn btnName="Back" showIcon={false} onClick={() => {setTrainConfig(false) }} />
                <PrimaryButton btnName="Train" showIcon={false} onClick={() => {setShowDetails(true) }} />
            </div>
                </form>
            </div>
        </>
    );
};

export default TrainingEdit;