import React from 'react';
import { Modal } from 'reactstrap';

const CustomDatePicker = ({ showModal, toggle, close }) => {
    return (
        <div>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='doc-modal create-folder'
                toggle={toggle}>
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>Select Date</h1>
                        <span onClick={close} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body p-0">
                    <input type='text' className='form-control m-0' />
                </div>
            </Modal>
        </div>
    );
};

export default CustomDatePicker;