import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardTitle,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik, Formik } from "formik"
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AddNew, getAdminById, getAdminRoles } from "../../store/actions"
import { useParams } from "react-router-dom"
import SelectSearch from "react-select-search";
import PageLoader from '../../components/Common/PageLoader';
import { adminFormResetFieldTouched } from "constants/ResetFormField"
import Select, { createFilter } from "react-select";
import { checkUserAsSuperAdmin } from "../../constants/utils";
import { getAdminByIdSuccess } from "store/actions";

const AdminForm = props => {
  let { id } = useParams()
  const [roleValue, setroleValue] = useState(null);
  const [rolelistvalue, setrolelistvalue] = useState(null);
  const [userData, setUserData] = useState(null)
  document.title = `${!id ? "Add New Admin" : "Update Admin"} | Yenmin AI`

  const [selectedGroup, setselectedGroup] = useState(null)
  const dispatch = useDispatch()
  const { roles } = useSelector(state => ({
    roles: state?.GetAdminRoles?.roles,
  }))

  // Form validation
  const adminForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters'),
      lastName: Yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters'),
      email: Yup.string().email('Enter valid email address').required("Enter email address").max(30, 'Email address should not exceed 30 characters'),
      phoneNumber: Yup.string().required("Enter a phone number").max(15, 'Phone number should not exceed 15 characters'),
      role: Yup.string().required("Select a role"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (id) {
        dispatch(AddNew(values, props.history, id))
      } else {
        dispatch(AddNew(values, props.history))
      }
    },
  })
  const getUserData = () => {
    dispatch(getAdminById(id))
  }
  const { admin } = useSelector(state => ({
    admin: state.GetAdminById.user,
  }))

  useEffect(() => {
    if (id) {
      getUserData()
    }
  }, [id])
  useEffect(() => {
    setUserData(admin)
  }, [admin])

  useEffect(() => {
    setFormValues()
  }, [userData])
  const loading = useSelector(state => ({
    loaderstatus: state.AddAdmin.loading
  }))
  function setRoleValue(role) {
    setselectedGroup(role);
    adminForm.setFieldValue("role", role);
  }
  const roleList = roles && roles?.map((role) => ({
    name: role.label,
    value: role.value,
  }));

  useEffect(() => {
    if (roles) setrolelistvalue([{ label: "Select role", value: '' }, ...roles])
  }, [roles])


  function setrolevalue(role) {
    const value = { label: role, value: role }
    setroleValue(value);
    adminForm.setFieldValue("role", role);
  }

  const currentUser = JSON.parse(localStorage.getItem("authUser"));
  const setFormValues = () => {
    if (id && admin) {
      adminForm.setFieldValue("firstName", admin.firstName)
      adminForm.setFieldValue("lastName", admin.lastName)
      adminForm.setFieldValue("email", admin.email)
      adminForm.setFieldValue("phoneNumber", admin.phoneNumber)
      adminForm.setFieldValue("role", admin.role)
      setrolevalue(admin.role)
    }
  }

  return (
    <React.Fragment>
      {loading.loaderstatus ? <PageLoader /> : ""}
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admins" dashurl="/admin" breadcrumbItem={!id ? "Add New Admin" : "Update Admin"}
          />
         
          <Row>
            <Col xl="12">
              <Form
                className="needs-validation"
                onSubmit={e => {
                  e.preventDefault()
                  adminForm.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">First name <span>*</span></Label>
                          <Input
                            name="firstName"
                            type="text"
                            className="form-control"
                            onChange={adminForm.handleChange}
                            onBlur={adminForm.handleBlur}
                            value={adminForm.values.firstName || ""}
                            invalid={
                              adminForm.touched.firstName &&
                                adminForm.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {adminForm.touched.firstName &&
                            adminForm.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {adminForm.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Last name <span>*</span></Label>
                          <Input
                            name="lastName"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={adminForm.handleChange}
                            onBlur={adminForm.handleBlur}
                            value={adminForm.values.lastName || ""}
                            invalid={
                              adminForm.touched.lastName &&
                                adminForm.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {adminForm.touched.lastName &&
                            adminForm.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {adminForm.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="">Email <span>*</span></Label>
                          <Input
                            name="email"
                            type="text"
                            className="form-control"
                            disabled={id && admin && checkUserAsSuperAdmin(admin)}
                            onChange={adminForm.handleChange}
                            onBlur={adminForm.handleBlur}
                            value={adminForm.values.email || ""}
                            invalid={
                              adminForm.touched.email && adminForm.errors.email
                                ? true
                                : false
                            }
                          />
                          {adminForm.touched.email && adminForm.errors.email ? (
                            <FormFeedback type="invalid">
                              {adminForm.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label>Role <span>*</span></Label>
                          <div className={`position-relative drop-down ${adminForm.touched.role && adminForm.errors.role ? 'role' : ''}`}>
                            <Select
                              options={rolelistvalue}
                              className={`form-control p-0`}
                              classNamePrefix="custom_select"
                              // menuIsOpen={true}
                              search={true}
                              name="role"
                              isDisabled={id && admin && checkUserAsSuperAdmin(admin)}
                              filterOption={createFilter({ matchFrom: 'start' })}
                              value={roleValue}
                              placeholder={`Select role`}
                              onChange={(value) => {
                                setrolevalue(value.value);
                              }}
                            />
                          </div>
                          {adminForm.touched.role && adminForm.errors.role ? (
                            <span className="role-error invalid-feedback">{adminForm.errors.role}</span>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="">Phone Number <span>*</span></Label>
                          <Input
                            name="phoneNumber"
                            onWheel={(e) => e.target.blur()}
                            type='number'
                            className="form-control"
                            onChange={adminForm.handleChange}
                            onBlur={adminForm.handleBlur}
                            value={adminForm.values.phoneNumber || ""}
                            invalid={
                              adminForm.touched.phoneNumber &&
                                adminForm.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                          {adminForm.touched.phoneNumber &&
                            adminForm.errors.phoneNumber ? (
                            <FormFeedback type="invalid">
                              {adminForm.errors.phoneNumber}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody >
                </Card >
                <div className="mb-30">
                  <button className="primary-button" type="submit">
                    Save
                  </button>
                  <button type="reset" onClick={() => {
                    if (id) {
                      setFormValues()
                      adminFormResetFieldTouched(false, adminForm)
                    } else {
                      adminForm.resetForm()
                      setselectedGroup(undefined)
                      setroleValue(null)
                    }
                  }} className="ms-4 secountry-btn">
                    Clear
                  </button>
                </div>
              </Form >
            </Col >
          </Row >
        </Container >
      </div >

    </React.Fragment >
  )
}

export default AdminForm
