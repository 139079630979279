import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import TableContainer from 'components/Common/TableContainer';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';

import pageicon from '../../../assets/images/sm-image.png';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import downloadRow from '../../../assets/images/icons/dwinload-list.svg';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import SearchBar from 'components/Common/SearchBar';
import DatePickerDropDown from 'components/Common/DatePickerDropDown';

const TrainingDetails = ({setShowDetails}) => {
    const [isCheckVisible, setCheckVisible] = useState(false);
    const [date, setDate] = useState(null);
  const handleButtonClick = () => {
    setCheckVisible(!isCheckVisible);
  };
    const columns = useMemo(
        () => [
            {
                Header: ' ',
                Cell: cellProps => { 
                    return (
                        <div>
                            <div onClick={handleButtonClick}>
                                <p className='m-0' >{cellProps?.row?.index + 1}</p>
                            </div>
                             <div className="form-check">
                            {isCheckVisible && 
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                />
                            }
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: '  ',
                Cell: cellProps => {
                    return (
                        <div>
                            <i className='bx bxs-file'></i>
                        </div>
                    )
                }
            },
            {
                Header: '    ',
                Cell: cellProps => {
                    return (
                        <div>
                            <div>
                                <img src={pageicon} alt='page' width='30%' />
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'File Name',
                sort: true,
                accessor: 'fileName'
            },
            {
                Header: 'Review Status',
                Cell: cellProps => {
                    return (
                        <div className={`file-status ${cellProps?.row?.original?.status}`}>
                           <p>{cellProps.row.original?.status}</p>
                        </div>
                    )
                }
               
            },
            {
                Header: 'Assigned to',
                sort: true,
                accessor: 'assignedTo'
            },
            {
                Header: 'Models',
                accessor: 'models'
            },
            {
                Header: 'Uploaded by',
                accessor: 'uploadedby'
            },
            {
                Header: 'Approved by',
                accessor: 'approvedby'
            },
            {
                Header: 'Uploaded on',
                Cell: cellProps => {
                    return (
                        <div>
                           {moment(cellProps.row.original?.uploadedOn).format('DD/MM/YYYY')}
                        </div>
                    )
                }
            },
            {
                Header: '     ',
                Cell: cellProps => {
                    return (
                        <div className=''>
                            <div className="more-action">
                                <i className='bx bx-dots-horizontal-rounded font-size-18'></i>
                            </div>
                            <div className="file-action-btns">
                                <ul className='d-flex p-0'>
                                <li><img src={downloadRow} alt='downloadRow' /></li>
                                    <li><img src={deleteIcon} alt='delete-icon' /></li>
                                </ul>
                            </div>
                           
                        </div>
                    )
                }
            },
        ],
        []
    );

    const data = [
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "fileName": "Invoice-01",
            "status": "Approved",
            "assignedTo": "Jones",
            "models": "Jones",
            "uploadedby": "James",
            "approvedby": "Jones",
            "uploadedOn": "2023-05-18T13:53:45Z"
        }
             
    ];
    return (
        <>
            <Card>
                <CardBody className='b-radius-x-0'>
                       <div>
                          
                             <div className='table-filters'>
                                        <div className=''>
                                            <h5 className='m-0 table-heading'>Shipping Label_V1</h5>
                                        </div>
                                        <div className='filters-group'>
                                            <SearchBar />
                                            < DatePickerDropDown setDate={setDate} />


                                        </div>
                                    </div>
                            <TableContainer
                                columns={columns}
                                data={data}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={10}
                                iSPageOptions={false}
                                customPagination={true}
                                className="custom-header-css my-doc"
                            />
                        </div>
                   
                </CardBody>
            </Card>
            <div className='filter-btn download-btn d-flex'>
                <SecountryBtn btnName="Back" showIcon={false} onClick={() => { setShowDetails(true) }} />
            </div>
      </>
    );
};

export default TrainingDetails;