import { STATUS, STATUS_FAIL, STATUS_SUCCESS } from "./actionType"

export const statusChange = (status, history, id, url) => {
  return {
    type: STATUS,
    payload: { status, history, id, url },
  }
}

export const statusSuccess = user => {
  return {
    type: STATUS_SUCCESS,
    payload: user,
  }
}
export const statusError = error => {
  return {
    type: STATUS_FAIL,
    payload: error,
  }
}
