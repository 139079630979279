import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ADD_ADMIN } from "./actionType"
import { addNewAdminError, addNewAdminSuccess,adminLoading } from "./action"
//Include Both Helper File with needed methods
import { postNewAdmin } from "../../../helpers/backend_helper"
import "toastr/build/toastr.min.css"
import toast from "react-hot-toast";

function* newAdmin({ payload: { user, history, id } }) {
  try {
    const data = {}
    data["firstName"] = user.firstName
    data["lastName"] = user.lastName
    data["email"] = user.email
    data["phoneNumber"] = user.phoneNumber
    data["role"] = user.role
    if (id) {
      data["userId"] = id
    }
    yield put(adminLoading(true))
    const response = yield call(postNewAdmin, data)
    yield put(adminLoading(false))
    if (response.status === 'error') {
      toast.error(response.message)
    } else {
      toast.success(response.message)
      yield put(addNewAdminSuccess(response))
      history.push("/admin");
    }
  } catch (error) {
    yield put(adminLoading(false))
    yield put(addNewAdminError(error))
    toast.error(error.response.data.message)
  }
}

function* AddAdminSaga() {
  yield takeEvery(ADD_ADMIN, newAdmin)
}

export default AddAdminSaga
