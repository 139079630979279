import { ADD_ADMIN, ADD_ADMIN_FAIL, ADD_ADMIN_SUCCESS,ADMIN_LOADING } from "./actionType"

export const AddNew = (user, history, id) => {
  return {
    type: ADD_ADMIN,
    payload: { user, history, id },
  }
}

export const addNewAdminSuccess = user => {
  return {
    type: ADD_ADMIN_SUCCESS,
    payload: user,
  }
}
export const addNewAdminError = error => {
  return {
    type: ADD_ADMIN_FAIL,
    payload: error,
  }
}
export const adminLoading = (loading) => {
  return {
    type: ADMIN_LOADING,
    payload: loading,
  }
}
