import React, { memo, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import exportFromJSON from 'export-from-json';

//Component
import TableContainer from 'components/Common/TableContainer';

//Assets
import pageicon from '../../../assets/images/sm-image.png';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import downloadRow from '../../../assets/images/icons/dwinload-list.svg';
import uploadDoc from '../../../assets/images/icons/upload-doc.svg';
import tableFileIcon from '../../../assets/images/icons/table-file.svg';
import { useDispatch, useSelector } from 'react-redux';
import { assignDocToUser, deleteDocument, getAssignedUsers, getDocCountByStatus, getDownloadDocument, getNyDocuments, getNyDocumentsSuccess, reUplaod } from 'store/actions';
import TableSkeleton from 'components/Common/TableSkeleton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Confirmation from 'components/Common/Modals/Confirmation';
import { downloadDocuments } from 'constants/downloadDocuments';
import ImageViewModel from 'components/Common/Modals/ImageViewModel';
import imagePath from '../../../assets/images/emptyImage.png'
import { UncontrolledTooltip } from 'reactstrap';
import Loader from 'components/Common/Loader';
import { downloadRecord } from 'components/Common/Modals/DownloadModal';
import axios from 'axios';
import { useSocket } from 'App';
import { GET_MY_DOCUMENTS } from 'helpers/application_url_helper';
import { post } from 'helpers/api_helper';

const AllFiles = ({ setAssignUsers, assignUsers, subUrl, filter, company_id, documentsData, setDocumentsData, setSelectFile, translate, setAdvancedConf, modelData, setModelData, setCurrentPage, currentPage, refresh }) => {
    const [sortBy, setsortBy] = useState(null)
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [imageOpen, setImageOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [isHovered, setIsHovered] = useState('');
    const [showInfoModel, setShowInfoModel] = useState(false);
    const [success, setSuccess] = useState(null);
    const [documents, setDocuments] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const dataPerPage = process.env.REACT_APP_TABLE_DATA_PER_PAGE

    const socket = useSocket();

    const docData = useSelector(state => state.GetMyDocuments?.data?.data)
    const assignUserLoader = useSelector(state => state.AssignUser?.loading)
    const deleteDcumentLoader = useSelector(state => state.DeleteDocument?.loading)
    const uploadFileLoading = useSelector(state => state.DocumentUpload?.loading);
    const myDocLoader = useSelector(state => state.GetMyDocuments?.loading);
    const reUploadLoader = useSelector(state => state.ReUpload?.loading);
    const deleteDocumentLoader = useSelector(state => state.DeleteDocument?.loading);

    const getRole = JSON.parse(localStorage.getItem("authUser"));

    const dispatch = useDispatch()
    const history = useHistory()

    const getDocuments = (data, subUrl) => {
        dispatch(getNyDocuments(data, subUrl))
    }
    useEffect(() => {
        setDocuments([])
    }, [subUrl]);

    useEffect(() => {
        if (assignUsers && documents?.length > 0) {
            dispatch(assignDocToUser({ documents: documents, assingedTo: assignUsers }, dispatch, getAssignedUsers))
        }
    }, [assignUsers])

    useEffect(() => {
        if (!assignUserLoader && assignUsers) {
            const data = getDocumentsData()
            if (data) getDocuments(data, subUrl)
            setAssignUsers(null);
            setDocuments([]);
            setSelectFile(0);
        }
    }, [assignUserLoader])

    useEffect(() => {
        if (company_id && !deleteDcumentLoader) {
            const data = getDocumentsData()
            if (data) {
                getDocuments(data, subUrl)

            }
        }
    }, [deleteDcumentLoader, company_id, subUrl, currentPage, sortBy, filter, refresh])

    const getDocumentsData = () => {
        const data = {}
        if (sortBy) data['sort'] = sortBy
        else data['sort'] = {
            value: -1,
            field: 'createdAt',
            type: 'date'
        }
        data['currentPage'] = currentPage
        data['dataPerPage'] = dataPerPage
        data['filter'] = filter
        data['company_id'] = company_id
        return data
    }

    const putDocuments = (id, e, modelId) => {
        let doc = documents

        const index = doc.indexOf(id);

        if (e.target.checked === false) {
            if (index === -1) {
                doc.push(id)
            }
        } else {
            if (index > -1) {
                doc.splice(index, 1)
            }
        }
        setDocuments(doc)
        setDocumentsData(documents)
        setSelectFile(documents?.length)

        // Get modelId From Advanced Configuration
        let modelitem = modelData;
        const modelndex = modelitem.indexOf(modelId);
        if (e.target.checked === false) {
            if (modelndex === -1) {
                modelitem.push(modelId)
            }
        } else {
            if (modelndex > -1) {
                modelitem.splice(modelndex, 1);
            }
        }
        setModelData(modelitem)
        setAdvancedConf(() => {
            if (modelData?.length === 1) {
                return true;
            } else {
                return false;
            };
        })
    }
    //End

    const handleDownload = (downloadData, type) => {
        const record = downloadRecord(downloadData);
        const resultData = downloadDocuments(record?.newdownloadData, type)
        exportFromJSON({
            data: resultData?.data, fileName: resultData?.fileName, exportType: resultData?.type
        })
    }

    //Individual Row Data Download
    const handleExportRow = (id) => {
        dispatch(getDownloadDocument({ data: [id] }, 'download', handleDownload))
    };

    const callGetDocuments = () => {
        const data = getDocumentsData();
        if (data) getDocuments(data, subUrl);
    };

    const handleReUpload = (id) => {
        const data = {
            document_id: id
        }
        dispatch(reUplaod(data, callGetDocuments, 'my-documents'));
    };

    //Select Table List
    const checkTableData = (tabledata, e) => {
        let doc = documents;
        let modelId = [];
        tabledata?.map((getId) => {
            const index = doc.indexOf(getId?._id);
            if (e.target.checked === false) {
                if (!modelId.includes(getId?.model_id)) {
                    modelId.push(getId?.model_id);
                }
                if (index === -1) {
                    doc.push(getId?._id);
                };
            } else {
                modelId = [];
                if (index > -1) {
                    doc.splice(index, 1);
                };
            };
        });
        setModelData(modelId)
        if (e.target.checked === false) {
            setDocumentsData(doc)
            setDocuments(doc)
            setSelectFile(doc?.length)
        } else {
            setDocumentsData([])
            setDocuments([])
            setSelectFile(0)
        }
    }

    useEffect(() => {
        setDocumentsData(documents);
    }, [documents]);

    const toggleImageModel = () => {
        setImageOpen(!imageOpen)
    }

    const toggleInfoModal = () => {
        setShowInfoModel(!showInfoModel)
    }

    const columns = useMemo(
        () => [
            {
                // Header checkbox column
                id: 'selection',
                Header: ({ cellProps }) => {
                    let checked = [];
                    docData?.pageData?.map((item) => {
                        if (documents?.includes(item?._id)) {
                            checked.push(true);
                        } else {
                            checked.push(false);
                        };
                    });
                    return (
                        <div className='checkbox-head'>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={checked?.includes(false) ? false : true}
                                onClick={(e) => {
                                    checkTableData(docData?.pageData, e);
                                }}
                                onChange={(e) => { }}
                            />
                        </div>
                    )
                },
                Cell: cellProps => (
                    (documents?.includes(cellProps?.row?.original?._id) || isHovered === cellProps?.row?.original?._id) &&
                        (cellProps.row.original?.status !== 'FAILED' && cellProps.row.original?.status !== 'IN_PROGRESS') ?
                        <div className='check-row'>
                            <input
                                className="form-check-input check-box-input"
                                type="checkbox"
                                id={cellProps?.row?.original?._id}
                                checked={documents?.includes(cellProps?.row?.original?._id) ? true : false}
                                onClick={(e) => {
                                    putDocuments(cellProps?.row?.original?._id, e, cellProps?.row?.original?.model_id);
                                    // handleDcumentData(cellProps?.row?.original)
                                }}
                                onChange={() => { }}
                            />
                        </div>
                        : <div className='check-row'><p className='m-0 ps-1'>{((currentPage - 1) * dataPerPage) + (cellProps?.row?.index + 1)}</p></div>
                ),
            },
            // {
            //     Header: '  ',
            //     Cell: cellProps => {
            //         return (
            //             <div>
            //                 <img src={tableFileIcon} alt='tableFileIcon' width={'16px'} />
            //             </div>
            //         )
            //     }
            // },
            // {
            //     Header: '    ',
            //     Cell: cellProps => {
            //         return (
            //             <div className='file-preview'>
            //                 <div className='image-overlay' onClick={() => {
            //                     setImage({
            //                         path: cellProps?.row?.original?.path || imagePath,
            //                         name: cellProps.row.original?.fileName || 'document-image'
            //                     });
            //                     setImageOpen(true);
            //                 }}><i className='mdi mdi-eye'></i></div>
            //                 <img style={{ cursor: 'pointer' }}
            //                     className='rounded'
            //                     src={cellProps?.row?.original?.path}
            //                     alt={cellProps.row.original?.fileName}
            //                     width="50px"
            //                 />
            //                 {/* <img src={pageicon} alt='page' width="40px" /> */}

            //             </div>
            //         )
            //     }
            // },
            {
                Header: `${translate('fileName')}`,
                customSort: true,
                enableSort: true,
                accessor: 'fileName',
                id: 'fileName',
                sortType: 'string',
                Cell: cellProps => <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.fileName}</div>
            },
            {
                Header: `${translate('model')}`,
                customSort: true,
                enableSort: true,
                accessor: 'model_details.name',
                subField: 'name',
                id: 'model_details',
                sortType: 'string',
                Cell: cellProps => <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.model_details?.name}</div>
            },
            {
                Header: `${translate('documentType')}`,
                customSort: true,
                enableSort: true,
                sortType: 'string',
                accessor: 'docType',
                id: 'docType',
                Cell: cellProps => <div className='text-capitalize' onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.docType.toLowerCase()}</div>
            },
            {
                Header: `${translate('reviewStatus')}`,
                customSort: true,
                enableSort: true,
                id: 'status',
                sortType: 'string',
                Cell: cellProps => {
                    return (
                        <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} className={`file-status ${cellProps?.row?.original?.status?.toLowerCase()}`}>
                            <p>{cellProps.row.original?.status?.toLowerCase()?.replaceAll("_", " ")}</p>
                        </div>
                    )
                }
            },
            {
                Header: `${translate('assignedTo')}`,
                customSort: true,
                enableSort: true,
                accessor: 'assignedPersonDetails.fullName',
                subField: 'fullName',
                id: 'assignedPersonDetails',
                sortType: 'string',
                Cell: cellProps => <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.assignedPersonDetails?.fullName}</div>
            },
            {
                Header: `${translate('uploadedBy')}`,
                customSort: true,
                enableSort: true,
                sortType: 'string',
                accessor: 'creatorDetails.fullName',
                id: 'creatorDetails',
                subField: 'fullName',
                Cell: cellProps => <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.creatorDetails?.fullName}</div>
            },
            {
                Header: `${translate('approvedBy')}`,
                customSort: true,
                enableSort: true,
                sortType: 'string',
                accessor: 'approvedPersonDetails.fullName',
                id: 'approvedPersonDetails',
                subField: 'fullName',
                Cell: cellProps => <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.approvedPersonDetails?.fullName}</div>
            },
            {
                Header: `${translate('uploadedOn')}`,
                customSort: true,
                sortType: 'date',
                enableSort: true,
                id: "createdAt",
                Cell: cellProps => {
                    return (
                        <div onClick={() => handleRowClick(cellProps.row.original)} style={{ cursor: 'pointer' }}>
                            {cellProps.row.original?.createdAt ? moment(cellProps.row.original?.createdAt).format('DD/MM/YYYY') : ''}
                        </div>
                    )
                }
            },
            {
                Header: '     ',
                Cell: cellProps => {
                    return (
                        <div className='' style={{ width: '100px' }}>
                            <div className="more-action">
                                <i className='bx bx-dots-horizontal-rounded font-size-18'></i>
                            </div>
                            <div className="file-action-btns">
                                {/* reUplaod */}
                                {cellProps.row.original?.status !== 'IN_PROGRESS' &&
                                    <ul className='d-flex p-0'>
                                        {
                                            cellProps.row.original?.status === 'FAILED' ?
                                                <li id={`re-upload_${cellProps.row.original?._id}`} onClick={() => { handleReUpload(cellProps.row.original?._id) }}>
                                                    <img src={uploadDoc} alt='uploadDoc' />
                                                    <UncontrolledTooltip placement="top" target={`re-upload_${cellProps.row.original?._id}`}>
                                                        Re-upload
                                                    </UncontrolledTooltip>
                                                </li> :
                                                <li id={`download_${cellProps.row.original?._id}`} onClick={() => { handleExportRow(cellProps.row.original?._id) }}>
                                                    <img src={downloadRow} alt='downloadRow' />
                                                    <UncontrolledTooltip placement="top" target={`download_${cellProps.row.original?._id}`}>
                                                        Download
                                                    </UncontrolledTooltip>
                                                </li>
                                        }
                                        {getRole?.user?.role !== "USER" && <li id={`delete_${cellProps.row.original?._id}`} onClick={() => handleDelete(cellProps.row.original?._id, cellProps.row.original?.company_id)}>
                                            <img src={deleteIcon} alt='delete-icon' />
                                            <UncontrolledTooltip placement="top" target={`delete_${cellProps.row.original?._id}`}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </li>}
                                    </ul>
                                }
                            </div>
                        </div>
                    )
                }
            },
        ],
        [documents, docData, isHovered]
    );

    const handleRowClick = (data) => {
        if (data?.status === 'IN_PROGRESS') {
            setShowInfoModel(true);
            setSuccess(true);
        } else if (data?.status === 'FAILED') {
            setShowInfoModel(true);
            setSuccess(false);
        } else {
            history.push("/my-document/" + data._id);
        }
    }

    const deleteDocumentData = () => {
        if (documentId && companyId) dispatch(deleteDocument(documentId, dispatch, getDocCountByStatus, companyId))
        setConfirmModal(false)
    }

    const handleDelete = (id, companyId) => {
        setDocumentId(id);
        setCompanyId(companyId)
        setConfirmModal(true)
    }

    function toggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    useEffect(() => {
        // Check if socket is initialized before using it
        if (socket) {
            // Listen for real-time user list updates
            socket.on('mydocumentUpdated', async () => {
                try {
                    const data = getDocumentsData();
                    if (data) {
                        const response = await post(GET_MY_DOCUMENTS + '/' + subUrl, data);
                        dispatch(getNyDocumentsSuccess(response));
                    }
                } catch (error) {
                    console.log("error: ", error);
                }
            });

            return () => socket.off('mydocumentUpdated');
        }
    }, [socket, getDocumentsData]);

    return (
        <>
            {!myDocLoader && !assignUserLoader && !reUploadLoader && docData ? "" : <Loader />}
            <TableContainer
                columns={columns}
                data={docData?.pageData || []}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={dataPerPage}
                iSPageOptions={true}
                className="custom-header-css my-doc"
                sortBy={sortBy}
                setsortBy={setsortBy}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                customPagination={true}
                totalCount={docData?.totalCount} //total count of records in Database
                setIsHovered={setIsHovered}
            />

            <Confirmation
                showModal={isConfirmModal}
                toggle={() => toggleModal()}
                primaryAction={() => deleteDocumentData()}
                modalTitle="Delete document"
                primaryBtn="Delete"
                secountBtn="Cancel"
                description="Are you sure you want to delete this document?"
                loader={deleteDocumentLoader}
            />

            <ImageViewModel
                toggle={() => toggleImageModel()}
                showModal={imageOpen}
                path={image?.path}
                name={image?.name}
            />

            {/* document view when status in-progress or failed*/}
            <Confirmation
                showModal={showInfoModel}
                toggle={toggleInfoModal}
                primaryAction={() => toggleInfoModal()}
                modalTitle={`Info`}
                primaryBtn={"Ok"}
                hideCancel={true}
                description={success === true ? 'The document is under processing, please try to view in some other time.' : 'The document upload is failed, please re-upload the document.'}
            />
        </>
    );
};

export default AllFiles;