import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";
import {
    getActivitiesFail,
    getActivitiesSuccess,
} from "./actions";
import { GET_ACTIVITIES } from "./actionTypes";
import { getActivities } from "helpers/backend_helper";

//Get Plans
function* getactivities({ payload: { id, subUrl, data } }) {
    try {
        const response = yield call(getActivities, id, subUrl, data);
        if (response.status?.toUpperCase() === responseStatus.error) {
            toast.error(response.message);
            yield put(getActivitiesFail(error));
        } else if (response.status?.toUpperCase() === responseStatus.success) {
            yield put(getActivitiesSuccess(response));
        };
    } catch (error) {
        yield put(getActivitiesFail(error));
    };
};

export function* GetActivitiesSaga() {
    yield takeEvery(GET_ACTIVITIES, getactivities);
};