import React, { memo } from 'react';
import AuthLoader from '../AuthLoader';

const SecountryBtn = ({ btnType, btnName, btnIcon, showIcon, onClick, loader = false }) => {
    return (
        <>
            <button className={`btn-rounded btn-common secountry-btn ${loader ? "p-0" : ""}`} type={btnType} onClick={onClick}>
                {loader ? <AuthLoader /> : <>
                    {showIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
                    <span>{btnName}</span>
                </>}
            </button>
        </>
    );
};

export default memo(SecountryBtn);