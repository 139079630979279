import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

//Components
import { getDocumentById, getDownloadDocument } from 'store/actions';
import { useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'components/Common/Breadcrumb';
import ReviewPage from 'constants/Review/ReviewPage';
import { withTranslation } from 'react-i18next';
import { groupLabeledTexts } from 'constants/Review/utils/jsonConvertion';

const Review = (props) => {
    const { t } = props;
    document.title = `${t("reviewModel")} | ${t('appName')}`;

    const [documentDetails, setDocumentDetails] = useState();

    const { id } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const dbDocumentDetails = useSelector(state => state?.GetDocumentById?.data?.data);
    const documentLoading = useSelector(state => state?.GetDocumentById?.loading);
    const approveDocumentLoading = useSelector(state => state?.ApproveDocument?.loading);
    const fieldwiseApproveLoader = useSelector(state => state?.FieldwiseApproveDocument?.loading);

    const previousPage = () => {
        history.push('/my-document');
    };

    useEffect(() => {
        if (id) {
            dispatch(getDocumentById(id));
            dispatch(getDownloadDocument({ data: [id] }));
        };

        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
        return () => {
            body.style.overflowY = 'auto';
        };
    }, [id]);

    useEffect(() => {
        const labels = dbDocumentDetails?.document?.result_data?.labels || [];
        const labeledTexts = dbDocumentDetails?.document?.result_data?.labeled_texts || [];
        const groupedData = groupLabeledTexts(labels, labeledTexts, dbDocumentDetails?.document);
        let newLabels = [];
        dbDocumentDetails?.document?.model_details?.fields?.labels?.map((item => {
            const filtered = groupedData?.filter((filterItem) => filterItem?.labelName?.toLowerCase() === item?.Name?.toLowerCase()?.split(" ")?.join("_"))
            if (filtered.length > 0) {
                newLabels.push(filtered[0]);
            }
        }))        
        setDocumentDetails({
            ...dbDocumentDetails,
            fieldDetails: newLabels
        });
    }, [dbDocumentDetails])

    return (
        <React.Fragment>
            <div className='review-layout' id='review-layout'>
                <div className="page-content" >
                    <Container fluid className="p-0">
                        <div className='review-breadcrumb'>
                            <Breadcrumb title={t('myDocuments')} dashurl="/my-document" breadcrumbItem={documentDetails?.document?.fileName + ": " + "Review model"} goBack={true} documentLoading={documentLoading} />
                        </div>

                        <ReviewPage documentDetails={documentDetails} documentLoading={documentLoading || approveDocumentLoading} previousPage={previousPage} id={id} translate={t} fieldLoading={fieldwiseApproveLoader} />

                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Review));