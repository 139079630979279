//Get Models Doc
export const GET_MODELS_DOC = "GET_MODELS_DOC";
export const GET_MODELS_DOC_SUCCESS = "GET_MODELS_DOC_SUCCESS";
export const GET_MODELS_DOC_FAIL = "GET_MODELS_DOC_FAIL";

//Delete models
export const DELETE_MODELS_DOC = "DELETE_MODELS_DOC";
export const DELETE_MODELS_DOC_SUCCESS = "DELETE_MODELS_DOC_SUCCESS";
export const DELETE_MODELS_DOC_FAIL = "DELETE_MODELS_DOC_FAIL";

//get model document by id
export const GET_MODEL_DOC_BY_ID = "GET_MODEL_DOC_BY_ID";
export const GET_MODEL_DOC_BY_ID_SUCCESS = "GET_MODEL_DOC_BY_ID_SUCCESS";
export const GET_MODEL_DOC_BY_ID_FAIL = "GET_MODEL_DOC_BY_ID_FAIL";

//train document
export const TRAIN_DOCUMENT = "TRAIN_DOCUMENT";
export const TRAIN_DOCUMENT_SUCCESS = "TRAIN_DOCUMENT_SUCCESS";
export const TRAIN_DOCUMENT_FAIL = "TRAIN_DOCUMENT_FAIL";