import {
    GET_ACTIVITIES,
    GET_ACTIVITIES_SUCCESS,
    GET_ACTIVITIES_FAIL,
} from "./actionTypes";
//Get Models
export const getActivities = (id, subUrl, data) => {
    return {
        type: GET_ACTIVITIES,
        payload: { id, subUrl, data },
    };
};
export const getActivitiesSuccess = (id) => {
    return {
        type: GET_ACTIVITIES_SUCCESS,
        payload: id,
    };
};
export const getActivitiesFail = (error) => {
    return {
        type: GET_ACTIVITIES_FAIL,
        payload: error,
    };
};
