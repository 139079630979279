import axios from "axios"; 
import accessToken from "./jwt-token-access/accessToken";
import {browserHistory} from 'react-router'
// import authHeader from './jwt-token-access/auth-token-header'
// pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_APP_BACKEND_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});
 if(token) {
  axiosApi.defaults.headers.common["Authorization"] = token;
 }

 
// axiosApi.interceptors.response.use(
//   response => response,
//   error =>{    
//     if(error.response.status === 400){ 
//       // return window.location.href = '/badrequest';
//     }

//     if(error.response.status === 401){ 
//       // return window.location.href = '/unauthorized';
//     }
//     if(error.response.status === 404){ 
//       // return window.location.href = '/notfound';
//     }

//     if(error.response.status === 500){ 
//       // return window.location.href = '/servererror';
//     }


//     Promise.reject(error)
//   }
// );

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

export function clearToken() {
  axiosApi.defaults.headers.common["Authorization"] = "";
}

export function setToken() {
  const accessToken = `Bearer ${localStorage.getItem('jwt')}`
  axiosApi.defaults.headers.common["Authorization"] = accessToken;
}
