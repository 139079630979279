import * as d3 from "d3";

const removeLeaderLine = () => {
    // d3.selectAll("svg").attr("class", "leader-line").remove();

    // Select all SVG elements with the class 'leader-line'
    const leaderLines = d3.selectAll("svg.leader-line");

    // Check if any elements are selected
    if (!leaderLines.empty()) {
        leaderLines.remove();
    }
}

export default removeLeaderLine