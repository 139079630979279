import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';

const MonthInvoice = ({ data, loader, translate }) => {
  const [series, setseries] = useState([{ name: '', data: [] }])
  const [categories, setcategories] = useState([''])

  useEffect(() => {
    if (data) {
      setseries(data?.data || [])
      setcategories(data?.categories || [])
    }
  }, [data])

  const options = {

    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      markers: {
        width: 17,
        height: 17,
        radius: 0,
      },
    },
    chart: {

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
    },
    colors: ['#ED593A','#FF7B51','#BF4329', '#FFAE8B'],
    tooltip: {
      fillSeriesColor: true,
      theme: false,
      followCursor: true,
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      x: {
        show: false,
      },
    },
    grid: {
      borderColor: "#E1E1E1",
    },
    xaxis: {
      categories: categories,
    },
  }


  return (
    <div className='chats-bg bar-chats'>
      {loader ? <><Skeleton height={"20px"} width={'180px'} />
        <Skeleton height={"88%"} width={'100%'} className='mt-4' />
      </> :
        <><h4>{translate('monthwise')}</h4>
          <ReactApexChart options={options} series={series} type="bar" height="350" />
        </>}
    </div>
  )
};

export default MonthInvoice;