/* eslint-disable react/no-unknown-property */
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import RadioButton from 'components/Common/RadioButton';
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import { Toggle } from 'rsuite';

import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card,
     CardBody, Label, Row } from 'reactstrap';
     import deleteIcon from '../../../assets/images/icons/delete.svg';


const ApproveStages = () => {
    const [open, setOpen] = useState("0");
    const [isRule, setisRule] = useState("0");
    const [selectFilter, setSelectFilter] = useState(null);
    const [isSwitch, setSwitch] = useState(false);

    const { register, control, handleSubmit, reset, trigger, setError } = useForm({
        // defaultValues: {}; you can populate the fields by this attribute 
        defaultValues: {
            rule: [{ value: "chocolate" }],
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "test"
    });
    const {
        fields: ruleFields,
        append: ruleAppend,
        remove: ruleRemove
    } = useFieldArray({ control, name: "rule" });
    // Stage
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const toggleRule = (id) => {
        if (isRule === id) {
            setisRule();
        } else {
            setisRule(id);
        }
    };
    const options = [
        { value: 'michael@gmail.com', label: 'michael@gmail.com' },
        { value: 'arul@gmail.com', label: 'arul@gmail.com' },
        { value: 'arul@gmail.com', label: 'arul@gmail.com' }
    ]
    return (
        <React.Fragment>
            <div>
                <form>
                    <Card>
                        <CardBody className='b-radius-x-0'>
                            <div className='approve-stages'>

                                <ul className='p-0'>
                                    {fields.map((item, index) => {
                                        return (
                                            <>
                                                <div className='stages-item'>
                                                <li key={item.id}>
                                                    <Accordion open={open} toggle={toggle}>
                                                        <AccordionItem>
                                                            <AccordionHeader targetId={index.toString()}>Stage {index + 1}</AccordionHeader>
                                                            <AccordionBody accordionId={index.toString()}>
                                                                <div className='approve-content'>
                                                                    <div className="form-group bot-line">
                                                                        <Label className="form-label">Review type</Label>
                                                                        <div className='reviewer-check'>
                                                                            <RadioButton label={'Assign reviewer only if file is flagged'} id={'123654'} selectValue={setSelectFilter} />
                                                                            <RadioButton label={'Mandatory review (even if not flagged)'} id={'123654'} selectValue={setSelectFilter} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group bot-line pt-3">
                                                                        <Label className="form-label">Reviewers <span className='f-size-12 preheader-text'>(Optional)</span></Label>
                                                                        <div className='drop-down'>
                                                                            <Select
                                                                                options={options}
                                                                                // isMulti
                                                                                className={`form-control p-0`}
                                                                                classNamePrefix="custom_select"
                                                                                placeholder={'Choose Events'}
                                                                                search={true}
                                                                            />
                                                                        </div>
                                                                        <p className='m-0 f-size-13 preheader-text pt-1'>The flagged file will be assigned to any one of the selected team members</p>
                                                                    </div>
                                                                    <div className="form-group bot-line pt-3">
                                                                        <Label className="form-label m-0">Flag files</Label><br />
                                                                        <span>Specify conditions for the file to be flagged</span>
                                                                        <div className='flag-rule-child'>
                                                                            <div className='title-left'>
                                                                                <p>Flag if</p>
                                                                            </div>
                                                                            <div className='child-accordion'>
                                                                                <ul className='p-0'>

                                                                                    {ruleFields.map((item, index) => {
                                                                                        return (
                                                                                            <li key={item.id}>

                                                                                                <Accordion open={isRule} toggle={toggleRule}>
                                                                                                    <AccordionItem>
                                                                                                        <AccordionHeader targetId={index.toString()}> Rule {index + 1}
                                                                                                            <div className='rule-remove' type="button" onClick={() => ruleRemove(index)}>
                                                                                                               <img src={deleteIcon}  alt='delete-icon'/>
                                                                                                            </div>
                                                                                                        </AccordionHeader>
                                                                                                        <AccordionBody accordionId={index.toString()}>
                                                                                                            <div className='rule-content'>
                                                                                                                <div className="form-group">
                                                                                                                    <Label className="form-label">Apply to field</Label>
                                                                                                                    <div className='custom-input'>
                                                                                                                        <div className='drop-down'>
                                                                                                                            <Select
                                                                                                                                options={options}
                                                                                                                                // isMulti
                                                                                                                                className={`p-0`}
                                                                                                                                classNamePrefix="custom_select"
                                                                                                                                placeholder={'Choose Events'}
                                                                                                                                search={true}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <p className='m-0 f-size-13 preheader-text pt-1'>Which field do you want to apply a rule to? (e.g., Invoice number)</p>
                                                                                                                </div>
                                                                                                                <div className='validation-rule'>
                                                                                                                    <p>Select a validation rule</p>
                                                                                                                </div>
                                                                                                                <div className="form-group">
                                                                                                                    <Label className="form-label">Condition</Label>
                                                                                                                    <div className='custom-input'>
                                                                                                                        <div className='drop-down'>
                                                                                                                            <Select
                                                                                                                                options={options}
                                                                                                                                // isMulti
                                                                                                                                className={`p-0`}
                                                                                                                                classNamePrefix="custom_select"
                                                                                                                                placeholder={'Choose Events'}
                                                                                                                                search={true}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <p className='m-0 f-size-13 preheader-text pt-1'>File will be flagged if is ≠ not equal to</p>
                                                                                                                </div>
                                                                                                                <div className="form-group pt-3">
                                                                                                                    <Label className="form-label">Operator</Label>
                                                                                                                    <div className='custom-input'>
                                                                                                                        <div className='drop-down'>
                                                                                                                            <Select
                                                                                                                                options={options}
                                                                                                                                // isMulti
                                                                                                                                className={`form-control p-0`}
                                                                                                                                classNamePrefix="custom_select"
                                                                                                                                placeholder={'Choose Events'}
                                                                                                                                search={true}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group custom-input mb-4">
                                                                                                                    <Label className="form-label">Value</Label>
                                                                                                                    <input type="text" className='form-control ' />
                                                                                                                </div>
                                                                                                                <SecountryBtn btnName="Done" showIcon={false} />

                                                                                                            </div>
                                                                                                        </AccordionBody>
                                                                                                    </AccordionItem>
                                                                                                </Accordion>


                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                                    <div className='more-rule'
                                                                                       onClick={() => {ruleAppend({ name: "" });}}
                                                                                    >
                                                                                       <i className='bx bx-plus'></i>  Add Another Rule
                                                                                    </div>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='advanced-option'>
                                                                        <div>
                                                                       
                   <div className='ms-3'>
                   <Toggle />
                   </div>
                                                                        </div>
                                                                        <div className='option-cont'>
                                                                            <Label className='form-label m-0'>Require All Rules Passed</Label>
                                                                            <p className='m-0 f-size-13 preheader-text pt-1'>Prevent files from moving past this review stage, until all<br/> conditions are met

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </li>
                                                <div className='append-group'>
                                                    <div className='stage-remove' onClick={() => remove(index)}><img src={deleteIcon}  alt='delete-icon'/></div>
                                                    <div className='newappend'
                                                        type="button"
                                                        onClick={() => append({ firstName: "bill", lastName: "luo" })}
                                                    >
                                                       <i className='bx bx-plus'></i>
                                                    </div>
                                                </div>   
                                                </div>
                                            </>
                                        )
                                    })}
                                </ul>
                                {fields.length === 0 ?
                                    <button className='btn-common btn-primary btn-block primary-button'
                                        type="button"
                                        onClick={() => append({ firstName: "bill", lastName: "luo" })}
                                    >
                                        Add review stages
                                    </button>
                                    : ''
                                }

                                {/* /* <input type="submit" /> */}
                            </div>
                        </CardBody>
                    </Card>
                    <PrimaryButton btnName="Save Stage" showIcon={false} />
                </form>


            </div>
        </React.Fragment>
    );
};

export default ApproveStages;