import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DOCUMENTS_COUNT_FOR_BAR_CHART, GET_DOCUMENT_COUNT_BY_STATUS, GET_USERS_COUNT } from "./actionTypes"

import { getDocCountByStatusSuccess, getDocCountByStatusFail, getUsersCountFail, getUsersCountSuccess, getCountForDocumentForBarChartFail, getCountForDocumentForBarChartSuccess } from "./actions"

import { getDocumentCountsByStatus, getUsersCount, getCountForDocumentForBarChart } from "../../helpers/backend_helper"

//Get document status count
function* fetchDocStatus({ payload: { id, docType, month } }) {
  try {
    const response = yield call(getDocumentCountsByStatus, id, docType, month)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getDocCountByStatusFail(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getDocCountByStatusSuccess(response))
    }
  } catch (error) {
    yield put(getDocCountByStatusFail(error))
  }
}
export function* GetDocumentCountsByStatusSaga() {
  yield takeEvery(GET_DOCUMENT_COUNT_BY_STATUS, fetchDocStatus)
}

//Get users count
function* fetchUsersCount() {
  try {
    const response = yield call(getUsersCount)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getUsersCountFail(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getUsersCountSuccess(response))
    }
  } catch (error) {
    yield put(getUsersCountFail(error))
  }
}
export function* GetUsersCountSaga() {
  yield takeEvery(GET_USERS_COUNT, fetchUsersCount)
}

//Get documents count for bar chart
function* fetchDocumentCountsForBarChart({ payload: { month, type } }) {
  try {
    const response = yield call(getCountForDocumentForBarChart, month, type)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getCountForDocumentForBarChartFail(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getCountForDocumentForBarChartSuccess(response))
    }
  } catch (error) {
    yield put(getCountForDocumentForBarChartFail(error))
  }
}
export function* GetDocumentCountForBarChartSaga() {
  yield takeEvery(GET_DOCUMENTS_COUNT_FOR_BAR_CHART, fetchDocumentCountsForBarChart)
}