import LeaderLine from "react-leader-line";
import removeLeaderLine from "./removeLeaderLine";

export const leaderline = async (id, rect, set_H_position) => {
    const end = document.getElementById("text-" + id);
    const start = document.getElementById("dragabele-element");

    await set_H_position({
        left: rect.x,
        top: rect.y,
        width: rect.w,
        height: rect.h
    })

    if (start && end) {
        const options = {
            path: "fluid",
            startPlug: "arrow1",
            endPlug: "arrow1",
            color: `#576efb`,
            size: 2,
            // endSocketGravity: 500,
            endSocket: 'left'
        };
        removeLeaderLine();

        const line = new LeaderLine(start, end, options);
        const scrollableBox = document.querySelector(".reviewpage");
        scrollableBox?.addEventListener(
            "scroll",
            function () {
                line?.position();
            },
            false
        );
        const scrollableBox1 = document.querySelector(".sidebar-container");
        scrollableBox1?.addEventListener(
            "scroll",
            function () {
                line?.position();
            },
            false
        );
        const scrollableBox2 = document.querySelector(".review-doc");
        scrollableBox2?.addEventListener(
            "scroll",
            function () {
                line?.position();
            },
            false
        );
        const scrollableBox3 = document.querySelector(".review-table");
        scrollableBox3?.addEventListener(
            "scroll",
            function () {
                line?.position();
            },
            false
        );
    }
    await setPopUpPosition(rect, id)
};

const setPopUpPosition = (rect, id) => {

    const imageContainer = document.querySelector('.image-container');
    const popup = document.querySelector('.pop-up-invoice-field-selection');

    popup.style.display = 'block'
    let top = imageContainer?.offsetHeight - (rect?.h + popup?.offsetHeight + rect?.y)
    let right = imageContainer?.offsetWidth - (rect?.x + popup?.offsetWidth);

    if (top && Math.sign(top) == -1) {
        popup.style.bottom = (rect.h + 5) + 'px'
        popup.style.top = 'auto'
    }
    else {
        popup.style.top = (rect.h + 5) + 'px'
        popup.style.bottom = 'auto'
    }

    if (right && Math.sign(right) == -1) {
        popup.style.right = 0
        popup.style.left = 'auto'
    }
    else {
        popup.style.left = 0
        popup.style.right = 'auto'
    }
}