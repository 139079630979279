import React, { useState } from 'react';
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { uploadModelDocument } from 'store/actions';
import { updateModelDataCount, uploadFilesShowHide, uploadModelFiles } from 'store/uploadFiles/action';
import Uuid from 'constants/Uuid';

const UploadModelDocuments = ({ showModal, toggle, modalTitle, primaryBtnName, secoundaryBtnName, id }) => {
    const dispatch = useDispatch();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filterFiles, setFilterFiles] = useState(null);

    const uploadFileLoading = useSelector(state => state.ModelDocumentUpload?.loading);
    const modelFiles = useSelector(state => state.UploadFiles.modelData);

    const formValues = {
        files: []
    };

    const uploadSchema = yup.object().shape({
        files: yup
            .mixed()
            .required('Select files from the system')
            .test(
                'required',
                'Select files from the system',
                value => value?.length > 0
            )
            .test(
                'fileFormat',
                'Unsupported file format',
                value => value.every(file => ['image/jpeg', 'image/png'].includes(file.type))
            )
            .test(
                'fileSize',
                'Each file size should not exceed 2 MB.',
                value => value.every(file => file.size <= 2097152) // 2MB
            ),
    });

    const { handleSubmit, reset, clearErrors, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(uploadSchema),
        defaultValues: formValues
    });

    const afterUploadSuccess = () => {
        handleCancel();

    }

    const onSubmit = (values) => {

        let fileDetails = [...modelFiles], ids = [];
        values?.files?.map((item) => {
            const id = Uuid();
            ids.push(id);
            fileDetails.push({ fileName: item?.name, status: "in_progress", id: id })
        })

        const data = {
            model_id: id,
            company_id: `1`,
            files: values.files,
            ids: ids
        }

        dispatch(updateModelDataCount({ total: fileDetails?.length, uploaded: 0 }));
        dispatch(uploadModelFiles(fileDetails));
        dispatch(uploadFilesShowHide(true));
        dispatch(uploadModelDocument(data, afterUploadSuccess))
    };

    // Drop the files
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setValue('files', droppedFiles);
        setSelectedFiles(droppedFiles);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Cancel upload files
    const handleFileCancel = (fileIndex) => {
        const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
        setValue('files', updatedFiles);
        setSelectedFiles(updatedFiles);
    };

    // Cancel upload files from serach
    const handleSearchFileCancel = (cancelFile, currentIndex) => {
        const updatedFiles = selectedFiles.filter((_, index) => index !== cancelFile?.index);
        const forFilterFiles = filterFiles.filter((_, index) => index !== currentIndex);
        setFilterFiles(forFilterFiles);
        setValue('files', updatedFiles);
        setSelectedFiles(updatedFiles);
    };

    const handleCancel = () => {
        reset();
        setSelectedFiles([]);
        clearErrors(["files"]);
        toggle();
    }

    const handleFilters = (e) => {
        let newfiltered = [];
        const filtered = selectedFiles?.filter((filter, index) => {
            if (filter.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
                newfiltered.push({ index: index, file: filter });
            }
        });
        setFilterFiles(newfiltered);
    }

    return (
        <Modal size="xl" isOpen={showModal} centered={true}
            className='doc-modal create-folder upload-files-modal modal-document-upload'
            toggle={toggle}
        >
            <div className="modal-header p-0 border-0">
                <div className='head-item'>
                    <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                    {uploadFileLoading === false &&
                        <span onClick={handleCancel} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    }
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body px-0">
                    {uploadFileLoading === true ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className='mb-0'>Uploading please wait...</p>
                            <div className="spinner-border ms-3" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                        :
                        <>
                            <div className='upload-container'>
                                <Row>
                                    <Col className="col-6">
                                        <Card>
                                            <CardBody>
                                                <div className="form-group">
                                                    <Label className="form-label">Upload Files</Label>
                                                    <div className='uploading-files'>
                                                        <Controller
                                                            name="files"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <div className={`file-uploader ${errors.files?.message ? 'is-invalid' : ''}`}
                                                                    onDrop={handleDrop}
                                                                    onDragOver={handleDragOver}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        multiple
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={(e) => {
                                                                            const files = Array.from(e.target.files);
                                                                            field.onChange(files);
                                                                            setSelectedFiles(files);
                                                                        }}
                                                                    />
                                                                    <div className="">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                    </div>
                                                                    <h5>Drop files here or click to upload.</h5>
                                                                    <p>(Allowed document types jpg & png)</p>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors.files?.message && <p className={`error-field`} >{errors.files?.message}</p>}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="col-6">
                                        <Card>
                                            <CardBody>
                                                <div className="form-group">
                                                    <Label className="form-label">Uploaded Files</Label>
                                                    <input type="text" placeholder={`Search`} className={`form-control`} style={{ background: '#fff' }} onChange={(e) => {
                                                        handleFilters(e);
                                                    }} />
                                                    <div className='uploading-files'>
                                                        {filterFiles ? (
                                                            <div className="dropzone-previews mt-3" id="file-previews" style={{ paddingRight: filterFiles.length > 3 ? "10px" : "" }}>
                                                                {filterFiles.map((file, index) => (
                                                                    <div key={index} className="mt-1 mb-0">
                                                                        <div className="col-auto">
                                                                            <i className='bx bx-file'></i>
                                                                            <p>{file?.file?.name}</p>
                                                                            <p className='file-close' onClick={() => handleSearchFileCancel(file, index)}><i className='bx bx-x'></i></p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div className="dropzone-previews mt-3" id="file-previews" style={{ paddingRight: selectedFiles?.length > 3 ? "10px" : "" }}>
                                                                {selectedFiles?.map((file, index) => (
                                                                    <div key={index} className="mt-1 mb-0">
                                                                        <div className="col-auto">
                                                                            <i className='bx bx-file'></i>
                                                                            <p>{file.name}</p>
                                                                            <p className='file-close' onClick={() => handleFileCancel(index)}><i className='bx bx-x'></i></p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className='filter-btn d-flex'>
                                <SecountryBtn btnName={secoundaryBtnName} showIcon={false} btnType={'button'} onClick={handleCancel} />

                                <span className={`d-flex ${uploadFileLoading === false ? 'disabled' : ''}`}>
                                    <PrimaryButton btnName={primaryBtnName} showIcon={false} />
                                </span>
                            </div>
                        </>
                    }
                </div>
            </form>
        </Modal>
    )
}

export default UploadModelDocuments