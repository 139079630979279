import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import { Login, ForgetPassword, ResetPassword, ForgetPasswordResendCode, LogoutActivity } from "./auth/reducers"
// import ForgetPassword from "./auth/forgetpwd/reducer"
// import ResetPassword from "./auth/resetpwd/reducer"
// import { ForgetPasswordResendCode } from "./auth/forgetpwd/reducer"

//Admin
import GetAdmin, { GetAdminRoles } from "./admin/getAdmin/reducer"
import AddAdmin from "./admin/addAdmin/reducer"
import GetAdminById from "./admin/getAdminById/reducer"
import changeStatus from "./admin/Activate--De-Activate/reducer"
import { GetMyDocuments, AssignUser, GetDocTypes, GetDocStatus, GetAssignedUsers, DocumentUpload, DeleteDocument, GetDocumentById, ApproveDocument, GetDownloadDocument, FieldwiseApproveDocument, ReUpload } from "./myDocuments/reducer"
import authInfo from "./authInfo/reducer"

//profile
import { UpdateProfile, LoginPasswordChange, UpdateProfileImg } from './profile/reducer'
import { GetDocumentCountsByStatus, GetUsersCount, GetDocumentCountForBarChart } from './dashboard/reducer'
import { GetAssignUsers, GetUsers, AddUsers, ActivateAndDeactivateUser, DeleteUser, UpdateSettingsData } from './users/reducer'
import { GetCompanyById } from './company/reducer'
import { GetModelsDoc, DeleteModelsDoc, GetModelDocumentById, TrainDocument } from './modelsdocuments/reducers'
import FileUpload from './fileuploadInfo/reducer';
import { GetModelsList, DeleteModels, DeleteMulityModels, CreateModal, ModelDocumentUpload, GetModelById, PublishModel, GetStartedModel, TrainModel, PreModelStartStop, GetPreModel, ModelStartStop } from './models/reducers'
import { GetPlans } from './plans/reducers';
import { GetActivities } from "./activity/reducers"
import UploadFiles from "./uploadFiles/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  ResetPassword,
  ForgetPasswordResendCode,
  GetAdmin,
  AddAdmin,
  GetAdminById,
  changeStatus,
  GetAdminRoles,
  UpdateProfile,
  LoginPasswordChange,
  UpdateProfileImg,
  LogoutActivity,

  GetMyDocuments,
  GetAssignUsers,
  AssignUser,
  GetDocTypes,
  GetDocStatus,
  GetAssignedUsers,
  GetDocumentCountsByStatus,
  GetUsersCount,
  GetDocumentCountForBarChart,
  DocumentUpload,
  GetUsers,
  authInfo,
  AddUsers,
  ActivateAndDeactivateUser,
  DeleteUser,
  GetCompanyById,
  UpdateSettingsData,
  GetModelsList,
  DeleteModels,
  DeleteMulityModels,
  DeleteDocument,
  GetModelsDoc,
  DeleteModelsDoc,
  CreateModal,
  ModelDocumentUpload,
  GetModelDocumentById,
  GetDocumentById,
  GetModelById,
  ApproveDocument,
  GetDownloadDocument,
  FileUpload,
  FieldwiseApproveDocument,
  TrainDocument,
  PublishModel,
  GetStartedModel,
  TrainModel,
  GetPlans,
  GetActivities,
  ReUpload,
  PreModelStartStop,
  GetPreModel,
  ModelStartStop,
  UploadFiles
})

export default rootReducer
