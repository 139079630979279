import { FILE_UPLOAD_PROGRESS, FILE_UPLOAD_PROGRESS_SUCCESS } from "./actionTypes"

const initialState = {
    data: [],
    // loading: null,
  }
  
  const FileUpload = (state = initialState, action) => { 
      switch (action.type) {
          case FILE_UPLOAD_PROGRESS:
        state = {
          data: action.payload,
        //   loading: true,
        }
        break
    }
    return state
  }
  
  export default FileUpload;