import React, { useContext, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Select from "react-select";
import { Toggle } from 'rsuite';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import { FilterContext, SelectedFieldContext } from './InputFields';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createModal } from 'store/actions';

const GeneralTab = ({ setInputFieldsTab, id }) => {
    const { filterData, setFilterData } = useContext(FilterContext);
    const { selectedField, setSelectedField } = useContext(SelectedFieldContext);

    const [dataTypeValue, setDataTypeValue] = useState(null)
    const [stringCaseValue, setStringCaseValue] = useState(null)
    const [mandatoryField, setmandatoryField] = useState(false)
    const [uniqueField, setuniqueField] = useState(false)
    const [removeWhiteSpace, setremoveWhiteSpace] = useState(false)

    const getModelDetails = useSelector(state => state?.GetModelById?.data?.data)
    const modelDetails = useSelector(state => state?.CreateModal?.data?.data)
    const authUser = useSelector(state => state?.authInfo?.data)

    const dispatch = useDispatch()

    const formValues = {
        dataType: "",
        defaultValue: '',
        mandatoryField: false,
        uniqueField: false,
        min: "",
        max: "",
        removeWhiteSpace: false,
        stringCase: '',
    }

    const { register, reset, setValue, clearErrors, getValues, trigger, formState: { errors, } } = useForm({
        defaultValues: formValues
    });

    const dataTypeOptions = [
        { value: 'STRING', label: 'String' },
        { value: 'NUMBER', label: 'Number' }
    ]

    const stringCaseOptions = [
        { value: 'UPPER', label: 'Uppercase' },
        { value: 'LOWER', label: 'Lowercase' }
    ]

    const onSubmit = (values) => {
        let fieldIndex, sectionIndex
        const section = filterData[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            let filter = { ...(filterData[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration), generalFilter: values }
            let currentSection = JSON.parse(JSON.stringify(filterData))

            currentSection[selectedField?.field][sectionIndex]?.sectionFields?.map((item, index) => {
                if (item?.fieldId === selectedField?.fieldId) {
                    item['configuration'] = filter
                }
                return item
            })
            setFilterData(currentSection)
            if (id) {
                createModelFunction(currentSection, id)
            } else {
                createModelFunction(currentSection, modelDetails?._id)
            }
        }
    }

    const createModelFunction = (currentSection, modelId) => {
        const data = {
            company_id: authUser?.user?.company_id,
            modelId: modelId,
            fields: selectedField?.field == 'fields' ? currentSection[selectedField?.field] : filterData?.fields,
            table: selectedField?.field == 'table' ? currentSection[selectedField?.field] : filterData?.table,
        }
        dispatch(createModal(data, setInputFieldsTab, 'Extraction'))
    }

    useEffect(() => {
        if (modelDetails && !id) {
            setFormValues(modelDetails)
        } else if (id && getModelDetails) {
            setFormValues(getModelDetails)
        }
    }, [modelDetails, selectedField, getModelDetails])

    const setFormValues = (modelDetails) => {
        let fieldIndex, sectionIndex
        const section = modelDetails[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            const filter = modelDetails[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration?.generalFilter
            if (filter) {
                reset(filter)
                setmandatoryField(filter?.mandatoryField)
                setuniqueField(filter?.uniqueField)
                setremoveWhiteSpace(filter?.removeWhiteSpace)
                const dataType = dataTypeOptions?.filter(item => item?.value == filter?.dataType)
                if (dataType) setDataTypeValue(dataType)

                const stringCase = stringCaseOptions?.filter(item => item?.value == filter?.stringCase)
                if (stringCase) setStringCaseValue(stringCase)
            }
            else {
                reset(formValues)
                setDataTypeValue(null)
                setStringCaseValue(null)
                setmandatoryField(false)
                setuniqueField(false)
                setremoveWhiteSpace(false)
            }
        }
    }

    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault()
                const error = trigger()
                error.then(result => {
                    if (result) {
                        onSubmit(getValues())
                    }
                })
            }} >
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Data Type</Label>
                            <div className='drop-down'>
                                <Select
                                    options={dataTypeOptions}
                                    className={`form-control p-0`}
                                    classNamePrefix="custom_select"
                                    placeholder={'Choose Events'}
                                    // menuIsOpen={true}
                                    value={dataTypeValue}
                                    onChange={(value) => {
                                        setDataTypeValue(value)
                                        setValue('dataType', value.value)
                                        clearErrors('dataType')
                                    }}
                                    search={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Default Value</Label>
                            <input type="text" {...register('defaultValue')} className='form-control' />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <h6 className='tabsub-heading'>Field Setting</h6>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4 d-flex">
                            <Label className="form-label">Mandatory Field</Label>
                            <div className='ms-3'>
                                <Toggle
                                    checked={mandatoryField}
                                    onClick={() => {
                                        if (mandatoryField) {
                                            setmandatoryField(false)
                                            setValue('mandatoryField', false)
                                        } else {
                                            setmandatoryField(true)
                                            setValue('mandatoryField', true)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='d-flex'>
                            <div>
                                <Label className="form-label">Unique Field</Label><br />
                                <span>This Field is unique in this<br /> document types</span>
                            </div>

                            <div className='ms-3'>
                                <Toggle
                                    checked={uniqueField}
                                    onClick={() => {
                                        if (uniqueField) {
                                            setuniqueField(false)
                                            setValue('uniqueField', false)
                                        } else {
                                            setuniqueField(true)
                                            setValue('uniqueField', true)
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row align-items-center'>
                    <h6 className='tabsub-heading'>Value Setting</h6>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Length</Label>
                            <div className='minmax-group'>
                                <input type="number" className='form-control' {...register('min')} onChange={() => {
                                    clearErrors('min')
                                }} placeholder='Min' />
                                <input type="number" className='form-control'{...register('max')} onChange={() => {
                                    clearErrors('max')
                                }} placeholder='Max' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='d-flex'>
                            <Label className="form-label">Remove white<br /> space</Label>
                            <div className='ms-3'>
                                <Toggle
                                    checked={removeWhiteSpace}
                                    onClick={() => {
                                        if (removeWhiteSpace) {
                                            setremoveWhiteSpace(false)
                                            setValue('removeWhiteSpace', false)
                                        } else {
                                            setremoveWhiteSpace(true)
                                            setValue('removeWhiteSpace', true)
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-4">
                    <Label className="form-label">String Case</Label>
                    <div className='drop-down'>
                        <Select
                            options={stringCaseOptions}
                            className={`form-control p-0`}
                            classNamePrefix="custom_select"
                            placeholder={'Choose Events'}
                            // menuIsOpen={true}
                            value={stringCaseValue}
                            onChange={(value) => {
                                setStringCaseValue(value)
                                setValue('stringCase', value.value)
                                clearErrors('stringCase')
                            }}
                            search={true}
                        />
                    </div>
                </div>
                <div className="action">
                    <PrimaryButton btnType='submit' btnName="Ok and continue" showIcon={false} />
                </div>
            </form>
        </div>
    );
};

export default GeneralTab;