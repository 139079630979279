import { all, fork } from "redux-saga/effects"

//public
import { AuthSaga, ForgetPasswordSaga, ResetPasswordSaga, ForgetPasswordResendCodeSaga, LogoutActivitySaga } from "./auth/sagas"
// import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"

import AdminSaga, { RoleAdminSaga } from './admin/getAdmin/saga'
import AddAdminSaga from './admin/addAdmin/saga'
import GetAdminByIdSaga from './admin/getAdminById/saga'
import StatusSaga from "./admin/Activate--De-Activate/saga"
// import ResetPasswordSaga from './auth/resetpwd/saga'
// import { ForgetPasswordResendCodeSaga } from "./auth/forgetpwd/saga"
import { UpdateProfileImageSaga, UpdateProfileSaga, LoginPasswordChangeSaga, getProfileImagePathSaga } from './profile/saga'
import { ApproveDocumentSaga, AssignUserSaga, DeleteDocumentSaga, DocumentUploadSaga, FieldwiseApproveDocumentSaga, GetAssignedUsersSaga, GetDocStatusSaga, GetDocTypesSaga, GetDocumentByIdSaga, GetDownloadDocumentSaga, GetMyDocumentsSaga, ReUplaodSaga } from "./myDocuments/saga"
import { GetDocumentCountForBarChartSaga, GetDocumentCountsByStatusSaga, GetUsersCountSaga } from "./dashboard/saga"
import { AddUsersSaga, GetUsersSaga, GetAssignUsersSaga, ActivateAndDeactivateUserSaga, DeleteUserSaga, UpdatesettingsDataSaga } from "./users/saga"
import { GetCompanyByIdSaga } from "./company/saga"
import { DeleteModelDocSaga, GetDocModelsSaga, GetModelDocumentByIdSaga, TrainDocumentSaga } from "./modelsdocuments/sagas"
import { CreateModalSaga, DeleteModelSaga, DeleteMulitySaga, GetModelByIdSaga, GetModelsSaga, GetPreModelSaga, GetStartedModelSaga, ModelDocumentUploadSaga, ModelStartStopSaga, PreModelStartStopSaga, PublishModelSaga, TrainModelSaga } from "./models/sagas"
import { GetCreditPlansSaga } from "./plans/sagas"
import { GetActivitiesSaga } from "./activity/sagas"
export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(ForgetPasswordSaga),
    fork(ResetPasswordSaga),
    fork(ForgetPasswordResendCodeSaga),
    fork(AdminSaga),
    fork(AddAdminSaga),
    fork(GetAdminByIdSaga),
    fork(StatusSaga),
    fork(RoleAdminSaga),
    fork(UpdateProfileSaga),
    fork(LoginPasswordChangeSaga),
    fork(UpdateProfileImageSaga),
    fork(getProfileImagePathSaga),


    fork(GetMyDocumentsSaga),
    fork(GetAssignUsersSaga),
    fork(AssignUserSaga),
    fork(GetDocTypesSaga),
    fork(GetDocStatusSaga),
    fork(GetAssignedUsersSaga),
    fork(GetDocumentCountsByStatusSaga),
    fork(GetUsersCountSaga),
    fork(GetDocumentCountForBarChartSaga),
    fork(DocumentUploadSaga),
    fork(GetUsersSaga),
    fork(AddUsersSaga),
    fork(ActivateAndDeactivateUserSaga),
    fork(DeleteUserSaga),
    fork(GetCompanyByIdSaga),
    fork(UpdatesettingsDataSaga),
    fork(GetModelsSaga),
    fork(DeleteModelSaga),
    fork(DeleteMulitySaga),
    fork(DeleteDocumentSaga),
    fork(GetDocModelsSaga),
    fork(DeleteModelDocSaga),
    fork(CreateModalSaga),
    fork(ModelDocumentUploadSaga),
    fork(GetModelDocumentByIdSaga),
    fork(GetDocumentByIdSaga),
    fork(GetModelByIdSaga),
    fork(ApproveDocumentSaga),
    fork(GetDownloadDocumentSaga),
    fork(FieldwiseApproveDocumentSaga),
    fork(TrainDocumentSaga),
    fork(PublishModelSaga),
    fork(GetStartedModelSaga),
    fork(TrainModelSaga),
    fork(GetCreditPlansSaga),
    fork(LogoutActivitySaga),
    fork(GetActivitiesSaga),
    fork(ReUplaodSaga),
    fork(PreModelStartStopSaga),
    fork(GetPreModelSaga),
    fork(ModelStartStopSaga),
  ])
}