import {
  GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS,
  GET_DOCUMENT_COUNT_BY_STATUS_FAIL,
  GET_DOCUMENT_COUNT_BY_STATUS,
  GET_USERS_COUNT,
  GET_USERS_COUNT_SUCCESS,
  GET_USERS_COUNT_FAIL,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL,
} from "./actionTypes"

//Get Document status counts
const INITIAL_STATE = {
  loading: false,
  data: [],
  error: {}
}
export const GetDocumentCountsByStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT_COUNT_BY_STATUS:
      return {
        ...state,
        loading: true,
      }
    case GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_DOCUMENT_COUNT_BY_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

//Get users count
const usersCount = {
  loading: false,
  data: [],
  error: {}
}
export const GetUsersCount = (state = usersCount, action) => {
  switch (action.type) {
    case GET_USERS_COUNT:
      return {
        ...state,
        loading: true,
      }
    case GET_USERS_COUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_USERS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

//Get count for documents for bar chart
const barChart = {
  loading: false,
  data: [],
  error: {}
}
export const GetDocumentCountForBarChart = (state = barChart, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_COUNT_FOR_BAR_CHART:
      return {
        ...state,
        loading: true,
      }
    case GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}