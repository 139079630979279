import React, { useEffect, useMemo, useState } from 'react'
import { Rnd } from "react-rnd";
import { fieldwiseApproveDocument } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { leaderline } from '../utils/leaderLine';
import getDifference from '../utils/getDifference';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import hidePopUp from '../utils/hidePopUp';
import imagePath from '../../../assets/images/emptyImage.png'
import reviewApproved from '../../../assets/images/icons/review-approved.svg';
import reviewDelete from '../../../assets/images/icons/review-delete.svg';
import getPositions from '../utils/getPositions';
import createHighlight from '../utils/createHighlight';
import { async } from 'q';
import removeLeaderLine from '../utils/removeLeaderLine';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ImageLoader = ({ documentDetails, setDocumentDetails, h_position, set_H_position, zoomLevel, documentId, selectedId, setSelectedId, createPosition, setPositions, positions, train, boundryRect, setBoundryRect, setMarking, marking, setIsTable, isTable }) => {

    const { model } = useParams();

    const dispatch = useDispatch();

    const [difference, setdifference] = useState();
    // const [fieldValue, setFieldValue] = useState();

    const getDifferenceOfSize = () => {
        return getDifference();
    }

    // useEffect(() => {
    //     setFieldValue(selectedId?.fieldValue);
    // }, [selectedId]);

    useEffect(() => {
        setTimeout(() => {
            let diff = getDifferenceOfSize();
            setdifference(diff);
            if (selectedId?.id) {
                const rect = {
                    x: selectedId?.rect?.x * diff,
                    y: selectedId?.rect?.y * diff,
                    w: selectedId?.rect?.w * diff,
                    h: selectedId?.rect?.h * diff,
                };
                leaderline(selectedId?.id, rect, set_H_position);
            };
        }, 1000);
        setTimeout(() => {
            setMarking(false);
        }, 1200);
    }, [])

    useEffect(() => {
        if (!marking) {
            let diff = getDifferenceOfSize();
            setdifference(diff);
            if (selectedId?.id) {
                const rect = {
                    x: selectedId?.rect?.x * diff,
                    y: selectedId?.rect?.y * diff,
                    w: selectedId?.rect?.w * diff,
                    h: selectedId?.rect?.h * diff,
                };
                leaderline(selectedId?.id, rect, set_H_position);
            };
        };
    }, [difference, zoomLevel]);

    function getHighlight(item) {
        if (difference && item?.BoundingBox) {
            const left = item?.BoundingBox?.x * difference;
            const top = item?.BoundingBox?.y * difference;
            const width = item?.BoundingBox?.Width * difference;
            const height = item?.BoundingBox?.Height * difference;
            const id = item?.id;
            return <div
                id={"highlight" + id}
                key={id}
                onClick={async () => {
                    const rect = {
                        w: width,
                        h: height,
                        x: left,
                        y: top
                    };
                    leaderline(id, rect, set_H_position);
                    setSelectedId({
                        id: id,
                        fieldName: item?.labelName,
                        fieldValue: item?.value,
                        rect: item?.BoundingBox
                    });
                    setIsTable(false)
                }}
                className={'highlight_part_' + id + ' default-highlight'}
                style={{
                    zIndex: 0,
                    background: '#9f9e95',
                    opacity: 0.4,
                    borderStyle: 'dashed',
                    position: 'absolute',
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }}
            ></div>
        };
    };

    const getTableHighlights = (item) => {
        if (difference && item?.BoundingBox) {
            const left = item?.BoundingBox?.x * difference
            const top = item?.BoundingBox?.y * difference
            const width = item?.BoundingBox?.Width * difference
            const height = item?.BoundingBox?.Height * difference
            const id = item?.id
            return <div
                id={"highlight" + id}
                key={id}
                onClick={async () => {
                    const rect = {
                        w: width,
                        h: height,
                        x: left,
                        y: top
                    };
                    leaderline(id, rect, set_H_position);
                    setSelectedId({
                        id: id,
                        fieldValue: item?.DetectedText,
                        rect: item?.BoundingBox
                    });
                    setIsTable(true)
                }}
                className={'highlight_part_' + id + ' default-highlight'}
                style={{
                    zIndex: 0,
                    background: '#9f9e95',
                    opacity: 0.4,
                    borderStyle: 'dashed',
                    position: 'absolute',
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }}
            ></div>
        };
    }

    const handleRemoveLeaderLine = () => {
        setSelectedId();
        hidePopUp();
        removeLeaderLine();
        set_H_position({
            left: -100,
            top: -100,
            width: 0,
            height: 0,
        });
    }

    const handleFieldApprove = () => {
        const newLabeledtext = [...documentDetails?.document?.result_data?.labeled_texts];

        if (isTable) {
            const index = newLabeledtext.findIndex(indexitem => indexitem.id === selectedId.id);
            if (index !== -1) {
                newLabeledtext[index].DetectedText = selectedId?.fieldValue; // Update the DetectedText
            }
        } else {
            selectedId?.fieldValue?.map((item) => {
                const index = newLabeledtext.findIndex(indexitem => indexitem.id === item.id);
                if (index !== -1) {
                    newLabeledtext[index].DetectedText = item?.DetectedText; // Update the DetectedText
                }
            })
        }

        const payload = {};
        payload['id'] = documentId;
        payload['data'] = {
            ...documentDetails?.document?.result_data,
            labeled_texts: newLabeledtext
        };
        handleRemoveLeaderLine();
        dispatch(fieldwiseApproveDocument(payload));
    };

    const handleDragStop = (e, d) => {
        const rect = {
            w: h_position.width,
            h: h_position.height,
            x: d.x,
            y: d.y
        }
        if (selectedId) {
            leaderline(selectedId?.id, rect, set_H_position)
        } else {
            set_H_position({
                width: h_position.width,
                height: h_position.height,
                left: d.x,
                top: d.y,
            })
        }
        if (train === true) {
            setNewPositions(rect);
        };
    };

    const setNewPositions = (rect) => {
        const copyPositions = JSON.parse(JSON.stringify(positions));
        let newPositions = [];

        copyPositions?.map((item) => {
            if (item?.id === selectedId?.id) {
                newPositions.push({
                    ...item,
                    Confidence: 75,
                    BoundingBox: {
                        'x': rect?.x / difference,
                        'y': rect?.y / difference,
                        'x2': (rect?.x / difference) + (rect?.w / difference),
                        'y2': (rect?.y / difference) + (rect?.h / difference),
                        'Width': rect?.w / difference,
                        'Height': rect?.h / difference,
                    }
                })
            } else {
                newPositions.push(item);
            }
        });

        setBoundryRect(newPositions);
    }

    const handleOnResizeStop = (e, direction, ref, delta, position) => {
        const width = ref.style.width.replace(/[a-z]/gi, '');
        const height = ref.style.height.replace(/[a-z]/gi, '');
        const rect = {
            w: Number(width),
            h: Number(height),
            x: position.x,
            y: position.y
        };
        if (selectedId) {
            leaderline(selectedId?.id, rect, set_H_position);
        } else {
            set_H_position({
                width: Number(width),
                height: Number(height),
                left: position.x,
                top: position.y,
            });
        };
        if (train === true) {
            setNewPositions(rect);
        };
    };

    const handleSetPosition = () => {
        const newDocumentDetails = JSON.parse(JSON.stringify({
            ...documentDetails,
            fieldDetails: boundryRect
        }))
        setDocumentDetails(newDocumentDetails);
        setPositions(boundryRect);
        hidePopUp();
    }

    const handleChange = (index, event) => {
        const newFieldValue = [...selectedId?.fieldValue];
        newFieldValue[index].DetectedText = event.target.value;

        setSelectedId({
            ...selectedId,
            fieldValue: newFieldValue
        });
    };

    return (
        <div className='image-loader' >
            <div
                className='image-container'
                style={{ overflow: 'hidden', position: 'relative' }}
            // onDrag={(e) => getPositions(e)}
            // onDoubleClick={(e) => {
            //     if (createPosition === true) {
            //         hidePopUp();
            //         const rect = createHighlight(e);
            //         set_H_position(rect);
            //         setSelectedId();
            //     };
            // }}
            >

                <img
                    draggable={false}
                    onClick={() => {
                        hidePopUp()
                    }}
                    id={`invoice-image`} style={{ width: "100%" }}
                    src={documentDetails?.file || imagePath}
                    alt="pdf-image"
                />

                <Rnd
                    id={'dragabele-element'}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #576efb",
                        zIndex: 1,
                    }}
                    size={h_position}
                    position={{ x: h_position.left, y: h_position.top }}
                    onDrag={async (e, d) => {
                        if (train)
                            await handleDragStop(e, d);
                    }}
                    onDragStop={async (e, d) => {
                        if (train)
                            await handleDragStop(e, d);
                    }}
                    onResizeStart={() => {
                        hidePopUp();
                    }}
                    onResize={async (e, direction, ref, delta, position) => {
                        if (train)
                            await handleOnResizeStop(e, direction, ref, delta, position);
                    }}
                    onResizeStop={async (e, direction, ref, delta, position) => {
                        if (train)
                            await handleOnResizeStop(e, direction, ref, delta, position);
                    }}
                >
                    <div className='pop-up-invoice-field-selection custom-input font-size-15' onMouseOver={(e) => e.stopPropagation()}>
                        {(!model || isTable) && <>
                            <p className='d-flex justify-content-between w-100'><span>Text</span><span className='close-click' onClick={() => hidePopUp()}><i className="mdi mdi-close" /></span></p>

                            {isTable ?
                                <textarea
                                    id='ivoice-selection-value'
                                    className='form-control'
                                    value={selectedId?.fieldValue}
                                    onChange={(e) => {
                                        const newvalues = { ...selectedId, fieldValue: e.target.value }
                                        setSelectedId(newvalues)
                                        // setFieldValue(e.target.value)
                                    }}>
                                </textarea> :
                                <div className='form-control field-update-input-container'>
                                    {selectedId?.fieldValue?.map((field, index) => (
                                        <input
                                            key={"ivoice-selection-value-" + field?.id}
                                            type="text"
                                            className={`field-input ${index !== 0 ? "mt-2" : ""}`}
                                            value={field?.DetectedText}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                    ))}
                                </div>
                            }
                        </>}
                        <>
                            {isTable ? null : <>
                                {model ? <p className='d-flex justify-content-between w-100'><span>Label</span><span className='close-click' onClick={() => hidePopUp()}><i className="mdi mdi-close" /></span></p> :
                                    <p >Label</p>
                                }
                                <input id='ivoice-selection-label' className='form-control' value={selectedId?.fieldName} onChange={() => { }}></input>
                            </>}
                        </>
                        <div className="action">
                            {train ?
                                <>
                                    <SecountryBtn onClick={() => {
                                        handleSetPosition();
                                    }} btnName="Ok" showIcon={true} btnIcon={reviewApproved} />
                                    <PrimaryButton
                                        btnName="Cancel"
                                        // showIcon={true}
                                        // btnIcon={reviewDelete}
                                        onClick={() => {
                                            // hidePopUp();
                                            handleRemoveLeaderLine();
                                        }}
                                    />
                                </> :
                                <>
                                    <SecountryBtn onClick={() => {
                                        handleFieldApprove();
                                    }} btnName="Change" showIcon={true} btnIcon={reviewApproved} />
                                    <PrimaryButton
                                        btnName="cancel"
                                        // btnName="Reject"
                                        // showIcon={true}
                                        // btnIcon={reviewDelete}
                                        onClick={() => {
                                            // handleFieldApprove();
                                            handleRemoveLeaderLine();
                                        }}
                                    />
                                </>
                            }
                        </div>
                    </div>

                </Rnd>

                {difference && documentDetails?.file &&
                    documentDetails?.fieldDetails?.map((item, index) => {
                        if (item?.labelName !== 'table_data') {
                            let value = ""
                            item?.labeledTexts?.length > 0 && item?.labeledTexts?.map((labelItem) => {
                                value = value + labelItem?.DetectedText + " ";
                            })
                            // return getHighlight({ ...item, value: value });
                            return getHighlight({ ...item, value: item?.labeledTexts });
                        } else {
                            return item?.labeledTexts?.length > 0 && item?.labeledTexts?.map((row, rowIndex) => {
                                if (rowIndex !== 0) {
                                    return row?.length > 0 && row?.map((cell) => {
                                        if (cell?.DetectedText && cell.DetectedText !== "") {
                                            return getTableHighlights(cell)
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

                {difference && train && documentDetails?.file &&
                    documentDetails?.fieldDetails?.map((item, index) => {
                        if (item?.labelName === 'table_data') {
                            let value = ""
                            item?.labeledTexts?.length > 0 && item?.labeledTexts?.map((labelItem) => {
                                value = value + labelItem?.DetectedText + " ";
                            })
                            // return getHighlight({ ...item, value: value });
                            return getHighlight({ ...item, value: item?.labeledTexts });
                        }
                    })
                }
            </div>
        </div >
    )
}

export default ImageLoader