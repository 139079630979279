import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from 'components/CommonForBoth/UseDetectOutsideClick';
import CreateFolder from './Modals/CreateFolder';
import { useDispatch } from 'react-redux';
import { uploadDocument } from 'store/actions';
import UploadFiles from './Modals/UploadFiles';

const FileUpload = () => {
    const [fileUploadDrop, setFileUploadDrop] = useState(false);
    const [fileUploadModal, setFileUploadModal] = useState(false);
    const [showSubMenu, setshowSubMenu] = useState(false);
    const [isCreateFolder, setCreateFolder] = useState(false);
    const [isHovering, setIsHovering] = useState(true);

    const dispatch = useDispatch()

    const handleOutsideClick = () => {
        setFileUploadDrop(fileUploadDrop);
    };

    //Onclick Close
    const closeDropDown = () => {
        setFileUploadDrop(false);
    }
    // Click SubMenu
    const openSubMenu = () => {
        setshowSubMenu(!showSubMenu);
    };
    //Open Modal
    function tog_large() {
        setCreateFolder(!isCreateFolder);
        // removeBodyCss();
    }

    const toggleuploadModal = () => {
        setFileUploadModal(!fileUploadModal);
    }
    // const closeUploadModal = () => {
    //     setFileUploadModal(false);
    // }

    //Click Out Size
    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef?.current?.contains(e.target)) {
                setFileUploadDrop(false);
                setshowSubMenu(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [fileUploadDrop]);

    const uploadFile = (file) => {
        const data = {
            file: file,
        }
        dispatch(uploadDocument(data))
    }

    return (
        <div className='new-btn'>
            <div className='file-upload' onClick={() => setFileUploadDrop(!fileUploadDrop)}>
                <p className='new-file'><i className='bx bx-plus'></i><span>New</span></p>
            </div>
            {fileUploadDrop &&
                <div ref={menuRef}>
                    <div className='dropdown-group'>
                        <ul>
                            <li onClick={() => { setFileUploadModal(!fileUploadModal); closeDropDown() }}>
                                Upload Invoice
                                {/* <input type="file" id="actual-btn" onChange={e => {
                                    uploadFile(e.target.files[0])
                                }} style={{ visibility: 'hidden', width: 0 }} /> */}
                                {/* <label htmlFor="actual-btn">Upload Invoice</label> */}
                            </li>
                            <li style={{ pointerEvents: 'none', opacity: 0.4 }}
                                onClick={() => { tog_large(); closeDropDown() }}>Create Folder</li>
                            <li style={{ pointerEvents: 'none', opacity: 0.4 }}
                                onClick={openSubMenu} className='folder-upload-icon'>Folder Upload
                                <i className={showSubMenu ? 'bx bx-chevron-left' : 'bx bx-chevron-right'}></i>
                                {showSubMenu && (
                                    <div className='drop-submenu'>
                                        <ul>
                                            <li onClick={() => { setFileUploadModal(!fileUploadModal); closeDropDown() }}>Upload Invoice</li>
                                            <li>Upload Aadhar Back</li>
                                            <li>Upload W9 Forms</li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            {/* <li onClick={closeDropDown}>Upload Aadhar Back</li>
                            <li onClick={closeDropDown}>Upload W9 Forms </li> */}
                        </ul>
                    </div>
                </div>
            }
            <CreateFolder showModal={isCreateFolder} toggle={tog_large} />
            {fileUploadModal ?
                <UploadFiles
                    showModal={fileUploadModal}
                    toggle={toggleuploadModal}
                    close={setFileUploadModal}
                    modalTitle="Upload Files"
                    primaryBtn="Upload Files"
                    secountBtn="Cancel"

                />
                : ""
            }
        </div>

    );
};

export default FileUpload;