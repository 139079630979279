import {
    GET_ACTIVITIES,
    GET_ACTIVITIES_SUCCESS,
    GET_ACTIVITIES_FAIL,
} from "./actionTypes";

//Get Plans
const initialState = {
    error: "",
    data: null,
    loading: false,
};

export const GetActivities = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITIES:
            state = {
                error: '',
                data: null,
                loading: true,
            };
            break;
        case GET_ACTIVITIES_SUCCESS:
            state = {
                error: '',
                data: action.payload,
                loading: false,
            };
            break;
        case GET_ACTIVITIES_FAIL:
            state = {
                data: '',
                error: action.payload,
                loading: false,
            };
            break;
    }
    return state;
};
