import { v4 as uuid } from 'uuid';

function isBoundingBoxOverlapping(box1, box2) {
    return !(box1?.Left > box2?.Left + box2?.Width ||
        box1?.Left + box1?.Width < box2?.Left ||
        box1?.Top > box2?.Top + box2?.Height ||
        box1?.Top + box1?.Height < box2?.Top);
}

const convertAwsRectToPixel = (BoundingBox, document) => {
    // const invoiceImage = document.getElementById('invoice-image');
    // // const o_height = 1280;
    // // const o_width = 989;
    // const o_height = invoiceImage?.naturalHeight;
    // const o_width = invoiceImage?.naturalWidth;

    // const { height = 1, width = 1 } = document;
    const height = document?.height || 1;
    const width = document?.width || 1;

    const bounding = {};
    if (BoundingBox) {
        bounding["Width"] = width * BoundingBox.Width;
        bounding["Height"] = height * BoundingBox.Height;
        bounding["x"] = width * BoundingBox.Left;
        bounding["y"] = height * BoundingBox.Top;

        bounding['x2'] = (width * BoundingBox.Left) + (width * BoundingBox.Width);
        bounding['y2'] = (height * BoundingBox.Top) + (height * BoundingBox.Height);
    }

    return bounding;
}

function sortRowsByBoundingBoxX(rows) {
    return rows.map(row => row.sort((a, b) => a.BoundingBox.x - b.BoundingBox.x));
}

function settingAverageWidthForTableHeader(data) {
    const columnLength = data[0] && data[0]?.length;
    const tableHeader = JSON.parse(JSON.stringify(data[0]));
    for (let i = 0; i < columnLength; i++) {
        if ((i + 1) < columnLength) {
            const firstDiff = tableHeader[i + 1]?.BoundingBox?.x - tableHeader[i]?.BoundingBox?.x2;

            let secondDiff;
            if ((i + 2) < columnLength) {
                secondDiff = tableHeader[i + 2]?.BoundingBox?.x - tableHeader[i + 1]?.BoundingBox?.x2;
            }

            if ((firstDiff < secondDiff) || (firstDiff && !secondDiff)) {
                const val = firstDiff / 2;
                tableHeader[i].BoundingBox.x = val + tableHeader[i].BoundingBox.x;
                tableHeader[i].BoundingBox.x2 = val + tableHeader[i].BoundingBox.x2;
            } else if (firstDiff > secondDiff) {
                const val = secondDiff / 2;
                tableHeader[i].BoundingBox.x = val + tableHeader[i].BoundingBox.x;
                tableHeader[i].BoundingBox.x2 = val + tableHeader[i].BoundingBox.x2;
            }
        }
    }
    return tableHeader;
}

function fillMissingColumns(row, columnBoundaries) {
    const filledRow = [];
    // let skippedRow;

    const columns = JSON.parse(JSON.stringify(columnBoundaries));
    const currentRow = JSON.parse(JSON.stringify(row));

    for (let i = 0; i < columnBoundaries?.length; i++) {
        if ((i + 1) < columnBoundaries.length) {
            const filtered = currentRow?.filter((column, index) => {
                if (column?.BoundingBox?.x < columns[i]) {
                    columns[i] = null;
                    currentRow[index] = null;
                    return column;
                }
            })
            if (filtered.length > 0) {
                filledRow.push(filtered[0]);
            } else {
                filledRow.push({ id: uuid() });
            }
        } else {
            const filtered = currentRow?.filter((column, index) => {
                if (column?.BoundingBox?.x2 > columns[i]) {
                    columns[i] = null;
                    currentRow[index] = null;
                    return column;
                }
            })
            if (filtered.length > 0) {
                filledRow.push(filtered[0]);
            } else {
                filledRow.push({ id: uuid() });
            }
        }
    }

    // for (let i = 0; i < columnBoundaries?.length; i++) {
    //     if ((row[i]?.BoundingBox?.x < columnBoundaries[i])) {
    //         filledRow[i] = row[i];
    //     } else if (((i + 1) === columnBoundaries?.length) || (skippedRow?.BoundingBox?.x < columnBoundaries[i])) {
    //         filledRow[i] = skippedRow || '';
    //         if (row[i]) {
    //             skippedRow = row[i];
    //         }
    //     } else {
    //         if (row[i]) {
    //             skippedRow = row[i];
    //         }
    //         filledRow[i] = ''
    //     }
    // }

    return filledRow;
}

const missingCellAdded = (rowWiseData, newTableHeader) => {

    // Define the column boundaries based on expected x values from row1 (4 columns)
    const columnBoundaries = newTableHeader?.map(item => item.BoundingBox.x2);

    for (let i = 0; i < rowWiseData?.length; i++) {
        if ((rowWiseData[i]?.length !== columnBoundaries?.length) && rowWiseData[i]) {
            const filledRow = fillMissingColumns(rowWiseData[i], columnBoundaries);
            rowWiseData[i] = filledRow;
        }
    }
    return rowWiseData;
}

function transformToRowWiseDataWithBoundingBox(ocrData) {
    // Function to check if two bounding boxes are in the same row with dynamic tolerance
    function isSameRow(box1, box2, tolerance) {
        return Math.abs(box1.y - box2.y) <= tolerance;
    }

    // Calculate the average height of the bounding boxes
    const averageHeight = ocrData.reduce((sum, item) => sum + item.BoundingBox.Height, 0) / ocrData.length;

    // Set the tolerance based on average height
    const tolerance = Math.max(averageHeight * 0.5, 5); // 50% of the average height or at least 5 pixels

    // Array to hold the row-wise data
    const rowWiseData = [];
    let currentRow = [];
    let lastY = ocrData[0]?.BoundingBox?.y;

    for (let i = 0; i < ocrData?.length; i++) {
        const item = ocrData[i];
        const box = item.BoundingBox;

        // If the current item's y coordinate is significantly different from lastY, start a new row
        if (!isSameRow({ y: lastY }, box, tolerance)) {
            rowWiseData.push(currentRow);
            currentRow = [];
            lastY = box.y;
        }

        currentRow.push({
            id: item?.id,
            DetectedText: item.DetectedText,
            BoundingBox: item.BoundingBox,
            Confidence: item?.Confidence
        });
    }

    // Adding the last row
    if (currentRow.length > 0) {
        rowWiseData.push(currentRow);
    }

    const sortedData = sortRowsByBoundingBoxX(rowWiseData);

    const newTableHeader = settingAverageWidthForTableHeader(sortedData);
    const newTableRecord = missingCellAdded(rowWiseData, newTableHeader);
    return newTableRecord;
}



export function groupLabeledTexts(labels, labeledTexts, document) {
    const groupedTexts = [];

    labels.forEach(label => {
        const labelBoundingBox = label?.Geometry?.BoundingBox;

        const group = {
            id: uuid(),
            labelName: label?.Name,
            labeledTexts: [],
            BoundingBox: convertAwsRectToPixel(labelBoundingBox, document),
            Confidence: label?.Confidence
        };

        labeledTexts.forEach(text => {
            const newText = { ...text, id: text?.id ? text?.id : uuid() }

            if (text.LabelName === label.Name &&
                isBoundingBoxOverlapping(labelBoundingBox, newText.BoundingBox)) {
                if (newText?.BoundingBox) {
                    newText.BoundingBox = convertAwsRectToPixel(newText?.BoundingBox, document);
                    group.labeledTexts.push(newText);
                }
            }
        });

        if (group?.labelName === 'table_data') {
            if (group?.labeledTexts?.length > 0) {
                const rowWiseData = transformToRowWiseDataWithBoundingBox(group?.labeledTexts);
                groupedTexts.push({
                    id: uuid(),
                    labelName: label.Name,
                    labeledTexts: rowWiseData,
                    BoundingBox: convertAwsRectToPixel(labelBoundingBox, document),
                    Confidence: label?.Confidence
                });
            }
        } else {
            groupedTexts.push(group);
        }
    });

    return groupedTexts;
}

export const convertPixelToAwsRect = (bounding) => {
    const invoiceImage = document.getElementById('invoice-image');
    const o_height = invoiceImage?.naturalHeight;
    const o_width = invoiceImage?.naturalWidth;

    const BoundingBox = {};
    BoundingBox["Width"] = bounding?.Width / o_width;
    BoundingBox["Height"] = bounding?.Height / o_height;
    BoundingBox["Left"] = bounding?.x / o_width;
    BoundingBox["Top"] = bounding?.y / o_height;

    return BoundingBox;
}