import React, { useEffect, useMemo, useState } from 'react';
import { CardBody } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';

//Components
import RadioButton from './RadioButton';
import PrimaryButton from './Buttons/PrimaryButton';
import SecountryBtn from './Buttons/SecountryBtn';
import useClickOutside from 'components/CommonForBoth/UseDetectOutsideClick';
//rsuite
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import isAfter from 'date-fns/isAfter';
//date fns
import { subDays, startOfWeek, endOfWeek, addDays, startOfMonth, endOfMonth, addMonths } from 'date-fns';
//date fns
//rsuite

const DatePickerDropDown = ({ setDate }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [dateRange, setDateRange] = useState(null)

    const showAssignUser = () => {
        setShowDropdown(!showDropdown);
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (startDate && endDate) {
            const dateB = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
            const dateE = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);

            dateB.setUTCHours(0, 0, 0, 0)
            dateE.setUTCHours(23, 59, 59, 59)

            setDateRange({ start: dateB.toISOString(), end: dateE.toISOString() })
        }
        else setDateRange(null)
    }, [startDate, endDate])

    useEffect(() => {
        setDate(dateRange)
    }, [dateRange])

    const predefinedRanges = [
        {
            label: 'Reset',
            value: [new Date(), new Date()],
            placement: 'bottom',
            disabled: false,
            appearance: 'default',
            id: 'reset'
        },
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'Last week',
            closeOverlay: false,
            placement: 'left',
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            }
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        // {
        //     label: 'This year',
        //     value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        //     placement: 'left'
        // },
        // {
        //     label: 'Last year',
        //     value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        //     placement: 'left'
        // },
        // {
        //     label: 'All time',
        //     value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        //     placement: 'left'
        // }

    ];

    return (
        <>
            <div >
                <div className="date-range-picker">
                    <DateRangePicker
                        cleanable={false}
                        id="date-range-picker"
                        placement='rightStart'
                        ranges={predefinedRanges}
                        onOk={() => showAssignUser()}
                        defaultCalendarValue={[startDate, endDate]}
                        onChange={(e, name) => {
                            if (name.currentTarget.id === "reset") {
                                setStartDate(null)
                                setEndDate(null)
                            } else {
                                e ? setStartDate(e[0]) : setStartDate(null)
                                e ? setEndDate(e[1]) : setEndDate(null)
                            }
                        }}
                        shouldDisableDate={date => isAfter(date, new Date())}
                    />
                </div>
            </div>

        </>
    );
};

export default DatePickerDropDown;