
export const handleFilters = (roleFilter, searchValue, users, setFilterData, filterField) => {
    const userList = users;
    if ((roleFilter == null || roleFilter == "All") && (searchValue == null || searchValue == "")) {
        setFilterData(userList)
    } else if ((roleFilter !== null || roleFilter !== "All") && (searchValue == null || searchValue == "")) {
        if (users) {
            const filterData = userList.filter(userdata => {
                let result = false;
                if ((userdata[filterField]) === roleFilter) {
                    result = true;
                }
                return result;
            });
            setFilterData(filterData)
        }
    } else if ((roleFilter == null || roleFilter == "All") && (searchValue !== null || searchValue !== "")) {
        const filterData = userList.filter(userdata => {
            let result = false;
            if (userdata.firstName.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1) {
                result = true;
            }
            else if (userdata.lastName.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1) {
                result = true;
            }
            else if (userdata.email.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1) {
                result = true;
            }
            return result;
        })
        setFilterData(filterData);
    } else if ((roleFilter !== null || roleFilter !== "All") && (searchValue !== null || searchValue !== "")) {
        const filterData = userList.filter(userdata => {
            let result = false;
            if ((userdata.firstName.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1)
                && (userdata[filterField] === roleFilter)) {
                result = true;
            }
            else if ((userdata.lastName.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1)
                && (userdata[filterField] === roleFilter)) {
                result = true;
            }
            else if ((userdata.email.toLowerCase().indexOf(searchValue && searchValue.toLowerCase()) !== -1)
                && (userdata[filterField] === roleFilter)) {
                result = true;
            }
            return result;
        })
        setFilterData(filterData);
    }
}