import React, { useEffect, useRef, useState } from 'react';
import { CardBody, Collapse, Label } from 'reactstrap';
import Select from "react-select";
import ReactDatePicker from 'react-datepicker';
import classnames from "classnames";

//Components
import PrimaryButton from './Buttons/PrimaryButton';
import SecountryBtn from './Buttons/SecountryBtn';
import useClickOutside from 'components/CommonForBoth/UseDetectOutsideClick';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const SelectDropDown = ({ setFilter, translate, setCleared, cleared }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [docTypesValue, setDocTypesValue] = useState([])
    const [docStatusValue, setDocStatusValue] = useState([])
    const [assignedToValue, setAssignedToValue] = useState([])

    const docTypes = useSelector(state => state.GetDocTypes.data?.data)
    const docStatus = useSelector(state => state.GetDocStatus.data?.data)
    const assignedUsers = useSelector(state => state.GetAssignedUsers.data?.data)

    const getRole = JSON.parse(localStorage.getItem("authUser"));

    const handleAccordian = (col, setCol) => {
        let value = col
        setcol1(false);
        setcol2(false);
        setcol3(false);
        setCol(!value)
    };

    const showAssignUser = () => {
        setShowDropdown(!showDropdown);
        setIsOpen(!isOpen);
    }

    const insertValues = (value, setState, stateValue) => {
        let types = stateValue

        const index = types.indexOf(value);

        if (index > -1) types.splice(index, 1)
        else types.push(value)

        setState(types)
    }
    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef?.current?.contains(e.target)) {
                setShowDropdown(false);

            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, []);

    useEffect(() => {
        if (cleared === true) {
            setDocTypesValue([]);
            setAssignedToValue([]);
        };
        setcol1(false);
        setcol2(false);
        setcol3(false);
    }, [cleared])

    return (
        <>
            <div className='doc-filters' onClick={() => { showAssignUser() }}><i className='bx bx-filter-alt'></i></div>
            {showDropdown ?
                <div ref={menuRef}>
                    <div className={`assign-user select-group ${isOpen ? 'open-filter' : 'close-filter'}`}>
                        <CardBody>
                            <h2>{translate('filters')}</h2>
                            <div className=''>
                                <div className='users-list'>
                                    <div className="accordion" id="accordion">
                                        <div className="accordion-item" style={{ marginBottom: "10px", borderRadius: "10px" }}>
                                            <h2 className="accordion-header" id="headingOne" style={{ padding: 0 }}>
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col1 }
                                                    )}
                                                    type="button"
                                                    onClick={() => handleAccordian(col1, setcol1)}
                                                    style={{ cursor: "pointer", background: "transparent" }}
                                                >
                                                    {translate('docType')}
                                                </button>
                                            </h2>

                                            <Collapse isOpen={col1} className="accordion-collapse" style={{ borderTop: `1px solid rgba(0,0,0,0.13)` }}>
                                                <div className="checkboxes">
                                                    {docTypes?.map((item, index) => {
                                                        return <div key={index} className='checkbox-item'>
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="checkbox"
                                                                defaultChecked={docTypesValue.includes(item?.value) ? true : false}
                                                                value={item?.value}
                                                                id={item._id}
                                                                onChange={(e) => {
                                                                    insertValues(e.target.value, setDocTypesValue, docTypesValue)
                                                                }}
                                                            />
                                                            <Label htmlFor={item._id}>{item?.label}</Label>
                                                        </div>
                                                    })}
                                                </div>
                                            </Collapse>
                                        </div>
                                        {/* <div className="accordion-item" style={{ marginBottom: "10px", borderRadius: "10px" }}>
                                            <h2 className="accordion-header" id="headingTwo" style={{ padding: 0 }} >
                                                <button
                                                    className={classNames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col2 }
                                                    )}
                                                    type="button"
                                                    onClick={() => handleAccordian(col2, setcol2)}
                                                    style={{ cursor: "pointer", background: "transparent" }}
                                                >
                                                    Status Types
                                                </button>
                                            </h2>

                                            <Collapse isOpen={col2} className="accordion-collapse" style={{ borderTop: `1px solid rgba(0,0,0,0.13)` }}>
                                                <div className="checkboxes">
                                                    {docStatus?.map((item, index) => {
                                                        return <div key={index} className='checkbox-item'>
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="checkbox"
                                                                defaultChecked={docStatusValue.includes(item?.value) ? true : false}
                                                                value={item?.value}
                                                                id={item._id}
                                                                onChange={(e) => {
                                                                    insertValues(e.target.value, setDocStatusValue, docStatusValue)
                                                                }}
                                                            />
                                                            <Label htmlFor={item._id}>{item?.label}</Label>
                                                        </div>
                                                    })}
                                                </div>
                                            </Collapse>
                                        </div> */}
                                        {getRole?.user?.role !== "USER" && <div className="accordion-item" style={{ borderRadius: "10px", }}>
                                            <h2 className="accordion-header" id="headingThree" style={{ padding: 0 }}>
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col3 }
                                                    )}
                                                    type="button"
                                                    onClick={() => handleAccordian(col3, setcol3)}
                                                    style={{ cursor: "pointer", background: "transparent" }}
                                                >
                                                    {translate('selectUsers')}
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col3} className="accordion-collapse" style={{ borderTop: `1px solid rgba(0,0,0,0.13)` }}>
                                                <div className="checkboxes">
                                                    {(assignedUsers && assignedUsers?.length > 0) ? assignedUsers?.map((item, index) => {
                                                        return <div key={index} className='checkbox-item'>
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="checkbox"
                                                                defaultChecked={assignedToValue.includes(item?.assignedPersonDetails?._id) ? true : false}
                                                                value={item?.assignedPersonDetails?._id}
                                                                id={item?.assignedPersonDetails?._id}
                                                                onChange={(e) => {
                                                                    insertValues(e.target.value, setAssignedToValue, assignedToValue)
                                                                }}
                                                            />
                                                            <Label htmlFor={item?.assignedPersonDetails?._id}>{item?.assignedPersonDetails?.fullName}</Label>
                                                        </div>
                                                    }) : <div className='text-center'>{translate('assignedError')}</div>
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className='filter-btn'>
                                <SecountryBtn btnName={translate('cancel')} showIcon={false} onClick={() => { setShowDropdown(false) }} />
                                <PrimaryButton btnName={translate('apply')} showIcon={false} onClick={() => {
                                    setFilter({ docTypes: docTypesValue, docStatus: docStatusValue, assignedTo: assignedToValue })
                                    if (docTypesValue?.length > 0 || assignedToValue?.length > 0) {
                                        setCleared(false);
                                    };
                                    setShowDropdown(false);
                                }} />
                            </div>
                        </CardBody>
                    </div>
                </div>
                : null}
        </>
    );
};

export default SelectDropDown;