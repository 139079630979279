import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';

//Components
import UploadPlaceholder from 'components/Common/PlaceholderImage/UploadPlaceholder';
import TableContainer from 'components/Common/TableContainer';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import AssignUser from 'components/Common/AssignUser';
import DatePickerDropDown from 'components/Common/DatePickerDropDown';
import SelectDropDown from 'components/Common/SelectDropDown';
import SearchBar from 'components/Common/SearchBar';

//Assets
import pageicon from '../../../assets/images/sm-image.png';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import TrainingCompleted from 'components/Common/PlaceholderImage/TrainingCompleted';
import TrainProgress from 'components/Common/PlaceholderImage/TrainProgress';
import tableFileIcon from '../../../assets/images/icons/table-file.svg';
import UndoIcon from "../../../assets/images/icons/undo.svg";
import uploadDoc from '../../../assets/images/icons/upload-doc.svg';

//Store
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getModelsDoc, getModelsDocSuccess, modelsDocDelete, reUplaod, trainModel, uploadModelDocument } from 'store/actions';
import { useHistory } from 'react-router-dom';
import Confirmation from 'components/Common/Modals/Confirmation';
import { toast } from 'react-hot-toast';
import ImageViewModel from 'components/Common/Modals/ImageViewModel';
import TableSkeleton from 'components/Common/TableSkeleton';
import { mergeManifest } from 'constants/Review/utils/manifestFile';
import UploadModelDocuments from 'components/Common/Modals/UploadModelDocuments';
import Loader from 'components/Common/Loader';
import { useSocket } from 'App';
import { GET_DOC_MODELS } from 'helpers/application_url_helper';
import { post } from 'helpers/api_helper';
import { pageViewed } from 'store/uploadFiles/action';



const TrainModel = ({ setSelectedModel, id, translate }) => {
    document.title = "Models | Yenmin AI";

    const socket = useSocket();

    const history = useHistory();
    const dispatch = useDispatch()
    const [date, setDate] = useState(null);
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [imageOpen, setImageOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [sortBy, setsortBy] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [showInfoModel, setShowInfoModel] = useState(false);
    const [success, setSuccess] = useState(null);
    const [showUploadFilesModel, setShowUploadFilesModel] = useState(false);

    const dataPerPage = process.env.REACT_APP_TABLE_DATA_PER_PAGE;

    const uploadData = useSelector(state => state.GetModelsDoc?.data);
    const uploadDataLoading = useSelector(state => state.GetModelsDoc?.loading);
    const authUser = useSelector(state => state?.authInfo?.data)
    const modelDetails = useSelector(state => state?.CreateModal?.data?.data, shallowEqual)
    const documentUploadLoader = useSelector(state => state?.ModelDocumentUpload?.loading)
    const modelData = useSelector(state => state?.GetModelById?.data?.data);
    const modelDeleteLoader = useSelector(state => state?.DeleteModelsDoc.loading);
    const trainModelLoader = useSelector(state => state?.TrainModel.loading);

    const handleDeleteModels = (id) => {
        setModelId(id);
        toggleModal();
    }
    const deleteModelList = () => {
        dispatch(modelsDocDelete(modelId, callGetDocuments))
        setConfirmModal(false)
    }

    const toggleImageModel = () => {
        setImageOpen(!imageOpen)
    }

    function toggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    const getData = () => {
        const data = {};
        data['currentPage'] = currentPage;
        if (sortBy) {
            data['sort'] = sortBy;
        } else {
            data['sort'] = {
                value: -1,
                field: 'createdAt',
                type: 'date'
            };
        };
        data['dataPerPage'] = dataPerPage;
        data['modelId'] = id;
        return data;
    }

    const callGetDocuments = () => {
        const data = getData();
        if (data) dispatch(getModelsDoc(data));;
    };

    const handleReUpload = (record) => {
        const data = {};
        data['document_id'] = record?._id;
        dispatch(reUplaod(data, callGetDocuments, 'models'));
    };

    useEffect(() => {
        if (id) {
            const data = getData();
            dispatch(getModelsDoc(data));
        }
    }, [id, currentPage, sortBy, refresh])

    const handleNavigateToReview = (data) => {
        if (data?.status === 'IN_PROGRESS') {
            setShowInfoModel(true);
            setSuccess(true);
        } else if (data?.status === 'FAILED') {
            setShowInfoModel(true);
            setSuccess(false);
        } else {
            history.push("/models/" + data._id + "/model");
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: ' ',
                Cell: cellProps => {
                    return (
                        <div>
                            {/* <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                />
                            </div> */}
                            <div>
                                <p className='m-0'>{((currentPage - 1) * dataPerPage) + (cellProps?.row?.index + 1)}</p>
                            </div>
                        </div>
                    )
                }
            },
            // {
            //     Header: '  ',
            //     Cell: cellProps => {
            //         return (
            //             <div>
            //                 <img src={tableFileIcon} alt='tableFileIcon' width={'16px'} />
            //             </div>
            //         )
            //     }
            // },
            // {
            //     Header: '    ',
            //     Cell: cellProps => {
            //         return (
            //             <div className='file-preview'>
            //                 <div className='image-overlay' onClick={() => {
            //                     setImage({
            //                         path: cellProps?.row?.original?.path || imagePath,
            //                         name: cellProps.row.original?.fileName || 'document-image'
            //                     });
            //                     setImageOpen(true);
            //                 }}><i className='mdi mdi-eye'></i></div>
            //                 <img style={{ cursor: 'pointer' }}
            //                     className='rounded'
            //                     src={cellProps?.row?.original?.path}
            //                     alt={cellProps.row.original?.fileName}
            //                     width="50px"
            //                 />
            //                 {/* <img src={pageicon} alt='page' width="40px" /> */}

            //             </div>
            //         )
            //     }
            // },
            {
                Header: 'File Name',
                accessor: 'fileName',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'fileName',
                Cell: cellProps => <div onClick={() => handleNavigateToReview(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.fileName}</div>
            },
            {
                Header: 'Review Status',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'trained',
                Cell: cellProps => {
                    return (
                        <p style={{ cursor: 'pointer' }} onClick={() => handleNavigateToReview(cellProps.row.original)} className={`status-review ${cellProps.row.original?.trained?.toLowerCase()}`}>{cellProps.row.original?.trained?.toLowerCase()}</p>
                    );
                }
            },
            {
                Header: 'Status',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'status',
                Cell: cellProps => {
                    return (
                        <div onClick={() => handleNavigateToReview(cellProps.row.original)} style={{ cursor: 'pointer' }} className={`file-status ${cellProps?.row?.original?.status?.toLowerCase()}`}>
                            <p>{cellProps.row.original?.status?.toLowerCase()?.replaceAll("_", " ")}</p>
                        </div>
                    )
                }
            },
            {
                Header: 'Trained by',
                accessor: 'trainedPersonDetails.fullName',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'trainedPersonDetails',
                subField: 'fullName',
                Cell: cellProps => <div onClick={() => handleNavigateToReview(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.trainedPersonDetails?.fullName}</div>
            },
            {
                Header: 'Uploaded by',
                accessor: 'creatorDetails.fullName',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'creatorDetails',
                subField: 'fullName',
                Cell: cellProps => <div onClick={() => handleNavigateToReview(cellProps.row.original)} style={{ cursor: 'pointer' }} >{cellProps.row.original?.creatorDetails?.fullName}</div>
            },
            {
                Header: 'Uploaded on',
                customSort: true,
                sortType: 'date',
                enableSort: true,
                id: "createdAt",
                Cell: cellProps => {
                    return (
                        <div onClick={() => handleNavigateToReview(cellProps.row.original)} style={{ cursor: 'pointer' }}>
                            {cellProps.row.original?.createdAt && moment(cellProps.row.original?.createdAt).format('DD/MM/YYYY')}
                        </div>
                    )
                }
            },
            {
                Header: '     ',
                Cell: cellProps => {
                    return (
                        <div className=''>
                            <div className="more-action">
                                <i className='bx bx-dots-horizontal-rounded font-size-18'></i>
                            </div>
                            <div className="file-action-btns">
                                {cellProps.row.original?.status !== 'IN_PROGRESS' &&
                                    <ul className='d-flex p-0'>
                                        {
                                            cellProps.row.original?.status === 'FAILED' &&
                                            <li id={`re-upload_${cellProps.row.original?._id}`} onClick={() => { handleReUpload(cellProps.row.original) }}>
                                                <img src={uploadDoc} alt='uploadDoc' />
                                                <UncontrolledTooltip placement="top" target={`re-upload_${cellProps.row.original?._id}`}>
                                                    Re-upload
                                                </UncontrolledTooltip>
                                            </li>
                                        }
                                        <li id={`delete_${cellProps.row.original?._id}`} onClick={() => { handleDeleteModels(cellProps.row.original?._id) }}>
                                            <img src={deleteIcon} alt='delete-icon' />
                                            <UncontrolledTooltip placement="top" target={`delete_${cellProps.row.original?._id}`}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </li>
                                    </ul>
                                }
                            </div>

                        </div>
                    )
                }
            },
        ],
        [currentPage]
    );

    const handleTrainDocument = () => {
        let trainingData = [];
        uploadData && uploadData?.pageData?.map((item) => {
            if (item?.trained !== "DRAFT") {
                trainingData.push(item?.label_data);
            }
        });

        if (trainingData?.length >= 5) {
            const length = trainingData?.length;

            const eightyPercentIndex = Math.ceil(length * 0.8);
            const twentyPercentIndex = Math.ceil(length * 0.2);

            // Getting 80% of the array from the start
            const eightyPercentArray = trainingData.slice(0, eightyPercentIndex);

            // Getting 20% of the array from the end
            const twentyPercentArray = trainingData.slice(-twentyPercentIndex);

            const data = {};
            data['model_id'] = modelData?._id;
            data['trainFile'] = mergeManifest(eightyPercentArray, modelData?._id);
            data['testFile'] = mergeManifest(twentyPercentArray, modelData?._id);

            dispatch(trainModel(data));
        } else {
            toast.error("Atleast five documents needs to be labelled to train model")
        };
    };

    const toggleInfoModal = () => {
        setShowInfoModel(!showInfoModel)
    }

    const toggleUploadFilesModel = () => {
        setShowUploadFilesModel(!showUploadFilesModel)
    }


    useEffect(() => {
        // Check if socket is initialized before using it
        if (socket) {
            // Listen for real-time user list updates
            socket.on('modelDocumentUpdated', async () => {
                try {
                    const data = getData();
                    const response = await post(GET_DOC_MODELS, data);
                    dispatch(getModelsDocSuccess(response))
                } catch (error) {
                    console.log("error: ", error);
                }
            });

            // Clean up the socket event listener when component unmounts
            return () => socket.off('modelDocumentUpdated');
        }
    }, [socket, getData]);

    useEffect(() => {
        dispatch(pageViewed("train_model"));
        
        return () => {
            dispatch(pageViewed(""));
        }
    }, [])

    return (
        <div>
            <Card>
                <CardBody>

                    {!uploadDataLoading && !modelDeleteLoader ? "" : <Loader />}
                    {uploadData && uploadData?.pageData?.length > 0 ? (
                        <div>
                            <div className='table-filters'>
                                <div className=''>
                                    <h5 className='m-0 table-heading'>Uploaded documents</h5>
                                </div>
                                <div id='refresh' className='doc-filters me-2' style={{ cursor: 'pointer' }} onClick={() => setRefresh(prev => prev + 1)}>
                                    <img src={UndoIcon} alt="refresh" />
                                </div>
                                <UncontrolledTooltip placement="top" target={`refresh`}>Refresh</UncontrolledTooltip>
                            </div>
                            <TableContainer
                                columns={columns}
                                data={uploadData?.pageData || []}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={dataPerPage}
                                iSPageOptions={true}
                                customPagination={true}
                                sortBy={sortBy}
                                setsortBy={setsortBy}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                totalCount={uploadData?.totalCount}
                                className="custom-header-css my-doc"
                            />
                        </div>
                    ) : (
                        <UploadPlaceholder modelId={id} authUser={authUser} />
                        // <TrainingCompleted/>
                        // <TrainProgress/> 
                    )}
                </CardBody>
            </Card>
            {uploadData && uploadData?.pageData?.length > 0 ?
                <div className='filter-btn download-btn d-flex'>
                    {/* <PrimaryButton btnName="Train Document" showIcon={false} /> */}
                    <SecountryBtn btnName="Back" onClick={() => history.push(`/models/edit-model/${id}/1`)} showIcon={false} />
                    <span className='train-doc'><SecountryBtn disabled={documentUploadLoader} loader={trainModelLoader} btnName="Train Model" onClick={() => handleTrainDocument()} showIcon={false} /></span>
                    <PrimaryButton
                        btnName={`Upload Sample`}
                        onClick={toggleUploadFilesModel}
                        showIcon={false}
                    />
                </div>
                : ''}
            <Confirmation
                showModal={isConfirmModal}
                toggle={toggleModal}
                primaryAction={deleteModelList}
                modalTitle="Delete Model Document"
                primaryBtn="Delete Model Doc"
                secountBtn="Cancel"
                description="Are you sure you want to delete the model dodument?"
                loader={modelDeleteLoader}
            />
            {(!uploadData || uploadData?.pageData?.length === 0) && <SecountryBtn btnName="Back" onClick={() => history.push(`/models/edit-model/${id}/1`)} showIcon={false} />}

            <ImageViewModel
                toggle={() => toggleImageModel()}
                showModal={imageOpen}
                path={image?.path}
                name={image?.name}
            />

            {/* document view when status in-progress or failed*/}
            <Confirmation
                showModal={showInfoModel}
                toggle={toggleInfoModal}
                primaryAction={() => toggleInfoModal()}
                modalTitle={`Info`}
                primaryBtn={"Ok"}
                hideCancel={true}
                description={success === true ? 'The document is under processing, please try to view in some other time.' : 'The document upload is failed, please re-upload the document.'}
            />

            {/* Upload samples model */}
            <UploadModelDocuments
                modalTitle={`Upload Files`}
                primaryBtnName={`Upload Files`}
                secoundaryBtnName={`Cancel`}
                showModal={showUploadFilesModel}
                toggle={toggleUploadFilesModel}
                id={id}
            />
        </div>
    );
};

export default TrainModel;