import React, { memo } from 'react';
import { Modal } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

const Copylink = ({ showModal, toggle, link, copylinkAction }) => {
    return (
        <div>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='copy-link-model create-folder'
                toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item' style={{ display: 'flex', justifyContent: 'end' }}>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <div className='action' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className='link-field'>{link}</p>
                        <p className='copy-link' onClick={() => copylinkAction()}>Copy link</p>
                    </div>
                </div>
            </Modal >
        </div >
    );
};

export default memo(Copylink);