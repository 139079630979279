import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

//Components
import { useHistory, useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { getModelDocumentById, trainModelDocument } from 'store/actions';
import { useSelector } from 'react-redux';
import Breadcrumb from 'components/Common/Breadcrumb';
import ReviewPage from 'constants/Review/ReviewPage';
import getOriginalWidthAndHeight from 'constants/Review/utils/getOriginalImageWidthAndHeight';
import { withTranslation } from 'react-i18next';
import { convertPixelToAwsRect, groupLabeledTexts } from 'constants/Review/utils/jsonConvertion';
import { createManifest } from 'constants/Review/utils/manifestFile';
import getDifference from 'constants/Review/utils/getDifference';

const ModelReview = (props) => {
    document.title = "Review | Yenmin AI ";

    const { t } = props;
    const { id } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    const documentDetails = useSelector(state => state?.GetModelDocumentById?.data?.data);
    const documentLoading = useSelector(state => state?.GetModelDocumentById?.loading);
    const traindcumentLoading = useSelector(state => state?.TrainDocument?.loading);

    const [positions, setPositions] = useState();
    const [dbDocumentDetails, setDbDocumentDetails] = useState();

    useEffect(() => {
        if (id) {
            dispatch(getModelDocumentById(id));
        };

        const body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
        return () => {
            body.style.overflowY = 'auto';
        };
    }, [id]);

    const previousPage = () => {
        history.push(`/models/edit-model/${documentDetails?.document?.model_id}/2`)
    };

    useEffect(() => {
        // converting extracting json 
        const labels = documentDetails?.document?.result_data?.labels ? documentDetails?.document?.result_data?.labels : [];
        const labeledTexts = documentDetails?.document?.result_data?.labeled_texts ? documentDetails?.document?.result_data?.labeled_texts : [];

        const groupedData = groupLabeledTexts(labels, labeledTexts, documentDetails?.document);

        let newLabels = [];
        documentDetails?.document?.model_details?.fields?.labels?.map((item => {
            const filtered = groupedData?.filter((filterItem) => filterItem?.labelName?.toLowerCase() === item?.Name?.toLowerCase()?.split(" ")?.join("_"))
            if (filtered.length > 0) {
                newLabels.push(filtered[0]);
            }
        }))

        setDbDocumentDetails({
            ...documentDetails,
            fieldDetails: newLabels
        });

        // setPositions(positionFields);
        setPositions(groupedData);
    }, [documentDetails]);

    return (
        <React.Fragment>
            <div className='review-layout' id='review-layout'>
                <div className="page-content" >
                    <Container fluid className="p-0">
                        <div className='review-breadcrumb'>
                            <Breadcrumb title="Models" dashurl={`/models/edit-model/${documentDetails?.document?.model_id}/2`} breadcrumbItem={documentDetails?.document?.fileName + ": " + 'Labelling'} goBack={true} documentLoading={documentLoading} />
                        </div>

                        <ReviewPage
                            documentDetails={dbDocumentDetails}
                            setDocumentDetails={setDbDocumentDetails}
                            documentLoading={documentLoading || traindcumentLoading}
                            previousPage={previousPage}
                            id={id}
                            createPosition={true}
                            train={true}
                            setPositions={setPositions}
                            positions={positions}
                            // trainDocument={trainDocument}
                            translate={t} />

                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(ModelReview));