import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
// import { useFormik } from "formik";
// action
import { userForgetPasswordResendCode, userResetPassword } from "../../store/actions";

// import images
import { useParams } from 'react-router-dom';
import PasswordStrengthMeter from "constants/PasswordStrengthMeter";
import AuthLoader from "components/Common/AuthLoader";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import AppLogo from '../../assets/images/app_logo.svg';
import AppLogoWhite from '../../assets/images/logo-white.svg';
import { withTranslation } from "react-i18next";


const ResetPassword = props => {
    let { email } = useParams();
    const [passwordNew, setPasswordNew] = useState(false);
    const [passwordConf, setPasswordConf] = useState(false);
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [passwordValidate, setPasswordValidate] = useState('')
    const [getpassword, setGetPassword] = useState('')

    //meta title
    document.title = "Reset Password | Yenmin AI";

    const dispatch = useDispatch();

    const resetFormValues = {
        email: email,
        pwd: '',
        confPwd: '',
        verificationToken: ''
    };
    const validationSchema = Yup.object({
        pwd: Yup.string().required(`${props.t("newpwsError")}`).test("pwd_length", `${props.t('pwdlength')}`, val => passwordValidate >= 2),
        confPwd: Yup.string().required(`${props.t('confirmPws')}`).when("pwd", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("pwd")],
                `${props.t('pwsMatched')}`
            )
        }),
        verificationToken: Yup.string().required(`${props.t('verificationError')}`),
    })

    const { register, handleSubmit, clearErrors, setError, formState: { errors, }, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: resetFormValues
    });

    const handleChange = (event) => {
        setGetPassword(event.target.value);
        if (event.target.value?.length > 0) {
            clearErrors("pwd")
        } else {
            setError('pwd', { type: 'custom', message: 'Enter new password' });
        }
    };

    const onSubmit = (values) => {
        dispatch(userResetPassword(values, props.history));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    const resendOTP = () => {
        setMinutes(1);
        setSeconds(30);
        dispatch(userForgetPasswordResendCode({ email }))
    };
    const [password, setPassword] = useState('')
    useEffect(() => {
        setPassword(getpassword)
    }, [getpassword])

    const { Loader } = useSelector(state => ({
        Loader: state.ResetPassword.resetLoader
    }));

    return (
        <React.Fragment>

            <div className="account-pages">

                <Container fluid>
                    <Row className="h-100">
                        <Col lg={8} className="login-bg">
                            <div className="logo">
                                <img src={AppLogoWhite} alt="AppLogoWhite" />
                            </div>
                            <div className="welcome-content">
                                <h1>Welcome to <br />Yenmin AI invoice capture</h1>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac semper orci, eu porttitor lacus. </p> */}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="welcome-form">
                                <div className="text-center auth-logo">
                                    <img src={AppLogo} alt="Logo" />
                                </div>
                                <div className="text-center auth-content">
                                    <h4>{props.t('resetPsd')}</h4>
                                    <p>{props.t('resetDes')}</p>
                                </div>
                                <form
                                    className="form-horizontal"
                                    onSubmit={handleSubmit(onSubmit)}
                                >

                                    <div className="mb-3">
                                        <Label className="form-label mt-3 f-size-14">{props.t('verifyCode')} <span>*</span></Label>
                                        <input type="number" {...register("verificationToken")} onChange={(e) => {
                                            if (e.target.value?.length === 0) {
                                                setError('verificationToken', { type: 'custom', message: 'Enter verification code' });
                                            } else {
                                                clearErrors("verificationToken")
                                            }
                                        }} placeholder="xxxxxx" className={errors.verificationToken?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.verificationToken?.message ? <p className={`invalid-feedback`} >{errors.verificationToken?.message}</p> : null}
                                        <div className="countdown-text">
                                            {seconds > 0 || minutes > 0 ? (
                                                <p className="f-size-12 timing">{props.t('timeRemaining')}: <span className="remaining-time">{minutes < 10 ? `0${minutes}` : minutes}:
                                                    {seconds < 10 ? `0${seconds}` : seconds}</span>
                                                </p>
                                            ) : (
                                                <p className="f-size-12 timing">{props.t('notReceive')}</p>
                                            )}

                                            <button className="border-0 bg-transparent p-0 resend-code"
                                                disabled={seconds > 0 || minutes > 0}
                                                style={{
                                                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#DF0C00",
                                                }}
                                                onClick={resendOTP}
                                            >
                                                {props.t('resendCode')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="show-password">

                                            <Label className="form-label f-size-14">{props.t('mewPsw')} <span>*</span></Label>
                                            <span>
                                                <button onClick={() => setPasswordNew(prevState => !prevState)} type="button" id="password-addon">
                                                    {passwordNew ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                                </button>
                                            </span>
                                        </div>
                                        <div className="input-group auth-pass-inputgroup">
                                            <input type={passwordNew ? "text" : "password"} {...register("pwd")} onChange={handleChange} placeholder="*******" className={errors.pwd?.message ? 'form-control is-invalid' : '' + 'form-control'} />

                                            <div className="w-100">
                                                <PasswordStrengthMeter password={password} setPasswordValidate={setPasswordValidate} />
                                            </div>
                                            {errors.pwd?.message ? <p className={`invalid-feedback`} >{errors.pwd?.message}</p> : null}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="show-password">

                                            <Label className="form-label f-size-14">{props.t('confPsw')} <span>*</span></Label>
                                            <span>
                                                <button onClick={() => setPasswordConf(prevState => !prevState)} type="button" id="password-addon">
                                                    {passwordConf ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                                </button>
                                            </span>
                                        </div>
                                        <div className="input-group auth-pass-inputgroup">
                                            <input type={passwordConf ? "text" : "password"} {...register("confPwd")} onChange={(e) => {
                                                if (e.target.value?.length === 0) {
                                                    setError('confPwd', { type: 'custom', message: 'Enter confirm password' });
                                                } else {
                                                    clearErrors("confPwd")
                                                }
                                            }} placeholder="*******" className={errors.confPwd?.message ? 'form-control is-invalid' : '' + 'form-control'} />

                                            {errors.confPwd?.message ? <p className={`invalid-feedback`} >{errors.confPwd?.message}</p> : null}
                                        </div>
                                    </div>
                                    {Loader === true ? <div className="d-grid"><AuthLoader /></div> :
                                        <div className="d-grid">
                                            <PrimaryButton btnType="submit" btnName={props.t('resetPsd')} />
                                        </div>
                                    }
                                    <p className="go-back f-size-12 my-3 text-center">
                                        {props.t('backTo')}{" "}
                                        <Link to="/login" className="font-weight-medium primary-color f-size-12">
                                            {props.t('login')}
                                        </Link>{" "}
                                    </p>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ResetPassword.propTypes = {
    history: PropTypes.object,
};

export default withRouter(withTranslation()(ResetPassword));