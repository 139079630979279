import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_MODELS, DELETE_MODELS, DELETE_MODELS_MULTIPLE, CREATE_MODAL, UPLOAD_MODEL_DOCUMENT, GET_MODEL_BY_ID, PUBLISH_MODEL, GET_STARTED_MODEL, TRAIN_MODEL, PRE_MODEL_START_STOP, GET_PRE_MODEL, MODEL_StART_STOP } from "./actionTypes"
import { createModalError, createModalSuccess, deleteModelsError, deleteModelsSuccess, getModelByIdError, getModelByIdSuccess, getModelsListError, getModelsListSuccess, getPreModelError, getPreModelSuccess, getStartedModelError, getStartedModelSuccess, modelStartStopError, modelStartStopSuccess, mulityModelsDeleteError, mulityModelsDeleteSuccess, preModelStartStopError, preModelStartStopSuccess, publishModelError, publishModelSuccess, trainModelError, trainModelSuccess, updateModelById, uploadModelDocumentError, uploadModelDocumentSuccess } from "./actions";
import { deleteModels, getModules, createModal, deleteMulityModels, uploadModelDocument, getModelById, publishModel, trainModel, preModelStartStopHelper, getPreModelHelper, modelStartStopHelper, getRunningModel } from "../../helpers/backend_helper";
import toast from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";

// Get Models
function* GetModels({ payload: data }) {
  try {
    const response = yield call(getModules, data)
    if (response.status === responseStatus.error) {
      toast.error(response.message)
      yield put(getModelsListError(error))
    } else if (response.status === responseStatus.success) {
      yield put(getModelsListSuccess(response))
    }
  } catch (error) {
    yield put(getModelsListError(error))
  }
}
export function* GetModelsSaga() {
  yield takeEvery(GET_MODELS, GetModels)
}

// Delete Models
function* DeleteModelRecord({ payload: { id, dispatch, getModelsList, modelpage } }) {
  try {
    const response = yield call(deleteModels, id)
    if (response.status === responseStatus.error) {
      toast.error(response.message)
    } else {
      yield put(deleteModelsSuccess(response))
      toast.success(response.message)
      dispatch(getModelsList(modelpage()))
    }
  } catch (error) {
    yield put(deleteModelsError(error))
    toast.error(error.response.data.message)
  }
}

export function* DeleteModelSaga() {
  yield takeEvery(DELETE_MODELS, DeleteModelRecord)
}

// Delete Mulity Models
function* DeleteMulityRecord({ payload: { id, dispatch, getModelsList, modelpage } }) {
  const deleteList = id.map((delItem) => {
    return delItem.id
  })
  const data = {
    "models": deleteList
  }
  try {
    const response = yield call(deleteMulityModels, data)
    if (response.status === responseStatus.error) {
      toast.error(response.message)
    } else {
      yield put(mulityModelsDeleteSuccess(response))
      toast.success(response.message)
      dispatch(getModelsList(modelpage()))
    }
  } catch (error) {
    yield put(mulityModelsDeleteError(error))
    toast.error(error.response.data.message)
  }
}

export function* DeleteMulitySaga() {
  yield takeEvery(DELETE_MODELS_MULTIPLE, DeleteMulityRecord)
}

//create modal
function* createModalFunction({ payload: { data, handleTabSelect, tab, history } }) {
  try {
    const response = yield call(createModal, data)
    if (response?.status?.toUpperCase() === responseStatus.error) {
      toast.error(response.message)
      yield put(createModalError(response))
    } else {
      yield put(createModalSuccess(response))
      // toast.success(response.message)
      if (history) {
        history.push(`/models/edit-model/${response?.data?._id}/${tab}`)
      } else if (tab)
        handleTabSelect(tab)
    }
  } catch (error) {
    yield put(createModalError(error))
    toast.error(error?.response?.data?.message)
  }
}

export function* CreateModalSaga() {
  yield takeEvery(CREATE_MODAL, createModalFunction)
}


//upload document
function* DocumentUpload({ payload: { data, afterUploadSuccess } }) {
  try {
    const response = yield call(uploadModelDocument, data, { headers: { "Content-Type": "multipart/form-data" } })
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(uploadModelDocumentError(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      afterUploadSuccess();
      yield put(uploadModelDocumentSuccess(response))
      toast.success(response.message)
    }
  } catch (error) {
    yield put(uploadModelDocumentError(error))
  }
}

export function* ModelDocumentUploadSaga() {
  yield takeEvery(UPLOAD_MODEL_DOCUMENT, DocumentUpload)
}

//get model by id
function* getmodelbyid({ payload: id }) {
  try {
    const response = yield call(getModelById, id)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getModelByIdError(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getModelByIdSuccess(response))
      // toast.success(response.message)
    }
  } catch (error) {
    yield put(getModelByIdError(error))
  }
}

export function* GetModelByIdSaga() {
  yield takeEvery(GET_MODEL_BY_ID, getmodelbyid)
}

//publish model
function* publishmodel({ payload: { id, status, togglePublishModal, dispatch, getModelsList, modelpage } }) {
  try {
    const response = yield call(publishModel, id, status);
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(publishModelError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      togglePublishModal();
      dispatch(getModelsList(modelpage()))
      yield put(publishModelSuccess(response))
      toast.success(response.message);
    };
  } catch (error) {
    yield put(publishModelError(error));
  };
};

export function* PublishModelSaga() {
  yield takeEvery(PUBLISH_MODEL, publishmodel);
};

//get publish model
function* getstartedmodel() {
  try {
    const response = yield call(getRunningModel);
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(getStartedModelError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getStartedModelSuccess(response));
    };
  } catch (error) {
    yield put(getStartedModelError(error));
  };
};

export function* GetStartedModelSaga() {
  yield takeEvery(GET_STARTED_MODEL, getstartedmodel);
};

//train model
function* trainmodel({ payload: data }) {
  try {
    const response = yield call(trainModel, data, { headers: { "Content-Type": "multipart/form-data" } });
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(trainModelError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(trainModelSuccess(response));
      toast.success(response.message);
    };
  } catch (error) {
    yield put(trainModelError(error));
  };
};

export function* TrainModelSaga() {
  yield takeEvery(TRAIN_MODEL, trainmodel);
};

//pre model start stop
function* preModelStartStopFun({ payload: data }) {
  try {
    const response = yield call(preModelStartStopHelper, data);
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(preModelStartStopError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getPreModelSuccess(response));
      yield put(preModelStartStopSuccess(response));
      toast.success(response.message);
    };
  } catch (error) {
    yield put(preModelStartStopError(error));
  };
};

export function* PreModelStartStopSaga() {
  yield takeEvery(PRE_MODEL_START_STOP, preModelStartStopFun);
};

//get pre model
function* getPreModelFun() {
  try {
    const response = yield call(getPreModelHelper);
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(getPreModelError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getPreModelSuccess(response));
      // toast.success(response.message);
    };
  } catch (error) {
    yield put(getPreModelError(error));
  };
};

export function* GetPreModelSaga() {
  yield takeEvery(GET_PRE_MODEL, getPreModelFun);
};

// model start stop
function* modelStartStopFun({ payload: { data, afterStartStop } }) {
  try {
    const response = yield call(modelStartStopHelper, data);
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message);
      yield put(modelStartStopError(error));
    } else if (response.status.toUpperCase() === "SUCCESS") {
      afterStartStop();
      yield put(updateModelById(response?.data));
      yield put(modelStartStopSuccess(response));
      toast.success(response.message);
    };
  } catch (error) {
    yield put(modelStartStopError(error));
  };
};

export function* ModelStartStopSaga() {
  yield takeEvery(MODEL_StART_STOP, modelStartStopFun);
};