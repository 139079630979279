import React, { useEffect, useState } from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Components
import UserPlaceholder from './Components/UserPlaceholder';
import Users from './Components/Users';
import RecentActivity from './Components/RecentActivity';
import InvoiceStats from './Components/InvoiceStats';
import MonthInvoice from './Components/MonthInvoice';
import InvoiceFilters from './Components/InvoiceFilters';
import StatusCard from './Components/StatusCard';
import AccountCredit from './Components/AccountCredit';
import { useDispatch, useSelector } from 'react-redux';
import { getCountForDocumentForBarChart, getDocCountByStatus, getUsersCount } from 'store/actions';
import CustomDatePicker from 'components/Common/Modals/CustomDatePicker';

const Dashboard = (props) => {
    const { t } = props;

    document.title = `${t("dashboard")} | ${t('appName')}`;

    const [documentStatusdata, setDocumentStatusData] = useState()
    const [month, setmonth] = useState('3')
    const [barChart, setBarChart] = useState()
    const [documentType, setDocumentType] = useState({ label: 'Invoice', value: 'INVOICE' })
    const [chartDataPeriod, setChartDataPeriod] = useState({ label: 'Current Month', value: '0' },)
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch()

    const documentsStatusCounts = useSelector(state => state.GetDocumentCountsByStatus?.data?.data)
    const usersCount = useSelector(state => state.GetUsersCount?.data?.data)
    const barChartData = useSelector(state => state.GetDocumentCountForBarChart?.data?.data)
    const authUser = useSelector(state => state.authInfo?.data);
    const statusCardloader = useSelector(state => state.GetDocumentCountsByStatus?.loading);
    const barChartloader = useSelector(state => state.GetDocumentCountForBarChart?.loading);
    const documentsStatusLoader = useSelector(state => state.GetDocumentCountsByStatus?.loading);
    const authUserLoading = useSelector(state => state.GetUsersCount?.loading);

    const getChartPeriodData = (period, type) => {
        dispatch(getCountForDocumentForBarChart(period || chartDataPeriod?.value, type || documentType?.value?.toLowerCase()))
    }

    useEffect(() => {
        dispatch(getUsersCount())
        getChartPeriodData(chartDataPeriod?.value, documentType?.value?.toLowerCase())
    }, [])

    const getDocCounts = (docType, month) => {
        if (month === 'custom') {
            setShowModal(true);
        } else {
            dispatch(getDocCountByStatus(authUser?.user?.company_id, docType || documentType?.value?.toLowerCase(), month || chartDataPeriod?.value))
        }
    };

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        if (authUser?.user?.company_id) getDocCounts(documentType?.value?.toLowerCase(), chartDataPeriod?.value)
    }, [authUser?.user?.company_id])

    const getCards = () => {
        let values = []
        if (documentsStatusCounts) {
            for (var item in documentsStatusCounts) {
                values.push({ [item]: documentsStatusCounts[item] })
            }
        }
        setDocumentStatusData(values)
    }

    useEffect(() => {
        getCards();
    }, [documentsStatusCounts])

    useEffect(() => {
        if (barChartData && barChartData?.length > 0) {

            let categories = [], data = []
            const keys = Object.keys(barChartData[0])
            keys?.map(item => {
                if (!['from', 'to'].includes(item)) data.push({ name: item, data: [] })
            })
            barChartData?.map((item, index) => {
                let values = []
                for (var val in item) {
                    values.push({ [val]: item[val] })
                }

                values?.map((innerItem, idx) => {
                    keys?.map((key, idx) => {
                        if (!['from', 'to'].includes(key?.toLowerCase())) {

                            if (key?.toLowerCase() == 'date' && Object.keys(innerItem) == 'date') categories.push(innerItem[key])

                            else {
                                data.map((item, idx) => {
                                    if (item?.name?.toLowerCase() == key?.toLocaleLowerCase() && Object.keys(innerItem) == key) {
                                        data[idx]?.data?.push(innerItem[key])
                                    }
                                })
                            }
                        }
                    })

                })
            })

            data.map((item, idx) => {
                const key = Object.values(item)
                const label = key[0]?.split(/(?=[A-Z])/)
                label?.map((val, idx) => {
                    const s = val?.replace(/\w+/g, function (w) { return w[0].toUpperCase() + w?.slice(1)?.toLowerCase(); })
                    label[idx] = s
                })
                data[idx].name = label?.toString()?.replaceAll(',', ' ')
            })

            const index = data?.findIndex(x => x.name === "Date");
            data.splice(index, 1);

            setBarChart({ categories, data: data })
        }
    }, [barChartData])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <UserPlaceholder /> */}
                    {/* <AccountCredit translate={t} /> */}
                    <div>
                        <div className='invoice-header'>
                            <div>
                                <h1 className='page-title'>{t('overallInvoice')}</h1>
                            </div>
                            <div>
                                <InvoiceFilters getChartPeriodData={getChartPeriodData} chartDataPeriod={chartDataPeriod} setChartDataPeriod={setChartDataPeriod} getDocCounts={getDocCounts} documentType={documentType} setDocumentType={setDocumentType} translate={t} />
                            </div>
                        </div>
                        <div className='chart-section'>
                            <CardBody>
                                <StatusCard data={documentStatusdata} loader={statusCardloader} />
                                <div className='chart-group'>
                                    <div>
                                        <InvoiceStats chartData={documentStatusdata} loading={documentsStatusLoader} translate={t} />
                                    </div>
                                    <div>
                                        <MonthInvoice data={barChart} loader={barChartloader} translate={t} />
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    <div className='chart-group mt-3 pt-3'>
                        <div>
                            <Users data={usersCount} loading={authUserLoading} translate={t} />
                        </div>
                        <div>
                            <RecentActivity translate={t} />
                        </div>
                    </div>
                </Container>
            </div>
            <CustomDatePicker showModal={showModal} toggle={getDocCounts} close={handleClose} />
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Dashboard));