import { GET_ALL_ADMIN_SUCCESS, GET_ALL_ADMIN_FAIL ,GET_ADMIN_ROLES_SUCCESS,GET_ADMIN_ROLES_FAIL, GET_ADMIN_ROLES} from "./actionTypes"

//Get All Admins
const INITIAL_STATE = {
  users: [],
  error: {}
}
const GetAdmin = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_ALL_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
export default GetAdmin

//Get Admin Roles
const initialState = {
  roles: [],
  error: {},
  loader: false,
}
export const GetAdminRoles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_ROLES:
      return {
        ...state,
        loader: true,
      }

    case GET_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        loader: false,
      }

    case GET_ADMIN_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loader: false,
      }
    default:
      return state
  }
}