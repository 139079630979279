import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Container, Row, Button, Input, Col } from "reactstrap"
import { map } from "lodash"
import Breadcrumbs from "components/Common/Breadcrumb"
import Cards from "../../components/Cards"
import { useSelector, useDispatch } from "react-redux"
import { getAllAdmin, getAdminRoles } from "../../store/actions"
import NoDataFound from "components/Common/NoDataFound";
import Multiselect from 'multiselect-react-dropdown';
import SkeletonUserCard from '../../components/Common/SkeletonUserCard';
import { handleFilters } from "constants/SearchAndFilter";
import Select, { createFilter } from "react-select";

const AdminList = () => {
  //meta title
  document.title = "Admins | xxxx"
  const history = useHistory();
  const [filterData, setFilterData] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [roleFilter, setRoleFilter] = useState(null);
  const [loader, setLoader] = useState(true);
  const [selectedGroup, setselectedGroup] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAdmin())
  }, [])
  const { users } = useSelector(state => ({
    users: state.GetAdmin.users,
  }))

  const { roles } = useSelector(state => ({
    roles: state.GetAdminRoles.roles,
  }))

  const optionsData = roles && [{ label: "All", value: "All" }, ...roles]

  useEffect(() => {
    if (users) {
      setFilterData(users.users)
      setLoader(false)
    }
  }, [users])

  useEffect(() => {
    handleFilters(roleFilter, searchValue, users.users, setFilterData, "role")
  }, [roleFilter, searchValue])

  function setDropValue(data) {
    setselectedGroup(data);
    setRoleFilter(data.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Dashboard" dashurl="/dashboard" breadcrumbItem="Admins" /> */}
          
          <div className="search-addNew-div">
            {/* Do not delete this code */}
            {/* <div className={`filter border-0 d-${(searchValue === '' || searchValue === undefined) && (roleFilter === '' || roleFilter === undefined) ? 'none' : 'block'}`}>
              <div className="filter-items form-control">
                <div className={`filter-name d-${(searchValue === '') || (searchValue === undefined) ? 'none' : 'block'}`}>{searchValue} <span onClick={() => setSearchValue(undefined)}><i className="mdi mdi-close"></i></span></div>
                <div className={`filter-name d-${(roleFilter === '') || (roleFilter === undefined) ? 'none' : 'block'}`}>{roleFilter} <span onClick={() => setRoleFilter(undefined)}><i className="mdi mdi-close"></i></span></div>
                <span className="close-all-filter" onClick={() => {
                  setSearchValue(undefined)
                  setRoleFilter(undefined)
                }}><i className="mdi mdi-close"></i></span>
              </div>
            </div> */}
            <div className='my-4 d-flex justify-content-between filter-section'>
              <div className="d-flex action-field">
                <div className="drop-down">
                  <Select
                    options={optionsData}
                    className='form-control user-dropdown'
                    search={true}
                    filterOption={createFilter({ matchFrom: 'start' })}
                    value={selectedGroup}
                    placeholder={`Select role`}
                    onChange={(value) => {
                      setDropValue(value);
                    }}
                  />
                </div>
                <div className="search-box d-inline-block me-2 field">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0 m-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search this table
                      </span>
                      <input
                        onChange={e => {
                          setSearchValue(e.target.value)
                        }}
                        id="search-bar-0"
                        type="text"
                        className="form-control"
                        placeholder={`Search by name / email`}
                        value={searchValue ? searchValue : ''}
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 secountry-btn"
                onClick={() => {
                  history.push("/admin/add-new-admin")
                }}  >
                Add New
              </Button>
            </div>
          </div>          
          {!filterData ? <Row className="user-card-view skeleton">
            {[...Array(12)].map((item, index) => {
              return <SkeletonUserCard key={index} />;
            })}
          </Row> : <>{filterData.length === 0 ? <NoDataFound /> : <Row className="user-card-view"> {filterData.map((user, key) => {
            return 
            (<Cards user={user} key={"_user_" + key}/>);
          })}</Row>}</>} 
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AdminList)
