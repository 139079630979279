import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADMIN_BY_ID } from "./actionTypes"
import { getAdminByIdSuccess, getAdminByIdFail } from "./action"

//Include Both Helper File with needed methods
import { getAdminById } from "helpers/backend_helper"

function* fetchAdmin({ payload: id }) {
  try {
    const response = yield call(getAdminById, id)
    yield put(getAdminByIdSuccess(response.user))
  } catch (error) {
    yield put(getAdminByIdFail(error))
  }
}

function* GetAdminByIdSaga() {
  yield takeEvery(GET_ADMIN_BY_ID, fetchAdmin)
}

export default GetAdminByIdSaga
