import {
  GET_ADMIN_BY_ID,
  GET_ADMIN_BY_ID_SUCCESS,
  GET_ADMIN_BY_ID_FAIL,
} from "./actionTypes"

//Get Admin By Id
export const getAdminById = id => ({
  type: GET_ADMIN_BY_ID,
  payload: id,
})
export const getAdminByIdSuccess = user => ({
  type: GET_ADMIN_BY_ID_SUCCESS,
  payload: user,
})
export const getAdminByIdFail = error => ({
  type: GET_ADMIN_BY_ID_FAIL,
  payload: error,
})
