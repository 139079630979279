//add new value to review data
export const ADD_REVIEW_DATA = "ADD_REVIEW_DATA";
export const ADD_REVIEW_DATA_FAIL = "ADD_REVIEW_DATA_FAIL";
export const ADD_REVIEW_DATA_SUCCESS = "ADD_REVIEW_DATA_SUCCESS";

//get my documents
export const GET_MY_DOCUMENTS = "GET_MY_DOCUMENTS";
export const GET_MY_DOCUMENTS_FAIL = "GET_MY_DOCUMENTS_FAIL";
export const GET_MY_DOCUMENTS_SUCCESS = "GET_MY_DOCUMENTS_SUCCESS";

//assign document to user
export const ASSIGN_USER = "ASSIGN_USER";
export const ASSIGN_USER_FAIL = "ASSIGN_USER_FAIL";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";

//get doc types
export const GET_DOC_TYPES = "GET_DOC_TYPES";
export const GET_DOC_TYPES_FAIL = "GET_DOC_TYPES_FAIL";
export const GET_DOC_TYPES_SUCCESS = "GET_DOC_TYPES_SUCCESS";

//get doc status
export const GET_DOC_STATUS = "GET_DOC_STATUS";
export const GET_DOC_STATUS_FAIL = "GET_DOC_STATUS_FAIL";
export const GET_DOC_STATUS_SUCCESS = "GET_DOC_STATUS_SUCCESS";

//get assigned users
export const GET_ASSIGNED_USERS = "GET_ASSIGNED_USERS";
export const GET_ASSIGNED_USERS_FAIL = "GET_ASSIGNED_USERS_FAIL";
export const GET_ASSIGNED_USERS_SUCCESS = "GET_ASSIGNED_USERS_SUCCESS";

//upload document
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";

//delete document
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";

//get document by id
export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const GET_DOCUMENT_BY_ID_FAIL = "GET_DOCUMENT_BY_ID_FAIL";
export const GET_DOCUMENT_BY_ID_SUCCESS = "GET_DOCUMENT_BY_ID_SUCCESS";

//approve document
export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
export const APPROVE_DOCUMENT_FAIL = "APPROVE_DOCUMENT_FAIL";
export const APPROVE_DOCUMENT_SUCCESS = "APPROVE_DOCUMENT_SUCCESS";

//get download documents
export const GET_DOWNLOAD_DOCUMENTS = "GET_DOWNLOAD_DOCUMENTS";
export const GET_DOWNLOAD_DOCUMENTS_FAIL = "GET_DOWNLOAD_DOCUMENTS_FAIL";
export const GET_DOWNLOAD_DOCUMENTS_SUCCESS = "GET_DOWNLOAD_DOCUMENTS_SUCCESS";

//field wise approve document
export const FIELDWISE_APPROVE_DOCUMENT = "FIELDWISE_APPROVE_DOCUMENT";
export const FIELDWISE_APPROVE_DOCUMENT_FAIL = "FIELDWISE_APPROVE_DOCUMENT_FAIL";
export const FIELDWISE_APPROVE_DOCUMENT_SUCCESS = "FIELDWISE_APPROVE_DOCUMENT_SUCCESS";

//object detection
export const RE_UPLOAD = "RE_UPLOAD";
export const RE_UPLOAD_FAIL = "RE_UPLOAD_FAIL";
export const RE_UPLOAD_SUCCESS = "RE_UPLOAD_SUCCESS";