import { GET_CREDIT_PLAN, GET_CREDIT_PLAN_SUCCESS, GET_CREDIT_PLAN_FAIL } from "./actionTypes";

//Get Plans
const initialState = {
    error: "",
    data:  null,
    loading: false,
}

export const GetPlans = (state = initialState, action) => {
    switch (action.type) {
        case GET_CREDIT_PLAN:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_CREDIT_PLAN_SUCCESS:
            state = {
                ...state,
                data: action.payload,
                loading: false
            }
            break;
        case GET_CREDIT_PLAN_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false

            }
            break;
    }
    return state
}