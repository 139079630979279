import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, shallowEqual, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.png";
import { logoutActivity } from "store/actions";
import { useDispatch } from "react-redux";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();

  const [username, setusername] = useState("");
  const [isRole, setRole] = useState('')
  const [profileImg, setProfileImg] = useState(null)
  const profileKey = useSelector(state => (state.UpdateProfileImg.msg), shallowEqual)
  const authInfo = useSelector(state => (state.authInfo.data?.user), shallowEqual)
  useEffect(() => {
    if (authInfo) {
      setusername(authInfo?.fullName);
      setRole(authInfo?.role);
      // setProfileImg(profileKey)
    }
  }, [authInfo]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >{
            profileImg ?
              <img
                className="rounded-circle header-profile-user"
                src={profileImg || user1}
                alt="Header Avatar"
              /> :
              <div
                className={
                  "rounded-circle header-profile-user d-flex align-items-center justify-content-center"
                }
              >
                <span className="f-size-14 fw-600">{username?.charAt(0).toUpperCase()}</span>
              </div>
          }
          <p className="d-none d-xl-inline-block ms-2 me-1 mb-0">{username}</p>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{isRole}</span> */}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/settings">
            <DropdownItem>
              {" "}
              <i className="bx bx-cog font-size-16 align-middle me-1" />
              {` Account Settings`}
            </DropdownItem>
          </Link>
          {/* Do not delete these code's, it will be needed */}
          {/* <Link to="#">
          <DropdownItem>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
         </Link>
         <Link to="#">
          <DropdownItem>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
         </Link> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item" onClick={() => {
            const data = {};
            data['userName'] = username;
            dispatch(logoutActivity(data));
          }}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{` Logout`}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

export default ProfileMenu