import React, { useState } from 'react';
import UploadFiles from '../Modals/UploadFiles';
import SecountryBtn from './SecountryBtn';

const FileUploadBtn = ({ translate }) => {
    const [fileUploadDrop, setFileUploadDrop] = useState(false);
    //Open Modal
    const tog_large = () => {
        setFileUploadDrop(true);
    }
    const closeDropDown = () => {
        setFileUploadDrop(false);
    }
    return (
        <div className='file-upload-btn'>

            <SecountryBtn btnName={translate('newuploaddoc')} showIcon={false} onClick={tog_large} />
            <UploadFiles
                showModal={fileUploadDrop}
                toggle={tog_large}
                close={closeDropDown}
                //  primaryAction={deleteMethoed ? mulityDelete : deleteModelList}
                modalTitle="Upload document"
                primaryBtn="Upload"
                secountBtn="Cancel"

            />
        </div>
    );
};

export default FileUploadBtn;