import {
  GET_COMPANY_BY_ID,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAIL,
} from "./actionTypes"

//get company by id
const initialState = {
  error: "",
  data: "",
  loading: false,
}

export const GetCompanyById = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_BY_ID:
      state = {
        error: '',
        data: '',
        loading: true,
      }
      break
    case GET_COMPANY_BY_ID_SUCCESS:
      state = {
        error: '',
        data: action.payload,
        loading: false,
      }
      break
    case GET_COMPANY_BY_ID_FAIL:
      state = {
        data: '',
        error: action.payload,
        loading: false,
      }
      break
  }
  return state
}