//get document count by status for pie chart
export const GET_DOCUMENT_COUNT_BY_STATUS = "GET_DOCUMENT_COUNT_BY_STATUS"
export const GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS = "GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS"
export const GET_DOCUMENT_COUNT_BY_STATUS_FAIL = "GET_DOCUMENT_COUNT_BY_STATUS_FAIL"

//get active and in active users count
export const GET_USERS_COUNT = "GET_USERS_COUNT"
export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUNT_SUCCESS"
export const GET_USERS_COUNT_FAIL = "GET_USERS_COUNT_FAIL"

//get document count by status for bar chart
export const GET_DOCUMENTS_COUNT_FOR_BAR_CHART = "GET_DOCUMENTS_COUNT_FOR_BAR_CHART"
export const GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS = "GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS"
export const GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL = "GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL"