import { PAGE_VIEWED, UPDATE_DATA_COUNT, UPDATE_MODEL_DATA_COUNT, UPLOAD_FILES, UPLOAD_FILES_SHOW_HIDE, UPLOAD_MODEL_FILES } from "./actionTypes"


const initialState = {
  data: [],
  show: false,
  modelData: [],
  page: "",
  dataTotal: 0,
  dataUploaded: 0,
  modelDataTotal: 0,
  modelDataUploaded: 0,
}

const UploadFiles = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILES:
      state = {
        ...state,
        data: action.payload
      }
      break
    case UPLOAD_MODEL_FILES:
      state = {
        ...state,
        modelData: action.payload
      }
      break
    case PAGE_VIEWED:
      state = {
        ...state,
        page: action.payload
      }
      break
    case UPDATE_DATA_COUNT:
      state = {
        ...state,
        dataTotal: action.payload.total,
        dataUploaded: action.payload.uploaded,
      }
      break
    case UPDATE_MODEL_DATA_COUNT:
      state = {
        ...state,
        modelDataTotal: action.payload.total,
        modelDataUploaded: action.payload.uploaded,
      }
      break
    case UPLOAD_FILES_SHOW_HIDE:
      state = {
        ...state,
        show: action.payload
      }
      break
  }
  return state
}

export default UploadFiles
