import React from 'react'
import { useFieldArray } from 'react-hook-form';
import editIcon from '../../../../assets/images/icons/editcolor.svg';
import deleteIcon from '../../../../assets/images/icons/delete.svg';
import plusIcon from '../../../../assets/images/icons/plus-icon.svg';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import NestedFieldArray from './NestedFieldArray';
import Uuid from 'constants/Uuid';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

const FieldArray = ({ control, register, getValues, setValue, errors, setError, clearErrors }) => {
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "fields"
    });

    const handleAddSection = () => {
        const fields = getValues('fields')
        const lastSection = fields[fields?.length - 1]
        const lastfield = lastSection?.sectionFields[lastSection?.sectionFields?.length - 1]

        if (!lastSection?.section) {
            setError(`fields.${fields?.length - 1}.section`, { type: 'custom', message: `Please fill the current section` });
        }

        else if (!lastfield?.fieldName) {
            setError(`fields.${fields?.length - 1}.sectionFields.${lastSection?.sectionFields?.length - 1}.fieldName`, { type: 'custom', message: `Please fill the all fields in current section` });
        } else appendSection()
    }

    const appendSection = () => {
        append({ sectionId: Uuid(), section: '', sectionFields: [{ fieldName: "", fieldId: Uuid(), configuration: {} }] });
    }

    const handleDelete = (index) => {
        remove(index);
        const lastSection = getValues('fields')?.length === 0
        if (lastSection) appendSection()
    }

    return (
        <>
            {fields.map((item, index) => {
                return <div className='dropcontext mb-3' key={item.id}>
                    <div className='drop-header'>
                        <div>
                            <input type="text"
                                // {...register(`fields[${index}].section`)}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        clearErrors(`fields[${index}].section`);
                                    } else {
                                        setError(`fields.${index}.section`, { type: 'custom', message: `Section is required` });
                                    };
                                    if (e.target.value.match(/^[a-zA-Z0-9_ ]+$/) || !e.target.value) {
                                        setValue(`fields[${index}].section`, e.target.value?.toLowerCase()?.replaceAll(" ", "_"));
                                    } else {
                                        setError(`fields.${index}.section`, { type: 'custom', message: `Special characters are not allowed except "_"` });
                                    };
                                }}
                                placeholder='New Setion'
                                value={getValues(`fields[${index}].section`)}
                                className={errors.fields && errors.fields[index]?.section?.message ? 'form-control is-invalid m-0' : '' + 'form-control m-0'}
                            />
                            {errors.fields && errors.fields[index]?.section?.message ? <p className={`error-field`} >{errors.fields[index]?.section?.message}</p> : null}
                        </div>
                        <div>
                            {/* <img src={editIcon} alt="editIcon" style={{ cursor: 'pointer' }} className='px-3' /> */}
                            <img src={deleteIcon} alt="deleteIcon" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />
                        </div>
                    </div>
                    <NestedFieldArray field={'fields'} nestIndex={index} sectionId={item?.sectionId} {...{ control, register, getValues, setValue, errors, setError, clearErrors }} />
                </div>
            })}

            {/* TODO */}
            {/* <div className='new-section'><SecountryBtn btnName="Add Section" onClick={() => handleAddSection()} btnType={`button`} showIcon={true} btnIcon={plusIcon} /></div> */}
            {/* <div className='dropcontext mt-3'>
                <div className='drop-header'>
                    <div>
                        <input type="text" {...register(`table.0.section`)} readOnly
                            className='m-0 form-control' />
                    </div>
                </div>
                <NestedFieldArray field={'table'} nestIndex={0} sectionId={getValues('table[0].sectionId')} {...{ control, register, getValues, setValue, errors, setError, clearErrors }} />
            </div> */}
        </>
    )
}

export default FieldArray