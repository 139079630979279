export function createManifest(imagePath, annotations, imageSize, classMap) {
    const date = new Date(); // Creates a new Date object with the current date and time
    const isoString = date.toISOString();
    return {
        "source-ref": imagePath,
        "invoice-train_BB": {
            "annotations": annotations,
            "image_size": [imageSize]
        },
        "invoice-train_BB-metadata": {
            "job-name": "labeling-job/invoice-train_BB",
            "class-map": classMap,
            "human-annotated": "yes",
            "objects": annotations?.map(() => ({ "confidence": 1 })),
            "creation-date": isoString,
            "type": "groundtruth/object-detection"
        }
    };
}

// export const mergeManifest = (jsonData) => {
//     const manifestContent = jsonData.map(item => {
//         const annotations = JSON.stringify(item['invoice-train_BB'].annotations);
//         const imageSize = JSON.stringify(item['invoice-train_BB'].image_size);
//         const metadata = JSON.stringify(item['invoice-train_BB-metadata']);

//         return `{"source-ref": ${item['source-ref']}, "invoice-train_BB": {"annotations": ${annotations}, "image_size": ${imageSize}}, "invoice-train_BB-metadata": {"metadata": ${metadata}}}`;
//     }).join('\n');

//     // Convert manifest content to a Blob
//     const blob = new Blob([manifestContent], { type: 'text/plain' });

//     // Create a file from the Blob
//     const file = new File([blob], 'invoice_data.manifest', { type: 'text/plain' });

//     // Create FormData and append the file
//     // const formData = new FormData();
//     // formData.append('file', file);

//     return file;
// }

export const mergeManifest = (jsonData, model_id) => {
    const manifestContent = jsonData.map(item => {
        // const annotations = JSON.stringify(item['invoice-train_BB'].annotations);
        // const imageSize = JSON.stringify(item['invoice-train_BB'].image_size);
        // const metadata = JSON.stringify(item['invoice-train_BB-metadata']);
        return `${JSON.stringify(item)}`
        // return `{"source-ref": ${item['source-ref']}, "invoice-train_BB": {"annotations": ${annotations}, "image_size": ${imageSize}}, "invoice-train_BB-metadata": {"metadata": ${metadata}}}`;
    }).join('\n');

    // Convert manifest content to a Blob
    const blob = new Blob([manifestContent], { type: 'text/plain' });

    // Create a file from the Blob
    const file = new File([blob], `model_${model_id}.manifest`, { type: 'text/plain' });

    // Trigger download in browser environment
    // const url = URL.createObjectURL(file);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = `model_${model_id}.manifest`; // Ensures the correct file extension
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    return file;
}
