import { useSocket } from 'App';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap';
import { updateDataCount, updateModelDataCount, uploadFiles, uploadFilesShowHide, uploadModelFiles } from 'store/uploadFiles/action';
import tableFileIcon from '../assets/images/icons/table-file.svg';

const UploadingDetails = () => {

    const socket = useSocket();

    const dispatch = useDispatch();

    const files = useSelector(state => state.UploadFiles.data);
    const modelFiles = useSelector(state => state.UploadFiles.modelData);
    const filesShow = useSelector(state => state.UploadFiles.show);
    const page = useSelector(state => state.UploadFiles.page);
    const filesTotal = useSelector(state => state.UploadFiles.dataTotal);
    const modelFilesTotal = useSelector(state => state.UploadFiles.modelDataTotal);
    const filesUploaded = useSelector(state => state.UploadFiles.dataUploaded);
    const modelFilesUploaded = useSelector(state => state.UploadFiles.modelDataUploaded);

    useEffect(() => {
        if (socket) {
            socket.on('mydocumentUpload', async (changedFile) => {
                try {
                    const oldFiles = [...files];
                    let newFiles = [];
                    await oldFiles?.map((item) => {
                        if (item?.fileName === changedFile?.fileName) {
                            if (changedFile?.status === 'IN_PROGRESS') {
                                newFiles.push({ fileName: item?.fileName, status: "uploaded" });
                            } else {
                                newFiles.push({ fileName: item?.fileName, status: "failed" });
                            }
                        } else {
                            newFiles.push(item);
                        }
                    });
                    const order = { "in_progress": 1, "uploaded": 2, "failed": 3 };
                    const sortedData = newFiles?.sort((a, b) => order[a?.status] - order[b?.status]);

                    dispatch(updateDataCount({ total: filesTotal, uploaded: filesUploaded + 1 }));
                    dispatch(uploadFiles(sortedData));
                } catch (error) {
                    console.log("error: ", error);
                }
            });

            socket.on('modelDocumentUpload', async (changedFile) => {
                try {
                    const oldFiles = [...modelFiles];
                    let newFiles = [];
                    await oldFiles?.map((item) => {
                        if (item?.fileName === changedFile?.fileName) {
                            if (changedFile?.status === 'IN_PROGRESS') {
                                newFiles.push({ fileName: item?.fileName, status: "uploaded" });
                            } else {
                                newFiles.push({ fileName: item?.fileName, status: "failed" });
                            }
                        } else {
                            newFiles.push(item);
                        }
                    });
                    const order = { "in_progress": 1, "uploaded": 2, "failed": 3 };
                    const sortedData = newFiles?.sort((a, b) => order[a?.status] - order[b?.status]);

                    dispatch(updateModelDataCount({ total: modelFilesTotal, uploaded: modelFilesUploaded + 1 }));
                    dispatch(uploadModelFiles(sortedData));
                } catch (error) {
                    console.log("error: ", error);
                }
            });



            // Clean up the socket event listener when component unmounts
            return () => {
                socket.off('mydocumentUpload');
                socket.off('modelDocumentUpload');
            };
        }
    }, [socket, files, modelFiles]);

    const handleClose = () => {
        if (page === 'my_documents') {
            dispatch(uploadFiles([]));
        } else if (page === 'train_model') {
            dispatch(uploadModelFiles([]));
        }
    }

    return (
        <div
            className={`uploading-progress ${filesShow ? "" : "hide"}`}
            style={{ visibility: !page || (page === 'my_documents' && (!files || files?.length === 0)) || (page === 'train_model' && (!modelFiles || modelFiles?.length === 0)) ? "hidden" : "visible" }}
        >
            <div className='header'>
                <h5>Uploading {
                    page === 'my_documents' ?
                        `${filesTotal}`
                        :
                        `${modelFilesTotal}`
                } items</h5>
                <div className='icons'>
                    <span
                        id='upload-progress-details'
                        onClick={() => {
                            dispatch(uploadFilesShowHide(!filesShow));
                        }}
                        aria-hidden="true"
                    >
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6.5 6.5L12 1" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                        </svg>
                    </span>

                    <span
                        onClick={() => handleClose()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </span>

                </div>
                <UncontrolledTooltip target={`upload-progress-details`} placement="top">{filesShow ? "Hide" : "Show"}</UncontrolledTooltip>
            </div>

            <div className='sub-header'>
                <p className='mb-0'>
                    {
                        page === 'my_documents' ?
                            `Uploaded ${filesUploaded} of ${filesTotal}`
                            :
                            `Uploaded ${modelFilesUploaded} of ${modelFilesTotal}`
                    }
                </p>
            </div>

            {
                page === 'my_documents' ?
                    <UploadingDetailsHtml files={files} />
                    :
                    (page === 'train_model' ? <UploadingDetailsHtml files={modelFiles} /> : [])
            }
        </div>
    )
}

export default UploadingDetails

const UploadingDetailsHtml = ({ files }) => {
    return (
        <div className='body'>
            <div className='content'>
                {files?.map((item, index) => {
                    return <React.Fragment key={`uploading-file-${index}`}>
                        <div className={`uploading-items`}>
                            <div className='file-details'>
                                <div>
                                    <img src={tableFileIcon} alt='tableFileIcon' width={'16px'} />
                                </div>
                                <p className={`mb-0`}>{item?.fileName}</p>
                            </div>
                            {item?.status === "uploaded" ?
                                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14.5" cy="14.5005" r="14.5" fill="#06a506" />
                                    <path d="M21.2388 10.6323C20.7652 10.4429 20.3548 10.6007 20.0076 10.948C18.2397 12.7159 16.4717 14.4838 14.7038 16.2517C14.3566 16.599 14.0093 16.9462 13.662 17.2935C13.5989 17.2304 13.5673 17.1988 13.5042 17.1672C12.9675 16.6621 12.4308 16.1886 11.8941 15.6835C11.4522 15.273 10.9786 14.8311 10.5051 14.4522C9.96839 14.0103 9.11601 14.2628 8.86345 14.8942C8.67404 15.3678 8.80031 15.8413 9.21072 16.2201C10.4419 17.3566 11.6732 18.4616 12.9044 19.5981C13.4095 20.0716 14.0409 20.0401 14.546 19.5665C16.5349 17.5776 18.5238 15.6203 20.5127 13.6314C20.8915 13.2526 21.2388 12.9053 21.6176 12.5265C21.8702 12.2739 22.028 11.9898 21.9964 11.611C21.9333 11.1374 21.6807 10.7902 21.2388 10.6323Z" fill="white" />
                                </svg>
                                : (item?.status === "failed" ?
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 256 256"
                                    >
                                        <g
                                            style={{
                                                stroke: 'none',
                                                strokeWidth: 0,
                                                strokeDasharray: 'none',
                                                strokeLinecap: 'butt',
                                                strokeLinejoin: 'miter',
                                                strokeMiterlimit: 10,
                                                fill: 'none',
                                                fillRule: 'nonzero',
                                                opacity: 1,
                                            }}
                                            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                        >
                                            <circle
                                                cx="45"
                                                cy="45"
                                                r="45"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'butt',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(230,62,50)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                                transform="matrix(1 0 0 1 0 0)"
                                            />
                                            <path
                                                d="M 27.169 64.83 c -0.512 0 -1.024 -0.195 -1.414 -0.586 c -0.781 -0.781 -0.781 -2.047 0 -2.828 l 35.661 -35.661 c 0.781 -0.781 2.047 -0.781 2.828 0 c 0.781 0.781 0.781 2.047 0 2.828 L 28.583 64.244 C 28.193 64.635 27.681 64.83 27.169 64.83 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'butt',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(255,255,255)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                                transform="matrix(1 0 0 1 0 0)"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M 62.83 64.83 c -0.512 0 -1.023 -0.195 -1.414 -0.586 L 25.755 28.583 c -0.781 -0.781 -0.781 -2.047 0 -2.828 c 0.78 -0.781 2.048 -0.781 2.828 0 l 35.661 35.661 c 0.781 0.781 0.781 2.047 0 2.828 C 63.854 64.635 63.342 64.83 62.83 64.83 z"
                                                style={{
                                                    stroke: 'none',
                                                    strokeWidth: 1,
                                                    strokeDasharray: 'none',
                                                    strokeLinecap: 'butt',
                                                    strokeLinejoin: 'miter',
                                                    strokeMiterlimit: 10,
                                                    fill: 'rgb(255,255,255)',
                                                    fillRule: 'nonzero',
                                                    opacity: 1,
                                                }}
                                                transform="matrix(1 0 0 1 0 0)"
                                                strokeLinecap="round"
                                            />
                                        </g>
                                    </svg> : index === 0 ? <div className="loader"></div> : ""
                                )
                            }
                        </div>
                    </React.Fragment>
                })}
            </div>
        </div>
    )
}