import Skeleton from "react-loading-skeleton";
import { Card, CardBody } from "reactstrap";
import ImageLoader from "./components/ImageLoader";
import SideBar from "./components/SideBar";
import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import BackArrow from "../../assets/images/icons/backarrow.svg";
import pageIcon from "../../assets/images/sm-image.png";
import UndoIcon from "../../assets/images/icons/undo.svg";
import download from "../../assets/images/icons/download.svg";
import filesDownload from "../../assets/images/icons/files-download.svg";
import removeLeaderLine from "./utils/removeLeaderLine";

const ReviewPage = ({
    documentDetails,
    setDocumentDetails,
    documentLoading,
    previousPage,
    id,
    createPosition,
    train,
    setPositions,
    positions,
    // trainDocument,
    translate,
    fieldLoading }) => {
    const [zoomLevel, setZoomLevel] = useState(100);
    const [selectedId, setSelectedId] = useState();
    const [boundryRect, setBoundryRect] = useState();
    const [marking, setMarking] = useState(true);
    const [isTable, setIsTable] = useState(false);

    const [h_position, set_H_position] = useState({
        left: -100,
        top: -100,
        width: 0,
        height: 0,
    });

    //Zoom-In
    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 25);
    };

    //Zoom-Out
    const handleZoomOut = () => {
        if (zoomLevel > 100) {
            setZoomLevel(zoomLevel - 25);
        };
    };

    //reset zoom level
    const handleZoomReset = () => {
        if (zoomLevel > 100) {
            setZoomLevel(100);
        };
    };

    useEffect(() => {
        return () => {
            removeLeaderLine();
        };
    }, []);

    return (
        <div className="main-parent">
            {documentDetails?.lenth === 0 ? (
                <div className="sub-pages">
                    <div className="other-pages">
                        <div className="back-btn" onClick={() => previousPage()}>
                            {documentLoading ? (
                                <Skeleton height={"25px"} width={"50px"} />
                            ) : (
                                <img src={BackArrow} alt="BackArrow" />
                            )}
                        </div>
                        <div className="page-group">
                            <ul className="p-0">
                                {documentLoading || marking ? (
                                    <>
                                        {[...Array(5)].map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Skeleton height={"64px"} width={"64px"} />
                                                </li>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <img src={pageIcon} alt="pages" />{" "}
                                        </li>
                                        <li>
                                            <img src={pageIcon} alt="pages" />{" "}
                                        </li>
                                        <li>
                                            <img src={pageIcon} alt="pages" />{" "}
                                        </li>
                                        <li>
                                            <img src={pageIcon} alt="pages" />{" "}
                                        </li>
                                        <li>
                                            <img src={pageIcon} alt="pages" />{" "}
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        <div className="move-next">
                            <ul className="p-0 d-flex">
                                {documentLoading ? (
                                    <>
                                        <li>
                                            <Skeleton height={"25px"} width={"25px"} />
                                        </li>
                                        <li>
                                            <Skeleton height={"25px"} width={"25px"} />
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <i className="bx bx-chevron-left"></i>
                                        </li>
                                        <li>
                                            <i className="bx bx-chevron-right"></i>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="review-section-group">
                <div className="document-review">
                    <div className="preview">
                        <SimpleBar className="">
                            <div className="review-doc">
                                <Card style={{ width: `${zoomLevel}%` }}>
                                    <CardBody className="rounded-0">
                                        <div className="review-part text-center">
                                            {documentLoading ? (
                                                <>
                                                    {" "}
                                                    <Skeleton height={"100vh"} width={"100%"} />
                                                </>
                                            ) : (
                                                <ImageLoader
                                                    documentDetails={documentDetails}
                                                    setDocumentDetails={setDocumentDetails}
                                                    h_position={h_position}
                                                    set_H_position={set_H_position}
                                                    zoomLevel={zoomLevel}
                                                    documentId={id}
                                                    selectedId={selectedId}
                                                    setSelectedId={setSelectedId}
                                                    createPosition={createPosition}
                                                    setPositions={setPositions}
                                                    positions={positions}
                                                    train={train}
                                                    boundryRect={boundryRect}
                                                    setBoundryRect={setBoundryRect}
                                                    setMarking={setMarking}
                                                    marking={marking}
                                                    setIsTable={setIsTable}
                                                    isTable={isTable}
                                                />
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </SimpleBar>
                        {/* <div className="call-to">
                            <div className="control-group">
                                <div className="d-flex align-items-center">
                                    <div className="zoom-controls">
                                        <button onClick={handleZoomOut}>
                                            <i className="bx bx-minus"></i>
                                        </button>
                                        <span>{zoomLevel}%</span>
                                        <button onClick={handleZoomIn}>
                                            <i className="bx bx-plus"></i>
                                        </button>
                                        <button onClick={handleZoomReset}>
                                            <i className="bx bx-exit-fullscreen"></i>
                                        </button>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='ms-3'> <SecountryBtn btnName="Undo" btnIcon={UndoIcon} showIcon={true} /></div>
                                        <div className='ms-3 redo'> <SecountryBtn btnName="Redo" btnIcon={UndoIcon} showIcon={true} /></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="right-fixedbar">
                        <SideBar
                            documentDetails={documentDetails}
                            loading={documentLoading || marking}
                            h_position={h_position}
                            set_H_position={set_H_position}
                            zoomLevel={zoomLevel}
                            id={id}
                            selectedId={selectedId}
                            setSelectedId={setSelectedId}
                            train={train}
                            setPositions={setPositions}
                            positions={positions}
                            // trainDocument={trainDocument}
                            boundryRect={boundryRect}
                            setBoundryRect={setBoundryRect}
                            translate={translate}
                            setIsTable={setIsTable}
                            isTable={isTable}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewPage;
