import dash from '../assets/images/menu-icon/dash.svg';
import doc from '../assets/images/menu-icon/doc.svg';
import model from '../assets/images/menu-icon/model.svg';
import activity from '../assets/images/menu-icon/activity.svg';
import info from '../assets/images/menu-icon/info.svg';
import settings from '../assets/images/menu-icon/settings-icon.svg';

export const MenuItems = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: dash,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    {
        name: 'My Documents',
        path: '/my-document',
        icon: doc,
        role: ["SUPER_ADMIN", "ADMIN", "USER"]
    },
    {
        name: 'Models & Training',
        path: '/models',
        icon: model,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    {
        name: 'Activity',
        path: '/activitys',
        icon: activity,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    // {
    //     name: 'Help',
    //     path: '/login',
    //     icon: info,
    //     role:["SUPER_ADMIN"]
    // },
    {
        name: 'Settings',
        path: '/settings',
        icon: settings,
        role: ["SUPER_ADMIN", "ADMIN"]
    }
]