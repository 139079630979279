import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Label } from 'reactstrap';
import { FilterContext, SelectedFieldContext } from './InputFields';
import { useSelector, useDispatch } from 'react-redux';
import { createModal } from 'store/actions';

const ValidationTab = ({ id }) => {
    const { filterData, setFilterData } = useContext(FilterContext);
    const { selectedField, setSelectedField } = useContext(SelectedFieldContext);

    const getModelDetails = useSelector(state => state?.GetModelById?.data?.data)
    const modelDetails = useSelector(state => state?.CreateModal?.data?.data)
    const authUser = useSelector(state => state?.authInfo?.data)

    const dispatch = useDispatch()

    const formValues = {
        validationRule: "",
    }

    const { register, reset, setValue, clearErrors, getValues, trigger, formState: { errors, } } = useForm({
        defaultValues: formValues
    });

    const onSubmit = (values) => {
        let fieldIndex, sectionIndex
        const section = filterData[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            let filter = { ...(filterData[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration), validationFilter: values }
            let currentSection = JSON.parse(JSON.stringify(filterData))

            currentSection[selectedField?.field][sectionIndex]?.sectionFields?.map((item, index) => {
                if (item?.fieldId === selectedField?.fieldId) {
                    item['configuration'] = filter
                }
                return item
            })
            setFilterData(currentSection)
            if (id) {
                createModelFunction(currentSection, id)
            } else {
                createModelFunction(currentSection, modelDetails?._id)
            }
        }
    }

    const createModelFunction = (currentSection, modelId) => {
        const data = {
            company_id: authUser?.user?.company_id,
            modelId: modelId,
            fields: selectedField?.field == 'fields' ? currentSection[selectedField?.field] : filterData?.fields,
            table: selectedField?.field == 'table' ? currentSection[selectedField?.field] : filterData?.table,
        }
        dispatch(createModal(data))
    }

    useEffect(() => {
        if (modelDetails && !id) {
            setFormValues(modelDetails)
        } else if (id && getModelDetails) {
            setFormValues(getModelDetails)
        }
    }, [modelDetails, selectedField, getModelDetails])

    const setFormValues = (modelDetails) => {
        let fieldIndex, sectionIndex
        const section = modelDetails[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            const filter = modelDetails[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration?.validationFilter
            if (filter) {
                reset(filter)
            }
            else {
                reset(formValues)
            }
        }
    }

    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault()
                const error = trigger()
                error.then(result => {
                    if (result) {
                        onSubmit(getValues())
                    }
                })
            }} >
                <div className="form-group">
                    <Label className="form-label">Validation Rule</Label><br />
                    <span className='label-dec'>Used any excel function. Add == between the comparing sides <em className='call-action'>Learn more</em></span>
                    <textarea className='form-control' {...register('validationRule')} onChange={() => {
                        clearErrors('validationRule')
                    }}></textarea>
                </div>
                <div className="action mt-4">
                    <PrimaryButton btnType='submit' btnName="OK" showIcon={false} />
                </div>
            </form>
        </div>
    );
};

export default ValidationTab;