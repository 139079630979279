import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts"
import Skeleton from 'react-loading-skeleton';

const InvoiceStats = ({ chartData,loading, translate }) => {
    const [series, setseries] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        let val = [], keys = []
        chartData?.map((item, index) => {
            const key = Object.keys(item)
            const label = key[0]?.split(/(?=[A-Z])/)
            label?.map((item, idx) => {
                const s = item?.replace(/\w+/g, function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); })
                label[idx] = s
            })
            keys.push(label || '')
            val.push(item[Object.keys(item)] || 0)
        })
        setLabels(keys)
        setseries(val)
    }, [chartData])

    const options = {
        labels: labels,
        colors: ['#ED593A', '#FFAE8B','#FF7B51','#BF4329'],
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",

            markers: {
                width: 17,
                height: 17,
                radius: 0,
            },
        },
        dataLabels: {
            enabled: false,

        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
        },
        tooltip: {
            shared: false,
            intersect: false
        },
        stroke: {
            show: false,

        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                        width: 200,
                        type: 'donut',
                    },


                    plotOptions: {
                        pie: {
                            expandOnClick: true
                        }
                    },
                },
            },
        ],
    } 
    return (
        <div className='chats-bg pie-chats'>
            {loading ?
                <>
                    <Skeleton height={"20px"} width={'180px'} />
                    <Skeleton height={"88%"} width={'100%'} className='mt-4' />
                </>
                : <>
                    <h4>{translate('overallInvoice')}</h4>
                    <ReactApexChart options={options} series={series} type="donut" height="380" /></>}
        </div>
    );
};

export default InvoiceStats;