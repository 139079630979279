import Uuid from 'constants/Uuid';
import React, { useContext } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useFieldArray } from 'react-hook-form';
import { SelectedFieldContext } from '../InputFields';
import { debounce } from 'lodash';
import { toast } from 'react-hot-toast';
import plusIcon from '../../../../assets/images/icons/plus-icon.svg';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';

const NestedFieldArray = ({ field, nestIndex, sectionId, control, register, getValues, setValue, errors, setError, clearErrors }) => {

    const { selectedField, setSelectedField } = useContext(SelectedFieldContext);

    const { fields, remove, append, move } = useFieldArray({
        control,
        name: `[${field}][${nestIndex}].sectionFields`
    });

    const appendFields = () => {
        append({ fieldName: "", fieldId: Uuid(), configuration: {} });
    }

    const handleDelete = (index) => {
        remove(index);
        const lastSection = getValues(`${field}.${nestIndex}.sectionFields`).length === 0
        if (lastSection) appendFields()
    }

    const handleAppend = () => {
        const index = getValues(`${field}.${nestIndex}.sectionFields`).length - 1
        if (index >= 0) {
            const lastfield = getValues(`${field}.${nestIndex}.sectionFields.${index}.fieldName`)
            if (!lastfield) {
                setError(`${field}.${nestIndex}.sectionFields.${index}.fieldName`, { type: 'custom', message: `Please fill the all fields in current section` });
            } else appendFields()
        }
    }

    // Drag & Drop Fields
    const handleDrag = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <>
            <div className='drop-body'>
                <DragDropContext
                    onDragEnd={handleDrag}
                >
                    <ul className='p-0'>
                        <Droppable droppableId="test-items">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {fields.map((item, index) => {
                                        return (
                                            <Draggable
                                                key={`[${field}][${index}]`}
                                                draggableId={`item-${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <>
                                                        <li
                                                            key={item.id}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className='input-item'
                                                        >
                                                            <div className='input-settings' >
                                                                <input
                                                                    className={errors[field] && errors[field][nestIndex]?.sectionFields && errors[field][nestIndex]?.sectionFields[index]?.fieldName && errors[field][nestIndex]?.sectionFields[index]?.fieldName?.message ? 'form-control is-invalid' : '' + 'form-control'}
                                                                    // {...register(`[${field}][${nestIndex}].sectionFields[${index}.fieldName]`)}
                                                                    onChange={(e) => {
                                                                        if (e.target.value) {
                                                                            clearErrors(`[${field}][${nestIndex}].sectionFields[${index}.fieldName]`)
                                                                        } else {
                                                                            setError(`${field}.${nestIndex}.sectionFields.${index}.fieldName`, { type: 'custom', message: `Field name is required` });
                                                                        }
                                                                        if (e.target.value.match(/^[a-zA-Z0-9_ ]+$/) || !e.target.value) {
                                                                            setValue(`${field}.${nestIndex}.sectionFields.${index}.fieldName`, e.target.value?.toLowerCase()?.replaceAll(" ", "_"));
                                                                        } else {
                                                                            setError(`${field}.${nestIndex}.sectionFields.${index}.fieldName`, { type: 'custom', message: `Special characters are not allowed except "_"` });
                                                                        };

                                                                    }}
                                                                    value={getValues(`[${field}][${nestIndex}].sectionFields[${index}.fieldName]`)}
                                                                    placeholder='New Field'
                                                                />
                                                                <div className='d-flex'>
                                                                    {/* <div className={`close-icon me-1 ${item?.fieldId == selectedField?.fieldId ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        setSelectedField({ sectionId: sectionId, fieldId: item?.fieldId, field: field })
                                                                    }}>
                                                                        <i className='bx bx-cog setting'></i>
                                                                    </div> */}
                                                                    <div className='close-icon wind-up' onClick={() => {
                                                                        handleDelete(index)
                                                                        if (item?.fieldId == selectedField?.fieldId) setSelectedField({})
                                                                    }}><i className='bx bx-x danger'></i>
                                                                    </div>
                                                                    <div className='handle-drop'
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <i className='bx bx-dots-vertical-rounded'></i>
                                                                        <i className='bx bx-dots-vertical-rounded dots-right'></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {errors[field] && errors[field][nestIndex]?.sectionFields && errors[field][nestIndex]?.sectionFields[index]?.fieldName && errors[field][nestIndex]?.sectionFields[index]?.fieldName?.message ? <p className={`error-field`} >{errors[field] && errors[field][nestIndex]?.sectionFields && errors[field][nestIndex]?.sectionFields[index]?.fieldName && errors[field][nestIndex]?.sectionFields[index]?.fieldName?.message}</p> : null}
                                                        </li>
                                                    </>

                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </ul>
                </DragDropContext>

                <div>
                    {/* <button
                        type="button"
                        className="btn-rounded btn-common secountry-btn"
                        onClick={() => handleAppend()}
                    >
                        Add Field
                    </button> */}
                    <SecountryBtn btnName="Add Field" onClick={() => handleAppend()} btnType={`button`} showIcon={true} btnIcon={plusIcon} />
                </div>
            </div>
        </>
    )
}

export default NestedFieldArray