import {
  GET_DOCUMENT_COUNT_BY_STATUS,
  GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS,
  GET_DOCUMENT_COUNT_BY_STATUS_FAIL,
  GET_USERS_COUNT,
  GET_USERS_COUNT_SUCCESS,
  GET_USERS_COUNT_FAIL,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS,
  GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL,
} from "./actionTypes"

//Get documents count by status
export const getDocCountByStatus = (id, docType, month) => ({
  type: GET_DOCUMENT_COUNT_BY_STATUS,
  payload: { id, docType, month }
})
export const getDocCountByStatusSuccess = data => ({
  type: GET_DOCUMENT_COUNT_BY_STATUS_SUCCESS,
  payload: data,
})
export const getDocCountByStatusFail = error => ({
  type: GET_DOCUMENT_COUNT_BY_STATUS_FAIL,
  payload: error,
})

//Get users count
export const getUsersCount = () => ({
  type: GET_USERS_COUNT,
})
export const getUsersCountSuccess = data => ({
  type: GET_USERS_COUNT_SUCCESS,
  payload: data,
})
export const getUsersCountFail = error => ({
  type: GET_USERS_COUNT_FAIL,
  payload: error,
})

//Get document count for bar chart
export const getCountForDocumentForBarChart = (month, type) => ({
  type: GET_DOCUMENTS_COUNT_FOR_BAR_CHART,
  payload: { month, type }
})
export const getCountForDocumentForBarChartSuccess = data => ({
  type: GET_DOCUMENTS_COUNT_FOR_BAR_CHART_SUCCESS,
  payload: data,
})
export const getCountForDocumentForBarChartFail = error => ({
  type: GET_DOCUMENTS_COUNT_FOR_BAR_CHART_FAIL,
  payload: error,
})