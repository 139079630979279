// Get Models
export const GET_MODELS = "GET_MODELS_USERS";
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS";
export const GET_MODELS_FAIL = "GET_MODELS_FAIL";
export const UPDATE_MODEL_BY_ID = "UPDATE_MODEL_BY_ID";

//Delete Models
export const DELETE_MODELS = "DELETE_MODELS"
export const DELETE_MODELS_SUCCESS = "DELETE_MODELS_SUCCESS"
export const DELETE_MODELS_FAIL = "DELETE_MODELS_FAIL"

//Delete Multiple Items
export const DELETE_MODELS_MULTIPLE = "DELETE_MODELS_MULTIPLE"
export const DELETE_MODELS_MULTIPLE_SUCCESS = "DELETE_MODELS_MULTIPLE_SUCCESS"
export const DELETE_MODELS_MULTIPLE_FAIL = "DELETE_MODELS_MULTIPLE_FAIL"

//create modal
export const CREATE_MODAL = "CREATE_MODAL"
export const CREATE_MODAL_SUCCESS = "CREATE_MODAL_SUCCESS"
export const CREATE_MODAL_FAIL = "CREATE_MODAL_FAIL"

//upload document
export const UPLOAD_MODEL_DOCUMENT = "UPLOAD_MODEL_DOCUMENT";
export const UPLOAD_MODEL_DOCUMENT_FAIL = "UPLOAD_MODEL_DOCUMENT_FAIL";
export const UPLOAD_MODEL_DOCUMENT_SUCCESS = "UPLOAD_MODEL_DOCUMENT_SUCCESS";

//Get model by id
export const GET_MODEL_BY_ID = "GET_MODEL_BY_ID";
export const GET_MODEL_BY_ID_FAIL = "GET_MODEL_BY_ID_FAIL";
export const GET_MODEL_BY_ID_SUCCESS = "GET_MODEL_BY_ID_SUCCESS";

//publish model
export const PUBLISH_MODEL = "PUBLISH_MODEL";
export const PUBLISH_MODEL_FAIL = "PUBLISH_MODEL_FAIL";
export const PUBLISH_MODEL_SUCCESS = "PUBLISH_MODEL_SUCCESS";

//get publish model
export const GET_STARTED_MODEL = "GET_STARTED_MODEL";
export const GET_STARTED_MODEL_FAIL = "GET_STARTED_MODEL_FAIL";
export const GET_STARTED_MODEL_SUCCESS = "GET_STARTED_MODEL_SUCCESS";

//train model
export const TRAIN_MODEL = "TRAIN_MODEL";
export const TRAIN_MODEL_FAIL = "TRAIN_MODEL_FAIL";
export const TRAIN_MODEL_SUCCESS = "TRAIN_MODEL_SUCCESS";

//pre model sart stop
export const PRE_MODEL_START_STOP = "PRE_MODEL_START_STOP";
export const PRE_MODEL_START_STOP_FAIL = "PRE_MODEL_START_STOP_FAIL";
export const PRE_MODEL_START_STOP_SUCCESS = "PRE_MODEL_START_STOP_SUCCESS";

//get pre model
export const GET_PRE_MODEL = "GET_PRE_MODEL";
export const GET_PRE_MODEL_FAIL = "GET_PRE_MODEL_FAIL";
export const GET_PRE_MODEL_SUCCESS = "GET_PRE_MODEL_SUCCESS";

// model start stop
export const MODEL_StART_STOP = "MODEL_StART_STOP";
export const MODEL_StART_STOP_FAIL = "MODEL_StART_STOP_FAIL";
export const MODEL_StART_STOP_SUCCESS = "MODEL_StART_STOP_SUCCESS";