import React, { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';

import uploadSample from '../../../assets/images/svgimages/upload-sample.svg'
import UploadModelDocuments from '../Modals/UploadModelDocuments';

const UploadPlaceholder = ({ modelId, authUser }) => {

    const [showUploadFilesModel, setShowUploadFilesModel] = useState(false);

    const toggleUploadFilesModel = () => {
        setShowUploadFilesModel(!showUploadFilesModel)
    }

    return (
        <div>
            <div className="placeholder-image">
                <img src={uploadSample} alt='uploadSample' />
                <PrimaryButton
                    btnName={`Upload Sample`}
                    onClick={toggleUploadFilesModel}
                    showIcon={false}
                />
                <p>Upload and approve atleast 5 files. The more files you add, the better the results.</p>
            </div>

            {/* Upload samples model */}
            <UploadModelDocuments
                modalTitle={`Upload Files`}
                primaryBtnName={`Upload Files`}
                secoundaryBtnName={`Cancel`}
                showModal={showUploadFilesModel}
                toggle={toggleUploadFilesModel}
                id={modelId}
            />
        </div>
    );
};

export default UploadPlaceholder;