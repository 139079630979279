import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from "yup";

import editIcon from '../../../assets/images/icons/editcolor.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import InputRight from './InputRight';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import FieldArray from './InputFieldsSubComponent/FieldArray';
import Uuid from 'constants/Uuid';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createModal, getModelById } from 'store/actions';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { groupLabeledTexts } from 'constants/Review/utils/jsonConvertion';

export const FilterContext = createContext({
    filterData: {},
    setFilterData: () => { },
});

export const SelectedFieldContext = createContext({
    selectedField: {},
    setSelectedField: () => { },
});

const InputFields = ({ handleTabSelect, setSelectedModel, modelId, id, translate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [modelTitle, setmodelTitle] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [selectedField, setSelectedField] = useState({});

    const authUser = useSelector(state => state?.authInfo?.data)
    const modelDetails = useSelector(state => state?.CreateModal?.data)
    const modelDetailsLoader = useSelector(state => state?.CreateModal?.loading)
    const getModelDetails = useSelector(state => state?.GetModelById?.data?.data)

    const dispatch = useDispatch()
    const history = useHistory()

    const value = useMemo(() => {
        return { filterData, setFilterData }
    }, [filterData]);

    const selectedValue = useMemo(() => {
        return { selectedField, setSelectedField }
    }, [selectedField]);

    const defaultValues = {
        fields: [
            {
                sectionId: Uuid(),
                section: '',
                sectionFields: [{ fieldName: "", fieldId: Uuid(), configuration: {} }]
            }
        ],
        // TODO
        // table: [
        //     {
        //         sectionId: Uuid(),
        //         section: 'Line item',
        //         sectionFields: [{ fieldName: "", fieldId: Uuid(), configuration: {} }]
        //     }
        // ]
    }

    const inputFieldsFormSchema = Yup.object({
        fields: Yup.array().of(
            Yup.object().shape({
                section: Yup.string().required("Enter section name"),
                sectionFields: Yup.array().of(
                    Yup.object({
                        fieldName: Yup.string().required('Enter field name'),
                    })
                )
            })
        ),
        // TODO
        // table: Yup.array().of(
        //     Yup.object().shape({
        //         section: Yup.string().required("Enter section name"),
        //         sectionFields: Yup.array().of(
        //             Yup.object({
        //                 fieldName: Yup.string().required('Enter field name'),
        //             })
        //         )
        //     })
        // )
    })

    const { register, reset, control, watch, trigger, setError, handleSubmit, getValues, setValue, clearErrors, formState: { errors } } = useForm({
        resolver: yupResolver(inputFieldsFormSchema),
        defaultValues: defaultValues
    });

    //uses move from useFieldArray to change the position of the form
    const handleDrag = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    const handleEditTitle = () => {
        setIsEditing(!isEditing);
    };

    const handleChangeTitle = (event) => {
        setmodelTitle(event.target.value);
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) => setFilterData(value));
        return () => subscription.unsubscribe();
    }, [watch])

    const handleNext = (values) => {
        const fields = values?.fields[0]?.sectionFields?.map((item) => {
            const filterfield = getModelDetails?.fields?.labels?.filter((filterItem) => {
                return item?.fieldName === filterItem?.Name
            })
            if (filterfield?.length > 0) {
                return { ...filterfield[0], id: Uuid() }
            } else {
                return {
                    id: Uuid(),
                    Name: item?.fieldName,
                    Confidence: 0,
                    Geomentry: { BoundingBox: { Height: 0, Width: 0, Left: 0, Top: 0 } }
                }
            }
        })
        const data = {
            company_id: authUser?.user?.company_id,
            modelId: id || modelDetails?.data?._id,
            // fields: values?.fields,
            fields: { ...getModelDetails?.fields, labels: fields },
            // table: values?.table, TODO
        }
        dispatch(createModal(data, handleTabSelect, 2, history))
    }

    useEffect(() => {
        if ((modelId || id) && !modelDetailsLoader) {
            dispatch(getModelById((modelId || id)))
        }
    }, [modelId, id, modelDetailsLoader])

    useEffect(() => {
        resetFormValue()
    }, [modelDetails, modelId, id, getModelDetails])

    const getFields = () => {
        const labels = getModelDetails?.fields?.labels ? getModelDetails?.fields?.labels : [];
        const labeledTexts = getModelDetails?.fields?.labeled_texts ? getModelDetails?.fields?.labeled_texts : [];

        const groupedData = groupLabeledTexts(labels, labeledTexts);
        return groupedData;
    }

    const resetFormValue = () => {
        const resetForm = (modelDetails) => {
            const sectionFields = () => {
                const result = []
                getFields()?.map((item) => {
                    // if (item?.labelName !== 'table_data')
                    result.push({ fieldName: item?.labelName, fieldId: item?.id, configuration: {} });
                })
                return result;
            }
            reset({
                fields: getFields() && getFields()?.length > 0 ? [{
                    sectionId: Uuid(),
                    section: 'general_fields',
                    sectionFields: sectionFields()
                }] : [{
                    sectionId: Uuid(),
                    section: '',
                    sectionFields: [{ fieldName: "", fieldId: Uuid(), configuration: {} }]
                }],
            })
        }

        if ((!modelId || !id) && modelDetails?.data?.fields && modelDetails?.data?.fields?.length > 0) {
            resetForm(modelDetails?.data)
        } else if (modelId || id) {
            resetForm(getModelDetails)
        } else reset(defaultValues)
    }

    const handleSave = (values) => {
        const data = {
            company_id: getModelDetails?.company_id,
            modelId: modelId,
            fields: values?.fields,
            // table: values?.table, TODO
        }
        dispatch(createModal(data))
    }

    const handleValidate = (save) => {
        const fieldsValidation = validationFunc(getValues('fields'))
        // const tableValidation = validationFunc(getValues('table'))

        const fieldsSctionFields = getFieldValues(getValues(`fields`), 'fields')
        // const tableSectionFields = getFieldValues(getValues(`table`), 'table') TODO

        if (
            fieldsValidation === true
            && fieldsSctionFields === true
            // && tableSectionFields === true TODO
        ) {
            save(getValues())
        }
    }

    const getFieldValues = (fields, field) => {


        let ids = [], validateData = []
        for (let i = 0; i < fields.length; i++) {
            for (let j = 0; j < fields[i].sectionFields?.length; j++) {
                //check line item are enter in field
                if ((fields[i].sectionFields[j]?.fieldName?.toLowerCase() === 'line item') || (fields[i].sectionFields[j]?.fieldName?.toLowerCase() === 'line_item')) {
                    if (!ids.includes(fields[i].sectionFields[j]?.fieldId)) ids.push(fields[i].sectionFields[j]?.fieldId)
                    setError(`${field}.${i}.sectionFields.${j}.fieldName`, { type: 'custom', message: 'Field name "line item" is not allowed' });
                } else {
                    if (fields[i].sectionFields[j]?.fieldName) {
                        validateData.push({
                            fieldName: fields[i].sectionFields[j]?.fieldName,
                            id: fields[i].sectionFields[j]?.fieldId,
                            sectionIndex: i,
                            fieldIndex: j
                        })
                    }
                }
            }
        }

        //check fields uniqueness
        for (let i = 0; i < validateData.length; i++) {
            for (let j = i + 1; j < validateData.length; j++) {
                if ((validateData[i]?.fieldName === validateData[j]?.fieldName)) {
                    if (!ids.includes(validateData[i].id)) {
                        ids.push(validateData[i].id)
                        setError(`${field}.${validateData[i].sectionIndex}.sectionFields.${validateData[i].fieldIndex}.fieldName`, { type: 'custom', message: `Field name "${validateData[i]?.fieldName}" already exist` });
                    }
                    if (!ids.includes(validateData[j].id)) {
                        ids.push(validateData[j].id)
                        setError(`${field}.${validateData[j].sectionIndex}.sectionFields.${validateData[j].fieldIndex}.fieldName`, { type: 'custom', message: `Field name "${validateData[i]?.fieldName}" already exist` });
                    }
                }
            }
        }

        return ids?.length > 0 ? false : true;
    }

    const validationFunc = (fields) => {
        let index = []
        for (let i = 0; i < fields.length; i++) {
            for (let j = i + 1; j < fields.length; j++) {
                if ((fields[i]?.section && fields[j]?.section) && (fields[i]?.section === fields[j]?.section)) {
                    if (!index.includes(i)) {
                        index.push(i)
                        setError(`fields.${i}.section`, { type: 'custom', message: `Section name "${fields[i]?.section}" already exists` });
                    }
                    if (!index.includes(j)) {
                        index.push(j)
                        setError(`fields.${j}.section`, { type: 'custom', message: `Section name "${fields[j]?.section}" already exists` });
                    }
                }
            }
        }
        return index?.length > 0 ? false : true;
    }

    return (
        <SelectedFieldContext.Provider value={selectedValue}>
            <FilterContext.Provider value={value}>
                <div className='input-fields-create-model'>
                    <div className='models-layout'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={6}>
                                        <div className='input-left'>
                                            <form onSubmit={handleSubmit(handleNext)} className="form-horizontal custom-input">
                                                <FieldArray {...{ control, register, defaultValues, getValues, setValue, errors, setError, clearErrors }} />
                                            </form>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        {selectedField?.fieldId && <InputRight id={modelId || id} />}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <div className='filter-btn download-btn d-flex'>
                            {!modelId ?
                                <>
                                    <SecountryBtn btnType={`button`} btnName={translate('clear')} onClick={() => resetFormValue()} showIcon={false} />
                                    <SecountryBtn btnType={`button`} btnName={translate('back')} onClick={() => history.push(`/models/edit-model/${id}/0`)} showIcon={false} />
                                    <PrimaryButton onClick={() => {
                                        const checkError = trigger()
                                        checkError.then(result => {
                                            if (result) {
                                                handleValidate(handleNext)
                                            }
                                        })
                                    }} btnName={translate('next')} showIcon={false} />
                                </> :
                                <PrimaryButton onClick={() => {
                                    const checkError = trigger()
                                    checkError.then(result => {
                                        if (result) {
                                            handleValidate(handleSave)
                                        }
                                    })
                                }} btnName="Save" showIcon={false} />
                            }
                        </div>
                    </div>
                </div >
            </FilterContext.Provider>
        </SelectedFieldContext.Provider>
    );
};

InputFields.propTypes = {
    t: PropTypes.any,
};

export default withRouter(withTranslation()(InputFields));