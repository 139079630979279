import React, { useState } from 'react';
import classnames from "classnames";
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';

import GeneralTab from './GeneralTab';
import ExtractionTab from './ExtractionTab';
import ValidationTab from './ValidationTab';

const InputRight = ({ id }) => {
    const [iSInputFieldsTab, setInputFieldsTab] = useState("General");
    const toggleCustom = tab => {
        if (iSInputFieldsTab !== tab) {
            setInputFieldsTab(tab);
        }
    };
    return (
        <div className='input-right-col'>
            <div className='nav-tabs-custom nav-justified'>
                <Nav className="">
                    <NavItem>
                        <div
                            className={`tab-item ${classnames({ active: iSInputFieldsTab === "General", })}`}
                            onClick={() => {
                                toggleCustom("General");
                            }}
                        >
                            <span className="">General</span>
                        </div>
                    </NavItem>
                    <NavItem>
                        <div
                            className={`tab-item ${classnames({
                                active: iSInputFieldsTab === "Extraction",
                            })}`}
                            onClick={() => {
                                toggleCustom("Extraction");
                            }}
                        >

                            <span className="">Extraction</span>
                        </div>
                    </NavItem>
                    <NavItem>
                        <div
                            className={`tab-item ${classnames({
                                active: iSInputFieldsTab === "Validation",
                            })}`}
                            onClick={() => {
                                toggleCustom("Validation");
                            }}
                        >

                            <span className="">Validation</span>
                        </div>
                    </NavItem>

                </Nav>
            </div>
            <TabContent activeTab={iSInputFieldsTab}>
                <TabPane tabId="General">
                    <GeneralTab setInputFieldsTab={setInputFieldsTab} id={id} />
                </TabPane>
                <TabPane tabId="Extraction">
                    <ExtractionTab setInputFieldsTab={setInputFieldsTab} id={id} />
                </TabPane>
                <TabPane tabId="Validation" >
                    <ValidationTab id={id} />
                </TabPane>

            </TabContent>
        </div>
    );
};

export default InputRight;