import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"
import AdminList from "pages/Admin/AdminList"
import AdminForm from "pages/Admin/AdminForm"
import UnAuthorized from "components/UnAuthorized"
import InternalServer from "components/InternalServer"
import BadRequest from "components/BadRequest"
import InnerScreenNotFound from "components/InnerScreenNotFound"
import Review from "pages/MyDocument/Review"
import MyDocuments from "pages/MyDocument/MyDocuments"
import Settings from "pages/Settings/Settings"
import Dashboard from "pages/Dashboard/Dashboard"
import ModelsListing from "pages/Models/ModelsListing"
import CreateModel from "pages/Models/CreateModel"
import ModelDetails from "pages/ModelDetails/ModelDetails"
import Activitys from "pages/Activity/Activitys"
import ModelReview from "pages/Models/ModelReview"

const authProtectedRoutes = [
  { path: "/admin", component: AdminList, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/admin/add-new-admin", component: AdminForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/admin/update-admin/:id", component: AdminForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/notfound", component: InnerScreenNotFound, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/my-document/:id", component: Review, role: ["SUPER_ADMIN", "ADMIN", "USER"] },
  { path: "/my-document", component: MyDocuments, role: ["SUPER_ADMIN", "ADMIN", "USER"] },
  { path: "/my-document/:tab", component: MyDocuments, role: ["SUPER_ADMIN", "ADMIN", "USER"] },
  { path: "/settings", component: Settings, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/settings/:tab", component: Settings, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/dashboard", component: Dashboard, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/models", component: ModelsListing, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/models/create-model", component: CreateModel, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/models/edit-model/:id/:tab", component: CreateModel, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/models/model-details/:modelId", component: ModelDetails, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/models/:id/:model", component: ModelReview, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/activitys", component: Activitys, role: ["SUPER_ADMIN", "ADMIN"] },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*", component: () => <Redirect to="/notfound" /> },
]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:email", component: ResetPassword },
  { path: "/unauthorized", component: UnAuthorized },
  { path: "/servererror", component: InternalServer },
  { path: "/badrequest", component: BadRequest },


]


export { authProtectedRoutes, publicRoutes }
