import React, { useEffect, useMemo, useRef, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Row, UncontrolledAccordion } from "reactstrap";
import Select, { createFilter } from "react-select";
import shareIcon from "../../../assets/images/icons/share.svg";
import moreIcon from "../../../assets/images/icons/more.svg";
import editIcon from "../../../assets/images/icons/edit.svg";
import Inactive from "../../../assets/images/icons/inactive.svg";
import active from "../../../assets/images/icons/check-active.svg";
import rejectIcon from "../../../assets/images/svg/reject.svg";
import download from "../../../assets/images/icons/download.svg";
import filesDownload from "../../../assets/images/icons/files-download.svg";
import { useHistory, useParams, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import exportFromJSON from "export-from-json";

import { useSelector } from "react-redux";
import { leaderline } from "../utils/leaderLine";
import getDifference from "../utils/getDifference";
import Skeleton from "react-loading-skeleton";
import { approveDocument, trainModelDocument } from "store/actions";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { downloadDocuments } from "constants/downloadDocuments";
import Confirmation from "components/Common/Modals/Confirmation";
import Copylink from "components/Common/Modals/Copylink";
import { toast } from "react-hot-toast";
import hidePopUp from "../utils/hidePopUp";
import createHighlight from "../utils/createHighlight";
import { reject } from "lodash";
import removeLeaderLine from "../utils/removeLeaderLine";
import { downloadRecord } from "components/Common/Modals/DownloadModal";
import { createManifest } from "../utils/manifestFile";
import { convertPixelToAwsRect } from "../utils/jsonConvertion";
import RejectDocument from "components/Common/Modals/RejectDocument";

const SideBar = ({
    documentDetails,
    h_position,
    set_H_position,
    zoomLevel,
    loading,
    id,
    selectedId,
    setSelectedId,
    train,
    setPositions,
    positions,
    // trainDocument,
    boundryRect,
    setBoundryRect,
    translate,
    setIsTable,
    isTable
}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [difference, setdifference] = useState();
    const [shareOpen, setShareOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [documentStatus, setDocumentStatus] = useState();
    const [selectedField, setSelectedField] = useState();
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [rejectModel, setRejectModel] = useState(false);

    const link = window.location.href;
    const confidence = 75;

    const downloadData = useSelector(
        (state) => state?.GetDownloadDocument?.data?.data
    );
    const approveDocumentLoader = useSelector(state => state.ApproveDocument.loading);


    function toggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    function rejectToggleModal() {
        setRejectModel(!rejectModel);
    }

    const getDifferenceOfSize = () => {
        return getDifference();
    };

    useMemo(() => {
        setTimeout(() => {
            let diff = getDifferenceOfSize();
            setdifference(diff);
        }, 1000);
    }, [difference, zoomLevel]);

    //Download
    const handleDownload = () => {
        setIsOpen(!isOpen);
    };

    const handleDownloadDcument = (type) => {
        const record = downloadRecord(downloadData);
        const resultData = downloadDocuments(record?.newdownloadData, type)
        exportFromJSON({
            data: resultData?.data,
            fileName: resultData?.fileName,
            exportType: resultData?.type,
        });
        setTimeout(() => {
            setIsOpen(false);
        }, 500);
    };

    const handleApprove = (subUrl, id, status, reason) => {
        const data = {};
        data['status'] = status;
        if (status === 'rejected') {
            data['reason'] = reason;
        }
        handleRemoveLeaderLine();
        dispatch(approveDocument(subUrl, id, data, setConfirmModal, history));
    };

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef?.current?.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            handleRemoveLeaderLine();
            document.removeEventListener("mousedown", handler);
        };
    }, []);

    const toggleShare = () => {
        setShareOpen(!shareOpen);
    };

    const copylinkAction = () => {
        navigator.clipboard.writeText(link);
        toast.success("Link copied");
    };

    const handleRemoveLeaderLine = () => {
        setSelectedId();
        hidePopUp();
        removeLeaderLine();
        set_H_position({
            left: -100,
            top: -100,
            width: 0,
            height: 0,
        });
    };

    const trainDocument = (handleBack) => {
        const { height, width } = documentDetails.document;
        let annotations = [], class_map = {};
        positions?.length > 0 && positions?.map((item, index) => {
            annotations.push({
                left: item?.BoundingBox?.x,
                top: item?.BoundingBox?.y,
                width: item?.BoundingBox?.Width,
                height: item?.BoundingBox?.Height,
                class_id: index
            });
            class_map[`${index}`] = item?.labelName;
        });
        const imagePath = `s3://${process.env.REACT_APP_MANIFEST_BUCKETNAME_AND_FOLDER}/${documentDetails?.document?.key}`;
        const imageSize = {
            "width": Number(width),
            "height": Number(height),
            "depth": 3
        };
        const manifest = createManifest(imagePath, annotations, imageSize, class_map);
        const diff = getDifference();
        const newLabelsDetails = documentDetails?.document?.result_data?.labels?.map((item) => {
            const result = positions?.filter((filterItem) => {
                return filterItem?.labelName === item?.Name
            })
            if (result && result?.length > 0) {
                const rect = {
                    Height: result[0]?.BoundingBox?.Height / diff,
                    Width: result[0]?.BoundingBox?.Width / diff,
                    y: result[0]?.BoundingBox?.x / diff,
                    x: result[0]?.BoundingBox?.y / diff
                }

                return {
                    ...item,
                    Confidence: confidence,
                    Geometry: {
                        ...item?.Geometry,
                        BoundingBox: convertPixelToAwsRect(result[0]?.BoundingBox)
                        // BoundingBox: rect
                    }
                }
            } else {
                return item;
            }
        })
        documentDetails.document.result_data.labels = newLabelsDetails;
        const newResultData = documentDetails.document.result_data;

        const data = {
            label_data: manifest,
            result_data: newResultData,
            document_id: id
        };

        dispatch(trainModelDocument(data, handleBack))
    };

    const handleLabel = () => {
        handleRemoveLeaderLine();
        trainDocument(handleBack);
    }

    const handleOnclick = (innerItem, boundingRect) => {
        setSelectedId({
            id: innerItem?.id,
            fieldName: innerItem?.fieldName,
            fieldValue: innerItem?.fieldConfig?.data?.toString(),
            rect: boundingRect
        });
        // if (train) {
        //     hidePopUp();
        //     let rect;
        //     const previousrRect = positions.filter((item, index) => item?.id === innerItem?.fieldId);
        //     if (previousrRect[0]?.w && previousrRect[0]?.h && previousrRect[0]?.xmin && previousrRect[0]?.ymin) {
        //         rect = {
        //             w: previousrRect[0]?.w * difference,
        //             h: previousrRect[0]?.h * difference,
        //             x: previousrRect[0]?.xmin * difference,
        //             y: previousrRect[0]?.ymin * difference
        //         }
        //     } else {
        //         rect = createHighlight();
        //     };
        //     leaderline(innerItem?.fieldId, rect, set_H_position);
        //     const copyPositions = JSON.parse(JSON.stringify(positions));
        //     const newPositions = copyPositions?.map((item, index) => {
        //         if (item?.id === innerItem?.fieldId) {
        //             item['xmin'] = rect?.x / difference;
        //             item['ymin'] = rect?.y / difference;
        //             item['xmax'] = (rect?.x / difference) + (rect?.w / difference);
        //             item['ymax'] = (rect?.y / difference) + (rect?.h / difference);
        //             item['w'] = rect?.w / difference;
        //             item['h'] = rect?.h / difference;
        //         };
        //         return item;
        //     });
        //     setBoundryRect(newPositions);
        // };
    };

    const handleBack = () => {
        history.push(`/models/edit-model/${documentDetails?.document?.model_id}/2`)
    }

    const handleInputClick = (innerItem, boxSize, rect) => {
        setIsTable(false);
        let value = ""
        innerItem?.labeledTexts?.length > 0 && innerItem?.labeledTexts?.map((labelItem) => {
            value = value + labelItem?.DetectedText + " ";
        })
        setSelectedId({
            id: innerItem?.id,
            fieldName: innerItem?.labelName,
            fieldValue: innerItem?.labeledTexts,
            // fieldValue: value,
            rect: innerItem?.BoundingBox
        });
        // handleOnclick(innerItem, boxSize);
        if (boxSize
            //  && !train
        ) {
            leaderline(
                innerItem?.id,
                rect,
                set_H_position
            );
        } else if (!boxSize && !train) {
            hidePopUp();
            removeLeaderLine();
            set_H_position({
                left: -100,
                top: -100,
                width: 0,
                height: 0,
            })
        };
    }

    const handleTableInputClick = (nestedItem) => {
        const rect = {
            x: nestedItem?.BoundingBox?.x * difference,
            y: nestedItem?.BoundingBox?.y * difference,
            w: nestedItem?.BoundingBox?.Width * difference,
            h: nestedItem?.BoundingBox?.Height * difference,
        };
        setSelectedId({
            id: nestedItem?.id,
            fieldValue: nestedItem?.DetectedText,
            rect: nestedItem?.BoundingBox
        });
        setIsTable(true);
        leaderline(
            nestedItem?.id,
            rect,
            set_H_position
        );
    }

    return (
        <div className="invoice-info">
            <Card className="p-0">
                <CardBody className="p-0 rounded-0">
                    <div className="table-accordion">
                        <div className="invoice-title">
                            <div>
                                {loading ? (
                                    <Skeleton height={"15px"} width={"300px"} />
                                ) : (
                                    <p className="m-0">
                                        {/* <span className='me-3'>1/30</span> */}
                                        {documentDetails?.document?.fileName}
                                    </p>
                                )}
                            </div>
                            <div className="d-flex">
                                <img
                                    src={shareIcon}
                                    className=""
                                    alt={shareIcon}
                                    onClick={() => setShareOpen(true)}
                                    style={{ cursor: "pointer" }}
                                />
                                {/* To do: need to add more features */}
                                {/* <img src={moreIcon} alt={'more icon'} /> */}
                            </div>
                        </div>
                        {!loading && documentDetails?.document?.reason && <div className="invoice-title d-block">
                            <AccordionHeader>
                                Reason for reject:
                            </AccordionHeader>
                            <p className="mb-0 mt-2">{documentDetails?.document?.reason}</p>
                        </div>}
                        {loading ? (
                            <>
                                {[...Array(3)].map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${index == 2 ? "" : "mb-1"} p-2`}
                                        >
                                            <Skeleton height={"180px"} width={"100%"} />
                                        </div>
                                    );
                                })}{" "}
                            </>
                        ) : (
                            <UncontrolledAccordion defaultOpen={["1", "2"]} stayOpen>
                                <div className="sidebar-container">
                                    <div>
                                        <AccordionItem>
                                            <AccordionHeader targetId="">
                                                General fields
                                                {/* <img
                                                        src={editIcon}
                                                        alt="Edit-icon"
                                                        className="ms-2"
                                                    /> */}
                                            </AccordionHeader>

                                            <AccordionBody accordionId="1">
                                                <div className="reviewpage">
                                                    <form className="custom-input pe-2">
                                                        <Row>
                                                            {documentDetails?.fieldDetails?.map((item, index) => {
                                                                let value = ""
                                                                item?.labeledTexts?.length > 0 && item?.labeledTexts?.map((labelItem) => {
                                                                    value = value + labelItem?.DetectedText + " ";
                                                                });

                                                                const boxSize =
                                                                    item?.BoundingBox;

                                                                const rect = {
                                                                    x: boxSize?.x * difference,
                                                                    y: boxSize?.y * difference,
                                                                    w: boxSize?.Width * difference,
                                                                    h: boxSize?.Height * difference,
                                                                };

                                                                if (train || item?.labelName !== 'table_data') {
                                                                    return <Col key={`general-field-${index}`} lg={6}>
                                                                        <div id={`text-${item?.id}`}>
                                                                            <div className={`label-header ${((item?.id === selectedField) && train) ? "field-label" : ''} ${train ? 'mb-3' : ''}`}>
                                                                                <label
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (train) {
                                                                                            setSelectedField(item?.id)
                                                                                        }
                                                                                        handleInputClick(item, boxSize, rect);
                                                                                    }}
                                                                                >
                                                                                    {item?.labelName}
                                                                                </label>
                                                                                {item?.Confidence >= confidence ? (
                                                                                    <img src={active} alt="icons" />
                                                                                ) : !item?.Confidence ? (
                                                                                    <img src={Inactive} alt="icons" />
                                                                                ) : item?.Confidence < confidence && (
                                                                                    <img src={rejectIcon} alt="icons" />
                                                                                )}
                                                                            </div>
                                                                            {
                                                                                !train &&
                                                                                <>
                                                                                    <input
                                                                                        value={value}
                                                                                        type="text"
                                                                                        className={`form-control ${(item?.id === selectedId?.id) ? 'field-selected' : ''}`}
                                                                                        readOnly
                                                                                        onChange={() => { }}
                                                                                        onClick={() => {
                                                                                            handleInputClick(item, boxSize, rect);
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                }
                                                            })}
                                                        </Row>
                                                        {/* future reference */}
                                                        {/* <Row>
                                                                    {item?.sectionFields?.map(
                                                                        (innerItem, innerIndex) => {
                                                                            const fieldStatus =
                                                                                innerItem?.fieldConfig?.fieldStatus;

                                                                            const boxSize =
                                                                                innerItem?.fieldConfig?.bounding_box;
                                                                            // const rect = boxSize
                                                                            const rect = {
                                                                                x: boxSize?.x * difference,
                                                                                y: boxSize?.y * difference,
                                                                                w: boxSize?.w * difference,
                                                                                h: boxSize?.h * difference,
                                                                            };
                                                                            let positionSet = false;
                                                                            const previousrRect = positions && positions.filter((filterItem, filterIndex) => filterItem?.id === innerItem?.fieldId);
                                                                            if (previousrRect && previousrRect[0]?.w && previousrRect[0]?.h && previousrRect[0]?.xmin && previousrRect[0]?.ymin) {
                                                                                positionSet = true;
                                                                            }
                                                                            return (
                                                                                <Col key={innerIndex} lg={6}>
                                                                                    <div
                                                                                        id={`text-${innerItem?.fieldId}`}
                                                                                    // onClick={() => {
                                                                                    //     if (boxSize) {
                                                                                    //         leaderline(
                                                                                    //             innerItem?.fieldId,
                                                                                    //             rect,
                                                                                    //             set_H_position
                                                                                    //         );
                                                                                    //     }
                                                                                    // }}
                                                                                    >
                                                                                        <div className={`label-header ${((innerItem?.fieldId === selectedField) && train) ? "field-label" : ''} ${train ? 'mb-3' : ''}`}>
                                                                                            <label
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                onClick={() => {
                                                                                                    if (train) {
                                                                                                        setSelectedField(innerItem?.fieldId)
                                                                                                    }
                                                                                                    handleInputClick(innerItem, boxSize, rect);
                                                                                                }}
                                                                                            >
                                                                                                {innerItem?.fieldName}
                                                                                            </label>
                                                                                            {(fieldStatus === true || positionSet === true) ? (
                                                                                                <img src={active} alt="icons" />
                                                                                            ) : fieldStatus === false ? (
                                                                                                <img src={rejectIcon} alt="icons" />
                                                                                            ) : (
                                                                                                <img src={Inactive} alt="icons" />
                                                                                            )}
                                                                                        </div>
                                                                                        {
                                                                                            !train &&
                                                                                            <>
                                                                                                <input
                                                                                                    // onClick={(e) => e.stopPropagation()}
                                                                                                    value={innerItem?.fieldConfig?.data?.toString()}
                                                                                                    type="text"
                                                                                                    className={`form-control ${(innerItem?.fieldId === selectedId?.id) ? 'field-selected' : ''}`}
                                                                                                    readOnly
                                                                                                    onChange={() => { }}
                                                                                                    onClick={() => {
                                                                                                        handleInputClick(innerItem, boxSize, rect);
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Row> */}
                                                    </form>
                                                </div>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </div>
                                    {/* ); */}
                                    {/* })} */}
                                    {/* to do for table type of data */}
                                    {
                                        !train &&

                                        <AccordionItem className="table-section">
                                            <AccordionHeader targetId=" ">
                                                Table
                                                {/* <img src={editIcon} alt='Edit-icon' className='ms-2' /> */}
                                            </AccordionHeader>
                                            <AccordionBody accordionId="2">
                                                {/* <div className='d-flex justify-content-between'>
                                            <div>
                                                <span>Table 1</span>
                                            </div>
                                            <div>
                                                <span>Full Width <i className='bx bx-fullscreen ms-1 mt-1'></i></span>
                                            </div>
                                        </div> */}
                                                <div className='review-table table-responsive'>

                                                    <table>
                                                        {/* <thead>
                                                    <tr>
                                                        <th>
                                                            <div className='drop-down'>
                                                                <Select
                                                                    options={optValue}
                                                                    classNamePrefix="custom_select"
                                                                    search={true}
                                                                    placeholder="Select Column"
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='drop-down'>
                                                                <Select
                                                                    options={optValue}
                                                                    classNamePrefix="custom_select"
                                                                    search={true}
                                                                    placeholder="Select Column"

                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='drop-down'>
                                                                <Select
                                                                    options={optValue}
                                                                    classNamePrefix="custom_select"
                                                                    search={true}
                                                                    placeholder="Select Column"

                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='drop-down'>
                                                                <Select
                                                                    options={optValue}
                                                                    classNamePrefix="custom_select"
                                                                    search={true}
                                                                    placeholder="Select Column"


                                                                />
                                                            </div>
                                                        </th>

                                                    </tr>

                                                </thead> */}
                                                        <tbody>
                                                            {documentDetails?.fieldDetails?.map((item, index) => {
                                                                if (item?.labelName === 'table_data') {
                                                                    return item?.labeledTexts?.map((row, rowIndex) => {
                                                                        return <tr key={`row-${rowIndex}`}>
                                                                            {row?.length > 0 && row?.map((cell) => {
                                                                                return <td key={cell?.id}>
                                                                                    <div className='label-header'>
                                                                                        <input
                                                                                            id={`text-${cell?.id}`}
                                                                                            type="text" value={cell?.DetectedText}
                                                                                            readOnly
                                                                                            className={`form-control ${(cell?.id === selectedId?.id) ? 'field-selected' : ''}`}
                                                                                            onClick={() => {
                                                                                                if (rowIndex !== 0 && cell?.DetectedText) {
                                                                                                    handleTableInputClick(cell)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <div className='check-img'>
                                                                                            {(cell?.Confidence >= confidence
                                                                                            ) ? (
                                                                                                <img src={active} alt="icons" />
                                                                                            ) : !cell?.Confidence ? (
                                                                                                <img src={Inactive} alt="icons" />
                                                                                            ) : cell?.Confidence < confidence && (
                                                                                                <img src={rejectIcon} alt="icons" />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            })}
                                                                        </tr>
                                                                    })

                                                                    // return item?.label?.map((innerItem, innerIndex) => {
                                                                    //     return <tr key={innerIndex}>{
                                                                    //         innerItem?.map((nestedItem, nestedIndex) => {
                                                                    //             const box = nestedItem?.bounding_box;
                                                                    //             let positionSet = (box?.w && box?.h && box?.x && box?.y) ? true : false;
                                                                    //             return <td key={nestedItem?.cellId} >
                                                                    //                 <div className='label-header'>
                                                                    //                     <input
                                                                    //                         id={`text-${nestedItem?.cellId}`}
                                                                    //                         type="text" value={nestedItem?.value}
                                                                    //                         readOnly
                                                                    //                         className={`form-control ${(nestedItem?.cellId === selectedId?.id) ? 'field-selected' : ''}`}
                                                                    //                         onClick={() => {
                                                                    //                             handleTableInputClick(nestedItem)
                                                                    //                         }}
                                                                    //                     />
                                                                    //                     <div className='check-img'>
                                                                    //                         {(nestedItem?.fieldStatus === true
                                                                    //                             || positionSet === true
                                                                    //                         ) ? (
                                                                    //                             <img src={active} alt="icons" />
                                                                    //                         ) : nestedItem?.fieldStatus === false ? (
                                                                    //                             <img src={rejectIcon} alt="icons" />
                                                                    //                         ) : (
                                                                    //                             <img src={Inactive} alt="icons" />
                                                                    //                         )}
                                                                    //                     </div>
                                                                    //                 </div>
                                                                    //             </td>
                                                                    //         })
                                                                    //     }</tr>
                                                                    // })
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </AccordionBody>
                                        </AccordionItem>
                                    }
                                </div>
                            </UncontrolledAccordion>
                        )}
                    </div>

                    <div className="section-footer">
                        <div className="table-grop-btn">
                            {train === true ?
                                <>
                                    <SecountryBtn btnName={translate("back")} onClick={() => handleBack()} showIcon={false} />
                                    <PrimaryButton
                                        btnName="Label"
                                        onClick={() => handleLabel()}
                                        showIcon={false}
                                    />
                                </> : <>
                                    <SecountryBtn btnName={translate("reject")} showIcon={false} onClick={() => {
                                        if (id) {
                                            setDocumentStatus('rejected');
                                            rejectToggleModal();
                                        }
                                    }} />
                                    <PrimaryButton
                                        btnName={translate("approve")}
                                        onClick={() => {
                                            if (id) {
                                                setDocumentStatus('approved');
                                                toggleModal();
                                            }
                                        }}
                                        showIcon={false}
                                    />
                                    <div className="floting-btn" onClick={handleDownload}>
                                        <span>
                                            <img src={download} alt={download} />
                                        </span>
                                    </div>
                                    {isOpen && (
                                        <div className="download-file" ref={menuRef}>
                                            <ul>
                                                <li onClick={() => handleDownloadDcument("csv")}>
                                                    <span>CSV</span>
                                                    <img src={filesDownload} alt={"filesDownload"} />
                                                </li>
                                                <li onClick={() => handleDownloadDcument("xls")}>
                                                    <span>Excel</span>
                                                    <img src={filesDownload} alt={"filesDownload"} />
                                                </li>
                                                <li onClick={() => handleDownloadDcument("xml")}>
                                                    <span>Xml</span>
                                                    <img src={filesDownload} alt={"filesDownload"} />
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>

            {/* Document approve and reject confirmation model*/}
            <Confirmation
                showModal={isConfirmModal}
                toggle={toggleModal}
                primaryAction={() => {
                    handleApprove("approve-document", id, documentStatus);
                }}
                modalTitle={'Approve Document'}
                primaryBtn={"Approve"}
                secountBtn="Cancel"
                description={`Are you sure you want to approve this document`}
                loader={approveDocumentLoader}
            />

            {/* Document reject model*/}
            <RejectDocument
                showModal={rejectModel}
                toggle={rejectToggleModal}
                id={id}
                handleApprove={handleApprove}
            />

            {/* Copy link model */}
            <Copylink
                showModal={shareOpen}
                link={link}
                toggle={toggleShare}
                copylinkAction={copylinkAction}
            />
        </div>
    );
};

export default SideBar;
