import React from 'react';
import animationData from '../../assets/lottie/no-data.json';
import NoData from "../../assets/images/empty-box.png";
import { useLottie } from 'lottie-react';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const NoDataFound = () => {
    // const { NoData } = useLottie(defaultOptions, { height: 80, width: 80 })
      return (
        <div className='no-data'>
            <img src={NoData} />
             {/* <div id='datanotfound'>{NoData}</div> */}
         </div>
    );
};

export default NoDataFound;