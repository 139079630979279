import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { getCompanyById, updateSettingsData } from 'store/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const AccountTab = ({ translate }) => {
    const [userInfo, setUserInfo] = useState()
    const [enableEdit, setEnableEdit] = useState(false)
    const user = useSelector(state => state?.authInfo?.data?.user)
    const company = useSelector(state => state?.GetCompanyById?.data?.data)
    const updateSettingsDataLoading = useSelector(state => state?.UpdateSettingsData?.loading);

    const dispatch = useDispatch()

    const validusSchema = yup.object().shape({
        fullName: yup.string().trim().required(`${translate('nameError')}`).max(80, `${translate('nameCharacters')}`),
        companyName: yup.string().required(`${translate('companyError')}`).max(200, `${translate('companytextlimit')}`)
    })

    const formValues = {
        userId: "",
        company_id: "",
        fullName: '',
        companyName: ""
    }

    const { register, reset, setValue, getValues, trigger, clearErrors, formState: { errors, } } = useForm({
        resolver: yupResolver(validusSchema),
        defaultValues: formValues
    });

    useMemo(() => {
        if (user?.company_id) dispatch(getCompanyById(user?.company_id))
    }, [user])

    useEffect(() => {
        if (company) {
            const data = {
                _id: user?.userId,
                company_id: user?.company_id,
                fullName: user?.fullName,
                email: user?.email,
                companyName: company?.name
            }
            setUserInfo(data)
            if (userInfo) {
                resetFormValues()
            }
        }
    }, [company])

    const onSubmit = (values) => {
        dispatch(updateSettingsData(values))
        setEnableEdit(false)
    }

    const resetFormValues = () => {
        reset({
            userId: userInfo?._id,
            company_id: userInfo?.company_id,
            fullName: userInfo?.fullName,
            companyName: userInfo?.companyName
        })
    }

    return (
        <React.Fragment>
            <form className='accountTab'
                onSubmit={(e) => {
                    e.preventDefault()
                    const error = trigger()
                    error.then(result => {
                        if (result) {
                            onSubmit(getValues())
                        }
                    })
                }}
            >
                <div className="action">
                    <button type='button' onClick={() => {
                        resetFormValues()
                        if (enableEdit === true) {
                            setEnableEdit(false)
                        } else setEnableEdit(true)
                    }}>{enableEdit ? `${translate('cancel')}` :
                        `${translate('edit')}`}< i className={enableEdit ? 'mdi mdi-close-box' : 'bx bx-edit-alt'}></i> </button>
                </div>

                <Row>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <div className='lableAction'>
                                <Label>{translate('fullName')}</Label>
                            </div>
                            {
                                enableEdit ? <>
                                    <input
                                        {...register('fullName')}
                                        type="text"
                                        className={errors.fullName?.message ? 'form-control m-0 is-invalid' : '' + 'form-control m-0'}
                                        onChange={() => clearErrors('fullName')}
                                    />
                                    {errors.fullName?.message ? <p className={`error-field`} >{errors.fullName?.message}</p> : null}
                                </> :
                                    <label className="form-control" style={{ display: 'flex', alignItems: 'center' }}>{userInfo?.fullName}</label>
                            }
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <Label>{translate('companyName')}</Label>
                            {enableEdit ? <>
                                <input
                                    {...register('companyName')}
                                    type="text"
                                    className={errors.companyName?.message ? 'form-control m-0 is-invalid' : '' + 'form-control m-0'}
                                    onChange={() => clearErrors('companyName')}
                                />
                                {errors.companyName?.message ? <p className={`error-field`} >{errors.companyName?.message}</p> : null}
                            </> :
                                <label className="form-control" style={{ display: 'flex', alignItems: 'center' }}>{userInfo?.companyName}</label>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                {enableEdit && <div className='mt-4'>
                    <PrimaryButton loader={updateSettingsDataLoading} btnType={"submit"} btnName={translate('save')} showIcon={false} />
                </div>}
            </form>
        </React.Fragment >
    );
};

export default AccountTab;