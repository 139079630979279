import React from 'react'
import JSZip from 'jszip';

export const downloadDocuments = (downloadData, exportFile) => {
    let type = '', filename;

    if (exportFile === 'csv') {
        type = 'csv'
        filename = 'documents'
        return { ...{ data: downloadData, filename, type }, type: 'csv' }
    }

    else if (exportFile === 'csv-per') {
        const zip = new JSZip();
        for (let i = 0; i < downloadData?.length; i++) {
            let values = [], heading = [];
            downloadData[i].forEach((item, index) => {
                let value = [];
                for (const key in item) {
                    if (!heading.includes(key)) {
                        heading.push(key)
                    }
                    value.push(item[key])
                }
                values.push([value])

            })
            const csvData = [heading, ...values];
            const csvContent = csvData.map((row) => {
                return row.join(',')
            }).join('\n');
            zip.file(`document${i}.csv`, csvContent);
        }
        return { data: zip, type: 'zip' }
    }

    else if (exportFile === 'xml') {
        type = 'xml'
        filename = 'documents'
        return { ...{ data: downloadData, filename, type }, type: 'xml' }
    }

    else if (exportFile === 'xls') {
        type = 'xls'
        filename = 'documents'
        return { ...{ data: downloadData, filename, type }, type: 'xls' }
    }
}