import {
    GET_MODELS_DOC,
    GET_MODELS_DOC_SUCCESS,
    GET_MODELS_DOC_FAIL,
    DELETE_MODELS_DOC,
    DELETE_MODELS_DOC_SUCCESS,
    DELETE_MODELS_DOC_FAIL,
    GET_MODEL_DOC_BY_ID,
    GET_MODEL_DOC_BY_ID_SUCCESS,
    GET_MODEL_DOC_BY_ID_FAIL,
    TRAIN_DOCUMENT,
    TRAIN_DOCUMENT_SUCCESS,
    TRAIN_DOCUMENT_FAIL,
} from './actionsTypes';

//Get Models Doc
export const getModelsDoc = (data) => {
    return {
        type: GET_MODELS_DOC,
        payload: data
    }
}

export const getModelsDocSuccess = (data) => {
    return {
        type: GET_MODELS_DOC_SUCCESS,
        payload: data
    }
}

export const getModelsDocFail = (error) => {
    return {
        type: GET_MODELS_DOC_FAIL,
        payload: error
    }
}

//Delete Models Doc
export const modelsDocDelete = (id, callGetDocuments) => {
    return {
        type: DELETE_MODELS_DOC,
        payload: { id, callGetDocuments }
    }
}

export const modelsDocDeleteSuccess = (data) => {
    return {
        type: DELETE_MODELS_DOC_SUCCESS,
        payload: data
    }
}

export const modelsDocDeleteFail = (error) => {
    return {
        type: DELETE_MODELS_DOC_FAIL,
        payload: error
    }
}

//get model document by id
export const getModelDocumentById = (id) => {
    return {
        type: GET_MODEL_DOC_BY_ID,
        payload: id
    }
}
export const getModelDocumentByIdSuccess = (data) => {
    return {
        type: GET_MODEL_DOC_BY_ID_SUCCESS,
        payload: data
    }
}
export const getModelDocumentByIdFail = (error) => {
    return {
        type: GET_MODEL_DOC_BY_ID_FAIL,
        payload: error
    }
}

//train document
export const trainModelDocument = (data, handleBack) => {
    return {
        type: TRAIN_DOCUMENT,
        payload: { data, handleBack }
    }
}
export const trainDocumentSuccess = (data) => {
    return {
        type: TRAIN_DOCUMENT_SUCCESS,
        payload: data
    }
}
export const trainDocumentFail = (error) => {
    return {
        type: TRAIN_DOCUMENT_FAIL,
        payload: error
    }
}