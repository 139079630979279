import {
    GET_MODELS,
    GET_MODELS_SUCCESS,
    GET_MODELS_FAIL,
    DELETE_MODELS,
    DELETE_MODELS_SUCCESS,
    DELETE_MODELS_FAIL,
    DELETE_MODELS_MULTIPLE,
    DELETE_MODELS_MULTIPLE_SUCCESS,
    DELETE_MODELS_MULTIPLE_FAIL,
    CREATE_MODAL,
    CREATE_MODAL_SUCCESS,
    CREATE_MODAL_FAIL,
    UPLOAD_MODEL_DOCUMENT,
    UPLOAD_MODEL_DOCUMENT_SUCCESS,
    UPLOAD_MODEL_DOCUMENT_FAIL,
    GET_MODEL_BY_ID,
    GET_MODEL_BY_ID_SUCCESS,
    GET_MODEL_BY_ID_FAIL,
    PUBLISH_MODEL,
    PUBLISH_MODEL_SUCCESS,
    PUBLISH_MODEL_FAIL,
    TRAIN_MODEL,
    TRAIN_MODEL_SUCCESS,
    TRAIN_MODEL_FAIL,
    PRE_MODEL_START_STOP,
    PRE_MODEL_START_STOP_SUCCESS,
    PRE_MODEL_START_STOP_FAIL,
    GET_PRE_MODEL,
    GET_PRE_MODEL_SUCCESS,
    GET_PRE_MODEL_FAIL,
    MODEL_StART_STOP,
    MODEL_StART_STOP_SUCCESS,
    MODEL_StART_STOP_FAIL,
    UPDATE_MODEL_BY_ID,
    GET_STARTED_MODEL,
    GET_STARTED_MODEL_SUCCESS,
    GET_STARTED_MODEL_FAIL,
} from "./actionTypes"

//Get Models
export const getModelsList = (data) => {
    return {
        type: GET_MODELS,
        payload: data
    }
}
export const getModelsListSuccess = data => {
    return {
        type: GET_MODELS_SUCCESS,
        payload: data,
    }
}
export const getModelsListError = error => {
    return {
        type: GET_MODELS_FAIL,
        payload: error,
    }
}
export const updateModelById = data => {
    return {
        type: UPDATE_MODEL_BY_ID,
        payload: data,
    }
}

//Delete Models
export const deleteModels = (id, dispatch, getModelsList, modelpage) => {
    return {
        type: DELETE_MODELS,
        payload: { id, dispatch, getModelsList, modelpage }
    }
}

export const deleteModelsSuccess = (data) => {
    return {
        type: DELETE_MODELS_SUCCESS,
        payload: data
    }
}

export const deleteModelsError = error => {
    return {
        type: DELETE_MODELS_FAIL,
        payload: error,
    }
}

//Delete Multiple Items
export const mulityModelsDelete = (id, dispatch, getModelsList, modelpage) => {
    return {
        type: DELETE_MODELS_MULTIPLE,
        payload: { id, dispatch, getModelsList, modelpage }
    }
}

export const mulityModelsDeleteSuccess = (data) => {
    return {
        type: DELETE_MODELS_MULTIPLE_SUCCESS,
        payload: data
    }
}

export const mulityModelsDeleteError = error => {
    return {
        type: DELETE_MODELS_MULTIPLE_FAIL,
        payload: error,
    }
}

//create modal
export const createModal = (data, handleTabSelect, tab, history) => {
    return {
        type: CREATE_MODAL,
        payload: { data, handleTabSelect, tab, history }
    }
}
export const createModalSuccess = (data) => {
    return {
        type: CREATE_MODAL_SUCCESS,
        payload: data
    }
}
export const createModalError = error => {
    return {
        type: CREATE_MODAL_FAIL,
        payload: error,
    }
}

//upload document
export const uploadModelDocument = (data, afterUploadSuccess) => {
    return {
        type: UPLOAD_MODEL_DOCUMENT,
        payload: { data, afterUploadSuccess }
    }
}
export const uploadModelDocumentSuccess = data => {
    return {
        type: UPLOAD_MODEL_DOCUMENT_SUCCESS,
        payload: data,
    }
}
export const uploadModelDocumentError = error => {
    return {
        type: UPLOAD_MODEL_DOCUMENT_FAIL,
        payload: error,
    }
}

//get model by id
export const getModelById = (id) => {
    return {
        type: GET_MODEL_BY_ID,
        payload: id
    }
}
export const getModelByIdSuccess = data => {
    return {
        type: GET_MODEL_BY_ID_SUCCESS,
        payload: data,
    }
}
export const getModelByIdError = error => {
    return {
        type: GET_MODEL_BY_ID_FAIL,
        payload: error,
    }
}

//publish model
export const publishModel = (id, status, togglePublishModal, dispatch, getModelsList, modelpage) => {
    return {
        type: PUBLISH_MODEL,
        payload: { id, status, togglePublishModal, dispatch, getModelsList, modelpage }
    };
};
export const publishModelSuccess = data => {
    return {
        type: PUBLISH_MODEL_SUCCESS,
        payload: data
    };
};
export const publishModelError = error => {
    return {
        type: PUBLISH_MODEL_FAIL,
        payload: error
    };
};

//get publish model
export const getStartedModel = () => {
    return {
        type: GET_STARTED_MODEL
    };
};
export const getStartedModelSuccess = data => {
    return {
        type: GET_STARTED_MODEL_SUCCESS,
        payload: data
    };
};
export const getStartedModelError = error => {
    return {
        type: GET_STARTED_MODEL_FAIL,
        payload: error
    };
};

//train model
export const trainModel = (data) => {
    return {
        type: TRAIN_MODEL,
        payload: data
    };
};
export const trainModelSuccess = data => {
    return {
        type: TRAIN_MODEL_SUCCESS,
        payload: data
    };
};
export const trainModelError = error => {
    return {
        type: TRAIN_MODEL_FAIL,
        payload: error
    };
};

//pre model start stops
export const preModelStartStop = (data) => {
    return {
        type: PRE_MODEL_START_STOP,
        payload: data
    };
};
export const preModelStartStopSuccess = data => {
    return {
        type: PRE_MODEL_START_STOP_SUCCESS,
        payload: data
    };
};
export const preModelStartStopError = error => {
    return {
        type: PRE_MODEL_START_STOP_FAIL,
        payload: error
    };
};

//get pre model
export const getPreModel = () => {
    return {
        type: GET_PRE_MODEL,
    };
};
export const getPreModelSuccess = data => {
    return {
        type: GET_PRE_MODEL_SUCCESS,
        payload: data
    };
};
export const getPreModelError = error => {
    return {
        type: GET_PRE_MODEL_FAIL,
        payload: error
    };
};

// model start stops
export const modelStartStop = (data, afterStartStop) => {
    return {
        type: MODEL_StART_STOP,
        payload: { data, afterStartStop },
    };
};
export const modelStartStopSuccess = data => {
    return {
        type: MODEL_StART_STOP_SUCCESS,
        payload: data
    };
};
export const modelStartStopError = error => {
    return {
        type: MODEL_StART_STOP_FAIL,
        payload: error
    };
};