import React from 'react';
import { Card, CardBody, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from "yup";

const Settings = () => {
    const basicInputValues = {
        modelName: '',
        documentType: '',


    };
    const basicSchema = Yup.object({
        modelName: Yup.string().required("Enter a model name").max(40, "Should not exceed 40 characters"),
        documentType: Yup.string().required("Enter a last name").max(40, "Should not exceed 40 characters"),
    })

    const { register, handleSubmit, formState: { errors, }, } = useForm({
        resolver: yupResolver(basicSchema),
        defaultValues: basicInputValues
    });

    const onSubmit = (values) => {
        console.log(values);

    }
    return (
        <div className='models-layout'>
                    <form
                        className="form-horizontal custom-input"
                        onSubmit={handleSubmit(onSubmit)}
                        >
                        <Card>
                            <CardBody className='b-radius-x-0'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Model Name</Label>
                            <input type="text" {...register("modelName")} className={errors.modelName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                            {errors.modelName?.message ? <p className={`invalid-feedback`} >{errors.modelName?.message}</p> : null}
                        </div>
                        <div className="form-group mb-4">
                            <Label className="form-label">Model Id</Label>
                            <input type="text" {...register("modelName")} className={errors.modelName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                            {errors.modelName?.message ? <p className={`invalid-feedback`} >{errors.modelName?.message}</p> : null}
                        </div>
                        <div className="form-group mb-4">
                            <Label className="form-label d-block">Last Modified date</Label>
                           <div className='last-update'>
                            <p>12:50 PM <span>26 Apr 2023</span></p>
                           </div>
                        </div>
                        
                </CardBody>
            </Card>
              
                    </form>
        </div>
    );
};

export default Settings;