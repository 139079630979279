import {
    GET_MY_DOCUMENTS,
    GET_MY_DOCUMENTS_SUCCESS,
    GET_MY_DOCUMENTS_FAIL,
    ASSIGN_USER,
    ASSIGN_USER_SUCCESS,
    ASSIGN_USER_FAIL,
    GET_DOC_TYPES,
    GET_DOC_TYPES_SUCCESS,
    GET_DOC_TYPES_FAIL,
    GET_DOC_STATUS,
    GET_DOC_STATUS_SUCCESS,
    GET_DOC_STATUS_FAIL,
    GET_ASSIGNED_USERS,
    GET_ASSIGNED_USERS_SUCCESS,
    GET_ASSIGNED_USERS_FAIL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL,
    GET_DOCUMENT_BY_ID,
    GET_DOCUMENT_BY_ID_SUCCESS,
    GET_DOCUMENT_BY_ID_FAIL,
    APPROVE_DOCUMENT,
    APPROVE_DOCUMENT_SUCCESS,
    APPROVE_DOCUMENT_FAIL,
    GET_DOWNLOAD_DOCUMENTS,
    GET_DOWNLOAD_DOCUMENTS_SUCCESS,
    GET_DOWNLOAD_DOCUMENTS_FAIL,
    FIELDWISE_APPROVE_DOCUMENT,
    FIELDWISE_APPROVE_DOCUMENT_SUCCESS,
    FIELDWISE_APPROVE_DOCUMENT_FAIL,
    RE_UPLOAD,
    RE_UPLOAD_SUCCESS,
    RE_UPLOAD_FAIL,
} from "./actionTypes";

//get my documents
export const getNyDocuments = (data, subUrl) => {
    return {
        type: GET_MY_DOCUMENTS,
        payload: { data, subUrl }
    }
}
export const getNyDocumentsSuccess = data => {
    return {
        type: GET_MY_DOCUMENTS_SUCCESS,
        payload: data,
    }
}
export const getNyDocumentsError = error => {
    return {
        type: GET_MY_DOCUMENTS_FAIL,
        payload: error,
    }
}

//assign user for document
export const assignDocToUser = (data, dispatch, getAssignedUsers) => {
    return {
        type: ASSIGN_USER,
        payload: { data, dispatch, getAssignedUsers }
    }
}
export const assignDocToUserSuccess = data => {
    return {
        type: ASSIGN_USER_SUCCESS,
        payload: data,
    }
}
export const assignDocToUserError = error => {
    return {
        type: ASSIGN_USER_FAIL,
        payload: error,
    }
}

//get document types
export const getDocTypes = () => {
    return {
        type: GET_DOC_TYPES
    }
}
export const getDocTypesSuccess = data => {
    return {
        type: GET_DOC_TYPES_SUCCESS,
        payload: data,
    }
}
export const getDocTypesError = error => {
    return {
        type: GET_DOC_TYPES_FAIL,
        payload: error,
    }
}

//get document status
export const getDocStatus = () => {
    return {
        type: GET_DOC_STATUS
    }
}
export const getDocStatusSuccess = data => {
    return {
        type: GET_DOC_STATUS_SUCCESS,
        payload: data,
    }
}
export const getDocStatusError = error => {
    return {
        type: GET_DOC_STATUS_FAIL,
        payload: error,
    }
}

//get document status
export const getAssignedUsers = () => {
    return {
        type: GET_ASSIGNED_USERS
    }
}
export const getAssignedUsersSuccess = data => {
    return {
        type: GET_ASSIGNED_USERS_SUCCESS,
        payload: data,
    }
}
export const getAssignedUsersError = error => {
    return {
        type: GET_ASSIGNED_USERS_FAIL,
        payload: error,
    }
}

//upload document
export const uploadDocument = (data, history) => {
    return {
        type: UPLOAD_DOCUMENT,
        payload: { data, history }
    }
}
export const uploadDocumentSuccess = data => {
    return {
        type: UPLOAD_DOCUMENT_SUCCESS,
        payload: data,
    }
}
export const uploadDocumentError = error => {
    return {
        type: UPLOAD_DOCUMENT_FAIL,
        payload: error,
    }
}

//delete document
export const deleteDocument = (id, dispatch, getDocCountByStatus, company_id) => {
    return {
        type: DELETE_DOCUMENT,
        payload: { id, dispatch, getDocCountByStatus, company_id }
    }
}
export const deleteDocumentSuccess = data => {
    return {
        type: DELETE_DOCUMENT_SUCCESS,
        payload: data,
    }
}
export const deleteDocumentError = error => {
    return {
        type: DELETE_DOCUMENT_FAIL,
        payload: error,
    }
}

//delete document
export const getDocumentById = (id) => {
    return {
        type: GET_DOCUMENT_BY_ID,
        payload: id
    }
}
export const getDocumentByIdSuccess = data => {
    return {
        type: GET_DOCUMENT_BY_ID_SUCCESS,
        payload: data,
    }
}
export const getDocumentByIdError = error => {
    return {
        type: GET_DOCUMENT_BY_ID_FAIL,
        payload: error,
    }
}

//approve document
export const approveDocument = (subUrl, id, data, setConfirmModal, history) => {
    return {
        type: APPROVE_DOCUMENT,
        payload: { subUrl, id, data, setConfirmModal, history }
    }
}
export const approveDocumentSuccess = data => {
    return {
        type: APPROVE_DOCUMENT_SUCCESS,
        payload: data,
    }
}
export const approveDocumentError = error => {
    return {
        type: APPROVE_DOCUMENT_FAIL,
        payload: error,
    }
}

//get download document
export const getDownloadDocument = (data, download, handleDownload) => {
    return {
        type: GET_DOWNLOAD_DOCUMENTS,
        payload: { data, download, handleDownload }
    }
}
export const getDownloadDocumentSuccess = data => {
    return {
        type: GET_DOWNLOAD_DOCUMENTS_SUCCESS,
        payload: data,
    }
}
export const getDownloadDocumentError = error => {
    return {
        type: GET_DOWNLOAD_DOCUMENTS_FAIL,
        payload: error,
    }
}

//field wise approve document
export const fieldwiseApproveDocument = (data) => {
    return {
        type: FIELDWISE_APPROVE_DOCUMENT,
        payload: data
    }
}
export const fieldwiseApproveDocumentSuccess = data => {
    return {
        type: FIELDWISE_APPROVE_DOCUMENT_SUCCESS,
        payload: data,
    }
}
export const fieldwiseApproveDocumentError = error => {
    return {
        type: FIELDWISE_APPROVE_DOCUMENT_FAIL,
        payload: error,
    }
}

//object detection
export const reUplaod = (data, callGetDocuments, subUrl) => {
    return {
        type: RE_UPLOAD,
        payload: { data, callGetDocuments, subUrl }
    }
}
export const reUplaodSuccess = data => {
    return {
        type: RE_UPLOAD_SUCCESS,
        payload: data,
    }
}
export const reUplaodError = error => {
    return {
        type: RE_UPLOAD_FAIL,
        payload: error,
    }
}