import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    FORGET_PASSWORD_RESEND_CODE,
    FORGET_PASSWORD_RESEND_CODE_SUCCESS,
    FORGET_PASSWORD_RESEND_CODE_ERROR,
    LOGOUT_ACTIVITY,
    LOGOUT_ACTIVITY_SUCCESS,
    LOGOUT_ACTIVITY_ERROR
} from "./actionTypes"

const initialState = {
    error: "",
    loading: null,
}

export const Login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true,
            }
            break
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
            }
            break
        case LOGOUT_USER:
            state = { ...state, loading: false, }
            break
        case LOGOUT_USER_SUCCESS:
            state = { ...state, loading: false, }
            break
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

//Reset Password
const resetInitialState = {
    resetSuccessMsg: null,
    resetError: null,
    resetLoader: false,
}

export const ResetPassword = (state = resetInitialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD:
            state = {
                ...state,
                resetSuccessMsg: null,
                resetError: null,
                resetLoader: true,
            }
            break
        case RESET_PASSWORD_SUCCESS:
            state = {
                ...state,
                resetSuccessMsg: action.payload,
                resetLoader: false,
            }
            break
        case RESET_PASSWORD_ERROR:
            state = { ...state, resetError: action.payload, resetLoader: false, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

const forgetInitialState = {
    forgetSuccessMsg: null,
    forgetError: null,
    forgetLoader: null,
}

export const ForgetPassword = (state = forgetInitialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD:
            state = {
                ...state,
                forgetSuccessMsg: null,
                forgetError: null,
                forgetLoader: true,
            }
            break
        case FORGET_PASSWORD_SUCCESS:
            state = {
                ...state,
                forgetSuccessMsg: action.payload,
                forgetLoader: false,
            }
            break
        case FORGET_PASSWORD_ERROR:
            state = { ...state, forgetError: action.payload, forgetLoader: null, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

//Forget password resend code
export const ForgetPasswordResendCode = (state = forgetInitialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD_RESEND_CODE:
            state = {
                ...state,
                forgetSuccessMsg: null,
                forgetError: null,
                forgetLoader: true,
            }
            break
        case FORGET_PASSWORD_RESEND_CODE_SUCCESS:
            state = {
                ...state,
                forgetSuccessMsg: action.payload,
                forgetLoader: false,
            }
            break
        case FORGET_PASSWORD_RESEND_CODE_ERROR:
            state = { ...state, forgetError: action.payload, forgetLoader: false, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

const logoutActivity = {
    loading: false,
    status: ''
}

//logout activity
export const LogoutActivity = (state = logoutActivity, action) => {
    switch (action.type) {
        case LOGOUT_ACTIVITY:
            state = { status: 'pending', loading: true };
            break;
        case LOGOUT_ACTIVITY_SUCCESS:
            state = { status: 'success', loading: false };
            break;
        case LOGOUT_ACTIVITY_ERROR:
            state = { status: 'error', loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};