//Get assign users
export const GET_ASSIGN_USERS = "GET_ASSIGN_USERS";
export const GET_ASSIGN_USERS_FAIL = "GET_ASSIGN_USERS_FAIL";
export const GET_ASSIGN_USERS_SUCCESS = "GET_ASSIGN_USERS_SUCCESS";
/*GET ALL users */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/*ADD user */
export const ADD_USERS = "ADD_USERS"
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS"
export const ADD_USERS_FAIL = "ADD_USERS_FAIL"

/*Activate and de-activate*/
export const ACTIVATE_DEACTIVATE_USER = "ACTIVATE_DEACTIVATE_USER"
export const ACTIVATE_DEACTIVATE_USER_SUCCESS = "ACTIVATE_DEACTIVATE_USER_SUCCESS"
export const ACTIVATE_DEACTIVATE_USER_FAIL = "ACTIVATE_DEACTIVATE_USER_FAIL"

/*delete user*/
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/*update settings data*/
export const UPDATE_SETTINGS_DATA = "UPDATE_SETTINGS_DATA"
export const UPDATE_SETTINGS_DATA_SUCCESS = "UPDATE_SETTINGS_DATA_SUCCESS"
export const UPDATE_SETTINGS_DATA_FAIL = "UPDATE_SETTINGS_DATA_FAIL"
