import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { useSelector, useDispatch } from 'react-redux';
import { getDocTypes } from 'store/actions';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CustomDatePicker from 'components/Common/Modals/CustomDatePicker';

const InvoiceFilters = ({ getChartPeriodData, chartDataPeriod, setChartDataPeriod, getDocCounts, documentType, setDocumentType, translate }) => {
    const dispatch = useDispatch()

    const docTypes = useSelector(state => state.GetDocTypes.data?.data);

    useEffect(() => {
        dispatch(getDocTypes())

    }, [])

    const options = [
        { label: `${translate('currentMonth')}`, value: '0' },
        { label: `${translate('previousMonth')}`, value: '1' },
        { label: `${translate('last3Month')}`, value: '3' },
        { label: `${translate('custom')}`, value: 'custom' }
    ];

    return (
        <>
            <div className='dash-filters'>
                <p>{translate('documentType')}</p>
                <div className='drop-down'>
                    <Select
                        options={docTypes}
                        className={`form-control p-0`}
                        classNamePrefix="custom_select"
                        placeholder={'Select...'}
                        value={documentType}
                        onChange={val => {
                            setDocumentType(val)
                            getDocCounts(val?.value?.toLowerCase(), null)
                            getChartPeriodData(null, val?.value?.toLowerCase());
                        }}
                        search={true}
                    />
                </div>
                <div className='drop-down'>
                    <Select
                        options={options}
                        className={`form-control p-0`}
                        classNamePrefix="custom_select"
                        placeholder={'Select...'}
                        search={true}
                        value={chartDataPeriod}
                        onChange={(val) => {
                            setChartDataPeriod(val)
                            getChartPeriodData(val?.value, null)
                            getDocCounts(null, val?.value)
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default withRouter(withTranslation()(InvoiceFilters));