import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FORGET_PASSWORD, FORGET_PASSWORD_RESEND_CODE, LOGIN_USER, LOGOUT_ACTIVITY, LOGOUT_USER, RESET_PASSWORD } from "./actionTypes";
import { apiError, loginSuccess, logoutActivityError, logoutActivitySuccess, userForgetPasswordError, userForgetPasswordSuccess, userResetPasswordError, userResetPasswordSuccess } from "./actions";
import { applicationLogin, forgetPwd, forgetPwdResendCode, resetPwd, logoutActivity } from "../../helpers/backend_helper";
import { clearToken, setToken } from '../../helpers/api_helper';
import toast from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";

function* loginUser({ payload: { user, history, redirectUrl } }) {
    try {
        const response = yield call(applicationLogin, {
            email: user.email,
            password: user.password,
        });
        if (response.status === responseStatus.success) {
            toast.success(response.message, { position: 'bottom-right' });
            localStorage.setItem("jwt", response.token);
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(loginSuccess(response.message));
            setToken()
            if (redirectUrl) {
                history.push(`${redirectUrl}`);
                sessionStorage.removeItem("redirectUrl");
            } else {
                history.push("/dashboard");
            }

        } else if (response.status === responseStatus.error) {
            toast.error(response.message, { position: 'bottom-right' });
            yield put(loginSuccess(response.message));
        } else {
            toast.error(response.message);
            yield put(apiError(response.message));
        }
    } catch (error) {
        toast.error(error.response.data.message, { duration: 6000, position: 'bottom-right' })
        yield put(apiError(error.response.data.message));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("jwt");
        localStorage.removeItem("authUser");
        clearToken();
        history.push("/login");
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* AuthSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(LOGOUT_USER, logoutUser);
}


// Reset Password
function* resetUser({ payload: { users, history } }) {
    try {
        const response = yield call(resetPwd, {
            email: users.email,
            pwd: users.pwd,
            confPwd: users.confPwd,
            verificationToken: users.verificationToken
        })
        if (response.status === responseStatus.success) {
            toast.success(response.message, { position: 'bottom-right' });
            yield put(userResetPasswordSuccess(response.message));
            history.push("/login");
        } else {
            toast.error(response.message, { position: 'bottom-right' });
            yield put(userResetPasswordError(response.message));
        }
    } catch (error) {
        toast.error(error.response.data.message, { duration: 6000, position: 'bottom-right' })
        yield put(userResetPasswordError(error.response.data.message));
    }

}

export function* watchUserPasswordReset() {
    yield takeEvery(RESET_PASSWORD, resetUser)
}

export function* ResetPasswordSaga() {
    yield all([fork(watchUserPasswordReset)])
}

//Forget password
function* forgetUser({ payload: { user, history } }) {

    try {
        const response = yield call(forgetPwd, {
            email: user.email
        })
        if (response.status === responseStatus.success) {
            yield put(userForgetPasswordSuccess(response))
            toast.success(response.message, { position: 'bottom-right' });
            history.push(`/reset-password/${user.email}`);
        } else {
            yield put(userForgetPasswordError(response))
            toast.error(response.message, { position: 'bottom-right' });
        }
    } catch (error) {
        toast.error(error.response.data.message, { duration: 6000, position: 'bottom-right' })
        yield put(userForgetPasswordError(error))
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser)
}

//Forget password resend code
function* forgetUserResendCode({ payload: { user, history } }) {
    try {
        const response = yield call(forgetPwdResendCode, {
            email: user.email
        })
        if (response.status === responseStatus.success) {
            toast.success(response.message, { position: 'bottom-right' });
        } else {
            toast.error(response.message, { position: 'bottom-right' });
        }
    } catch (error) {
        toast.error(error.response.data.message, { duration: 6000 })

    }
}

export function* watchUserPasswordForgetResendCode() {
    yield takeEvery(FORGET_PASSWORD_RESEND_CODE, forgetUserResendCode)
}

export function* ForgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)])
}

export function* ForgetPasswordResendCodeSaga() {
    yield all([fork(watchUserPasswordForgetResendCode)])
}

//logout activity
function* logoutactivity({ payload: data }) {
    try {
        const response = yield call(logoutActivity, data);
        if (response.status === responseStatus.success) {
            yield put(logoutActivitySuccess(response));
        } else {
            yield put(logoutActivityError(response));
        };
    } catch (error) {
        yield put(logoutActivityError(error));
    };
};

export function* LogoutActivitySaga() {
    yield takeEvery(LOGOUT_ACTIVITY, logoutactivity);
};