import React, { memo } from 'react';
import AuthLoader from '../AuthLoader';

const PrimaryButton = ({ btnType, btnName, btnIcon, showIcon, onClick, disabled, loader = false }) => {
    return (
        <>
            <button className={`btn-common btn-primary btn-block primary-button ${loader ? 'p-0' : ''}`} type={loader ? "button" : btnType} onClick={loader ? () => { } : onClick} disabled={disabled}>
                {loader ? <AuthLoader /> : <>
                    {showIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
                    {btnName}
                </>}
            </button>
        </>
    );
};

export default memo(PrimaryButton);