import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { Col, FormGroup, Label, Row } from 'reactstrap';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { addUsers, getUsers } from '../../../../store/users/actions'
import { useDispatch } from 'react-redux';

const UserForm = ({ userData, index, newUser, editTo, setEditTo, translate }) => {

    const roles = useSelector(state => state.GetAdminRoles?.roles)
    const docTypes = useSelector(state => state.GetDocTypes.data?.data)
    const authUser = useSelector(state => state.authInfo.data)
    const addUsersLoader = useSelector(state => state.AddUsers.loading)

    const [role, setRole] = useState([])
    const [defaultRole, setDefaultRole] = useState()
    const userInfo = userData
    const dispatch = useDispatch()

    const validusSchema = yup.object().shape({
        fullName: yup.string().trim().required(`${translate('nameError')}`).max(80, `${translate('nameCharacters')}`),
        email: yup.string().trim().required(`${translate('emailError')}`).test('email', `${translate('validEmail')}`, val => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
            else return true
        }),
        role: yup.string().required(`${translate('roleError')}`),
        accessModel: yup.array().min(1, `${translate('modelError')}`)
    })

    const formValues = {
        _id: userInfo?._id,
        company_id: authUser?.user?.company_id,
        fullName: userInfo?.fullName || '',
        email: userInfo?.email,
        role: userInfo?.role,
        accessModel: userInfo?.accessModel || [],
    }

    const { register, reset, setValue, getValues, trigger, clearErrors, formState: { errors, } } = useForm({
        resolver: yupResolver(validusSchema),
        defaultValues: formValues
    });

    useEffect(() => {
        reset({
            _id: userInfo?._id,
            company_id: authUser?.user?.company_id,
            fullName: userInfo?.fullName || '',
            email: userInfo?.email,
            role: userInfo?.role,
            accessModel: userInfo?.accessModel || [],
        });
        assignRole();
    }, [userInfo]);

    useEffect(() => {
        if (roles && roles.length > 0) {
            let result = []
            roles.map((item) => {
                result.push({ label: item?.label, value: item?.role })
            })
            setRole(result)
        }
    }, [roles])

    const insertValues = (value) => {
        let types = getValues('accessModel')

        const index = types.indexOf(value);

        if (index > -1) types.splice(index, 1)
        else types.push(value)

        setValue('accessModel', types)
    }

    const onSubmit = (data) => {
        dispatch(addUsers(data, dispatch, getUsers));
        // if (editTo) setEditTo(null)
        // setEditTo(null)
    }

    const assignRole = () => {
        const result = role.filter(role => role.value === userInfo?.role)
        setDefaultRole(result[0])
    }

    useEffect(() => {
        if (userInfo && role) {
            assignRole()
        }
    }, [role])

    const enableEdit = userInfo?._id == editTo ? true : false

    return (
        <>{
            enableEdit ?
                <form onSubmit={(e) => {
                    e.preventDefault()
                    const error = trigger()
                    error.then(result => {
                        if (result) {
                            onSubmit(getValues())
                        }
                    })
                }} >
                    <Row>
                        <Col md="8">
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <div className='lableAction'>
                                            <Label>{translate('fullName')}</Label>
                                        </div>
                                        <input className={`form-control ${errors.fullName?.message && 'is-invalid'}`} type="text" {...register('fullName')} onChange={() => clearErrors('fullName')} />
                                        {errors.fullName?.message ? <p className={`error-field`} >{errors.fullName?.message}</p> : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <div className='lableAction'>
                                            <Label>{translate('email')}</Label>
                                        </div>
                                        <input className={`form-control ${errors.email?.message && 'is-invalid'}`} type="text" {...register('email')} onChange={() => clearErrors('email')} />
                                        {errors.email?.message ? <p className={`error-field`} >{errors.email?.message}</p> : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label>{translate('role')} </Label>
                                        <div className={`position-relative drop-down`}>
                                            <Select
                                                options={role}
                                                className={`form-control p-0 ${errors.role?.message ? 'is-invalid' : ''}`}
                                                classNamePrefix="custom_select"
                                                search={true}
                                                value={defaultRole}
                                                name="role"
                                                placeholder={`Select role`}
                                                onChange={(value) => {
                                                    setDefaultRole(value)
                                                    setValue('role', value?.value)
                                                    clearErrors('email')
                                                }}
                                            />
                                            {errors.role?.message ? <p className={`error-field`} >{errors.role?.message}</p> : null}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="4">
                            <Label>{`${translate('modelAccess')} (${docTypes?.length > 0 ? docTypes?.length : "0"})`}</Label>
                            <div className={`model-access form-control ${errors.accessModel?.message ? 'is-invalid' : ''}`}>
                                {/* <div key={index} className="form-check">
                                    <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        checked={getValues('accessModel')?.length == docTypes?.length}
                                        value=""
                                        name=''
                                        id={`selectAll${index}`}
                                        onClick={(e) => {
                                            if (!e.target.checked) {
                                                let result = []
                                                docTypes?.map((item, idx) => {
                                                    result?.push(item?.value)
                                                })
                                                setValue('accessModel', result)
                                            } else setValue('accessModel', [])
                                        }}
                                    />
                                    <Label htmlFor={`selectAll${index}`}>{translate('selectall')}</Label>
                                </div> */}
                                {docTypes?.map((item, idx) => {
                                    const checked = userInfo?.accessModel?.includes(item?.value) ? true : false
                                    return <div key={idx} className={enableEdit ? "form-check" : ''}>
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            defaultChecked={checked}
                                            value={item?.value}
                                            {...register('accessModel')}
                                            onChange={(e) => {
                                                insertValues(e.target.value)
                                                clearErrors('accessModel')
                                            }}
                                            id={`checbox${idx}${index}`}
                                        />
                                        <Label htmlFor={`checbox${idx}${index}`}>{item?.label}</Label>
                                    </div>
                                })}
                            </div>
                            {errors.accessModel?.message ? <p className={`error-field`} >{errors.accessModel?.message}</p> : null}
                        </Col>
                    </Row>
                    <div className='d-flex'>
                        <SecountryBtn btnType={"button"} onClick={() => {
                            reset()
                            setDefaultRole(null)
                            if (editTo) setEditTo('')
                        }} btnName={"Cancel"} showIcon={false} />
                        <div className={"ms-3"}>
                            <PrimaryButton loader={addUsersLoader} btnType={"submit"} btnName={translate("save")} showIcon={false} />
                        </div>
                    </div>
                </form >
                : <>
                    <Row>
                        <Col md="8">
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <div className='lableAction'>
                                            <Label>{translate('fullName')}</Label>
                                        </div>
                                        <label className="form-control text-capitalize" style={{ display: 'flex', alignItems: 'center' }}>{userInfo?.fullName}</label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <div className='lableAction'>
                                            <Label>{translate('email')}</Label>
                                        </div>
                                        <label className="form-control" style={{ display: 'flex', alignItems: 'center' }}>{userInfo?.email}</label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label>{translate('role')}</Label>
                                        <label className="form-control text-capitalize" style={{ display: 'flex', alignItems: 'center' }}>{userInfo?.role?.toLowerCase()?.replaceAll('_', ' ')}</label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="4">
                            <Label>{`${translate('modelAccess')} (${docTypes?.length > 0 ? docTypes?.length : "0"})`}</Label>
                            <div className='model-access form-control'>
                                {docTypes?.map((item, idx) => {
                                    const checked = userInfo?.accessModel?.includes(item?.value) ? true : false
                                    return <div key={idx} className={enableEdit ? "form-check" : ''}>
                                        <input
                                            className='form-check-input me-2'
                                            type="checkbox"
                                            checked={checked}
                                            onChange={() => { }}
                                            id={`checbox${idx}${index}`} />
                                        <Label htmlFor={`checbox${idx}${index}`}>{item?.label}</Label>
                                    </div>
                                })}
                            </div>
                        </Col>
                    </Row>
                </>
        }
        </>
    );
};

export default UserForm;