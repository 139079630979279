import React from 'react';
import { Modal } from 'reactstrap';

//Hook-Form
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"

//Components
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

const CreateFolder = ({ showModal, toggle }) => {
    const folderForm = {
        createFolder: "",

    }
    const folderSchema = Yup.object().shape({
        createFolder: Yup.string().required("Please enter the name of folder").max(20, "Should not exceed 40 characters"),
    })

    const { register, handleSubmit, formState: { errors, }, } = useForm({
        resolver: yupResolver(folderSchema),
        defaultValues: folderForm
    });

    const onSubmit = (values) => {
        // dispatch(loginUser(values, props.history, redirectUrl));
    }
    return (
        <>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='doc-modal create-folder'
                toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                    <h1 className='page-title font-size-18 p-0'>New Folder</h1>
                      <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body p-0">
                    <form onClick={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <input type="text" {...register("createFolder")} className={errors.createFolder?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                            {errors.createFolder?.message ? <p className={`invalid-feedback`} >{errors.createFolder?.message}</p> : null}
                        </div>
                        <div className='filter-btn d-flex'>
                            <SecountryBtn btnName="Cancel" showIcon={false} onClick={toggle} />
                            <PrimaryButton btnName="Create" showIcon={false} />
                        </div>
                    </form>

                </div>
            </Modal>
        </>
    );
};

export default CreateFolder;