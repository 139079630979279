import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch()
  const obj = JSON.parse(localStorage.getItem("authUser"));

  const notification = undefined

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          {notification?.data?.totalCount > 0 ?
            <span className="badge bg-danger rounded-pill">{notification?.data?.totalCount}</span> : ""}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }} className={`notification-msg ${notification?.data?.totalCount === 0 ? "no-notification" : ""}`}>
            {/* {notification?.data?.totalCount === 0 ? <p className="error-msg">No more notification</p> : ""}
            {notification && notification?.data?.data?.map((item, index) => {
              return <React.Fragment key={index}>
                <Link to={`/enquiries/${item?.siStatusData ? (item?.siStatusData?.shipperType === 'SEA' ? item?.siStatusData?.shipperStatus === "re-submit" ? `update-shipper-information/${item?.siStatusData?.quoteId}/${item.siStatusData.shipperId}` : `sea-shipper-information-details/${item.siStatusData.shipperId}` : `${item?.siStatusData?.shipperStatus === "re-submit" ? `update-shipper-information/${item?.siStatusData?.quoteId}` : "air-shipper-information-details"}/${item.siStatusData.shipperId}`) : (item?.quoteStatus === "re-submit" ? `update-quote/${item?._id}` : item?._id)}`} className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16 notification-icon">
                        <img src={item?.siStatusData ? (item?.siStatusData?.shipperType === 'SEA' ? ship : air) : enquiries} alt={"ship"} />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {item?.siStatusData ? item.quoteReferenceID : item?.quoteNumber}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {item?.siStatusData ? `${item?.siStatusData?.shipperStatus.toUpperCase()} - ${item?.siStatusData?.shipperType} SI` : `${item?.quoteStatus?.toUpperCase()} - ENQUIRY`}
                        </p>
                        <span className="lable-name">{item?.siStatusData ? "SI" : "Enquiry"}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            })} */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/enquiries">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View All..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};