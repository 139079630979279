import React, { useState } from 'react';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    return (
        <>
            <div className='search-group'>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                />
                <div className='search-icon'>
                    <i className='bx bx-search'></i>
                </div>
            </div>
        </>
    );
};

export default SearchBar;