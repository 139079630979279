import {
  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_MODELS_FAIL,
  DELETE_MODELS,
  DELETE_MODELS_SUCCESS,
  DELETE_MODELS_FAIL,
  DELETE_MODELS_MULTIPLE,
  DELETE_MODELS_MULTIPLE_SUCCESS,
  DELETE_MODELS_MULTIPLE_FAIL,
  CREATE_MODAL,
  CREATE_MODAL_SUCCESS,
  CREATE_MODAL_FAIL,
  UPLOAD_MODEL_DOCUMENT,
  UPLOAD_MODEL_DOCUMENT_SUCCESS,
  UPLOAD_MODEL_DOCUMENT_FAIL,
  GET_MODEL_BY_ID,
  GET_MODEL_BY_ID_SUCCESS,
  GET_MODEL_BY_ID_FAIL,
  PUBLISH_MODEL,
  PUBLISH_MODEL_SUCCESS,
  PUBLISH_MODEL_FAIL,
  TRAIN_MODEL,
  TRAIN_MODEL_SUCCESS,
  TRAIN_MODEL_FAIL,
  PRE_MODEL_START_STOP,
  PRE_MODEL_START_STOP_SUCCESS,
  PRE_MODEL_START_STOP_FAIL,
  GET_PRE_MODEL,
  GET_PRE_MODEL_SUCCESS,
  GET_PRE_MODEL_FAIL,
  MODEL_StART_STOP,
  MODEL_StART_STOP_SUCCESS,
  MODEL_StART_STOP_FAIL,
  UPDATE_MODEL_BY_ID,
  GET_STARTED_MODEL,
  GET_STARTED_MODEL_SUCCESS,
  GET_STARTED_MODEL_FAIL,
} from "./actionTypes"

//Get Models
const modelsInitialState = {
  error: "",
  data: "",
  loading: false,
}

export const GetModelsList = (state = modelsInitialState, action) => {
  switch (action.type) {
    case GET_MODELS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MODELS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
      }
      break
    case GET_MODELS_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case UPDATE_MODEL_BY_ID:
      const prevData = JSON.parse(JSON.stringify(state?.data));
      const result = prevData?.data?.pageData?.map((item) => {
        if (item?.id === action?.payload?.id) {
          return action?.payload
        } else {
          return item;
        }
      })
      const newState = {
        ...state,
        data: {
          ...state?.data,
          pageData: result
        }
      }

      state = {
        error: newState?.error,
        data: newState?.data,
        loading: newState?.loading
      };
      break
  }
  return state
}

// Delete Models
const deleteModels = {
  error: "",
  success: "",
  loading: false
}

export const DeleteModels = (state = deleteModels, action) => {
  switch (action.type) {
    case DELETE_MODELS:
      state = {
        ...state,
        loading: true
      }
      break
    case DELETE_MODELS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false
      }
      break
    case DELETE_MODELS_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false
      }
      break
  }
  return state
}

// Mulity Delete Models
const mulityModelsDelete = {
  error: "",
  success: "",
  loading: false
}

export const DeleteMulityModels = (state = mulityModelsDelete, action) => {
  switch (action.type) {
    case DELETE_MODELS_MULTIPLE:
      state = {
        ...state,
        loading: true
      }
      break
    case DELETE_MODELS_MULTIPLE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false
      }
      break
    case DELETE_MODELS_MULTIPLE_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false
      }
      break
  }
  return state
}

// create modal
const createModal_state = {
  error: "",
  data: "",
  loading: false,
}

export const CreateModal = (state = createModal_state, action) => {
  switch (action.type) {
    case CREATE_MODAL:
      state = {
        error: "",
        data: "",
        loading: true,
      }
      break
    case CREATE_MODAL_SUCCESS:
      state = {
        error: "",
        loading: false,
        data: action.payload,
      }
      break
    case CREATE_MODAL_FAIL:
      state = {
        data: "",
        loading: false,
        error: action.payload
      }
      break
  }
  return state
}


//upload document
const UPLOAD_MODEL_DOCUMENT_initialState = {
  error: "",
  data: "",
  loading: false,
}

export const ModelDocumentUpload = (state = UPLOAD_MODEL_DOCUMENT_initialState, action) => {
  switch (action.type) {
    case UPLOAD_MODEL_DOCUMENT:
      state = { data: '', error: '', loading: true }
      break
    case UPLOAD_MODEL_DOCUMENT_SUCCESS:
      state = { error: '', data: action.payload, loading: false }
      break
    case UPLOAD_MODEL_DOCUMENT_FAIL:
      state = { data: '', error: action.payload, loading: false }
      break
  }
  return state
}

//get model by id
const getModelById_initialState = {
  error: "",
  data: "",
  loading: false,
}

export const GetModelById = (state = getModelById_initialState, action) => {
  switch (action.type) {
    case GET_MODEL_BY_ID:
      state = { data: '', error: '', loading: true }
      break
    case GET_MODEL_BY_ID_SUCCESS:
      state = { error: '', data: action.payload, loading: false }
      break
    case GET_MODEL_BY_ID_FAIL:
      state = { data: '', error: action.payload, loading: false }
      break
  }
  return state
}

//publish model
const publish_model_state = {
  error: "",
  data: "",
  loading: false
};

export const PublishModel = (state = publish_model_state, action) => {
  switch (action.type) {
    case PUBLISH_MODEL:
      state = { data: '', error: '', loading: true };
      break;
    case PUBLISH_MODEL_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case PUBLISH_MODEL_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};

//get publish model
const getpublish_model_state = {
  error: "",
  data: "",
  loading: false
};

export const GetStartedModel = (state = getpublish_model_state, action) => {
  switch (action.type) {
    case GET_STARTED_MODEL:
      state = { data: '', error: '', loading: true };
      break
    case GET_STARTED_MODEL_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case GET_STARTED_MODEL_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};

//train model
const train_model_state = {
  error: "",
  data: "",
  loading: false
};

export const TrainModel = (state = train_model_state, action) => {
  switch (action.type) {
    case TRAIN_MODEL:
      state = { data: '', error: '', loading: true };
      break;
    case TRAIN_MODEL_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case TRAIN_MODEL_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};

//pre model start stop
const pre_model_start_stop_state = {
  error: "",
  data: "",
  loading: false
};

export const PreModelStartStop = (state = pre_model_start_stop_state, action) => {
  switch (action.type) {
    case PRE_MODEL_START_STOP:
      state = { data: '', error: '', loading: true };
      break;
    case PRE_MODEL_START_STOP_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case PRE_MODEL_START_STOP_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};

//get pre model
const get_pre_model_state = {
  error: "",
  data: "",
  loading: false
};

export const GetPreModel = (state = get_pre_model_state, action) => {
  switch (action.type) {
    case GET_PRE_MODEL:
      state = { data: '', error: '', loading: true };
      break;
    case GET_PRE_MODEL_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case GET_PRE_MODEL_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};

// model start stop
const model_start_stop_state = {
  error: "",
  data: "",
  loading: false
};

export const ModelStartStop = (state = model_start_stop_state, action) => {
  switch (action.type) {
    case MODEL_StART_STOP:
      state = { data: '', error: '', loading: true };
      break;
    case MODEL_StART_STOP_SUCCESS:
      state = { error: '', data: action.payload, loading: false };
      break;
    case MODEL_StART_STOP_FAIL:
      state = { data: '', error: action.payload, loading: false };
      break;
  };
  return state;
};
