import { GET_ALL_ADMIN, GET_ALL_ADMIN_SUCCESS, GET_ALL_ADMIN_FAIL, GET_ADMIN_ROLES, GET_ADMIN_ROLES_FAIL, GET_ADMIN_ROLES_SUCCESS } from "./actionTypes"

//Get Admins
export const getAllAdmin = () => ({
  type: GET_ALL_ADMIN,
})
export const getAllAdminSuccess = users => ({
  type: GET_ALL_ADMIN_SUCCESS,
  payload: users,
})
export const getAllAdminFail = error => ({
  type: GET_ALL_ADMIN_FAIL,
  payload: error,
})


//Get Admin roles
export const getAdminRoles = () => ({
  type: GET_ADMIN_ROLES,
})
export const getAdminRolesSuccess = roles => (
  {
  type: GET_ADMIN_ROLES_SUCCESS,
  payload: roles,
})
export const getAdminRolesFail = error => ({
  type: GET_ADMIN_ROLES_FAIL,
  payload: error,
})