import {
    GET_MY_DOCUMENTS,
    GET_MY_DOCUMENTS_SUCCESS,
    GET_MY_DOCUMENTS_FAIL,
    GET_DOC_TYPES,
    GET_DOC_TYPES_SUCCESS,
    GET_DOC_TYPES_FAIL,
    ASSIGN_USER,
    ASSIGN_USER_SUCCESS,
    ASSIGN_USER_FAIL,
    GET_DOC_STATUS,
    GET_DOC_STATUS_SUCCESS,
    GET_DOC_STATUS_FAIL,
    GET_ASSIGNED_USERS,
    GET_ASSIGNED_USERS_SUCCESS,
    GET_ASSIGNED_USERS_FAIL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL,
    GET_DOCUMENT_BY_ID,
    GET_DOCUMENT_BY_ID_SUCCESS,
    GET_DOCUMENT_BY_ID_FAIL,
    APPROVE_DOCUMENT,
    APPROVE_DOCUMENT_SUCCESS,
    APPROVE_DOCUMENT_FAIL,
    GET_DOWNLOAD_DOCUMENTS,
    GET_DOWNLOAD_DOCUMENTS_SUCCESS,
    GET_DOWNLOAD_DOCUMENTS_FAIL,
    FIELDWISE_APPROVE_DOCUMENT,
    FIELDWISE_APPROVE_DOCUMENT_SUCCESS,
    FIELDWISE_APPROVE_DOCUMENT_FAIL,
    RE_UPLOAD,
    RE_UPLOAD_SUCCESS,
    RE_UPLOAD_FAIL,
} from "./actionTypes";

//get my documents
const getMydocuments_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetMyDocuments = (state = getMydocuments_initialState, action) => {
    switch (action.type) {
        case GET_MY_DOCUMENTS:
            state = {
                ...state,
                loading: true,
            }
            break
        case GET_MY_DOCUMENTS_SUCCESS:
            state = {
                ...state,
                data: action.payload,
                loading: false,
            }
            break
        case GET_MY_DOCUMENTS_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false,
            }
            break
    }
    return state
}

//assign user
const assignUser_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const AssignUser = (state = assignUser_initialState, action) => {
    switch (action.type) {
        case ASSIGN_USER:
            state = { error: '', data: '', loading: true }
            break
        case ASSIGN_USER_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case ASSIGN_USER_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//get doc types
const docTypes_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetDocTypes = (state = docTypes_initialState, action) => {
    switch (action.type) {
        case GET_DOC_TYPES:
            state = { ...state, loading: true }
            break
        case GET_DOC_TYPES_SUCCESS:
            state = { ...state, data: action.payload, loading: false }
            break
        case GET_DOC_TYPES_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
    }
    return state
}

//get doc status
const docStatus_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetDocStatus = (state = docStatus_initialState, action) => {
    switch (action.type) {
        case GET_DOC_STATUS:
            state = { ...state, loading: true }
            break
        case GET_DOC_STATUS_SUCCESS:
            state = { ...state, data: action.payload, loading: false }
            break
        case GET_DOC_STATUS_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
    }
    return state
}

//get assigned users
const getAssignedUsers_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetAssignedUsers = (state = getAssignedUsers_initialState, action) => {
    switch (action.type) {
        case GET_ASSIGNED_USERS:
            state = { ...state, loading: true }
            break
        case GET_ASSIGNED_USERS_SUCCESS:
            state = { ...state, data: action.payload, loading: false }
            break
        case GET_ASSIGNED_USERS_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
    }
    return state
}

//upload document
const UPLOAD_DOCUMENT_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const DocumentUpload = (state = UPLOAD_DOCUMENT_initialState, action) => {
    switch (action.type) {
        case UPLOAD_DOCUMENT:
            state = { data: '', error: '', loading: true }
            break
        case UPLOAD_DOCUMENT_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case UPLOAD_DOCUMENT_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//delete document
const DELETE_DOCUMENT_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const DeleteDocument = (state = DELETE_DOCUMENT_initialState, action) => {
    switch (action.type) {
        case DELETE_DOCUMENT:
            state = { data: '', error: '', loading: true }
            break
        case DELETE_DOCUMENT_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case DELETE_DOCUMENT_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//delete document
const getDocumentById_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetDocumentById = (state = getDocumentById_initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_BY_ID:
            state = { data: '', error: '', loading: true }
            break
        case GET_DOCUMENT_BY_ID_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case GET_DOCUMENT_BY_ID_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//approve document
const approveDocument_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const ApproveDocument = (state = approveDocument_initialState, action) => {
    switch (action.type) {
        case APPROVE_DOCUMENT:
            state = { data: '', error: '', loading: true }
            break
        case APPROVE_DOCUMENT_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case APPROVE_DOCUMENT_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//get download document
const downloadDocument_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const GetDownloadDocument = (state = downloadDocument_initialState, action) => {
    switch (action.type) {
        case GET_DOWNLOAD_DOCUMENTS:
            state = { data: '', error: '', loading: true }
            break
        case GET_DOWNLOAD_DOCUMENTS_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case GET_DOWNLOAD_DOCUMENTS_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//field wise approve document
const fieldwiseDocument_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const FieldwiseApproveDocument = (state = fieldwiseDocument_initialState, action) => {
    switch (action.type) {
        case FIELDWISE_APPROVE_DOCUMENT:
            state = { data: '', error: '', loading: true }
            break
        case FIELDWISE_APPROVE_DOCUMENT_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case FIELDWISE_APPROVE_DOCUMENT_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}

//object detection
const objectDetection_initialState = {
    error: "",
    data: "",
    loading: false,
}

export const ReUpload = (state = objectDetection_initialState, action) => {
    switch (action.type) {
        case RE_UPLOAD:
            state = { data: '', error: '', loading: true }
            break
        case RE_UPLOAD_SUCCESS:
            state = { error: '', data: action.payload, loading: false }
            break
        case RE_UPLOAD_FAIL:
            state = { data: '', error: action.payload, loading: false }
            break
    }
    return state
}