import { STATUS, STATUS_SUCCESS, STATUS_FAIL } from "./actionType"

const initialState = {
  error: "",
  success: "",
  loader: false,
}

const changeStatus = (state = initialState, action) => {
  switch (action.type) {
    case STATUS:
      state = {
        ...state,
        success: "",
        loader: true,
      }
      break
    case STATUS_SUCCESS:
      state = { ...state, success: action.payload,loader: false, }
      break
    case STATUS_FAIL:
      state = { ...state, error: action.payload,loader: false, }
      break
  }
  return state
}

export default changeStatus
