import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import Skeleton from 'react-loading-skeleton';

const Breadcrumb = props => {
  return (
    <Row>
      {props?.documentLoading === true ?
        <Col className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="page-title p-0">
              {props.goBack ? <span><Skeleton height={"35px"} width={'35px'} circle={'30px'} className="me-2" /></span> : ''}
              <Skeleton height={"18px"} width={'180px'} /></h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Skeleton height={"18px"} width={'130px'} />
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Skeleton height={"18px"} width={'130px'} />
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>
        :
        <Col className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="page-title p-0">
              {props.goBack ? <Link to={props.dashurl}><span><i className="bx bx-arrow-back"></i></span></Link> : ''}
              {props.breadcrumbItem}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to={props.dashurl}>{props.title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{props.breadcrumbName ? props.breadcrumbName : props.breadcrumbItem}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>}

    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  goBack: PropTypes.bool,
  documentLoading: PropTypes.bool
}

export default Breadcrumb
