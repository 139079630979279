import React, { memo, useState } from 'react';
import { Modal } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';
import { useSelector } from 'react-redux';

const RejectDocument = ({ showModal, toggle, handleApprove, id }) => {

    const loader = useSelector(state => state.ApproveDocument.loading);

    const [reason, setReason] = useState(false);
    const [error, setError] = useState({ reason: false });

    const handleReject = () => {
        if (!reason) {
            setError({ reason: true });
            return;
        }
        handleApprove("approve-document", id, 'rejected', reason);
    }

    return (
        <div>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='doc-modal create-folder'
                toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>Reject document</h1>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <div>
                        <textarea className={`form-control ${!error?.reason ? "mb-3" : ""}`} style={{ border: `1px solid #dedede`, minHeight: "90px" }} onChange={(e) => {
                            if (e.target.value) {
                                setError({ reason: false });
                            } else {
                                setError({ reason: true });
                            }
                            setReason(e.target.value)
                        }}></textarea>
                        {error?.reason && <p className={`d-block invalid-feedback`} >Reason for reject is required</p>}
                    </div>
                    <div className='filter-btn d-flex justify-content-center'>
                        <SecountryBtn btnName={`Cancel`} showIcon={false} onClick={toggle} />
                        <PrimaryButton loader={loader} btnName={`Reject`} showIcon={false} onClick={() => handleReject()} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default memo(RejectDocument);