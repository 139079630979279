import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_COMPANY_BY_ID } from "./actionTypes";
import { getCompanyByIdError, getCompanyByIdSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getCompanyById } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

// get company by id
function* GetCompanyById({ payload: id }) {
  try {
    const response = yield call(getCompanyById, id)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getCompanyByIdError(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getCompanyByIdSuccess(response))
    }
  } catch (error) {
    yield put(getCompanyByIdError(error))
  }
}

export function* GetCompanyByIdSaga() {
  yield takeEvery(GET_COMPANY_BY_ID, GetCompanyById)
}