import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import Select from "react-select";
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'

import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

//Store
import { useDispatch, useSelector } from 'react-redux';
import UploadingItems from '../UploadingItems';
import { fileUploadProgress } from 'store/fileuploadInfo/action';
import { getNyDocuments, getStartedModel, uploadDocument } from 'store/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateDataCount, uploadFiles, uploadFilesShowHide } from 'store/uploadFiles/action';
import Uuid from 'constants/Uuid';

const UploadFiles = ({ showModal, toggle, close, modalTitle, primaryBtn, secountBtn }) => {
    const fileLength = process.env.REACT_APP_FILE_UPLOAD_LENGTH;

    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedFiles, setSelectedFiles] = useState([]);
    // const [selectedModel, setSelectedModel] = useState(null);
    const [shwoProgress, setShowProgress] = useState([]);
    const [filterFiles, setFilterFiles] = useState(null);

    //Auth info
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));
    const uploadFileLoading = useSelector(state => state.DocumentUpload);

    // Get Models List
    const modelData = useSelector(state => state.GetStartedModel?.data?.data);

    //get upload files
    const uploadFileInfo = useSelector(state => state.FileUpload?.data);
    const files = useSelector(state => state.UploadFiles.data);

    const modelName = modelData && modelData?.map((item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });

    const formValues = {
        modelsName: null,
        files: []
    };

    const uploadSchema = yup.object().shape({
        modelsName: yup.string().nullable().required('Select the model name'),
        files: yup
            .mixed()
            .required('Select files from the system')
            .test(
                'required',
                'Select files from the system',
                value => value?.length > 0
            )
            .test(
                'required',
                `Files length should be less than or equal to ${fileLength}`,
                value => value?.length <= fileLength
            )
            .test(
                'fileFormat',
                'Unsupported file format',
                value => value.every(file => ['image/jpeg', 'image/png'].includes(file.type))
            )
            .test(
                'fileSize',
                'Each file size should not exceed 2 MB.',
                value => value.every(file => file.size <= 2097152) // 2MB
            ),
    });

    const { handleSubmit, reset, clearErrors, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(uploadSchema),
        defaultValues: formValues
    });

    const afterUploadSuccess = () => {
        close(false);
        reset();
        // setSelectedModel(null);
        setSelectedFiles([]);
    }

    const onSubmit = (values) => {

        // let fileData = [...uploadFileInfo]
        // fileData.push(selectedFiles)
        // dispatch(fileUploadProgress(fileData));

        let fileDetails = [...files], ids = [];
        values?.files?.map((item) => {
            const id = Uuid();
            ids.push(id);
            fileDetails.push({ fileName: item?.name, status: "in_progress", id: id })
        })

        const data = {
            files: values?.files,
            company_id: userInfo?.company_id,
            model_id: values?.modelsName,
            pre_model: false,
            ids: ids
        };

        dispatch(updateDataCount({ total: fileDetails?.length, uploaded: 0 }));
        dispatch(uploadFiles(fileDetails));
        dispatch(uploadFilesShowHide(true));
        afterUploadSuccess();
        dispatch(uploadDocument(data, history));
    };

    useEffect(() => {
        dispatch(getStartedModel());
    }, []);

    // Drop the files
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setValue('files', droppedFiles);
        setSelectedFiles(droppedFiles);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Cancel upload files
    const handleFileCancel = (fileIndex) => {
        const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
        setValue('files', updatedFiles);
        setSelectedFiles(updatedFiles);
    };

    // Cancel upload files from serach
    const handleSearchFileCancel = (cancelFile, currentIndex) => {
        const updatedFiles = selectedFiles.filter((_, index) => index !== cancelFile?.index);
        const forFilterFiles = filterFiles.filter((_, index) => index !== currentIndex);
        setFilterFiles(forFilterFiles);
        setValue('files', updatedFiles);
        setSelectedFiles(updatedFiles);
    };

    const handleCancel = () => {
        close(false);
        reset();
        setSelectedFiles([]);
        // setSelectedModel(null);
        clearErrors(["modelsName", "files"]);
    }

    const handleFilters = (e) => {
        let newfiltered = [];
        const filtered = selectedFiles?.filter((filter, index) => {
            if (filter.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
                newfiltered.push({ index: index, file: filter });
            }
        });
        setFilterFiles(newfiltered);
    }

    return (
        <div>
            <Modal size="xl" isOpen={showModal} centered={true}
                className='doc-modal create-folder upload-files-modal'
                toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        {uploadFileLoading.loading === false &&
                            <span onClick={handleCancel} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                        }
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body px-0">
                        {/* {uploadFileLoading.loading === true ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p className='mb-0'>Uploading please wait...</p>
                                <div className="spinner-border ms-3" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                            : */}
                        <>
                            <div className='upload-container'>
                                <Row>
                                    <Col className="col-6">
                                        <Card>
                                            <CardBody>

                                                <div className="form-group mb-4">
                                                    <Label className="form-label">Model name</Label>
                                                    <div className='drop-down'>
                                                        <Controller
                                                            name="modelsName"
                                                            control={control}
                                                            render={({ field, value }) => (
                                                                <Select
                                                                    search={true}
                                                                    options={modelName?.length > 0 ? modelName : [{ label: "No active models", value: "" }]}
                                                                    value={value}
                                                                    className={`${errors.modelsName ? 'is-invalid ' : ''} form-control p-0`}
                                                                    classNamePrefix="custom_select"
                                                                    placeholder={'Select....'}
                                                                    onChange={(value) => {
                                                                        field.onChange(value?.value);
                                                                        // setSelectedModel(value)
                                                                        // setValue('modelsName', value?.value)
                                                                        // clearErrors('modelsName')
                                                                    }}

                                                                />
                                                            )}
                                                        />

                                                        {errors.modelsName?.message ? <p className={`error-field`} >{errors.modelsName?.message}</p> : null}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <Label className="form-label">Upload Files</Label>
                                                    <div className='uploading-files'>
                                                        <Controller
                                                            name="files"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <div className={`file-uploader ${errors.files?.message ? 'is-invalid' : ''}`}
                                                                    onDrop={handleDrop}
                                                                    onDragOver={handleDragOver}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        multiple
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={(e) => {
                                                                            const files = Array.from(e.target.files);
                                                                            field.onChange(files);
                                                                            setSelectedFiles(files);
                                                                        }}
                                                                    />
                                                                    <div className="">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                    </div>
                                                                    <h5>Drop files here or click to upload.</h5>
                                                                    <p>(Allowed document types jpg & png)</p>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors.files?.message && <p className={`error-field`} >{errors.files?.message}</p>}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="col-6">
                                        <Card>
                                            <CardBody>
                                                <div className="form-group">
                                                    <Label className="form-label">Uploaded Files</Label>
                                                    <input type="text" placeholder={`Search`} className={`form-control`} style={{ background: '#fff' }} onChange={(e) => {
                                                        handleFilters(e);
                                                    }} />
                                                    <div className='uploading-files'>
                                                        {filterFiles ? (
                                                            <div className="dropzone-previews mt-3" id="file-previews" style={{ paddingRight: filterFiles.length > 3 ? "10px" : "" }}>
                                                                {filterFiles.map((file, index) => (
                                                                    <div key={index} className="mt-1 mb-0">
                                                                        <div className="col-auto">
                                                                            <i className='bx bx-file'></i>
                                                                            <p>{file?.file?.name}</p>
                                                                            <p className='file-close' onClick={() => handleSearchFileCancel(file, index)}><i className='bx bx-x'></i></p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div className="dropzone-previews mt-3" id="file-previews" style={{ paddingRight: selectedFiles?.length > 3 ? "10px" : "" }}>
                                                                {selectedFiles?.map((file, index) => (
                                                                    <div key={index} className="mt-1 mb-0">
                                                                        <div className="col-auto">
                                                                            <i className='bx bx-file'></i>
                                                                            <p>{file.name}</p>
                                                                            <p className='file-close' onClick={() => handleFileCancel(index)}><i className='bx bx-x'></i></p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className='filter-btn d-flex'>
                                <SecountryBtn btnName={secountBtn} showIcon={false} btnType={'button'} onClick={handleCancel} />

                                <span className={`d-flex ${uploadFileLoading.loading === false ? 'disabled' : ''}`}>
                                    <PrimaryButton btnName={primaryBtn} showIcon={false} />
                                </span>
                            </div>
                        </>
                        {/* } */}
                    </div>
                </form>
            </Modal>
            {/* <UploadingItems shwoProgress={shwoProgress} selectedFiles={selectedFiles} /> */}
        </div>
    );
};

export default UploadFiles;
