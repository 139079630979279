import {
    GET_COMPANY_BY_ID,
    GET_COMPANY_BY_ID_SUCCESS,
    GET_COMPANY_BY_ID_FAIL,
} from "./actionTypes"

//get company by id
export const getCompanyById = (id) => {
    return {
        type: GET_COMPANY_BY_ID,
        payload: id
    }
}
export const getCompanyByIdSuccess = data => {
    return {
        type: GET_COMPANY_BY_ID_SUCCESS,
        payload: data,
    }
}
export const getCompanyByIdError = error => {
    return {
        type: GET_COMPANY_BY_ID_FAIL,
        payload: error,
    }
}
