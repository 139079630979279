import {
    GET_ASSIGN_USERS,
    GET_ASSIGN_USERS_SUCCESS,
    GET_ASSIGN_USERS_FAIL,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    ADD_USERS,
    ADD_USERS_SUCCESS,
    ADD_USERS_FAIL,
    ACTIVATE_DEACTIVATE_USER,
    ACTIVATE_DEACTIVATE_USER_SUCCESS,
    ACTIVATE_DEACTIVATE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    UPDATE_SETTINGS_DATA,
    UPDATE_SETTINGS_DATA_SUCCESS,
    UPDATE_SETTINGS_DATA_FAIL,
} from "./actionTypes"

//get assign users
export const getAssignUsers = () => {
    return {
        type: GET_ASSIGN_USERS
    }
}
export const getAssignUsersSuccess = data => {
    return {
        type: GET_ASSIGN_USERS_SUCCESS,
        payload: data,
    }
}
export const getAssignUsersError = error => {
    return {
        type: GET_ASSIGN_USERS_FAIL,
        payload: error,
    }
}


//Get all users
export const getUsers = () => ({
    type: GET_USERS,
})
export const getUsersSuccess = users => ({
    type: GET_USERS_SUCCESS,
    payload: users,
})
export const getUsersFail = error => ({
    type: GET_USERS_FAIL,
    payload: error,
})

//Add user
export const addUsers = (data, dispatch, getAllUsers) => ({
    type: ADD_USERS,
    payload: { data, dispatch, getAllUsers },
})
export const addUsersSuccess = users => ({
    type: ADD_USERS_SUCCESS,
    payload: users,
})
export const addUsersFail = error => ({
    type: ADD_USERS_FAIL,
    payload: error,
})

//Activate and de-activate user
export const activateAndDeActivateUser = (data, dispatch, getAllUsers) => ({
    type: ACTIVATE_DEACTIVATE_USER,
    payload: { data, dispatch, getAllUsers },
})
export const activateAndDeActivateUserSuccess = users => ({
    type: ACTIVATE_DEACTIVATE_USER_SUCCESS,
    payload: users,
})
export const activateAndDeActivateUserFail = error => ({
    type: ACTIVATE_DEACTIVATE_USER_FAIL,
    payload: error,
})

//delete user
export const deleteUser = (id, dispatch, getAllUsers) => ({
    type: DELETE_USER,
    payload: { id, dispatch, getAllUsers },
})
export const deleteUserSuccess = users => ({
    type: DELETE_USER_SUCCESS,
    payload: users,
})
export const deleteUserFail = error => ({
    type: DELETE_USER_FAIL,
    payload: error,
})

//update settings data
export const updateSettingsData = (data) => ({
    type: UPDATE_SETTINGS_DATA,
    payload: data,
})
export const updateSettingsDataSuccess = users => ({
    type: UPDATE_SETTINGS_DATA_SUCCESS,
    payload: users,
})
export const updateSettingsDataFail = error => ({
    type: UPDATE_SETTINGS_DATA_FAIL,
    payload: error,
})
