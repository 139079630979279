import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./application_url_helper";

// Login Method
const applicationLogin = data => post(url.APPLICATION_LOGIN, data);
const forgetPwd = data => post(url.FORGET_PASSWORD, data);
export const forgetPwdResendCode = data => post(url.FORGET_PASSWORD_RESEND_CODE, data);
const resetPwd = data => post(url.RESET_PASSWORD_URL, data);
export const logoutActivity = data => post(url.LOGOUT_ACTIVITY, data);

//admin
export const getAllAdmins = () => get(url.GET_ALL_ADMIN);
export const postNewAdmin = data => post(url.POST_NEW_ADMIN, data);
export const getAdminById = (id) => {
  return get(`${url.GET_ADMIN_BY_ID}/${id}`, {
    params: { id },
  });
};
export const updateStatus = data => post(url.UPDATE_STATUS, data);
export const getAdminRoles = () => get(url.GET_ADMIN_ROLES);

//password
export {
  applicationLogin,
  forgetPwd,
  resetPwd,
}

//Generate password for user and admin
export const generatePwd = data => post(url.GENERATE_PASSWORD, data);

export const updateProfile = data => post(url.UPDATE_PROFILE, data);
export const changeLoginPassword = data => post(url.CHANGE_PASSWORD, data);

//update profile image
export const updateProfileImg = (header, data) => post(url.UPDATE_PROFILE_IMG, data, header);
export const getDecodeImage = (data, type) => post(`${type}${url.GET_DECODE_IMAGE}`, data)

//settings
export const getNotificationUrl = () => get(url.GET_NOTIFICATION);

//AI
export const getMyDocuments = (data, subUrl) => post(url.GET_MY_DOCUMENTS + '/' + subUrl, data);
export const getAssignUsers = () => get(url.GET_ASSIGN_USERS);
export const assignUser = (data) => put(url.ASSIGN_USER, data);
export const getDocTypes = () => get(url.GET_DOC_TYPES);
export const getDocStatus = () => get(url.GET_DOC_STATUS);
export const getAssignedUsers = () => get(url.GET_ASSIGNED_USERS);
export const getDocumentCountsByStatus = (id, docType, month) => get(url.GET_DOCUMENT_COUNT_BY_STATUS + '/' + id + '/' + docType + '/' + month);
export const getUsersCount = () => get(url.GET_USERS_COUNT);
export const getCountForDocumentForBarChart = (month, type) => get(`${url.GET_DOCUMENTS_COUNT_FOR_BAR_CHART}${month}/${type}`);
export const uploadDocument = (data, header) => post(`${url.UPLOAD_DOCUMENT}`, data, header);
export const getUsers = () => get(url.GET_USERS);
export const addUsers = (data) => post(url.ADD_USERS, data);
export const activateAndDeActivateUser = (data) => post(url.ACTIVATE_DEACTIVATE_USER, data);
export const deleteUser = (id) => del(`${url.DELETE_USER}/${id}`)
export const updateSettings = (data) => put(`${url.UPDATE_SETTINGS_DATA}`, data)
export const getCompanyById = (id) => get(`${url.GET_COMPANY_BY_ID}/${id}`)
export const getModules = (data) => post(url.GET_MODELS, data);
export const deleteModels = (id) => del(`${url.DELETE_MODELS}/${id}`);
export const deleteMulityModels = (data) => post(`${url.DELETE_MULITY_MODELS}`, data);
export const deleteDocument = (id) => del(`${url.DELETE_DOCUMENT}/${id}`)
export const getDocModels = (data) => post(url.GET_DOC_MODELS, data);
export const docModelsDelete = (id) => del(`${url.DOC_MODELS_DELETE}/${id}`);
export const createModal = (data) => post(`${url.CREATE_MODAL}`, data)
export const uploadModelDocument = (data, header) => post(`${url.UPLOAD_MODEL_DOCUMENT}`, data, header);
export const getModelDcumentByid = (id) => get(`${url.GET_MODEL_DCUMENT_BY_ID}/${id}`);
export const getDocumentById = (id) => get(`${url.GET_DCUMENT_BY_ID}/${id}`);
export const getModelById = (id) => post(`${url.GET_MODEL_BY_ID}/${id}`);
export const approveDocument = (subUrl, id, data) => put(`${url.APPROVE_DOCUMENT}/${subUrl}/${id}`, data);
export const getDownloadDocument = (data) => post(`${url.GET_DOWNLOAD_DOCUMENTS}`, data);
export const fieldwiseApproveDocument = (data) => put(`${url.FIELDWISE_APPROVE_DOCUMENT}`, data);
export const trainDocument = (data) => post(`${url.TRAIN_DOCUMENT}`, data);
export const publishModel = (id, status) => put(`${url.PUBLISH_MODEL}/${id}/${status}`);
export const getRunningModel = () => get(`${url.GET_RUNNING_MODEL}`);
export const trainModel = (data, header) => post(`${url.TRAIN_MODEL}`, data, header);
export const currentCreditPlan = (id) => get(`${url.CURRENT_CREDIT_PLANS}/${id}`);
export const getActivities = (id, subUrl, data) => post(`${url.GET_ACTIVITIES}/${id}/${subUrl}`, data);
export const reUpload = (data, subUrl) => post(`/${subUrl}${url.RE_UPLOAD}`, data);
export const preModelStartStopHelper = (data) => post(`${url.SETTINGS_PRE_MODEL_START_STOP}`, data);
export const getPreModelHelper = () => get(`${url.SETTINGS_GET_PRE_MODEL}`);
export const modelStartStopHelper = (data) => post(`${url.MODEL_START_STOP}/${data?.model_status === "STARTING" ? "start" : "stop"}`, data);
