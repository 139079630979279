import React, { useContext, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Select from "react-select";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import { FilterContext, SelectedFieldContext } from './InputFields';
import { useDispatch, useSelector } from 'react-redux';
import { createModal } from 'store/actions';

const ExtractionTab = ({ setInputFieldsTab, id }) => {
    const { filterData, setFilterData } = useContext(FilterContext);
    const { selectedField, setSelectedField } = useContext(SelectedFieldContext);

    const [sentenceTypeValue, setSentenceTypeValue] = useState(null)
    const [keyWordsPositionValue, setKeyWordsPositionValue] = useState(null)

    const getModelDetails = useSelector(state => state?.GetModelById?.data?.data)
    const modelDetails = useSelector(state => state?.CreateModal?.data?.data)
    const authUser = useSelector(state => state?.authInfo?.data)

    const dispatch = useDispatch()

    const formValues = {
        sentenceType: "",
        keyWordsNearBy: '',
        keyWordsFuzzyRatio: '',
        keyWordsPosition: '',
        min: "",
        max: "",
        customPostProcessing: ''
    }

    const { register, reset, setValue, clearErrors, getValues, trigger, formState: { errors, } } = useForm({
        defaultValues: formValues
    });


    const sentenceTypeOptions = [
        { value: 'LEFT', label: 'Left' },
        { value: 'RIGHT', label: 'Right' },
        { value: 'CENTER', label: 'Center' }
    ]

    const keyWordsPositionOptions = [
        { value: 'LEFT', label: 'Left' },
        { value: 'RIGHT', label: 'Right' },
        { value: 'CENTER', label: 'Center' }
    ]

    const onSubmit = (values) => {
        let fieldIndex, sectionIndex
        const section = filterData[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            let filter = { ...(filterData[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration), extractionFilter: values }
            let currentSection = JSON.parse(JSON.stringify(filterData))

            currentSection[selectedField?.field][sectionIndex]?.sectionFields?.map((item, index) => {
                if (item?.fieldId === selectedField?.fieldId) {
                    item['configuration'] = filter
                }
                return item
            })
            setFilterData(currentSection)
            if (id) {
                createModelFunction(currentSection, id)
            } else {
                createModelFunction(currentSection, modelDetails?._id)
            }
        }
    }

    const createModelFunction = (currentSection, modelId) => {
        const data = {
            company_id: authUser?.user?.company_id,
            modelId: modelId,
            fields: selectedField?.field == 'fields' ? currentSection[selectedField?.field] : filterData?.fields,
            table: selectedField?.field == 'table' ? currentSection[selectedField?.field] : filterData?.table,
        }
        dispatch(createModal(data, setInputFieldsTab, 'Validation'))
    }

    useEffect(() => {
        if (modelDetails && !id) {
            setFormValues(modelDetails)
        } else if (id && getModelDetails) {
            setFormValues(getModelDetails)
        }
    }, [modelDetails, selectedField, getModelDetails])

    const setFormValues = (modelDetails) => {
        let fieldIndex, sectionIndex
        const section = modelDetails[selectedField?.field]?.filter((item, index) => {
            if (item?.sectionId === selectedField?.sectionId) {
                sectionIndex = index
                return item
            }
        })

        if (section && section.length > 0) {
            fieldIndex = section[0]?.sectionFields?.findIndex(item => item?.fieldId == selectedField?.fieldId)

            const filter = modelDetails[selectedField?.field][sectionIndex]?.sectionFields[fieldIndex]?.configuration?.extractionFilter
            if (filter) {
                reset(filter)

                const sentenceType = sentenceTypeOptions?.filter(item => item?.value == filter?.sentenceType)
                if (sentenceType) setSentenceTypeValue(sentenceType)

                const keyWordsPosition = keyWordsPositionOptions?.filter(item => item?.value == filter?.keyWordsPosition)
                if (keyWordsPosition) setKeyWordsPositionValue(keyWordsPosition)
            }
            else {
                reset(formValues)
                setKeyWordsPositionValue(null)
                setSentenceTypeValue(null)
            }
        }
    }

    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault()
                const error = trigger()
                error.then(result => {
                    if (result) {
                        onSubmit(getValues())
                    }
                })
            }} >
                <div className="form-group mb-4">
                    <Label className="form-label">Sentence Type</Label><br />
                    <span className='label-dec'>Location of information on page</span>
                    <div className='drop-down'>
                        <Select
                            options={sentenceTypeOptions}
                            className={`form-control p-0`}
                            classNamePrefix="custom_select"
                            placeholder={'Choose Events'}
                            // menuIsOpen={true}
                            value={sentenceTypeValue}
                            onChange={(value) => {
                                setSentenceTypeValue(value)
                                setValue('sentenceType', value.value)
                                clearErrors('sentenceType')
                            }}
                            search={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Keywords Near By</Label><br />
                            <span className='label-dec'>Please add one keyword per line</span>
                            <input type="text" className='form-control' {...register('keyWordsNearBy')} onChange={() => {
                                clearErrors('keyWordsNearBy')
                            }} />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">Keywords Fuzzy Ratio</Label>
                            <input type="text" {...register('keyWordsFuzzyRatio')} onChange={() => {
                                clearErrors('keyWordsFuzzyRatio')
                            }} className='form-control' />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    <div className='col-lg-6'>
                        <div className="form-group mb-4">

                            <Label className="form-label">Keywords Position</Label><br />
                            <span className='label-dec'>Location of keywords compare to value</span>

                            <div className='drop-down'>
                                <Select
                                    options={keyWordsPositionOptions}
                                    className={`form-control p-0`}
                                    classNamePrefix="custom_select"
                                    placeholder={'Choose Events'}
                                    // menuIsOpen={true}
                                    value={keyWordsPositionValue}
                                    onChange={(value) => {
                                        setKeyWordsPositionValue(value)
                                        setValue('keyWordsPosition', value.value)
                                        clearErrors('keyWordsPosition')
                                    }}
                                    search={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="form-group mb-4">
                            <Label className="form-label">No Of Lines Of Values</Label><br />
                            <span className='label-dec'>If value are multiline please specify no of lines</span>
                            <div className='minmax-group'>
                                <input type="number" className='form-control' {...register('min')} onChange={() => {
                                    clearErrors('min')
                                }} placeholder='Min' />
                                <input type="number" className='form-control' {...register('max')} onChange={() => {
                                    clearErrors('max')
                                }} placeholder='Max' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="form-group">
                    <Label className="form-label">Custom Post Processing</Label><br />
                    <span className='label-dec'>Custom excel function to post process value. Learn more</span>
                    <textarea className='form-control' {...register('customPostProcessing')} onChange={() => {
                        clearErrors('customPostProcessing')
                    }}></textarea>
                </div>
                <div className="action mt-4">
                    <PrimaryButton btnType='submit' btnName="Ok and continue" showIcon={false} />
                </div>
            </form>
        </div>
    );
};

export default ExtractionTab;