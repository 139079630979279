import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_ASSIGN_USERS, GET_USERS, ADD_USERS, ACTIVATE_DEACTIVATE_USER, DELETE_USER, UPDATE_SETTINGS_DATA } from "./actionTypes";
import { getAssignUsersError, getAssignUsersSuccess, getUsersSuccess, getUsersFail, addUsersSuccess, addUsersFail, activateAndDeActivateUserSuccess, activateAndDeActivateUserFail, deleteUserSuccess, deleteUserFail, updateSettingsDataSuccess, updateSettingsDataFail } from "./actions";

//Include Both Helper File with needed methods
import { getAssignUsers, getUsers, addUsers, activateAndDeActivateUser, deleteUser, updateSettings } from "../../helpers/backend_helper";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { authInfoSuccess } from "store/authInfo/action";

// get assign users
function* GetAssignUsers() {
  try {
    const response = yield call(getAssignUsers)
    if (response.status.toUpperCase() === 'ERROR') {
      toast.error(response.message)
      yield put(getAssignUsersError(error))
    } else if (response.status.toUpperCase() === "SUCCESS") {
      yield put(getAssignUsersSuccess(response))
    }
  } catch (error) {
    yield put(getAssignUsersError(error))
  }
}

export function* GetAssignUsersSaga() {
  yield takeEvery(GET_ASSIGN_USERS, GetAssignUsers)
}

//Get All users
function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    if (response.status.toUpperCase() === "SUCCESS") yield put(getUsersSuccess(response))
    else {
      yield put(getUsersFail(response))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
export function* GetUsersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
}

//Add user
function* AddUsers({ payload: { data, dispatch, getAllUsers } }) {
  try {
    const response = yield call(addUsers, data)
    if (response.status.toLowerCase() === "success") {
      yield put(addUsersSuccess(response))
      // yield call(getUsers)
      dispatch(getAllUsers())
      toast.success(response.message)
    }
    else {
      yield put(addUsersFail(response))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(addUsersFail(error))
  }
}
export function* AddUsersSaga() {
  yield takeEvery(ADD_USERS, AddUsers)
}

//Activate and de-activate user
function* activateAndDeactivate({ payload: { data, dispatch, getAllUsers } }) {
  try {
    const response = yield call(activateAndDeActivateUser, data)
    if (response.status.toLowerCase() === "success") {
      yield put(activateAndDeActivateUserSuccess(response))
      // yield call(getUsers)
      dispatch(getAllUsers())
      toast.success(response.message)
    }
    else {
      yield put(activateAndDeActivateUserFail(response))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(activateAndDeActivateUserFail(error))
  }
}
export function* ActivateAndDeactivateUserSaga() {
  yield takeEvery(ACTIVATE_DEACTIVATE_USER, activateAndDeactivate)
}

//delete user
function* userDelete({ payload: { id, dispatch, getAllUsers } }) {
  try {
    const response = yield call(deleteUser, id)
    if (response.status.toLowerCase() === "success") {
      yield put(deleteUserSuccess(response))
      // yield call(getUsers)
      dispatch(getAllUsers())
      toast.success(response.message)
    }
    else {
      yield put(deleteUserFail(response))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
export function* DeleteUserSaga() {
  yield takeEvery(DELETE_USER, userDelete)
}

//update settings data
function* updateSettingsData({ payload: data }) {
  try {
    const response = yield call(updateSettings, data)
    if (response.status.toLowerCase() === "success") {
      yield put(updateSettingsDataSuccess(response))
      localStorage.setItem("jwt", response.token);
      localStorage.setItem("authUser", JSON.stringify(response));
      const decoded = jwt_decode(response?.token);
      yield put(authInfoSuccess(decoded))
      setToken()
      toast.success(response.message)
    }
    else {
      yield put(updateSettingsDataFail(response))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(updateSettingsDataFail(error))
  }
}
export function* UpdatesettingsDataSaga() {
  yield takeEvery(UPDATE_SETTINGS_DATA, updateSettingsData)
}
