import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

//Component
import TableContainer from 'components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from 'store/actions';
import TableSkeleton from 'components/Common/TableSkeleton';

const RecentActivity = ({ translate }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const authInfo = useSelector(state => state.authInfo?.data?.user);
    const activities = useSelector(state => state.GetActivities?.data?.data);
    const activitiesLoader = useSelector(state => state.GetActivities?.loading);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState(null);

    const dataPerPage = 5;

    useEffect(() => {
        if (authInfo?.company_id) {
            const data = {};
            data['currentPage'] = 1;
            data['dataPerPage'] = 5;
            if (sortBy) data['sort'] = sortBy
            else data['sort'] = {
                value: -1,
                field: 'createdAt',
                type: 'date'
            }
            dispatch(getActivities(authInfo?.company_id, "all", data));
        };
    }, [authInfo, currentPage, sortBy]);
    const activitysPage = () => {
        history.push('/activitys');
    }
    const columns = useMemo(
        () => [
            {
                Header: `${translate('sno')}`,
                Cell: cellProps => {
                    return (
                        <div>
                            <div>
                                <p className='m-0'>{cellProps?.row?.index + 1}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: `${translate('description')}`,
                Cell: cellProps => {
                    return (
                        <p className='m-0 text-lowercase'>{cellProps.row.original?.description}</p>
                    )
                },
            
            },
            {
                Header: `${translate('type')}`,
                Cell: cellProps => {
                    return (
                        <div className='document-type'>
                            <p className={`file-status ${cellProps.row.original?.type?.toLowerCase()}`}>{cellProps.row.original?.type?.toLowerCase()}</p>
                        </div>
                    )
                }

            },


            {
                Header: `${translate('user')}`,
                accessor: 'userDetails.fullName',
            },
            {
                Header: `${translate('dateAdded')}`,
                Cell: cellProps => {
                    return (
                        <div className='activity-added'>
                            {cellProps.row.original?.createdAt && <p>{moment(cellProps.row.original?.createdAt).format('LT')}<span> {moment(cellProps.row.original?.createdAt).format('LL')}</span></p>}

                        </div>
                    )
                }
            },


        ],
        [activities]
    );

    return (
        <div className='users recent-active'>
            <div className='card-header'>
                <h1 className='page-title'>{translate('recentActivity')}</h1>
                <span onClick={activitysPage}>{translate('viewAll')}</span>
            </div>

            <CardBody>
                <div className='table-recent'>
                    {activitiesLoader === false && activities ?
                        <TableContainer
                            columns={columns}
                            data={activities?.pageData}
                            isGlobalFilter={false}
                            // isAddOptions={true}
                            customPageSize={dataPerPage}
                            iSPageOptions={false}
                            customPagination={false}
                            className="activity-list"
                            totalCount={activities?.totalCount}
                            sortBy={sortBy}
                            setsortBy={setSortBy}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                        :
                        <TableSkeleton datacount={5} listcount={5} />
                    }
                </div>
            </CardBody>

        </div>
    );
};

export default RecentActivity;