import classnames from "classnames";
import InputFields from 'pages/Models/Components/InputFields';
import React, { useEffect, useState } from 'react';
import { Container, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import Settings from "./Components/Settings";
import Training from "./Components/Training";
import ApproveStages from "./Components/ApproveStages";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ModelDetails = () => {

    const { modelId } = useParams()

    const [iSmodelDetailsTab, setModelDetailsTab] = useState("Fields");
    const toggleCustom = tab => {
        if (iSmodelDetailsTab !== tab) {
            setModelDetailsTab(tab);
        }
    };

    return (
        <div>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h1 className='page-title'>Model Details</h1>
                        <div className="model-details">
                            <div className='nav-tabs-custom nav-justified nav-detail'>
                                <Nav className="">
                                    <NavItem>
                                        <div
                                            className={`tab-item ${classnames({ active: iSmodelDetailsTab === "Fields", })}`}
                                            onClick={() => {
                                                toggleCustom("Fields");
                                            }}
                                        >
                                            <span className="">Fields</span>
                                        </div>
                                    </NavItem>
                                    <NavItem>
                                        <div
                                            className={`tab-item ${classnames({
                                                active: iSmodelDetailsTab === "Training",
                                            })}`}
                                            onClick={() => {
                                                toggleCustom("Training");
                                            }}
                                        >

                                            <span className="">Training</span>
                                        </div>
                                    </NavItem>
                                    <NavItem>
                                        <div
                                            className={`tab-item ${classnames({
                                                active: iSmodelDetailsTab === "ApproveStages",
                                            })}`}
                                            onClick={() => {
                                                toggleCustom("ApproveStages");
                                            }}
                                        >

                                            <span className="">Approve Stages</span>
                                        </div>
                                    </NavItem>
                                    <NavItem>
                                        <div
                                            className={`tab-item ${classnames({
                                                active: iSmodelDetailsTab === "Settings",
                                            })}`}
                                            onClick={() => {
                                                toggleCustom("Settings");
                                            }}
                                        >

                                            <span className="">Settings</span>
                                        </div>
                                    </NavItem>

                                </Nav>
                            </div>
                            <TabContent activeTab={iSmodelDetailsTab}>
                                <TabPane tabId="Fields">
                                    <InputFields modelId={modelId} />
                                </TabPane>
                                <TabPane tabId="Training">
                                    <Training />

                                </TabPane>
                                <TabPane tabId="ApproveStages">
                                    <ApproveStages />
                                </TabPane>
                                <TabPane tabId="Settings">
                                    <Settings />
                                </TabPane>

                            </TabContent>
                        </div>
                    </Container>
                </div>
            </React.Fragment>

        </div>
    );
};

export default ModelDetails;