import React, { useEffect } from 'react';
import { CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import userActive from '../../../assets/images/icons/user-active.svg'
import userInActive from '../../../assets/images/icons/user-inactive.svg'
import Skeleton from 'react-loading-skeleton';
const Users = ({ data, loading, translate }) => {
    const history = useHistory();
    const usersPage = (tab) => {
        history.push(`/settings/${tab}`);

    }
    return (
        <div className='users'>
            <div className='card-header'>
                <h1 className='page-title'>{translate('users')}</h1>
                <span onClick={() => usersPage('users')}>{translate('viewAll')}</span>
            </div>
            <CardBody>
                <div className='active-user'>
                    <div className='user-info'>
                        <div className='user-icon'>
                            <img src={userActive} alt='userActive' />
                        </div>
                        <div className='users-status'>
                            <h4>{translate('activelabel')}</h4>
                            {loading ? <Skeleton className='mt-2' height={"30px"} width={'100px'} /> : <h2>{data?.activeUsers}</h2>}

                        </div>
                    </div>
                    <div className='user-info in-active'>
                        <div className='user-icon'>
                            <img src={userInActive} alt='userActive' />
                        </div>
                        <div className='users-status'>
                            <h4>{translate('inactivelabel')}</h4>
                            {loading ? <Skeleton className='mt-2' height={"30px"} width={'100px'} /> : <h2>{data?.inActiveUsers}</h2>}
                        </div>
                    </div>
                </div>
            </CardBody>
        </div>
    );
};

export default Users;