import React, { useEffect, useRef, useState } from 'react';
import { CardBody } from 'reactstrap';

//Components
import RadioButton from './RadioButton';
import PrimaryButton from './Buttons/PrimaryButton';
import SecountryBtn from './Buttons/SecountryBtn';
import useClickOutside from 'components/CommonForBoth/UseDetectOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignUsers } from 'store/actions';
import { toast } from 'react-hot-toast';

const AssignUser = ({ documentsData, setAssignUsers, translate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectFilter, setSelectFilter] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const dispatch = useDispatch()
    const assignUsers = useSelector(state => state?.GetAssignUsers?.data?.data)
    const showAssignUser = () => {
        setShowDropdown(!showDropdown);
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        dispatch(getAssignUsers())
    }, [])
    //Click Out Size
    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            const classList = e.target.classList;
            if (!menuRef?.current?.contains(e.target) && !classList.contains('check-box-input')) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [documentsData]);

    return (
        <>
            <div className='doc-filters' onClick={() => {
                showAssignUser()
                setSelectFilter(null)
                if (documentsData?.length === 0) {
                    toast.error(`${translate('assignError')}`)
                };
            }}><i className='bx bx-user-circle'></i></div>
            {showDropdown ?
                <div ref={menuRef}>
                    <div className={`assign-user ${isOpen ? 'open-filter' : 'close-filter'}`} >
                        <CardBody>
                            <h2>{`${translate('assign')}  ${translate('user')}`}</h2>
                            <div className='users-list'>
                                {(assignUsers && assignUsers?.length > 0) ? assignUsers?.map((item, index) => {
                                    return <div key={`user${index}`}>
                                        <RadioButton label={item?.fullName} id={item?._id} selectValue={setSelectFilter} />
                                    </div>
                                }) : <div className='text-center'>{translate('noactiveuser')}</div>}
                            </div>
                            <div className='filter-btn'>
                                <SecountryBtn btnName={translate('cancel')} showIcon={false} onClick={() => {
                                    setShowDropdown(false);
                                }} />
                                <PrimaryButton btnName={translate('assign')} disabled={(documentsData?.length === 0 || !selectFilter) ? true : false} showIcon={false} onClick={() => {
                                    setAssignUsers(selectFilter)
                                    setShowDropdown(false)
                                }} />
                            </div>
                        </CardBody>
                    </div>
                </div>
                : null}

        </>
    );
};

export default AssignUser;