import { ClientPagination } from "../constants/Pagination";

export const APPLICATION_LOGIN = "/user/login";
export const FORGET_PASSWORD = "/user/forgot-password-token";
export const FORGET_PASSWORD_RESEND_CODE = "/user/forgot-password-resend-token";
export const RESET_PASSWORD_URL = "/user/forgot-password-reset";
export const LOGOUT_ACTIVITY = "/activity";

// admin
export const GET_ALL_ADMIN = "/user/getAdmins/0/0";
export const POST_NEW_ADMIN = "/user/create-new";
export const GET_ADMIN_BY_ID = "/user";
export const UPDATE_STATUS = "/user/user-status";
export const GET_ADMIN_ROLES = "/user/getRoles";

//Generate password
export const GENERATE_PASSWORD = "/user/reset-password-user";

export const UPDATE_PROFILE = "/user/update-profile";
export const CHANGE_PASSWORD = "/user/update-password";

//update profile image
export const UPDATE_PROFILE_IMG = "/user/update-profile-image";
export const GET_DECODE_IMAGE = "/read";

//settings
export const GET_NOTIFICATION = "/settings/notification";

//AI
export const GET_MY_DOCUMENTS = "/my-documents";
export const GET_ASSIGN_USERS = "/user/active-users";
export const ASSIGN_USER = "/my-documents/assign-user";
export const GET_DOC_TYPES = "/document-types";
export const GET_DOC_STATUS = "/document-status";
export const GET_ASSIGNED_USERS = "/user/assign-users";
export const GET_DOCUMENT_COUNT_BY_STATUS = "/my-documents/status-counts";
export const GET_USERS_COUNT = "/user/count/get-active-inactive-users";
export const GET_DOCUMENTS_COUNT_FOR_BAR_CHART = "/my-documents/bar-chart/";
export const UPLOAD_DOCUMENT = "/my-documents/upload";
export const GET_USERS = "/user/get-all/0/0";
export const ADD_USERS = "/user/save-user";
export const ACTIVATE_DEACTIVATE_USER = "/user/user-status";
export const DELETE_USER = "/user";
export const UPDATE_SETTINGS_DATA = "/settings";
export const GET_COMPANY_BY_ID = "/company";


//Models
export const GET_MODELS = "/models/get-all";
export const DELETE_MODELS = "/models/single-delete";
export const DELETE_MULITY_MODELS = "/models/multiple-delete";
export const DELETE_DOCUMENT = "/my-documents";
export const GET_DOC_MODELS = "/models/get-documents";
export const DOC_MODELS_DELETE = "/models";
export const CREATE_MODAL = "/models";
export const UPLOAD_MODEL_DOCUMENT = "/models/upload";
export const GET_MODEL_DCUMENT_BY_ID = "/models";
export const GET_DCUMENT_BY_ID = "/my-documents";
export const GET_MODEL_BY_ID = "/models/get-model-by-id";
export const APPROVE_DOCUMENT = "/my-documents";
export const GET_DOWNLOAD_DOCUMENTS = "/my-documents/get-download-data";
export const FIELDWISE_APPROVE_DOCUMENT = "/my-documents/approve-field";
export const TRAIN_DOCUMENT = "/models/label";
export const PUBLISH_MODEL = "/models/progress-status";
export const GET_RUNNING_MODEL = "/models/get-running";
export const TRAIN_MODEL = "/models/train-model";
export const CURRENT_CREDIT_PLANS = "/credit-usage"
export const GET_ACTIVITIES = "/activity"
export const RE_UPLOAD = "/detect-data"
export const SETTINGS_PRE_MODEL_START_STOP = "/models/pre-model"
export const SETTINGS_GET_PRE_MODEL = "/models/pre-model"
export const MODEL_START_STOP = "/models"