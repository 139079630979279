import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { withRouter, Link } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import { withTranslation } from 'react-i18next';

//redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../store/actions";

// Assets
import AuthLoader from "components/Common/AuthLoader";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import AppLogo from '../../assets/images/app_logo.svg';
import AppLogoWhite from '../../assets/images/logo-white.svg';
import GoogleLogin from '../../assets/images/icons/google-icon.svg';
import Microsoft from '../../assets/images/icons/microsoft.svg';


const Login = (props) => {
  //meta title
  document.title = "Login | Yenmin AI ";
  const dispatch = useDispatch();
  const redirectUrl = (sessionStorage.getItem("redirectUrl"));

  const loginForm = {
    email: "",
    password: ""
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string().required(`${props.t('emailError')}`).test('email', `${props.t('validEmail')}`, val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }),
    password: Yup.string().required(`${props.t('passwordError')}`),
  })

  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: loginForm
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values, props.history, redirectUrl));
  }
  const [passwordShow, setPasswordShow] = useState(false);
  const { loader, } = useSelector(state => ({
    loader: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container fluid>
          <Row className="h-100">
            <Col lg={8} className="login-bg">
              <div className="logo">
                <img src={AppLogoWhite} alt="AppLogoWhite" />
              </div>
              <div className="welcome-content">
                <h1>Welcome to <br />Yenmin AI invoice capture</h1>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac semper orci, eu porttitor lacus. </p> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="welcome-form">
                <div className="text-center auth-logo">
                  <img src={AppLogo} alt="Logo" />
                </div>
                <div className="text-center auth-content">
                  <h4>{props.t('login')}</h4>
                  <p>{props.t('loginDes')}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                  className="form-horizontal" >

                  <div className="mb-3">
                    <Label className="form-label f-size-14">{props.t('emailAddress')} <span>*</span></Label>
                    <input type="text" {...register("email")} placeholder={props.t("emailplaceholder")} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                    {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                  </div>

                  <div className="mb-3">

                    <div className="show-password">
                      <Label className="form-label f-size-14">{props.t('password')} <span>*</span></Label>
                      <span>  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                        {passwordShow ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                      </button></span>
                    </div>
                    <div className="input-group auth-pass-inputgroup">
                      <input type={passwordShow ? "text" : "password"} {...register("password")} placeholder="*******" className={errors.password?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      {errors.password?.message ? <p className={`invalid-feedback`} >{errors.password?.message}</p> : null}
                    </div>
                  </div>

                  <div className="forgot-password d-flex justify-content-center">
                    <Link to="/forgot-password" className="f-size-14">
                      {props.t('forgotpsw')}
                    </Link>
                  </div>
                  {loader === true ? <div className="d-grid"><AuthLoader /></div> :
                    <div className="d-grid">
                      <PrimaryButton btnType="submit" btnName={props.t('login')} />
                    </div>
                  }

                </form>
                {/* <div className="or-option">
                  <div className="line"></div>
                  <p><span>OR</span></p>
                </div>
                <div className="social-login">

                  <button>
                    <img src={GoogleLogin} alt="GoogleLogin" /> <span>Google</span>
                  </button>
                  <button>
                    <img src={Microsoft} alt="Microsoft" /><span>Microsoft</span>
                  </button>

                </div> */}
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));