import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { STATUS } from "./actionType"
import { statusError, statusSuccess } from "./action"
//Include Both Helper File with needed methods
import { updateStatus } from "../../../helpers/backend_helper"
import "toastr/build/toastr.min.css"
import toast from "react-hot-toast";

function* statusUpdate({ payload: { status, history, id, url } }) {
  try {
    const data = {}
    data["userStatus"] = status
    data["userId"] = id
    const response = yield call(updateStatus, data)
    if (response.status === 'error') {
      toast.error(response.message)
    } else {
      toast.success(response.message)
      yield put(statusSuccess(response))
      history.push(`/${url}`);
    }
  } catch (error) {
    yield put(statusError(error))
    toast.error(error.response.data.message)
  }
}

function* StatusSaga() {
  yield takeEvery(STATUS, statusUpdate)
}

export default StatusSaga
