import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Card, CardBody, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from "yup";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import { useSelector } from 'react-redux';
import { createModal, createModalSuccess, getDocTypes, getModelById } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ModelBasicInput = ({ handleTabSelect, id, translate }) => {

    const getModelDetails = useSelector(state => state?.GetModelById?.data?.data)
    const model = useSelector(state => state.CreateModal.data?.data)
    const docTypes = useSelector(state => state.GetDocTypes.data?.data)
    const authUser = useSelector(state => state.authInfo.data)
    const [docTypesValue, setDocTypesValue] = useState(null)

    const dispatch = useDispatch()
    const history = useHistory()

    const basicInputValues = {
        modelName: '',
        documentType: '',
    };

    const basicSchema = Yup.object({
        modelName: Yup.string().required("Enter model name").max(40, "Should not exceed 40 characters"),
        documentType: Yup.string().required("Select document type")
    })

    const { register, handleSubmit, trigger, setValue, reset, getValues, clearErrors, formState: { errors, }, } = useForm({
        resolver: yupResolver(basicSchema),
        defaultValues: basicInputValues
    });

    const onSubmit = (values) => {
        const data = {}
        data['company_id'] = authUser?.user?.company_id
        data['modelName'] = values?.modelName
        data['docType'] = values?.documentType
        if (id) {
            data['modelId'] = id
            data['fields'] = getModelDetails?.fields
            data['table'] = getModelDetails?.table
        }
        dispatch(createModal(data, handleTabSelect, 1, history))
    }

    useEffect(() => {
        dispatch(getDocTypes())
    }, [])

    useEffect(() => {
        resetFormValues()
    }, [model, getModelDetails])

    useEffect(() => {
        if (id) dispatch(getModelById(id))
    }, [id])

    const resetFormValues = () => {
        const resetForm = (getModelDetails) => {
            reset({ modelName: getModelDetails?.name, documentType: getModelDetails?.doc_type })
            const docType = docTypes && docTypes?.filter((item, idx) => item?.value === getModelDetails?.doc_type)
            if (docType && docType.length > 0) setDocTypesValue(docType[0])
        }

        if (getModelDetails) {
            resetForm(getModelDetails)
        }
        else if (model) {
            resetForm(model)
        } else {
            reset()
            setDocTypesValue(null)
        }
    }

    return (
        <div className='models-layout'>
            <form
                className="form-horizontal custom-input"
                onSubmit={(e) => {
                    e.preventDefault()
                    const checkError = trigger()
                    checkError.then(result => {
                        if (result) {
                            onSubmit(getValues())
                        }
                    })
                }}
            >
                <Card>
                    <CardBody>
                        <div className="form-group mb-4">
                            <Label className="form-label">{translate('modelName')}</Label>
                            {id ? <input type="text" {...register("modelName")} readOnly onChange={() => { }} className={errors.modelName?.message ? 'form-control m-0 is-invalid' : '' + 'form-control m-0'} /> : <input type="text" {...register("modelName")} onChange={(e) => {
                                if (e.target.value.match(/^[a-zA-Z0-9_ ]+$/) || !e.target.value) {
                                    setValue(`modelName`, e.target.value?.toLowerCase()?.replaceAll(" ", "_"));
                                    clearErrors("modelName");
                                } else {
                                    setError(`modelName`, { type: 'custom', message: `Enter model name` });
                                };
                            }} className={errors.modelName?.message ? 'form-control m-0 is-invalid' : '' + 'form-control m-0'} />}
                            {errors.modelName?.message ? <p className={`error-field`} >{errors.modelName?.message}</p> : null}
                        </div>
                        <div className="form-group mb-4">
                            <Label className="form-label">{translate('documentType')}</Label>
                            {id ?
                                <input type="text" value={getValues('documentType')?.toLowerCase()} style={{ textTransform: 'capitalize' }} readOnly onChange={() => { }} className={errors.documentType?.message ? 'form-control m-0 is-invalid' : '' + 'form-control m-0'} /> :
                                <div className='drop-down'>
                                    <Select
                                        options={docTypes}
                                        className={`${errors.documentType ? 'is-invalid m-0' : ''} form-control p-0 m-0`}
                                        classNamePrefix="custom_select"
                                        placeholder={translate('selectdocType')}
                                        // menuIsOpen={true}
                                        search={true}
                                        value={docTypesValue}
                                        onChange={(value) => {
                                            setDocTypesValue(value)
                                            setValue('documentType', value?.value)
                                            clearErrors("documentType")
                                        }}
                                    />
                                </div>
                            }
                            {errors.documentType?.message ? <p className={`error-field`} >{errors.documentType?.message}</p> : null}
                        </div>
                    </CardBody>
                </Card>
                <div className='filter-btn download-btn d-flex'>
                    <SecountryBtn btnType='button' onClick={() => {
                        resetFormValues()
                    }} btnName={translate('clear')} showIcon={false} />
                    <PrimaryButton btnType='submit' btnName={translate('next')} showIcon={false} />
                </div>
            </form>
        </div>
    );
};

export default ModelBasicInput;