import React from "react"
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { ErrorReset } from "store/errorscreen/actions";

//Import Images
import error from "../assets/images/svg/error/400-bad-request.svg";

const BadRequest = () => {
  //meta title
  document.title = "400 - Bad Request | xxxx";
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="error-screen">
        <div className="account-pages m-0 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <h1 className="display-2 fw-medium">
                    4<i className="bx bx-buoy bx-spin text-primary display-3" />
                    0
                  </h1>
                  <h4 className="text-uppercase">Bad Request</h4>
                  <p className="font-size-16">Your request resulted in an error.</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div className="error-img">
                  <img src={error} alt="400" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center mt-4 action-btn">
                  <div className="text-center mx-2">
                    <Link
                      className="btn btn-primary waves-effect waves-light "
                      to="/dashboard"
                      onClick={()=>{ dispatch(ErrorReset());}}
                    >
                      Back to Dashboard
                    </Link>
                  </div>
                  <div className="text-center mx-2">
                    <Link
                      className="btn btn-primary waves-effect waves-light "
                      to="/logout"
                      onClick={()=>{ dispatch(ErrorReset());}}
                    >
                      Back to Login
                    </Link>
                  </div>

                </div>
              </Col>

            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BadRequest
