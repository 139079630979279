import { GET_CREDIT_PLAN, GET_CREDIT_PLAN_SUCCESS, GET_CREDIT_PLAN_FAIL} from "./actionTypes";
//Get Models
export const getCreditPlan = (id) => {
    return {
        type: GET_CREDIT_PLAN,
        payload: id
    }
}
export const getCreditPlanSuccess = (id) => {
    return {
        type: GET_CREDIT_PLAN_SUCCESS,
        payload: id
    }
}
export const getCreditPlanFail = (error) => {
    return {
        type: GET_CREDIT_PLAN_FAIL,
        payload: error
    }
}