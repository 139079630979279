import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { ADD_REVIEW_DATA, APPROVE_DOCUMENT, ASSIGN_USER, DELETE_DOCUMENT, FIELDWISE_APPROVE_DOCUMENT, GET_ASSIGNED_USERS, GET_DOCUMENT_BY_ID, GET_DOC_STATUS, GET_DOC_TYPES, GET_DOWNLOAD_DOCUMENTS, GET_MY_DOCUMENTS, RE_UPLOAD, RE_UPLOAD_SUCCESS, UPLOAD_DOCUMENT } from "./actionTypes";
import { approveDocumentError, approveDocumentSuccess, assignDocToUserError, assignDocToUserSuccess, deleteDocumentError, deleteDocumentSuccess, fieldwiseApproveDocumentError, fieldwiseApproveDocumentSuccess, getAssignedUsersError, getAssignedUsersSuccess, getDocStatusError, getDocStatusSuccess, getDocTypesError, getDocTypesSuccess, getDocumentByIdError, getDocumentByIdSuccess, getDownloadDocumentError, getDownloadDocumentSuccess, getNyDocumentsError, getNyDocumentsSuccess, reUplaodError, uploadDocumentError, uploadDocumentSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getMyDocuments, assignUser, getDocTypes, getDocStatus, getAssignedUsers, uploadDocument, deleteDocument, getDocumentById, approveDocument, getDownloadDocument, fieldwiseApproveDocument, reUpload } from "../../helpers/backend_helper";
import toast from "react-hot-toast";

//get my documents
function* GetDocuments({ payload: { data, subUrl } }) {
    try {
        const response = yield call(getMyDocuments, data, subUrl)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getNyDocumentsError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getNyDocumentsSuccess(response))
        }
    } catch (error) {
        yield put(getNyDocumentsError(error))
    }
}

export function* GetMyDocumentsSaga() {
    yield takeEvery(GET_MY_DOCUMENTS, GetDocuments)
}

//assign user
function* AssignUser({ payload: { data, dispatch, getAssignedUsers } }) {
    try {
        const response = yield call(assignUser, data)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(assignDocToUserError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            dispatch(getAssignedUsers());
            yield put(assignDocToUserSuccess(response))
            toast.success(response.message)
        }
    } catch (error) {
        yield put(assignDocToUserError(error))
    }
}

export function* AssignUserSaga() {
    yield takeEvery(ASSIGN_USER, AssignUser)
}

//get doc types
function* GetDocTypes() {
    try {
        const response = yield call(getDocTypes)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getDocTypesError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getDocTypesSuccess(response))
        }
    } catch (error) {
        yield put(getDocTypesError(error))
    }
}

export function* GetDocTypesSaga() {
    yield takeEvery(GET_DOC_TYPES, GetDocTypes)
}

//get doc types
function* GetDocStatus() {
    try {
        const response = yield call(getDocStatus)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getDocStatusError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getDocStatusSuccess(response))
        }
    } catch (error) {
        yield put(getDocStatusError(error))
    }
}

export function* GetDocStatusSaga() {
    yield takeEvery(GET_DOC_STATUS, GetDocStatus)
}

//get assigned users
function* GetAssignedUsers() {
    try {
        const response = yield call(getAssignedUsers)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getAssignedUsersError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getAssignedUsersSuccess(response))
        }
    } catch (error) {
        yield put(getAssignedUsersError(error))
    }
}

export function* GetAssignedUsersSaga() {
    yield takeEvery(GET_ASSIGNED_USERS, GetAssignedUsers)
}

//upload document
function* DocumentUpload({ payload: { data, history } }) {
    try {
        const response = yield call(uploadDocument, data, { headers: { "Content-Type": "multipart/form-data" } })
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(uploadDocumentError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(uploadDocumentSuccess(response))
            toast.success(response.message)
            history.push('/my-document');
        }
    } catch (error) {
        yield put(uploadDocumentError(error))
    }
}

export function* DocumentUploadSaga() {
    yield takeEvery(UPLOAD_DOCUMENT, DocumentUpload)
}


//delete document
function* DeleteDocument({ payload: { id, dispatch, getDocCountByStatus, company_id } }) {
    try {
        const response = yield call(deleteDocument, id)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(deleteDocumentError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(deleteDocumentSuccess(response))
            toast.success(response.message)
        }
    } catch (error) {
        yield put(deleteDocumentError(error))
    }
}

export function* DeleteDocumentSaga() {
    yield takeEvery(DELETE_DOCUMENT, DeleteDocument)
}

//get document by id
function* getdocumentbyid({ payload: id }) {
    try {
        const response = yield call(getDocumentById, id)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getDocumentByIdError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getDocumentByIdSuccess(response))
        }
    } catch (error) {
        yield put(getDocumentByIdError(error))
    }
}

export function* GetDocumentByIdSaga() {
    yield takeEvery(GET_DOCUMENT_BY_ID, getdocumentbyid)
}

//approve document
function* approvedocument({ payload: { subUrl, id, data, setConfirmModal, history } }) {
    try {
        const response = yield call(approveDocument, subUrl, id, data)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(approveDocumentError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(approveDocumentSuccess(response))
            setConfirmModal(false);
            history.push('/my-document');
            toast.success(response.message)
        }
    } catch (error) {
        yield put(approveDocumentError(error))
    }
}

export function* ApproveDocumentSaga() {
    yield takeEvery(APPROVE_DOCUMENT, approvedocument)
}

//get download document
function* GetDownloadDocument({ payload: { data, download, handleDownload } }) {
    try {
        const response = yield call(getDownloadDocument, data)
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message)
            yield put(getDownloadDocumentError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            yield put(getDownloadDocumentSuccess(response))
            if (download) {
                handleDownload(response?.data, 'csv')
            }
        }
    } catch (error) {
        yield put(getDownloadDocumentError(error))
    }
}

export function* GetDownloadDocumentSaga() {
    yield takeEvery(GET_DOWNLOAD_DOCUMENTS, GetDownloadDocument)
}

//get download document
function* FieldWiseApproveDocument({ payload: data }) {
    try {
        const response = yield call(fieldwiseApproveDocument, data);
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message);
            yield put(fieldwiseApproveDocumentError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            toast.success(response.message);
            yield put(fieldwiseApproveDocumentSuccess(response));
            yield put(getDocumentByIdSuccess(response))
        };
    } catch (error) {
        yield put(fieldwiseApproveDocumentError(error));
    };
};

export function* FieldwiseApproveDocumentSaga() {
    yield takeEvery(FIELDWISE_APPROVE_DOCUMENT, FieldWiseApproveDocument);
};

//object detection
function* reupload({ payload: { data, callGetDocuments, subUrl } }) {
    try {
        const response = yield call(reUpload, data, subUrl);
        if (response.status.toUpperCase() === 'ERROR') {
            toast.error(response.message);
            yield put(reUplaodError(error))
        } else if (response.status.toUpperCase() === "SUCCESS") {
            toast.success(response.message);
            callGetDocuments();
            yield put(RE_UPLOAD_SUCCESS(response));
        };
    } catch (error) {
        yield put(reUplaodError(error));
    };
};

export function* ReUplaodSaga() {
    yield takeEvery(RE_UPLOAD, reupload);
};