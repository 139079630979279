import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import UserForm from './template/UserForm';
import { activateAndDeActivateUser, deleteUser, getDocTypes, getUsers } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from 'components/Common/Modals/Confirmation';
import PageLoader from 'components/Common/PageLoader';
import UsersSkeleton from 'components/Common/UsersSkeleton';
import Loader from 'components/Common/Loader';


const Users = ({ newUser, setNewUser, setUserInfo, userInfo, translate }) => {
    const [open, setOpen] = useState("-1");
    const modelaccess = ["Invoice", "Aadhaar Back", "W9 Forms"];
    const [menu, setMenu] = useState(false);
    const [editTo, setEditTo] = useState('');
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [isActiveId, setActiveId] = useState();
    const [isActiveStatus, setActiveStatus] = useState();
    const dispatch = useDispatch()
    const usersData = useSelector(state => state?.GetUsers?.users?.data)
    const userLoader = useSelector(state => state?.GetUsers?.loading);
    const addUserLoader = useSelector(state => state?.AddUsers?.loading);
    const activateAndDeactivateUserLoader = useSelector(state => state?.ActivateAndDeactivateUser?.loading);

    const toggle = (id) => {
        if (open === id) {
            setOpen('-1');
            if (open === id) {
                setEditTo(null)
            }
        } else {
            setOpen(id);
        }
    };
    const userInfoHeader = [{
        col: "4",
        name: `${translate("user")}`
    }, {
        col: "2",
        name: `${translate("currentRole")}`
    }, {
        col: "2",
        name: `${translate("APIAccess")}`
    }, {
        col: "2",
        name: `${translate('status')}`
    },
    {
        col: "2",
        name: `${translate('actions')}`
    }]

    useEffect(() => {
        if (usersData) {
            setUserInfo(usersData)
        }
    }, [usersData])

    useEffect(() => {
        if (newUser == true) {
            const data = userInfo
            const newObj = {
                _id: '',
                company_id: '',
                fullName: "",
                email: "",
                role: "",
                accessModel: [],
                newUser: true,
            };
            data.unshift(newObj);
            setUserInfo(() => {
                const result = data
                return result
            })
            setNewUser(false)
        }
    }, [newUser])

    useEffect(() => {
        dispatch(getDocTypes())
        dispatch(getUsers())
    }, [])

    const userStatusChange = (id, status) => {
        let currentStatus
        if (isActiveStatus === true) {
            currentStatus = false
        } else {
            currentStatus = true
        }

        const data = {
            userId: isActiveId,
            userStatus: currentStatus
        }
        dispatch(activateAndDeActivateUser(data, dispatch, getUsers))
    }

    // const handleDeleteUser = (id) => {
    //     dispatch(deleteUser(id, dispatch, getUsers))
    // }
    const changeActiveStatus = () => {
        userStatusChange()
        setConfirmModal(false)
    }

    function activeToggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    //Change User Status

    return (
        <React.Fragment>
            {(userLoader || addUserLoader) ? <div className='userList'><Loader /> </div> :
                <div className='userList'>
                    {userInfo && userInfo?.length > 0 && userInfo?.map((userInfo, index) => {
                        return userInfo?.newUser && <div className='accordion-item'>
                            <div className='accordion-body'>
                                <UserForm userInfo={userInfo} index={index} newUser={userInfo.newUser} translate={translate} />
                            </div>
                        </div>
                    })}

                    <div className='mb-3 d-flex px-3'>
                        {userInfoHeader?.map((col, index) => {
                            return <Col md={col.col} key={index}>
                                <p>{col.name}</p>
                            </Col>
                        })}
                    </div>
                    {userInfo && userInfo?.length > 0 && userInfo?.map((userInfo, index) => {
                        return !userInfo?.newUser && <div key={userInfo._id}><Accordion open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId={index.toString()}>
                                    <Row className='w-100'>
                                        <Col md="4">
                                            <div className='userInfo'>
                                                <div className='avather'>{userInfo?.fullName?.charAt(0).toUpperCase()}{userInfo?.name?.charAt(userInfo?.name?.lastIndexOf(' ') + 1).toUpperCase()}</div>
                                                <div className='details'>
                                                    <p>{userInfo?.fullName}</p>
                                                    <span>{userInfo?.email}</span>
                                                </div>
                                            </div>
                                        </Col >
                                        <Col md="2">
                                            <p>{userInfo?.role?.toLowerCase()?.replace(/_/g, " ")}</p>
                                        </Col>
                                        <Col md="2">
                                            <p>{userInfo?.access}</p>
                                        </Col>
                                        <Col md="2">
                                            <div className={`status ${userInfo?.active ? 'active' : 'deactive'}`}>
                                                <span>{userInfo?.active ? `${translate('active')}` : `${translate('deactive')}`}</span>
                                            </div>
                                        </Col>
                                        <Col md="2" className='action'>
                                            <Dropdown isOpen={menu === index} toggle={(e) => {
                                                e.stopPropagation();
                                                if (menu !== index) setMenu(index);
                                                else setMenu(null);
                                            }} className="d-inline-block" >
                                                <DropdownToggle className="btn d-flex align-items-center p-0 border-0" id={`user-dropdown${index}`} tag="div" >
                                                    <span className='action-btn'>...</span>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem tag="div" disabled={userInfo?.active == false ? true : false}>
                                                        <div className='py-2' onClick={(e) => {
                                                            e.stopPropagation();
                                                            setMenu("");
                                                            setOpen(index.toString())
                                                            setEditTo(userInfo._id)

                                                        }}>{translate(`edit`)}</div>
                                                    </DropdownItem>
                                                    <div className="dropdown-divider m-0" />
                                                    <DropdownItem tag="div">
                                                        <div className='py-2' onClick={(e) => {
                                                            e.stopPropagation();
                                                            setMenu("")
                                                            setActiveId(userInfo?._id, true)
                                                            setActiveStatus(userInfo?.active ? true : false)
                                                            activeToggleModal()
                                                        }}> {translate(`${userInfo?.active === true ? 'deactive' : 'active'}`)}</div>
                                                    </DropdownItem>
                                                    {/* <div className="d
                                                    ropdown-divider m-0" /> */}
                                                    {/* <DropdownItem tag="div" disabled={userInfo?.active == true ? false : true}>
                                                        <div className='py-2' onClick={(e) => {
                                                            e.stopPropagation();
                                                            setMenu("")
                                                            setActiveId(userInfo?._id)
                                                            setActiveStatus(false)
                                                            activeToggleModal()
                                                        }}>  {translate(`deactive`)}</div>
                                                    </DropdownItem> */}
                                                    <div className="dropdown-divider m-0" />
                                                    {/* <DropdownItem tag="div">
                                                    <div className='py-2' onClick={(e) => {
                                                        e.stopPropagation();
                                                        setMenu("")
                                                        handleDeleteUser(userInfo?._id)
                                                    }}>   {`Delete`}</div>
                                                </DropdownItem> */}
                                                </DropdownMenu >
                                            </Dropdown >
                                        </Col >
                                    </Row >
                                </AccordionHeader >
                                <AccordionBody accordionId={index.toString()}>
                                    <UserForm userData={userInfo} index={index} newUser={false} editTo={editTo} setEditTo={setEditTo} open={open} translate={translate} />
                                </AccordionBody>
                            </AccordionItem >
                        </Accordion ></div >
                    })}

                    <Confirmation
                        showModal={isConfirmModal}
                        toggle={activeToggleModal}
                        primaryAction={changeActiveStatus}
                        modalTitle={`${isActiveStatus == false ? 'Activate' : 'Deactivate'} User`}
                        primaryBtn={`${isActiveStatus == false ? 'Activate' : 'Deactivate'}`}
                        secountBtn="Cancel"
                        description={`Are you sure, do you want to  ${isActiveStatus == false ? 'activate' : 'deactivate'} this User?`}
                        loader={activateAndDeactivateUserLoader}
                    />
                </div >
            }
        </React.Fragment >
    );
};

export default Users;