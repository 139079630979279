import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    FORGET_PASSWORD_RESEND_CODE,
    FORGET_PASSWORD_RESEND_CODE_SUCCESS,
    FORGET_PASSWORD_RESEND_CODE_ERROR,
    LOGOUT_ACTIVITY,
    LOGOUT_ACTIVITY_SUCCESS,
    LOGOUT_ACTIVITY_ERROR,
} from "./actionTypes"

//Login
export const loginUser = (user, history, redirectUrl) => {
    return {
        type: LOGIN_USER,
        payload: { user, history, redirectUrl },
    }
}

export const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    }
}

//LogOut
export const logoutUser = history => {
    return {
        type: LOGOUT_USER,
        payload: { history },
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    }
}

export const apiError = error => {
    return {
        type: API_ERROR,
        payload: error,
    }
}

//Reset Password
export const userResetPassword = (users, history) => {
    return {
        type: RESET_PASSWORD,
        payload: { users, history },
    }
}

export const userResetPasswordSuccess = message => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: message,
    }
}

export const userResetPasswordError = message => {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: message,
    }
}

//Forget password
export const userForgetPassword = (user, history) => {
    return {
        type: FORGET_PASSWORD,
        payload: { user, history },
    }
}

export const userForgetPasswordSuccess = (message) => {
    return {
        type: FORGET_PASSWORD_SUCCESS,
        payload: message,
    }
}

export const userForgetPasswordError = message => {
    return {
        type: FORGET_PASSWORD_ERROR,
        payload: message,
    }
}

//Forget password resend code
export const userForgetPasswordResendCode = (user, history) => {
    return {
        type: FORGET_PASSWORD_RESEND_CODE,
        payload: { user, history },
    }
}

export const userForgetPasswordResendCodeSuccess = message => {
    return {
        type: FORGET_PASSWORD_RESEND_CODE_SUCCESS,
        payload: message,
    }
}

export const userForgetPasswordResendCodeError = message => {
    return {
        type: FORGET_PASSWORD_RESEND_CODE_ERROR,
        payload: message,
    }
}

//logout activity
export const logoutActivity = (data) => {
    return {
        type: LOGOUT_ACTIVITY,
        payload: data,
    }
}
export const logoutActivitySuccess = message => {
    return {
        type: LOGOUT_ACTIVITY_SUCCESS,
        payload: message,
    }
}
export const logoutActivityError = message => {
    return {
        type: LOGOUT_ACTIVITY_ERROR,
        payload: message,
    }
}