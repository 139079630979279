import {
  GET_ASSIGN_USERS,
  GET_ASSIGN_USERS_SUCCESS,
  GET_ASSIGN_USERS_FAIL,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  ACTIVATE_DEACTIVATE_USER,
  ACTIVATE_DEACTIVATE_USER_SUCCESS,
  ACTIVATE_DEACTIVATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_SETTINGS_DATA,
  UPDATE_SETTINGS_DATA_SUCCESS,
  UPDATE_SETTINGS_DATA_FAIL,
} from "./actionTypes"

//get assign users
const initialState = {
  error: "",
  data: "",
  loading: false,
}

export const GetAssignUsers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGN_USERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ASSIGN_USERS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
      }
      break
    case GET_ASSIGN_USERS_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
  }
  return state
}


//Get All users
const INITIAL_STATE = {
  users: '',
  error: '',
  loading: false
}
export const GetUsers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

//Add user
const ADD_INITIAL_STATE = {
  users: '',
  error: '',
  loading: false
}
export const AddUsers = (state = ADD_INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_USERS:
      return {
        users: '',
        error: '',
        loading: true
      }
    case ADD_USERS_SUCCESS:
      return {
        error: '',
        users: action.payload,
        loading: false
      }

    case ADD_USERS_FAIL:
      return {
        users: '',
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

//Activate and de-activate user
const ACTIVATE_DEACTIVATE_INITIAL_STATE = {
  users: '',
  error: '',
  loading: false
}
export const ActivateAndDeactivateUser = (state = ACTIVATE_DEACTIVATE_INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIVATE_DEACTIVATE_USER:
      return {
        users: '',
        error: '',
        loading: true
      }
    case ACTIVATE_DEACTIVATE_USER_SUCCESS:
      return {
        error: '',
        users: action.payload,
        loading: false
      }

    case ACTIVATE_DEACTIVATE_USER_FAIL:
      return {
        users: '',
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

//delete user
const DELETE_USER_INITIAL_STATE = {
  users: '',
  error: '',
  loading: false
}
export const DeleteUser = (state = DELETE_USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_USER:
      return {
        users: '',
        error: '',
        loading: true
      }
    case DELETE_USER_SUCCESS:
      return {
        error: '',
        users: action.payload,
        loading: false
      }

    case DELETE_USER_FAIL:
      return {
        users: '',
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

//update settings data
const UPDATE_SETTINGS_INITIAL_STATE = {
  users: '',
  error: '',
  loading: false
}
export const UpdateSettingsData = (state = UPDATE_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS_DATA:
      return {
        users: '',
        error: '',
        loading: true
      }
    case UPDATE_SETTINGS_DATA_SUCCESS:
      return {
        error: '',
        users: action.payload,
        loading: false
      }

    case UPDATE_SETTINGS_DATA_FAIL:
      return {
        users: '',
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}
