import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Container, UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Components
import TableContainer from 'components/Common/TableContainer';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import TableSkeleton from 'components/Common/TableSkeleton';
import Confirmation from 'components/Common/Modals/Confirmation';
import PageLoader from 'components/Common/PageLoader';

//Assets
import deleteIcon from '../../assets/images/icons/delete.svg';
import downloadRow from '../../assets/images/icons/dwinload-list.svg';
import editIcon from '../../assets/images/icons/editcolor.svg';

//Store
import { useDispatch, useSelector } from 'react-redux';
import { createModalSuccess, deleteModels, getModelByIdSuccess, getModelsList, getModelsListSuccess, modelStartStop as modelStartStopAction, mulityModelsDelete, publishModel } from 'store/actions';
import { toast } from 'react-hot-toast';
import Loader from 'components/Common/Loader';
import { useSocket } from 'App';
import axios from 'axios';
import { post } from 'helpers/api_helper';
import { GET_MODELS } from 'helpers/application_url_helper';

const ModelsListing = (props) => {
    const { t } = props;

    const socket = useSocket();

    document.title = `${t("models")} | ${t('appName')}`;
    document.title = "Models | Yenmin AI";

    const history = useHistory();
    const dispatch = useDispatch()
    const [isConfirmModal, setConfirmModal] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [isHidden, setIsHidden] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [deleteMethoed, setDeleteMethod] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [isHovered, setIsHovered] = useState('');
    const [showPublishModel, setShowPublishModel] = useState(false);
    const [publish, setPublish] = useState(false);
    const [startStopModel, setStartStopModel] = useState(false);
    const [startStopModelData, setStartStopModelData] = useState();
    const [canStartStopModel, setCanStartStopModel] = useState(false);
    const [modelStartStop, setModelStartStop] = useState();

    const modelData = useSelector(state => state.GetModelsList?.data?.data);
    const modelLoading = useSelector(state => state.GetModelsList?.loading);
    const modelDeleteLoading = useSelector(state => state.DeleteModels?.loading);
    const publishModelLoading = useSelector(state => state.PublishModel?.loading);
    const deleteMulityModelsLoading = useSelector(state => state.DeleteMulityModels?.loading);
    const modelStartStopLoading = useSelector(state => state.ModelStartStop?.loading);

    const dataPerPage = process.env.REACT_APP_TABLE_DATA_PER_PAGE

    const handleClick = (id, value) => {
        const isSelect = modelList.map((list, index) => {
            if (index === id) {
                return {
                    ...list,
                    isSelect: value,
                }
            } else {
                return {
                    ...list,
                }
            }
        })
        setModelList(isSelect);

    };
    //Page Routing
    const createPage = () => {
        history.push('/models/create-model');
        dispatch(createModalSuccess(''))
        dispatch(getModelByIdSuccess(''))
    }
    const modelDetails = (data) => {
        if (data?.trained === "NOT_STARTED" || data?.trained === "PENDING") {
            if (data?.progressStatus === 'DRAFT') history.push('/models/edit-model/' + data?._id + '/0');
            else history.push('/models/model-details/' + data?._id);
        }
    }

    useEffect(() => {
        if (modelData) {
            const newModel = modelData && modelData?.pageData?.map((list) => {
                return {
                    ...list,
                    isSelect: false,
                }
            })
            setModelList(newModel)
        }
    }, [modelData])

    const modelpage = () => {
        const data = {}
        data['currentPage'] = currentPage;
        data['dataPerPage'] = dataPerPage;
        return data
    }

    const getModelListFunction = () => {
        dispatch(getModelsList(modelpage()));
    }

    useEffect(() => {
        getModelListFunction();
    }, [currentPage])

    const handleDeleteModels = (id) => {
        setDeleteMethod(false);
        setModelId(id);
        toggleModal()

    }
    const deleteModelList = () => {
        dispatch(deleteModels(modelId, dispatch, getModelsList, modelpage))
        setConfirmModal(false)
    }

    //Close Conrirm Modal
    function toggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    //Delete Mulitple
    const mulityDelete = () => {
        const deleteList = modelList.filter((obj) => {
            return obj.isSelect == true;
        });
        dispatch(mulityModelsDelete(deleteList, dispatch, getModelsList, modelpage))
        setDeleteMethod(false);
        setConfirmModal(false)
    }

    let obj = modelList.find(o => o.isSelect === true)

    let deleteDisabled = obj ? false : true

    const handlePublish = () => {
        if (publish) {
            dispatch(publishModel(modelId, 'published', togglePublishModal, dispatch, getModelsList, modelpage));
        } else {
            togglePublishModal();
        };
    };

    function toggleModalStartStop() {
        setStartStopModel(!startStopModel);
    }

    function togglePublishModal() {
        setShowPublishModel(!showPublishModel);
    }

    const afterStartStop = () => {
        // dispatch(getModelsList(modelpage()));
        toggleModalStartStop();
    }

    const handleStartStopModel = () => {
        if (canStartStopModel) {
            dispatch(modelStartStopAction(startStopModelData, afterStartStop));
        } else {
            toggleModalStartStop();
        }
    }

    // dispatch(modelStartStop(data));
    const handleModelStartStop = (status, id, record) => {
        if (record?.progressStatus === 'PUBLISHED') {
            const modelStatus = (status === 'STARTING' ? "start" : "stop");
            setModelStartStop(modelStatus);
            setCanStartStopModel(true);
            const data = {};
            data['model_status'] = status;
            data['model_id'] = id;
            setStartStopModelData(data);
            toggleModalStartStop();
        } else {
            setStartStopModelData();
            setCanStartStopModel(false);
            setModelStartStop();
            toggleModalStartStop();
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: `${t('sno')}`,
                Cell: cellProps => {
                    return (
                        <div>
                            <div>
                                {(cellProps?.row?.original.isSelect || isHovered === cellProps?.row?.original?._id) ? <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={""}
                                        checked={cellProps?.row?.original.isSelect}
                                        id={cellProps.row.original?._id}
                                        onClick={(e) => {
                                            if (e.target.checked === false) {
                                                handleClick(cellProps?.row?.index, true);
                                            } else {
                                                handleClick(cellProps?.row?.index, false);
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                </div> : <p className='m-0 ps-1'>{((currentPage - 1) * dataPerPage) + (cellProps?.row?.index + 1)}</p>}
                            </div>
                        </div>
                    )
                }
            },

            {
                Header: `${t('name')}`,
                Cell: cellProps => {
                    return (
                        <div>
                            <p className='m-0' onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>{cellProps.row.original?.name}</p>
                        </div>
                    )
                }
            },
            {
                Header: `${t('documentType')}`,
                accessor: 'doc_type',
                Cell: cellProps => {
                    return (
                        <div>
                            <p className='m-0 text-capitalize' onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>{cellProps.row.original?.doc_type.toLowerCase()}</p>
                        </div>
                    )
                }
            },
            {
                Header: `${t('createdDate')}`,
                Cell: cellProps => {
                    return (
                        <div onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>
                            {moment(cellProps.row.original?.createdAt).format('DD/MM/YYYY')}
                        </div >
                    )
                }
            },
            {
                Header: `${t('modifiedDate')}`,
                Cell: cellProps => {
                    return (
                        <div onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>
                            {moment(cellProps.row.original?.updatedAt).format('DD/MM/YYYY')}
                        </ div>
                    )
                }
            },
            {
                Header: `${t('traingStatus')}`,
                Cell: cellProps => {
                    return (
                        <div className={`model-status ${cellProps.row.original?.trained?.toLowerCase()}`} onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>
                            {cellProps.row.original?.trained?.toLowerCase()?.replaceAll('_', ' ')}
                        </div>
                    )
                }
            },
            // {
            //     Header: `${t('modelStatus')}`,
            //     Cell: cellProps => {
            //         return (
            //             <div className={`model-status ${cellProps.row.original?.progressStatus?.toLowerCase()}`} onClick={() => modelDetails(cellProps.row.original)} style={{ cursor: cellProps.row.original?.trained === 'NOT_STARTED' || cellProps.row.original?.trained === 'PENDING' ? 'pointer' : 'default' }}>
            //                 {cellProps.row.original?.progressStatus?.toLowerCase()}
            //             </div>
            //         )
            //     }
            // },
            {
                id: 'selection',
                Header: ({ cellProps }) => {
                    return (
                        <div className='text-center'>{t('publish')}</div>
                    )
                },
                Cell: cellProps => {
                    return (
                        <div className='text-center'>
                            {
                                cellProps.row.original?.progressStatus === 'DRAFT' ?
                                    <button className='document-status btn-common secountry-btn' onClick={() => {
                                        if (cellProps.row.original?.trained === "TRAINED") {
                                            setModelId(cellProps.row.original?._id);
                                            setPublish(true);
                                            setShowPublishModel(true);
                                        } else {
                                            setPublish(false);
                                            setShowPublishModel(true);
                                        };
                                    }} >Publish </button>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1D8E51" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                            }
                        </div>
                    )
                }
            },
            {
                id: 'model_start_and_end',
                Header: ({ cellProps }) => {
                    return (
                        <div className='text-center'>{t('model_start_stop')}</div>
                    )
                },
                Cell: cellProps => {
                    return (
                        <div className='text-center'>
                            {cellProps.row.original?.model_status === 'STOPPED' &&
                                <button className="btn-common btn-primary btn-block primary-button document-status"
                                    onClick={() => handleModelStartStop('STARTING', cellProps.row.original?._id, cellProps.row.original)}>Start
                                </button>}
                            {cellProps.row.original?.model_status === 'RUNNING' && <button className="btn-common btn-primary btn-block primary-button document-status"
                                onClick={() => handleModelStartStop('STOPPING', cellProps.row.original?._id, cellProps.row.original)}>Stop
                            </button>}
                            {cellProps.row.original?.model_status === 'STARTING' && <label className="mb-0 small btn-common btn-primary btn-block primary-button document-status">Starting
                                <span className='dot ms-2'>
                                </span>
                            </label>}
                            {cellProps.row.original?.model_status === 'STOPPING' && <label className="mb-0 small btn-common btn-primary btn-block primary-button document-status">Stopping
                                <span className='dot ms-2'>
                                </span>
                            </label>}
                            {/* {
                                cellProps.row.original?.model_status === 'STOPPED' ?
                                    <button className="btn-common btn-primary btn-block primary-button document-status"
                                        onClick={() => handleModelStartStop('STARTING', cellProps.row.original?._id, cellProps.row.original)}>Start
                                    </button> : cellProps.row.original?.model_status === 'RUNNING' ? <button className="btn-common btn-primary btn-block primary-button document-status"
                                        onClick={() => handleModelStartStop('STOPPING', cellProps.row.original?._id, cellProps.row.original)}>Stop
                                    </button> : <label className="mb-0 small btn-common btn-primary btn-block primary-button document-status">Starting
                                        <span className='dot ms-2'>
                                        </span>
                                    </label>
                            } */}
                        </div>
                    )
                }
            },
            {
                Header: '     ',
                Cell: cellProps => {
                    return (
                        <div className='qwer'>
                            <div className="more-action">
                                <i className='bx bx-dots-horizontal-rounded font-size-18 d-flex justify-content-center'></i>
                            </div>
                            <div className="file-action-btns">
                                <ul className='d-flex p-0'>
                                    {/* <li><img src={editIcon} alt='editIcon' /></li> */}
                                    <li id={`delete_${cellProps.row.original?._id}`} onClick={() => { handleDeleteModels(cellProps.row.original?._id) }}>
                                        <img src={deleteIcon} alt='delete-icon' />
                                        <UncontrolledTooltip placement="top" target={`delete_${cellProps.row.original?._id}`}>
                                            Delete
                                        </UncontrolledTooltip>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                }
            },
        ],
        [modelList, isHovered]
    );

    useEffect(() => {
        // Check if socket is initialized before using it
        if (socket) {
            // Listen for real-time user list updates
            socket.on('modelUpdated', async () => {
                try {
                    const response = await post(`${GET_MODELS}`, modelpage());
                    dispatch(getModelsListSuccess(response))
                } catch (error) {
                    console.log("error: ", error);
                }
            });

            // Clean up the socket event listener when component unmounts
            return () => socket.off('modelUpdated');
        }
    }, [socket]);

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <div className='model-header'>
                            <h1 className='page-title p-0'>{t("models")}</h1>
                            <PrimaryButton btnName={t('createModel')} showIcon={false} onClick={createPage} />
                        </div>
                        <div className='card'>
                            {/* <PageLoader/> */}

                            <div className='card-body'>
                                {modelLoading === false ? "" : <Loader />}
                                <TableContainer
                                    columns={columns}
                                    data={modelList || []}
                                    isGlobalFilter={false}
                                    // isAddOptions={true}
                                    customPageSize={dataPerPage}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    iSPageOptions={true}
                                    customPagination={true}
                                    totalCount={modelData?.totalCount}
                                    className="custom-header-css my-doc"
                                    setIsHovered={setIsHovered}
                                />
                            </div>
                        </div>
                        <div className='table-btns'>
                            <PrimaryButton btnName={t('delete')} disabled={deleteDisabled} showIcon={true} btnIcon={deleteIcon} onClick={() => {
                                setDeleteMethod(true);
                                toggleModal()
                            }} />
                        </div>
                        <Confirmation
                            showModal={isConfirmModal}
                            toggle={toggleModal}
                            primaryAction={deleteMethoed ? mulityDelete : deleteModelList}
                            modalTitle={`${t('delete')} ${t('model')}`}
                            primaryBtn={`${t('delete')} ${t('model')}`}
                            secountBtn={t("cancel")}
                            description={t('deleteDec')}
                            loader={deleteMulityModelsLoading || modelDeleteLoading}
                        />

                        {/* publish model */}
                        <Confirmation
                            showModal={showPublishModel}
                            toggle={togglePublishModal}
                            primaryAction={handlePublish}
                            modalTitle={`${t('Publish')} ${t('model')}`}
                            primaryBtn={publish ? "Publish" : "Ok"}
                            secountBtn={t("cancel")}
                            hideCancel={publish ? false : true}
                            description={publish ? 'Are you sure, do you want to publish this model?.' : 'Model should be trained before publish the model.'}
                            loader={publishModelLoading}
                        />
                        {/* model start stop */}
                        <Confirmation
                            showModal={startStopModel}
                            toggle={toggleModalStartStop}
                            primaryAction={handleStartStopModel}
                            modalTitle={`${canStartStopModel ? (modelStartStop === 'start' ? 'Start' : 'Stop') : 'Start/Stop'} model`}
                            primaryBtn={canStartStopModel ? (modelStartStop === 'start' ? 'Start' : 'Stop') : "Ok"}
                            secountBtn={t("cancel")}
                            hideCancel={canStartStopModel ? false : true}
                            description={canStartStopModel ? `Are you sure, do you want to ${modelStartStop} this model?.` : `Model should be published before ${canStartStopModel ? modelStartStop : 'start/stop'} the model.`}
                            loader={modelStartStopLoading}
                        />
                    </Container>
                </div>
            </React.Fragment>

        </>
    );
};

export default withRouter(withTranslation()(ModelsListing));
