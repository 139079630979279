import { GET_ADMIN_BY_ID_SUCCESS, GET_ADMIN_BY_ID_FAIL } from "./actionTypes"

const initialState = {
  error: "",
  success: "",
}

const GetAdminById = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case GET_ADMIN_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
  }
  return state
}

export default GetAdminById
