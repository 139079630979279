import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';

//Components
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import TableContainer from 'components/Common/TableContainer';
import TrainingDetails from './TrainingDetails';
import TrainingEdit from './TrainingEdit';
import SearchBar from 'components/Common/SearchBar';
import DatePickerDropDown from 'components/Common/DatePickerDropDown';

const Training = () => {
    const [isShowDetails, setShowDetails] = useState(true);
    const [isTrainConfig, setTrainConfig] = useState(false);
    const [date, setDate] = useState(null);

    const openTrainDetail = () => {
        setShowDetails(false);
    }

    const trainConfig = () => {
        setTrainConfig(true)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'S.No',
                Cell: cellProps => {
                    return (
                        <p className='m-0'>{cellProps?.row?.index + 1}</p>
                    )
                }
            },


            {
                Header: 'Name',
                Cell: cellProps => {
                    return (
                        <p className='m-0' onClick={openTrainDetail}>{cellProps?.row?.original?.name}</p>
                    )
                }
            },
            {
                Header: 'Document Type',
                accessor: 'documentType'
            },

            {
                Header: 'Trained by',
                accessor: 'traineddBy'
            },
            {
                Header: 'No of Documents',
                accessor: 'noDocument'
            },
            {
                Header: 'Status',
                Cell: cellProps => {
                    return (
                        <div className={`file-status ${cellProps?.row?.original?.status}`}>
                            <p>{cellProps.row.original?.status}</p>
                        </div>
                    )
                }

            },
            {
                Header: 'Date Added',
                Cell: cellProps => {
                    return (
                        <div>
                            {moment(cellProps.row.original?.uploadedOn).format('lll')}
                        </div>
                    )
                }
            },

        ],
        []
    );

    const data = [
        {
            "name": "Shipping Label_V1",
            "documentType": "Shipping label",
            "traineddBy": "Jones",
            "noDocument": "50",
            "status": "Trained",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "name": "Shipping Label_V2",
            "documentType": "Shipping label",
            "traineddBy": "Jones",
            "noDocument": "50",
            "status": "Trained",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "name": "Shipping Label_V3",
            "documentType": "Shipping label",
            "traineddBy": "Jones",
            "noDocument": "50",
            "status": "Trained",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "name": "Shipping Label_V4",
            "documentType": "Shipping label",
            "traineddBy": "Jones",
            "noDocument": "50",
            "status": "Trained",
            "uploadedOn": "2023-05-18T13:53:45Z"
        },
        {
            "name": "Shipping Label_V5",
            "documentType": "Shipping label",
            "traineddBy": "Jones",
            "noDocument": "50",
            "status": "Trained",
            "uploadedOn": "2023-05-18T13:53:45Z"
        }

    ];
    return (
        <>
            {!isTrainConfig ? <>
                {isShowDetails ?
                    <div>
                        <Card>
                            <CardBody className='b-radius-x-0'>

                                <div>

                                    <div className='table-filters'>
                                        <div className=''>
                                            <h5 className='m-0 table-heading'>Training</h5>
                                        </div>
                                        <div className='filters-group'>
                                            <SearchBar />
                                            < DatePickerDropDown setDate={setDate} />


                                        </div>
                                    </div>
                                    <TableContainer
                                        columns={columns}
                                        data={data}
                                        isGlobalFilter={false}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        iSPageOptions={false}
                                        customPagination={true}
                                        className="custom-header-css"
                                    />
                                </div>

                            </CardBody>
                        </Card>

                        <div className='filter-btn download-btn d-flex'>
                            <PrimaryButton btnName="Train Model" showIcon={false} onClick={trainConfig} />

                        </div>

                    </div> :

                    <TrainingDetails setShowDetails={setShowDetails} />
                }</> :
                <TrainingEdit setTrainConfig={setTrainConfig} setShowDetails={setShowDetails} />
            }

        </>
    );
};

export default Training;