import { call, put, takeEvery } from "redux-saga/effects"

import { GET_ALL_ADMIN, GET_ADMIN_ROLES } from "./actionTypes"

import { getAllAdminSuccess, getAllAdminFail, getAdminRolesFail, getAdminRolesSuccess } from "./actions"

import { getAllAdmins ,getAdminRoles} from "../../../helpers/backend_helper"

//Get All Admnins
function* fetchUsers() {
  try {
    const response = yield call(getAllAdmins)
    yield put(getAllAdminSuccess(response))
  } catch (error) {
    yield put(getAllAdminFail(error))
  }
}
function* AdminSaga() {
  yield takeEvery(GET_ALL_ADMIN, fetchUsers)
}
export default AdminSaga

//Get Admin Roles
function* fetchRoles() {
  try {
    const response = yield call(getAdminRoles)
    yield put(getAdminRolesSuccess(response))
  } catch (error) {
    yield put(getAdminRolesFail(error))
  }
}
export function* RoleAdminSaga() {
  yield takeEvery(GET_ADMIN_ROLES, fetchRoles)
}