import React from 'react';
import { Modal } from 'reactstrap';

const LoderModel = ({ showModal, modalTitle, waitingMessage }) => {

    return (
        <div>
            <Modal size="md" isOpen={showModal} centered={true}
                className='doc-modal create-folder'
            // toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                    </div>
                </div>
                <form>
                    <div className="modal-body px-0">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className='mb-0'>{waitingMessage}...</p>
                            <div className="spinner-border ms-3" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default LoderModel;