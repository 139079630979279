import React, { memo, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal } from 'reactstrap';
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import exportFromJSON from 'export-from-json'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

//Component
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

//Assets
import cloudDownload from '../../../assets/images/icons/cloud-download.svg';
import filesType from '../../../assets/images/icons/file-type.svg';
import downloadIcon from '../../../assets/images/icons/btn-download.svg';
import cloudDownloadColor from '../../../assets/images/icons/cloud-color.svg';
import csvFileType from '../../../assets/images/icons/CSV.svg';
import csvPerFileType from '../../../assets/images/icons/Zip.svg';
import xmlFileType from '../../../assets/images/icons/XML.svg';
import msxlFileType from '../../../assets/images/icons/MS-Excel.svg';
import { useDispatch } from 'react-redux';
import { getDownloadDocument, getModelById } from 'store/actions';
import { useSelector } from 'react-redux';
import { downloadDocuments } from 'constants/downloadDocuments';
import { groupLabeledTexts } from 'constants/Review/utils/jsonConvertion';

export const downloadRecord = (downloadData) => {
  let newdownloadData = [], forCsv = [];
  for (let i = 0; i < downloadData?.length; i++) {
    forCsv[i] = [];
    
    const result_data = downloadData[i]?.result_data || {};
    const labels = result_data?.labels;
    const labeledTexts = result_data?.labeled_texts;
    const groupedData = groupLabeledTexts(labels, labeledTexts, downloadData[i]);
    
    let general_fields = [], table;
    groupedData?.filter((item) => {
      if (item?.labelName === "table_data") {
        table = item
      } else {
        general_fields.push(item);
      }
    });

    const header = table?.labeledTexts[0];
    const fieldsData = {};

    for (let fields = 0; fields < general_fields?.length; fields++) {
      let value = ""
      general_fields[fields]?.labeledTexts?.length > 0 && general_fields[fields]?.labeledTexts?.map((labelItem) => {
        value = value + labelItem?.DetectedText + " ";
      })
      fieldsData[general_fields[fields]?.labelName] = value;
    }

    for (let j = 1; j < table?.labeledTexts?.length; j++) {
      let row = {};
      if (table?.labeledTexts[j]) {
        for (let x = 0; x < table?.labeledTexts[j]?.length; x++) {
          row[header[x]?.DetectedText] = table?.labeledTexts[j][x]?.DetectedText;
        }
        newdownloadData.push({ ...fieldsData, ...row });
      }
      forCsv[i].push({ ...fieldsData, ...row })
    }
  }
  return { newdownloadData, forCsv };
}

const DownloadModal = ({ data, disabledButton, translate, showAdvance, modelId }) => {

  const dispatch = useDispatch()
  const downloadData = useSelector(state => state?.GetDownloadDocument?.data?.data)
  const getModelFields = useSelector(state => state?.GetModelById?.data?.data)
  const [modal_large, setmodal_large] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [selectFiledList, setSelectFieldList] = useState([]);
  const [modelList, setModelList] = useState(null);
  const [advancedOpt, setAdvancedOpt] = useState('');


  // Function to handle drag end
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedOrder = Array.from(modelList);
    updatedOrder.splice(result.source.index, 1);
    updatedOrder.splice(result.destination.index, 0, modelList[result.source.index]);
    setModelList(updatedOrder);
    setExportFile(false);
  };

  const getOderObject = () => {
    let reArrangeOrder = [];
    modelList.map((item) => {
      const idx = selectFiledList.findIndex((a, b) => a === item?.fieldName?.toLowerCase().replaceAll(" ", "_"));
      if (idx !== -1) {
        reArrangeOrder.push(selectFiledList[idx]);
      };
    });
    return reArrangeOrder;
  }

  //Click Filed CheckBox
  const clickFiledCheck = (fieldData) => {
    const checkValue = modelList.map((items, index) => {
      if (index === fieldData) {
        return {
          ...items,
          checkbox: !items?.checkbox,
        }
      } else {
        return {
          ...items,
        }
      }
    })
    setModelList(checkValue);

    //Select Single Field Name
    let fieldItem = selectFiledList;
    checkValue?.map((checkval) => {
      const index = fieldItem.indexOf(checkval?.fieldName?.toLowerCase()?.replaceAll(' ', '_'));
      if (checkval?.checkbox === true) {
        if (index === -1) {
          fieldItem.push(checkval?.fieldName?.toLowerCase()?.replaceAll(' ', '_'));
        };
      } else {
        if (index > -1) {
          fieldItem.splice(index, 1);
        };
      };
    });
    setSelectFieldList(fieldItem);
    setExportFile(false)
  }

  const handleClick = (e) => {
    const record = downloadRecord(downloadData);
    const data = downloadDocuments(e == 'csv-per' ? record?.forCsv : record?.newdownloadData, e)
    setResultData(data)
    setExportFile(e);
  };

  function tog_large() {
    dispatch(getDownloadDocument({ data: data }))
    setmodal_large(!modal_large);
    removeBodyCss();
    setAdvancedOpt(false)
    setExportFile(false)
    setSelectFieldList([]);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleDrop = (id) => {
    if (advancedOpt === id) {
      setAdvancedOpt();
    } else {
      setAdvancedOpt(id);
    }
  };

  useEffect(() => {
    if (modelId && modelId?.length === 1) {
      dispatch(getModelById(modelId[0]));
    };
  }, [modelId?.length]);


  // Select all check
  const handleSelectAll = (allFieldsCheck, uncheck) => {

    const updatedCheckboxes = allFieldsCheck.map((items) => ({
      ...items,
      checkbox: uncheck,
    }));
    if (uncheck === true) {

      let fieldItem = selectFiledList;
      updatedCheckboxes?.map((checkval) => {
        const index = fieldItem.indexOf(checkval?.fieldName?.toLowerCase()?.replaceAll(' ', '_'));
        if (checkval?.checkbox === true) {
          if (index === -1) {
            fieldItem.push(checkval?.fieldName?.toLowerCase()?.replaceAll(' ', '_'));
          };
        }
      });
      setSelectFieldList(fieldItem);
    } else {
      setSelectFieldList([]);

    }
    setModelList(updatedCheckboxes);

  };

  //Custom Scroll
  const ref = useRef();
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <>
      <div className='table-btns'>
        <PrimaryButton btnName={translate('download')} disabled={disabledButton} showIcon={true} btnIcon={downloadIcon} onClick={() => { tog_large() }} />
      </div>
      <Modal
        size="lg"
        isOpen={modal_large}
        centered={true}
        className='doc-modal'
        toggle={() => {
          tog_large();
        }}
      >
        <div className="modal-header p-0">
          <h1 className='page-title'>{translate('exResults')}</h1>
          <button
            onClick={() => {
              setmodal_large(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{translate('selectDoc')}</p>
          <div className='doc-download'>
            <div className={`doc-download-item ${exportFile === "csv" ? 'active' : ''}`} onClick={() => { handleClick("csv") }}>
              <div className='download-title'>
                <div className="head-before">
                  <span></span>
                </div>
                <div className='file-option'>
                  <h6><img src={csvFileType} alt="filesType" />{translate('csv')} <span>{translate('recommed')}</span></h6>
                  <img src={exportFile === "csv" ? cloudDownloadColor : cloudDownload} alt='cloudDownload' />
                </div>
              </div>
            </div>
            <div className={`doc-download-item ${exportFile === "csv-per" ? 'active' : ''}`} onClick={() => { handleClick("csv-per") }}>
              <div className='download-title'>
                <div className="head-before">
                  <span></span>
                </div>
                <div className='file-option'>
                  <h6><img src={csvPerFileType} alt="filesType" />{translate('csvper')}</h6>
                  <img src={exportFile === "csv-per" ? cloudDownloadColor : cloudDownload} alt='cloudDownload' />
                </div>
              </div>

            </div>
            <div className={`doc-download-item ${exportFile === "xml" ? 'active' : ''}`} onClick={() => { handleClick("xml") }}>
              <div className='download-title'>
                <div className="head-before">
                  <span></span>
                </div>
                <div className='file-option'>
                  <h6><img src={xmlFileType} alt="filesType" />{translate('xml')}</h6>
                  <img src={exportFile === "xml" ? cloudDownloadColor : cloudDownload} alt='cloudDownload' />
                </div>
              </div>

            </div>
            <div className={`doc-download-item ${exportFile === "xls" ? 'active' : ''}`} onClick={() => { handleClick("xls") }}>
              <div className='download-title'>
                <div className="head-before">
                  <span></span>
                </div>
                <div className='file-option'>
                  <h6><img src={msxlFileType} alt="filesType" />{translate('msxl')}</h6>
                  <img src={exportFile === "ms" ? cloudDownloadColor : cloudDownload} alt='cloudDownload' />
                </div>
              </div>

            </div>
          </div>

          <div className='filter-btn download-btn table-btns d-flex'>
            <SecountryBtn btnName={translate('close')} showIcon={false} onClick={() => { setmodal_large(false) }} />
            
            {/* <span disabled={!exportFile}> */}
            <PrimaryButton btnName={translate('downloadresult')} disabled={!exportFile} onClick={() => {
              if (resultData?.type === 'zip') {
                resultData?.data?.generateAsync({ type: 'blob' }).then((content) => {
                  saveAs(content, 'My Documents.zip');
                });
              } else {
                exportFromJSON({
                  data: resultData?.data, fileName: resultData?.fileName, exportType: resultData?.type
                })
              };
              // setmodal_large(false)
            }} showIcon={true} btnIcon={downloadIcon} />
            {/* </span> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadModal;