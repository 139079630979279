import React, { useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";

import AllActivity from './Components/AllActivity';

// Asserts
import activityIcon from './../../assets/images/icons/activity.svg';
import activityActIcon from './../../assets/images/icons/activity-act.svg';
import documentIcon from './../../assets/images/icons/document.svg';
import documentActIcon from './../../assets/images/icons/document-act.svg';
import userTimeIcon from './../../assets/images/icons/user-time.svg';
import userTimeActIcon from './../../assets/images/icons/user-time-act.svg';
import rejectedIcon from './../../assets/images/icons/rejected.svg';
import rejectedActIcon from './../../assets/images/icons/reject-active.svg';
import apiIcon from './../../assets/images/icons/api.svg';
import apiActIcon from './../../assets/images/icons/api-act.svg';
import Pagination from 'components/Common/Pagination';
import SelectDropDown from 'components/Common/SelectDropDown';
import SearchBar from 'components/Common/SearchBar';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { withTranslation } from 'react-i18next';

const Activitys = (props) => {
    const { t } = props;
    document.title = `${t("activity")} | ${t('appName')}`;
    const [currentPage, setCurrentPage] = useState(1);
    const [myDocActiveTab, setmyDocActiveTab] = useState("all");

    const toggleCustom = (tab) => {
        if (myDocActiveTab !== tab) {
            setmyDocActiveTab(tab);
            setCurrentPage(1);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1 className='page-title'>{t('allActivity')}</h1>
                    <div>
                        <Card>
                            <CardBody>
                                <div className='nav-tabs-custom nav-justified'>
                                    <Nav className="">
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({ active: myDocActiveTab === "all", })}`}
                                                onClick={() => {
                                                    toggleCustom("all");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "all" ? activityActIcon : activityIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('allActivity')}</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "document",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("document");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "document" ? documentActIcon : documentIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('documents')}</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "user",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("user");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "user" ? userTimeActIcon : userTimeIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('users')}</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "settings",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("settings");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "settings" ? rejectedActIcon : rejectedIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('settings')}</span>
                                            </div>
                                        </NavItem>
                                        {/* <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "api",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("api");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "api" ? apiActIcon : apiIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('api')}</span>
                                            </div>
                                        </NavItem> */}
                                    </Nav>
                                    {/* <div className='filters-group act-filter'>
                                        <SearchBar />
                                        <SelectDropDown />
                                    </div> */}
                                </div>

                                <AllActivity subUrl={myDocActiveTab} translate={t} setCurrentPage={setCurrentPage} currentPage={currentPage} />

                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Activitys));