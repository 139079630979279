import React, { memo } from 'react';
import { Modal } from 'reactstrap';

const ImageViewModel = ({ showModal, toggle, path, name }) => {
    return (
        <div>
            <Modal size="md" isOpen={showModal} centered={true}
                className='doc-modal create-folder image-model'
                toggle={toggle}
            >
                <div className="modal-header p-0 border-0">
                    <div className='head-item'>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <img src={path} alt={name} />
            </Modal>
        </div>
    );
};

export default memo(ImageViewModel);