import React, { useState } from 'react';

import fileUpload from '../../assets/images/icons/upload-file.svg';
import Confirmation from './Modals/Confirmation';

const UploadingItems = () => {
    const [isOpenUpload, setOpenUpload] = useState(false);
    const [showupload, setShowUpload] = useState(true);
    const [isConfirmModal, setConfirmModal] = useState(false);

    const openUploadItem = () => {
        setShowUpload(!showupload)
    }

    //Close Conrirm Modal
    function toggleModal() {
        setConfirmModal(!isConfirmModal);
    }

    // Close Upload Items
    const showUpoaditem = () => {
        setOpenUpload(false)
    }

    return (
        <div>
            {isOpenUpload && (
            <div className='files-upload'>
                <div className='upload-header'>
                    <div>
                        <h5> Uploading 10 items</h5>
                    </div>
                    <div className='d-flex'>
                        <span className='up-down-arrow' onClick={openUploadItem}>
                            <i className={showupload ? 'bx bx-chevron-up' : 'bx bx-chevron-down'}></i>
                        </span>
                        <span className='close-icon' onClick={toggleModal}><i className='bx bx-x'></i></span>
                    </div>
                </div>
                {showupload &&
                    (
                        <div className='item-group'>
                            <p>Uploading 5 of 10</p>
                            <ul>
                                <li>
                                    <div>
                                        <img src={fileUpload} alt='fileUpload' />
                                    </div>
                                    <div className='file-items'>
                                        <h6>Invoice.jpg</h6>
                                        <span>1.2 of 3mb</span>
                                    </div>
                                    <div className='file-status'>
                                      <div className='status-type'>
                                        <i className='bx bx-chevron-right'></i>
                                      </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img src={fileUpload} alt='fileUpload' />
                                    </div>
                                    <div className='file-items'>
                                        <h6>Invoice.jpg</h6>
                                        <span>1.2 of 3mb</span>
                                    </div>
                                    <div className='file-status'>
                                      <div className='status-type'>
                                        <i className='bx bx-check'></i>
                                      </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img src={fileUpload} alt='fileUpload' />
                                    </div>
                                    <div className='file-items'>
                                        <h6>Invoice.jpg</h6>
                                        <span>1.2 of 3mb</span>
                                    </div>
                                    <div>
                                        <img src={fileUpload} alt='fileUpload' />
                                    </div>
                                </li>
                        

                            </ul>
                        </div>
                    )
                }

            </div>
            )}
            <Confirmation showModal={isConfirmModal}
             toggle={toggleModal}
             close={showUpoaditem}
             modalTitle={'Cancel Upload'}
             description={'Your upload is not complete. Do you want to cancel the upload?'}
             primaryBtn={'Continue Upload'}
             secountBtn={'Cancel'}
             />
        </div>
    );
};

export default UploadingItems;