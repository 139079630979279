import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "./NoDataFound";
import sortIcon from '../../assets/images/icons/sort-icon.svg';
import ascending from '../../assets/images/svg/ascending.svg'
import descending from '../../assets/images/svg/descending.svg'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <React.Fragment>
      <Col md={4} className="header">
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Search`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search search-icon"></i>
          </div>
        </div>

      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}

    </React.Fragment>
  );
}

const TableContainer = ({
  sortBy,
  setsortBy,
  currentPage,
  totalCount,
  customPagination,
  setCurrentPage,
  setIsHovered,

  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  btnname,
  iSPageOptions,
  renderRowSubComponent,
  hiddenValue
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    visibleColumns,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        hiddenColumns: hiddenValue || [],
        pageIndex: 0,
        pageSize: customPageSize,
        /*sortBy: [
          {
            desc: true,
          },
        ],*/
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };


  const generateCustomSortingIndicator = column => {
    if (sortBy === null) {
      return ""
    }
    else if (sortBy.value === 1) {
      return sortBy.field === column.id ? <img src={ascending} className="ps-2" alt="sorting" /> : ""
    }
    else if (sortBy.value === -1) {
      return sortBy.field === column.id ? <img src={descending} className="ps-2" alt="sorting" /> : ""
    }
  };

  return (
    <Fragment>
      <div className="card m-0">
        <div className="card-body p-0 rounded-0">

          <Row className="mb-2 table-filter-section">
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isJobListGlobalFilter={isJobListGlobalFilter}
              />
            )}
            {isAddOptions && (
              <Col sm="8">
                <div className="text-sm-end">
                  <button
                    type="button"
                    className="mb-2 me-2 secountry-btn"
                    onClick={handleOrderClicks}
                  >
                    {/* <i className="mdi mdi-plus me-1" /> */}
                    {btnname}
                  </button>
                </div>
              </Col>
            )}

          </Row>
          <div className="table-responsive react-table">
            <Table bordered hover {...getTableProps()} className={className}>
              <thead className="table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th className="" key={column.id}>
                        {column.enableSort && column?.customSort && <div className="pointer-curser"
                          onClick={() => {
                            if (sortBy === null || column.id !== sortBy.field) {
                              setsortBy({ value: 1, field: column.id, type: column.sortType, subField: column.subField });
                            }
                            else if (sortBy.value === 1) {
                              setsortBy({ value: -1, field: column.id, type: column.sortType, subField: column.subField })
                            }
                            else if (sortBy.value === -1) {
                              setsortBy(null)
                            }
                            setCurrentPage(1)
                          }}
                        >
                          <span className={`${column.isSorted && column.isSortedDesc == true ? "desc" : ""} ${column.isSorted && column.isSortedDesc == false ? "asec" : ""}`}>
                            {column.render("Header")}</span>
                          {generateCustomSortingIndicator(column)}
                        </div>}

                        {!column.enableSort && <div
                        // {...column.getSortByToggleProps()}
                        >
                          <span className={`${column.isSorted && column.isSortedDesc == true ? "desc" : ""} ${column.isSorted && column.isSortedDesc == false ? "asec" : ""}`}>
                            {column.render("Header")}</span>
                          {/* {generateSortingIndicator(column)} */}
                        </div>}
                        {/* <Filter column={column} /> */}
                      </th>
                    ))}
                  </tr>
                ))}

                {data?.length === 0 && <h6 className="py-4 no-data-available">No data is available</h6>}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr onMouseEnter={() => {
                        if (setIsHovered) {
                          setIsHovered(row?.original?._id)
                        };
                      }}
                        onMouseLeave={() => {
                          if (setIsHovered) {
                            setIsHovered('')
                          };
                        }}>
                        {row.cells.map((cell, idx) => {
                          return <td className={`${idx === 0 ? "arrow-icon" : ""}`} key={idx} {...cell.getCellProps()} >{cell.render("Cell") || <Skeleton height={"25px"} />}</td>
                        })}
                      </tr>
                      {row.isExpanded && (
                        <tr>
                          <td className="table-details" colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* {
            data?.length === 0 &&
            <div className="d-flex justify-content-center">
              <h6 className="py-4">No data is available</h6>
            </div>
          } */}

          {data?.length > 0 && iSPageOptions && !customPagination && (
            <Row className="justify-content-center justify-content-center align-items-center pagination">
              {/* <Col className="table-select">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col> */}

              <Col className="col-md-auto action-btn">
                <div className="d-flex gap-1">
                  {/* <Button
                    color="primary"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </Button> */}
                  <Button
                    color="primary"
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                  >
                    <i className="mdi mdi-chevron-left"></i>
                  </Button>
                </div>
              </Col>
              <Col className="col-md-auto d-none d-md-block">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </Col>
              <Col className="col-md-auto ">
                <Input
                  type="number"
                  min={1}
                  style={{ width: 65, height: 43, border: 0 }}
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={onChangeInInput}
                />
              </Col>

              <Col className="col-md-auto action-btn">
                <div className="d-flex gap-1">
                  <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Button>
                  {/* <Button
                    color="primary"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </Button> */}
                </div>
              </Col>
            </Row>
          )}

          {data?.length > 0 && iSPageOptions && customPagination && (
            <Row className="justify-content-center justify-content-center align-items-center pagination">
              {/* <Col className="table-select">
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col> */}
              <div className='table-pagiation mt-2'>
                <div className={`prev-group ${currentPage === 1 ? 'disabled' : 'last-page'}`}>
                  <button className={`${currentPage === 1 ? 'disabled' : ''}`} onClick={() => setCurrentPage(1)}
                    disabled={currentPage == 1 ? true : false}><i className='bx bx-chevrons-left'></i></button>
                  <button className={`${currentPage === -1 ? 'previous' : 'disabled'}`} onClick={() => {
                    if (currentPage > 1) setCurrentPage(currentPage => currentPage - 1)
                  }}
                    disabled={currentPage <= 1 ? true : false}><i className='bx bx-chevron-left'></i></button>
                </div>
                <div className='current-group'>
                  <p className='m-0'>Page <strong>{currentPage}</strong> to <strong>{Math.ceil(totalCount / customPageSize)}</strong> </p>
                  <div className='current-page'>
                    <Input
                      type="number"
                      defaultValue={currentPage}
                      onChange={(e) => {
                        setTimeout(() => {
                          const condition = e.target.value <= Math.ceil(totalCount / customPageSize) && e.target.value > 0
                          if (condition) setCurrentPage(e.target.value)
                        }, 1000);
                      }}
                    />
                  </div>
                </div>
                <div className={`next-group ${currentPage >= Math.ceil(totalCount / customPageSize) ? 'disabled' : 'next-page'}`}>
                  <button onClick={() => {
                    if (currentPage < Math.ceil(totalCount / customPageSize)) setCurrentPage(currentPage => currentPage + 1)
                  }}
                    disabled={currentPage >= Math.ceil(totalCount / customPageSize) ? true : false}><i className='bx bx-chevron-right'></i></button>
                  <button onClick={() => setCurrentPage(Math.ceil(totalCount / customPageSize))}
                    disabled={currentPage == Math.ceil(totalCount / customPageSize) ? true : false}><i className='bx bx-chevrons-right'></i></button>
                </div>
              </div>
              {/* <Col className="col-md-auto action-btn">
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage == 1 ? true : false}
                  >
                    {"<<"}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (currentPage > 1) setCurrentPage(currentPage => currentPage - 1)
                    }}
                    disabled={currentPage <= 1 ? true : false}
                  >
                    <i className="mdi mdi-chevron-left"></i>
                  </Button>
                </div>
              </Col>
              <Col className="col-md-auto d-none d-md-block">
                Page{" "}
                <strong>
                  {currentPage} of {Math.ceil(totalCount / customPageSize)}
                </strong>
              </Col>
              <Col className="col-md-auto ">
                <Input
                  type="number"
                  style={{ width: 65, height: 43, border: 0 }}
                  defaultValue={currentPage}
                  onChange={(e) => {
                    const condition = e.target.value <= Math.ceil(totalCount / customPageSize) && e.target.value > 0
                    if (condition) setCurrentPage(e.target.value)
                  }}
                />
              </Col>

              <Col className="col-md-auto action-btn">
                <div className="d-flex gap-1">
                  <Button color="primary" onClick={() => {
                    if (currentPage < Math.ceil(totalCount / customPageSize)) setCurrentPage(currentPage => currentPage + 1)
                  }}
                    disabled={currentPage >= Math.ceil(totalCount / customPageSize) ? true : false}
                  >
                    <i className="mdi mdi-chevron-right"></i>
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setCurrentPage(Math.ceil(totalCount / customPageSize))}
                    disabled={currentPage == Math.ceil(totalCount / customPageSize) ? true : false}
                  >
                    {">>"}
                  </Button>
                </div>
              </Col> */}
            </Row>
          )}

        </div>
      </div>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
