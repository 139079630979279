import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";
import { getCreditPlanFail, getCreditPlanSuccess } from "./actions";
import { GET_CREDIT_PLAN } from "./actionTypes";
import { currentCreditPlan } from "helpers/backend_helper";

//Get Plans
function* GetCreditPlans({ payload: id}) {
    try {
        const response = yield currentCreditPlan(id);
        if (response.status === responseStatus.error) {
            toast.error(response.message);
            yield put(getCreditPlanFail(error));
        } else if (response.status === responseStatus.success) {
            yield put(getCreditPlanSuccess(response));
        }
    } catch (error) {
        yield put(getCreditPlanFail(error))
    }
}

export function* GetCreditPlansSaga() {
    yield takeEvery(GET_CREDIT_PLAN, GetCreditPlans);
}