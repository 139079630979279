import { useSocket } from 'App';
import { get } from 'helpers/api_helper';
import { SETTINGS_GET_PRE_MODEL } from 'helpers/application_url_helper';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getPreModel, getPreModelSuccess, preModelStartStop } from 'store/actions';

const ModelConfig = () => {
    const dispatch = useDispatch();

    const socket = useSocket();

    const modelDetails = useSelector(state => state.GetPreModel.data?.data);

    // const data = "started";
    // const data = "stopped";
    const data = "starting";

    const handleSartStop = (status) => {
        const data = {};
        data["model_status"] = status;
        dispatch(preModelStartStop(data));
    }
    
    useEffect(() => {
        dispatch(getPreModel());

        // Check if socket is initialized before using it
        if (socket) {
            // Listen for real-time user list updates
            socket.on('modelUpdated', async () => {
                try {
                    const response = await get(`${SETTINGS_GET_PRE_MODEL}`);
                    dispatch(getPreModelSuccess(response))
                } catch (error) {
                    console.log("error: ", error);
                }
            });

            // Clean up the socket event listener when component unmounts
            return () => socket.off('modelUpdated');
        }
    }, [socket]);

    return (
        <div>
            <h5 className='mb-3'>Start/stop pre model</h5>
            {modelDetails?.model_status?.toLowerCase() === "stopping" &&
                <p className='m-0'>Model is stopping, it will take some times</p>}
            {modelDetails?.model_status?.toLowerCase() === "starting" &&
                <p className='m-0'>Model is starting, it will take some times</p>}
            {modelDetails?.model_status?.toLowerCase() === "running" &&
                <button className='btn-common btn-primary btn-block primary-button' onClick={() => handleSartStop("STOPPING")}>Stop</button>}
            {modelDetails?.model_status?.toLowerCase() === "stopped" && <button className='btn-common btn-primary btn-block primary-button' onClick={() => handleSartStop("STARTING")}>Start</button>}
        </div>
    )
}

export default ModelConfig