
const getDifference = () => {

    const invoiceImage = document.getElementById('invoice-image')
    const imageRect = document.querySelector('.image-container')
    let diff
    let originalWidth, originalHeight

    //get original width and height of image
    originalHeight = invoiceImage?.naturalHeight;
    originalWidth = invoiceImage?.naturalWidth;

    //get current width and height of image
    const currentWidth = imageRect?.offsetWidth;
    const currentHeight = imageRect?.offsetHeight;

    diff = ((currentWidth + currentHeight) / (originalWidth + originalHeight))

    return diff
}

export default getDifference