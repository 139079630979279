import React from 'react';

const RadioButton = ({ label, selectValue, id }) => {
    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id={`exampleRadios-${id}`}
                    value="option1"
                    onChange={(e) => {
                        if (e.target.checked === true) {
                            selectValue(id)
                        }
                    }}
                />
                <label
                    className="form-check-label"
                    htmlFor={`exampleRadios-${id}`}
                >
                    {label}
                </label>
            </div>
        </>
    );
};

export default RadioButton;