import {
  GET_MODELS_DOC,
  GET_MODELS_DOC_SUCCESS,
  GET_MODELS_DOC_FAIL,
  DELETE_MODELS_DOC,
  DELETE_MODELS_DOC_SUCCESS,
  DELETE_MODELS_DOC_FAIL,
  GET_MODEL_DOC_BY_ID,
  GET_MODEL_DOC_BY_ID_SUCCESS,
  GET_MODEL_DOC_BY_ID_FAIL,
  TRAIN_DOCUMENT,
  TRAIN_DOCUMENT_SUCCESS,
  TRAIN_DOCUMENT_FAIL,
} from './actionsTypes';

//Get ModelsDoc
const getModelsDocState = {
  error: "",
  data: "",
  loading: false
}

export const GetModelsDoc = (state = getModelsDocState, action) => {
  switch (action.type) {
    case GET_MODELS_DOC:
      state = {
        ...state, loading: true
      }
      break
    case GET_MODELS_DOC_SUCCESS:
      state = {
        ...state, ...action.payload, loading: false
      }
      break
    case GET_MODELS_DOC_FAIL:

      state = {
        ...state, error: action.payload, loading: false
      }
      break
  }
  return state;
}

//Delete Model Doc
const deleteDocModels = {
  error: "",
  success: "",
  loading: false
}

export const DeleteModelsDoc = (state = deleteDocModels, action) => {
  switch (action.type) {
    case DELETE_MODELS_DOC:
      state = {
        ...state,
        loading: true
      }
      break
    case DELETE_MODELS_DOC_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loading: false
      }
      break
    case DELETE_MODELS_DOC_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false
      }
      break
  }
  return state
}

//get model document by id
const getModelDcumentByIdState = {
  error: "",
  data: "",
  loading: false
}

export const GetModelDocumentById = (state = getModelDcumentByIdState, action) => {
  switch (action.type) {
    case GET_MODEL_DOC_BY_ID:
      state = {
        error: "",
        data: "",
        loading: true
      }
      break
    case GET_MODEL_DOC_BY_ID_SUCCESS:
      state = {
        error: "",
        loading: false,
        data: action.payload,
      }
      break
    case GET_MODEL_DOC_BY_ID_FAIL:
      state = {
        data: "",
        loading: false,
        error: action.payload
      }
      break
  }
  return state
}

//train document
const trainDocumentState = {
  error: "",
  data: "",
  loading: false
}

export const TrainDocument = (state = trainDocumentState, action) => {
  switch (action.type) {
    case TRAIN_DOCUMENT:
      state = {
        error: "",
        data: "",
        loading: true
      }
      break
    case TRAIN_DOCUMENT_SUCCESS:
      state = {
        error: "",
        loading: false,
        data: action.payload,
      }
      break
    case TRAIN_DOCUMENT_FAIL:
      state = {
        data: "",
        loading: false,
        error: action.payload
      }
      break
  }
  return state
}
