import { call, put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";
import { modelsDocDeleteFail, modelsDocDeleteSuccess, getModelsDocFail, getModelsDocSuccess, getModelDocumentByIdSuccess, getModelDocumentByIdFail, trainDocumentFail, trainDocumentSuccess } from "./actions";
import { DELETE_MODELS_DOC, GET_MODELS_DOC, GET_MODEL_DOC_BY_ID, TRAIN_DOCUMENT } from "./actionsTypes";
import { docModelsDelete, getDocModels, getModelDcumentByid, trainDocument } from "helpers/backend_helper";

//Get Models Doc
function* GetDocModels({ payload: data }) {
  try {
    const response = yield call(getDocModels, data);
    if (response.status === responseStatus.error) {
      toast.error(response.message)
      yield put(getModelsDocFail(error))
    } else if (response.status === responseStatus.success) {
      yield put(getModelsDocSuccess(response))
    }
  } catch (error) {
    yield put(getModelsDocFail(error))
  }
}

export function* GetDocModelsSaga() {
  yield takeEvery(GET_MODELS_DOC, GetDocModels);
}

// Delete Models Doc
function* DeleteModelDocRecord({ payload: { id, callGetDocuments } }) {
  try {
    const response = yield call(docModelsDelete, id)
    if (response.status === responseStatus.error) {
      toast.error(response.message)
    } else {
      yield put(modelsDocDeleteSuccess(response))
      toast.success(response.message)
      callGetDocuments();
    }
  } catch (error) {
    yield put(modelsDocDeleteFail(error))
    toast.error(error.response.data.message)
  }
}

export function* DeleteModelDocSaga() {
  yield takeEvery(DELETE_MODELS_DOC, DeleteModelDocRecord)
}

// get document by id
function* getdocById({ payload: id }) {
  try {
    const response = yield call(getModelDcumentByid, id)
    if (response.status.toUpperCase() === responseStatus.error) {
      toast.error(response.message)
      yield put(getModelDocumentByIdFail(error))
    } else {
      yield put(getModelDocumentByIdSuccess(response))
    }
  } catch (error) {
    yield put(getModelDocumentByIdFail(error))
    toast.error(error.response.data.message)
  }
}

export function* GetModelDocumentByIdSaga() {
  yield takeEvery(GET_MODEL_DOC_BY_ID, getdocById)
}

// train document
function* traindcument({ payload: { data, handleBack } }) {
  try {
    const response = yield call(trainDocument, data)
    if (response.status.toUpperCase() === responseStatus.error) {
      toast.error(response.message)
      yield put(trainDocumentFail(error))
    } else {
      yield put(trainDocumentSuccess(response))
      toast.success(response.message)
      handleBack();
    }
  } catch (error) {
    yield put(trainDocumentFail(error))
    toast.error(error.response.data.message)
  }
}

export function* TrainDocumentSaga() {
  yield takeEvery(TRAIN_DOCUMENT, traindcument)
}