import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Hook Form Validation
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
// action
import { userForgetPassword } from "../../store/actions";

// import images
import AuthLoader from "components/Common/AuthLoader";
import AppLogo from '../../assets/images/app_logo.svg';
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import AppLogoWhite from '../../assets/images/logo-white.svg';
import { Toaster, toast } from "react-hot-toast";
import { responseStatus } from "constants/ResStatus";
import { withTranslation } from "react-i18next";

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Forget Password | Yenmin AI";

  const dispatch = useDispatch();

  const forgetFormValues = {
    email: '',
  }
  const forgetFormSchema = Yup.object({
    email: Yup.string().required(`${props.t('emailError')}`).test('email', `${props.t('validEmail')}`, val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }),
  })
  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(forgetFormSchema),
    defaultValues: forgetFormValues
  });

  const onSubmit = (values) => {
    dispatch(userForgetPassword(values, props.history));
  }

  const { forgetLoader } = useSelector(state => ({
    forgetLoader: state.ForgetPassword.forgetLoader,
  }));

  return (
    <React.Fragment>

      <div className="account-pages">
      

      <Container fluid>
          <Row className="h-100">
            <Col lg={8} className="login-bg">
              <div className="logo">
                <img src={AppLogoWhite} alt="AppLogoWhite" />
              </div>
              <div className="welcome-content">
                <h1>Welcome to <br />Yenmin AI invoice capture</h1>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac semper orci, eu porttitor lacus. </p> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="welcome-form">
              <div className="text-center auth-logo">
                <img src={AppLogo} alt="Logo" />
              </div>
                <div className="text-center auth-content">
                  <h4>{props.t('ForgetTitle')}</h4>
                  <p>{props.t('forgotDes')}</p>
                </div>
                <form
                      noValidate
                      className="form-horizontal"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <div className="mb-3">
                        <Label className="form-label mt-3 f-size-14">{props.t('emailAddress')} <span>*</span></Label>
                        <input type="email" {...register("email")} placeholder={props.t("emailplaceholder")} className={errors.email?.message ? 'form-control mb-0 is-invalid' : '' + 'form-control mb-0'} />
                        {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                      </div>

                      {forgetLoader === true ? <div className="d-grid"><AuthLoader /></div> :
                        <div className="d-grid">
                          <PrimaryButton btnType="submit" btnName={props.t('sendCode')} />
                        </div>
                      }
                      <p className="go-back f-size-12 my-3 text-center">
                        {props.t('backTo')}{" "}
                        <Link to="/login" className="font-weight-medium primary-color f-size-12">
                        {props.t('login')}
                        </Link>{" "}
                      </p>
                    </form>
               
               
              </div>
            </Col>
          </Row>
        </Container>
      
      </div>
     </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
