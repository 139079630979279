import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Components
import AllFiles from './FilesTab/AllFiles';
import AssignUser from 'components/Common/AssignUser';
import SelectDropDown from 'components/Common/SelectDropDown';
import DatePickerDropDown from 'components/Common/DatePickerDropDown';
import DownloadModal from 'components/Common/Modals/DownloadModal';

// Asserts
import allFilesIcon from './../../assets/images/icons/allfiles.svg';
import allFilesActIcon from './../../assets/images/icons/allfile-active.svg';
import approvedIcon from './../../assets/images/icons/approved.svg';
import approvedActIcon from './../../assets/images/icons/approved-active.svg';
import reviewIcon from './../../assets/images/icons/review.svg';
import reviewActIcon from './../../assets/images/icons/review-active.svg';
import rejectedIcon from './../../assets/images/icons/rejected.svg';
import rejectedActIcon from './../../assets/images/icons/reject-active.svg';
import UndoIcon from "../../assets/images/icons/undo.svg";

//Store
import { useDispatch, useSelector } from 'react-redux';
import { getDocCountByStatus, getNyDocuments } from 'store/actions';
import { getAssignedUsers, getDocStatus, getDocTypes } from 'store/actions';
import UserPlaceholder from 'pages/Dashboard/Components/UserPlaceholder';
import FileUploadBtn from 'components/Common/Buttons/FileUploadBtn';
import { useLocation, useParams, } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import { pageViewed } from 'store/uploadFiles/action';

const MyDocuments = (props) => {
    const { t } = props;
    document.title = `${t("myDocuments")} | ${t('appName')}`;
    const [assignUsers, setAssignUsers] = useState(null);
    const [date, setDate] = useState(null);
    const [subUrl, setSubUrl] = useState('uploaded')
    const [filter, setfilter] = useState({})
    const [authUser, setAuthUser] = useState({})
    const [documentsData, setDocumentsData] = useState([])
    const [selectFile, setSelectFile] = useState(0)
    const [advancedConf, setAdvancedConf] = useState(false);
    const [modelData, setModelData] = useState([]);
    const [myDocActiveTab, setmyDocActiveTab] = useState("uploaded");
    const [currentPage, setCurrentPage] = useState(1);
    const [cleared, setCleared] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const history = useHistory();
    const dispatch = useDispatch();

    const docData = useSelector(state => state.GetMyDocuments?.data?.data)
    const authInfo = useSelector(state => state.authInfo?.data)

    const getRole = JSON.parse(localStorage.getItem("authUser"));

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const toggleCustom = (tab) => {
        if (myDocActiveTab !== tab) {
            setmyDocActiveTab(tab);
            setSubUrl(tab);
            setSelectFile(0);
            setCurrentPage(1);
            if (query.get("tab")) {
                handleChangeTab(tab);
            };
        };
    };

    useEffect(() => {
        if (query.get("tab")) {
            toggleCustom(query.get("tab"));
        };
    }, [query.get("tab")])

    useEffect(() => {
        dispatch(getDocTypes())
        dispatch(getDocStatus())
        dispatch(getAssignedUsers())
        dispatch(pageViewed("my_documents"))
        
        return () => {
            dispatch(pageViewed(""));
        }
    }, [])

    const setDocFilterValue = (value) => {

        let statusFilter
        if (value?.docStatus && value?.docStatus.length > 0) {
            statusFilter = {
                statusFilter: { status: { $in: value?.docStatus } },
            }
        } else statusFilter = { statusFilter: { status: null } }

        let typeFilter
        if (value?.docTypes && value?.docTypes.length > 0) {
            typeFilter = {
                typeFilter: { docType: { $in: value?.docTypes } }
            }
        } else typeFilter = { typeFilter: { docType: null } }

        let assignFilter
        if (value?.assignedTo && value?.assignedTo.length > 0) {
            assignFilter = {
                assignFilter: { assignedTo: { $in: value?.assignedTo } },
            }
        } else assignFilter = { assignFilter: { assignedTo: null } }

        setfilter({ ...filter, ...assignFilter, ...typeFilter, ...statusFilter })
    }

    useEffect(() => {
        let filters
        if (date?.start && date?.end) {
            filters = {
                dateFilter: { createdAt: { $gte: date?.start, $lte: date?.end } },
            }
        } else filters = { dateFilter: { createdAt: null } }
        setfilter({ ...filter, ...filters })
    }, [date])

    useEffect(() => {
        if (authInfo) setAuthUser(authInfo)
    }, [authInfo])

    const handleChangeTab = (tab) => {
        history.push({
            pathname: '/my-document',
            search: `?tab=${tab}`,
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1 className='page-title'>{t("myDocuments")}</h1>
                    <div>
                        <Card>
                            <CardBody>
                                <div className='nav-tabs-custom nav-justified'>
                                    <Nav className="">
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({ active: myDocActiveTab === "uploaded", })} `}
                                                onClick={() => {
                                                    toggleCustom("uploaded");

                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "uploaded" ? allFilesActIcon : allFilesIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('allFiles')} ({docData?.counts ? docData?.counts?.uploaded : '0'})</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "approved"
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("approved");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "approved" ? approvedActIcon : approvedIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('approved')} ({docData?.counts ? docData?.counts?.approved : '0'})</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "inreview",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("inreview");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "inreview" ? reviewActIcon : reviewIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('inReview')} ({docData?.counts ? docData?.counts?.inReview : '0'})</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "rejected",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("rejected");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "rejected" ? rejectedActIcon : rejectedIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{t('rejected')} ({docData?.counts ? docData?.counts?.rejected : '0'})</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "inprogress",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("inprogress");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "inprogress" ? rejectedActIcon : rejectedIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">{`In Progress`} ({docData?.counts ? (docData?.counts?.inProgress || 0) : '0'})</span>
                                            </div>
                                        </NavItem>
                                        <NavItem>
                                            <div
                                                className={`tab-item ${classnames({
                                                    active: myDocActiveTab === "failed",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("failed");
                                                }}
                                            >
                                                <span className="tab-icon">
                                                    <img src={myDocActiveTab === "failed" ? rejectedActIcon : rejectedIcon} alt='tab-icon' />
                                                </span>
                                                <span className="d-none d-sm-block">Failed ({docData?.counts ? (docData?.counts?.failed || 0) : '0'})</span>
                                            </div>
                                        </NavItem>
                                    </Nav>
                                    <div className='filters-group'>
                                        <div id='refresh' className='doc-filters me-2' style={{ cursor: 'pointer' }} onClick={() => setRefresh(prev => prev + 1)}>
                                            <img src={UndoIcon} alt="refresh" />
                                        </div>
                                        <UncontrolledTooltip placement="top" target={`refresh`}>Refresh</UncontrolledTooltip>
                                        {getRole?.user?.role !== "USER" &&
                                            <AssignUser documentsData={documentsData} setAssignUsers={setAssignUsers} translate={t} />
                                        }
                                        {/* < DatePickerDropDown setDate={setDate} /> */}
                                        <SelectDropDown setFilter={setDocFilterValue} translate={t} setCleared={setCleared} cleared={cleared} />
                                        {(filter && (filter?.dateFilter?.createdAt || filter?.assignFilter?.assignedTo || filter?.typeFilter?.docType) ?
                                            <p className='clear-filter' onClick={() => {
                                                setDate(null)
                                                setDocFilterValue(null)
                                                setCleared(true)
                                            }}>Clear</p>
                                            : '')
                                        }
                                    </div>
                                </div>
                                <TabContent activeTab={myDocActiveTab} className="table-content">
                                    <AllFiles
                                        setAssignUsers={setAssignUsers}
                                        assignUsers={assignUsers}
                                        subUrl={subUrl}
                                        filter={filter}
                                        company_id={authUser?.user?.company_id}
                                        setDocumentsData={setDocumentsData}
                                        documentsData={documentsData}
                                        setSelectFile={setSelectFile}
                                        translate={t}
                                        modelData={modelData}
                                        setModelData={setModelData}
                                        setAdvancedConf={setAdvancedConf}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        refresh={refresh}
                                    />
                                </TabContent>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='table-footer'>
                        <div className='select-download'>
                            <DownloadModal data={documentsData} disabledButton={selectFile <= 0 ? true : false} translate={t} showAdvance={advancedConf} modelId={modelData} />
                            {selectFile ? <p className='m-0 ps-4 fw-500 f-size-14'>{t('selectedFiles')} <span className='ps-2'>{selectFile}</span></p> : ""}

                        </div>
                        {
                            getRole?.user?.role !== "USER" &&
                            <FileUploadBtn translate={t} />
                        }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(MyDocuments));