import Loader from 'components/Common/Loader';
import TableContainer from 'components/Common/TableContainer';
import TableSkeleton from 'components/Common/TableSkeleton';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getActivities } from 'store/actions';

const AllActivity = ({ subUrl, translate, setCurrentPage, currentPage }) => {

    const dispatch = useDispatch();
    const authInfo = useSelector(state => state.authInfo?.data?.user);
    const activities = useSelector(state => state.GetActivities?.data?.data);
    const activitiesLoader = useSelector(state => state.GetActivities?.loading);

    const [sortBy, setSortBy] = useState(null);
    const dataPerPage = process.env.REACT_APP_TABLE_DATA_PER_PAGE;

    useEffect(() => {
        if (authInfo?.company_id) {
            const data = {};
            data['currentPage'] = currentPage;
            data['dataPerPage'] = dataPerPage;
            if (sortBy) data['sort'] = sortBy
            else data['sort'] = {
                value: -1,
                field: 'createdAt',
                type: 'date'
            }

            dispatch(getActivities(authInfo?.company_id, subUrl, data));
        };
    }, [authInfo, subUrl, currentPage, sortBy]);

    const columns = useMemo(
        () => [
            {
                Header: `${translate('sno')}`,
                Cell: cellProps => {
                    return (
                        <div>
                            <div>
                                <p className='m-0'>{((currentPage - 1) * dataPerPage) + (cellProps?.row?.index + 1)}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: `${translate('description')}`,
                Cell: cellProps => {
                    return (
                        <p className='m-0 text-lowercase'>{cellProps.row.original?.description}</p>
                    )
                },
            },
            {
                Header: `${translate('type')}`,
                customSort: true,
                enableSort: true,
                sortType: 'string',
                id: 'type',
                Cell: cellProps => {
                    return (
                        <div className='document-type'>
                            <p className={`file-status ${cellProps.row.original?.type?.toLowerCase()}`}>{cellProps.row.original?.type?.toLowerCase()}</p>
                        </div>
                    )
                }
            },
            {
                Header: `${translate('user')}`,
                accessor: 'userDetails.fullName',
                customSort: true,
                enableSort: true,
                sortType: 'string',
                subField: 'fullName',
                id: 'userDetails',
            },
            {
                Header: `${translate('dateAdded')}`,
                Cell: cellProps => {
                    return (
                        <div className='activity-added'>
                            {cellProps.row.original?.createdAt && <p>{moment(cellProps.row.original?.createdAt).format('LT')}<span> {moment(cellProps.row.original?.createdAt).format('LL')}</span></p>}
                        </div>
                    )
                },
                customSort: true,
                enableSort: true,
                sortType: 'date',
                id: 'createdAt',
            },
        ],
        [activities]
    );

    return (
        <div>
            {activitiesLoader === false && activities ? "" : <Loader />}
            <div className='activity-screen'>
                <TableContainer
                    columns={columns}
                    data={activities?.pageData || []}
                    isGlobalFilter={false}
                    // isAddOptions={true}
                    customPageSize={dataPerPage}
                    iSPageOptions={true}
                    customPagination={true}
                    className="activity-list"
                    totalCount={activities?.totalCount}
                    sortBy={sortBy}
                    setsortBy={setSortBy}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            </div>
        </div>
    );
};

export default AllActivity;