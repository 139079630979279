import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import arrowRight from '../../../assets/images/icons/arrow-right.svg';
import Skeleton from 'react-loading-skeleton';

const StatusCard = ({ data, loader }) => {
    const history = useHistory();

    //Page Routing
    const myDocPage = (tab) => {
        history.push({
            pathname: '/my-document',
            search: `?tab=${tab[0]?.toLowerCase()}`,
        });
    }

    return (
        <>
            <div className='status-group'>
                {loader && data ? <>
                    {[...Array(4)].map((item, index) => {
                        return <div className='card' key={index} >
                            <div className='call-to-action me-4'>
                                <Skeleton height={"35px"} width={'35px'} circle={'30px'} />
                            </div>
                            <h2><Skeleton height={"19px"} width={'80%'} /></h2>
                            <h1 className='mt-1'><Skeleton height={"48px"} width={'40%'} /></h1>
                        </div>
                    })} </> : <>
                    {data?.map((item, index) => {
                        const key = Object.keys(item)
                        const label = key[0].split(/(?=[A-Z])/)
                        label?.map((item, idx) => {
                            const s = item.replace(/\w+/g, function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); })
                            label[idx] = s
                        })
                        return <div className='card' key={index} onClick={() => {
                            myDocPage(key)
                        }}>
                            <div className='call-to-action'>
                                <img src={arrowRight} alt='right-arrow' />
                            </div>
                            <h2>{label.join(' ')}</h2>
                            <h1>{item[Object.keys(item)]}</h1>
                        </div>
                    })}</>}
            </div>
        </>
    );
};

export default StatusCard;