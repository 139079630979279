import React, { useEffect, useState } from 'react';
import { CardBody, Container, Progress } from 'reactstrap';

import ModelBasicInput from './Components/ModelBasicInput';
import InputFields from './Components/InputFields';
import TrainModel from './Components/TrainModel';

import tabSuccess from '../../assets/images/icons/active-check.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Publish from './Components/Publish';
import Breadcrumb from 'components/Common/Breadcrumb';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom';
import { withTranslation } from 'react-i18next';
import LoderModel from 'components/Common/Modals/loaderModel';
import { useSelector } from 'react-redux';

const CreateModel = (props) => {
    const { t } = props;
    const history = useHistory();
    const [selectedModel, setSelectedModel] = useState(0);
    const { id, tab } = useParams()

    const createModelLoading = useSelector(state => state?.CreateModal?.loading);

    const handleTabSelect = (index) => {
        setSelectedModel(index);
    };
    const afterFillChangeTab = (index) => {
        if (index < tab) {
            history.push(`/models/edit-model/${id}/${index}`)
        }
    }

    useEffect(() => {
        if (tab) setSelectedModel(Number(tab))
    }, [tab])

    return (
        <div>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Models" dashurl="/models" breadcrumbItem={!id ? `${t("createModels")}` : `${t('updateModel')}`} goBack={true} />
                        <div className="mt-5">
                            <div className="progress-tab">
                                <div className='tabs-group'>
                                    <div className={`tab-item ${selectedModel === 0 ? 'active-tab' : selectedModel >= 0 ? "success" : ""}`} onClick={() => { afterFillChangeTab(0) }}>
                                        <div className='tabcount'>
                                            <span>01</span>
                                            <img src={tabSuccess} alt='tab-success' />
                                        </div>
                                        <div className='pro-label'>
                                            {t('modelBasic')}
                                        </div>
                                    </div>
                                    <div className={`tab-item ${selectedModel === 1 ? 'active-tab' : selectedModel >= 1 ? "success" : ""}`} onClick={() => { afterFillChangeTab(1) }}>
                                        <div className='tabcount'>
                                            <span>02</span>
                                            <img src={tabSuccess} alt='tab-success' />
                                        </div>
                                        <div className='pro-label'>
                                            {t('inputFields')}
                                        </div>
                                    </div>
                                    <div className={`tab-item ${selectedModel === 2 ? 'active-tab' : selectedModel >= 2 ? "success" : ""}`} onClick={() => { afterFillChangeTab(2) }}>
                                        <div className='tabcount'>
                                            <span>03</span>
                                            <img src={tabSuccess} alt='tab-success' />
                                        </div>
                                        <div className='pro-label'>
                                            {t('trainModel')}
                                        </div>
                                    </div>
                                    {/* <div className={`tab-item ${selectedModel === 3 ? 'active-tab' : ''}`} >
                                        <div className='tabcount'>
                                            <span>04</span>
                                        </div>
                                        <div className='pro-label'>
                                            {t('publish')}
                                        </div>
                                    </div> */}
                                </div>
                                <Progress
                                    value={selectedModel === 0 && '35' || selectedModel === 1 && '65' || selectedModel === 2 && '100'}
                                    color="primary"
                                    style={{ height: '5px' }}
                                ></Progress>
                            </div>
                        </div>
                        <div>
                            {selectedModel === 0 && <ModelBasicInput handleTabSelect={handleTabSelect} id={id} translate={t} />}
                            {selectedModel === 1 && <InputFields handleTabSelect={handleTabSelect} setSelectedModel={setSelectedModel} id={id} translate={t} />}
                            {selectedModel === 2 && <TrainModel setSelectedModel={setSelectedModel} id={id} translate={t} />}
                            {/* {selectedModel === 3 && <Publish setSelectedModel={setSelectedModel} id={id} translate={t}/>} */}
                        </div>
                    </Container>
                    <LoderModel
                        modalTitle={id ? "Model Edit" : "Model creation"}
                        showModal={createModelLoading}
                        waitingMessage={id ? "Model is updating please wait" : "Creating model please wait"}
                    />
                </div>
            </React.Fragment>
        </div>
    );
};

export default withRouter(withTranslation()(CreateModel));
