import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useRef } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/Layout";
import NonAuthLayout from "./components/NonAuthLayout";
import { Toaster } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css';
import { getAdminRoles, getImagePath } from 'store/actions';
import AuthVerify from "./helpers/AuthVerify";
import { logoutUser } from "./store/actions"
import { useHistory } from "react-router-dom"
import UploadingItems from 'components/Common/UploadingItems';
import jwt_decode from "jwt-decode";
import { authInfoSuccess } from 'store/authInfo/action';
import axios from 'axios';
import { io } from 'socket.io-client';

const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_URL;
const SocketContext = createContext();
// Custom hook to use the socket
export const useSocket = () => useContext(SocketContext);

const App = props => {
  const socket = useRef(null);

  const dispatch = useDispatch();

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const obj = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    if (obj) {
      const imageKey = { imageKey: obj?.user?.profileImageKey }
      if (obj?.user?.profileImageKey) {
        dispatch(getImagePath(imageKey))
      }
      dispatch(getAdminRoles())
    }
  }, [obj?.user?.role])

  const Layout = getLayout();

  const history = useHistory();

  const logOut = () => {
    dispatch(logoutUser(history))
  };

  const accessToken = localStorage.getItem('jwt');

  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode(accessToken);
      dispatch(authInfoSuccess(decoded))
    }
  }, [accessToken])

  useEffect(() => {
    socket.current = io(SOCKET_SERVER_URL, {
      transports: ['websocket'], // Use WebSocket only
      reconnectionAttempts: 5,    // Number of reconnection attempts
      reconnectionDelay: 1000     // Delay between attempts
    });

    return () => {
      socket.current.disconnect();
    };
  }, [])

  return (
    <React.Fragment>
      <SocketContext.Provider value={socket.current}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                role={route.role}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
          {<AuthVerify logOut={logOut} />}
          <UploadingItems />
        </Router>
        <Toaster position="bottom-center" reverseOrder={false} toastOptions={{
          success: {
            iconTheme: {
              primary: '#fff',
              secondary: '#34c38f',
            },
            style: {
              background: '#34c38f',
              color: '#fff',
            },

          },
          error: {
            iconTheme: {
              primary: 'white',
              secondary: '#f46a6a',
            },
            style: {
              background: '#f46a6a',
              color: '#fff',
            },
          },
        }} />
      </SocketContext.Provider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
