import { PAGE_VIEWED, UPDATE_DATA_COUNT, UPDATE_MODEL_DATA_COUNT, UPLOAD_FILES, UPLOAD_FILES_SHOW_HIDE, UPLOAD_MODEL_FILES } from "./actionTypes"

export const uploadFiles = (data) => {
  return {
    type: UPLOAD_FILES,
    payload: data,
  }
}

export const uploadModelFiles = (data) => {
  return {
    type: UPLOAD_MODEL_FILES,
    payload: data,
  }
}

export const pageViewed = (data) => {
  return {
    type: PAGE_VIEWED,
    payload: data,
  }
}

export const uploadFilesShowHide = (data) => {
  return {
    type: UPLOAD_FILES_SHOW_HIDE,
    payload: data,
  }
}

export const updateDataCount = (data) => {
  return {
    type: UPDATE_DATA_COUNT,
    payload: data,
  }
}

export const updateModelDataCount = (data) => {
  return {
    type: UPDATE_MODEL_DATA_COUNT,
    payload: data,
  }
}
